import { Box, Button, Checkbox, makeStyles, Switch, TextField } from "@material-ui/core"
import { SectionRow } from "../components/section"
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import ChipInput from 'material-ui-chip-input'
import { useState, useEffect } from "react";
import StorefrontIcon from '@material-ui/icons/Storefront';
import { myMall, postDynamic } from "service/goods";
import ChooseCouponsDialog from "../components/chooseCoupons";
import EditorDialog from "../components/editorDialog";
import { Upload } from "../components/base";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '-webkit-fill-available',
    },
    articleArea: {
        width: '-webkit-fill-available',
        backgroundColor: 'white',
        borderRadius: 4,
        fontSize: 16,
        margin: theme.spacing(1),
    },
    bottom: {
        margin: theme.spacing(1),
    }
}))

const PublishArticle = (props) => {
    const classes = useStyles()
    const [tags, setTags] = useState([])
    const [store, setStore] = useState()
    const [showArticleDialog, setArticleDialog] = useState(false)
    const [showCouponDialog, setCouponsDialog] = useState(false)
    const [article, setArticle] = useState()
    const [coupon, setCoupon] = useState()
    const [title, setTitle] = useState()
    const [cover, setCover] = useState()

    const onAddTag = (tag) => {
        if (tags.length < 4) {
            setTags([...tags, tag])
        }
    }
    const onDeleteTag = (tag, index) => {
        const newTags = [...tags]
        newTags.splice(index, 1)
        setTags(newTags)
    }
    const onSave = (html) => {
        setArticle(html)
        setArticleDialog(false)
    }
    const onChoose = (coupon) => {
        setCoupon(coupon)
        setCouponsDialog(false)
    }
    useEffect(async () => {
        const { data } = await myMall()
        if (data) {
            setStore(data)
        }
    }, [])

    const publish = async () => {
        console.log('publish')
        const body = {
            textContent: title,
            html: article,
            tag: tags.join(','),
            type: 1,
            jsonType: 2,
            json: JSON.stringify(coupon),
            fileContent: cover,
        }
        console.log(body)
        const { data } = await postDynamic(body)
        console.log(data)
    }

    return <FormControl className={classes.root}>

        <SectionRow title="标题" arrow={false}>
            <TextField onChange={e => setTitle(e.target.value)} />
        </SectionRow>

        <Box marginLeft='8px'>
        <Upload onUpload={url => setCover(url)}>封面</Upload>
        </Box>
        {
            article ? <Box m={1}
                p={1}
                zIndex='9'
                maxHeight={280}
                overflow='scroll'
                top='0'
                border='solid 2px #aaa'
                borderRadius='8px'
                bgcolor='#dedede'
                dangerouslySetInnerHTML={{ __html: article }} /> : null
        }
        <Button className={classes.articleArea}
            onClick={() => setArticleDialog(true)}
        >
            点击编辑文章
        </Button>

        <SectionRow title="话题">
            <ChipInput
                placeholder="输入标签，按回车结束"
                value={tags}
                onAdd={onAddTag}
                onDelete={onDeleteTag}
            />

        </SectionRow>
        <SectionRow title="同步" arrow={false}>
            <FormControlLabel
                value="share"
                control={<Checkbox color="primary" />}
                label="悦分享"
            />
            <FormControlLabel
                value="moment"
                control={<Checkbox color="primary" />}
                label="动态"
            />
        </SectionRow>

        {
            store ? <>
                <Box m={1} display='flex' alignItems='center' fontWeight='500'>
                    <StorefrontIcon color='primary' />
                    <Box component='span' marginLeft={1}>店铺运营</Box>
                </Box>
                <SectionRow title="添加店铺优惠券" onClick={() => setCouponsDialog(true)}>
                    {coupon?.title}
                </SectionRow>
                <SectionRow title="关联店铺" arrow={false}>
                    {store?.storeName}
                    <Switch />
                </SectionRow>
            </> : null
        }

        <Button className={classes.bottom}
            onClick={publish}
            variant='contained' color='primary'>
            发布
        </Button>

        <EditorDialog
            open={showArticleDialog}
            onClose={() => setArticleDialog(false)}
            onFinish={onSave}
        />
        <ChooseCouponsDialog
            open={showCouponDialog}
            storeId={store?.id}
            onChoose={onChoose}
            onClose={() => setCouponsDialog(false)} />
    </FormControl >
}

export default PublishArticle