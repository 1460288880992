import { Box, ButtonBase, makeStyles } from "@material-ui/core";
import { setNavigationBar, useAppHistory } from '../utils/bridge';
import { NumberButton } from '../components/base';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';


const useStyles = makeStyles((theme) => ({
    card: {
        flex: 1,
        borderRadius: 8,
        padding: 12,
    },
    cardIcon: {
        width: 50,
        height: 50,
    },
    button: {
        backgroundColor: 'white',
        borderRadius: 8,
        padding: theme.spacing(1),
        margin: theme.spacing(1),
        display: 'flex',
        alignItems: 'center',
    }
}))
const MyLocationPage = (props) => {
    setNavigationBar({
        title: '我的位置'
    })
    const classes = useStyles()
    const history = useAppHistory()
    const goto = (path) => () => {
        history.push(path)
    }
    return (<Box>
        <Box height={12}></Box>
        <Box margin={1}
            padding={2}
            borderRadius={8}
            bgcolor='white'
            display='flex'
            flexDirection='row'>
            <NumberButton num={4} title="店铺关注" onClick={goto("/marked_shops")} />
            <NumberButton num={8} title="商品关注" onClick={goto("/marked_goods")} />
            <NumberButton num={212} title="优惠券" onClick={goto('/my_coupons')} />
        </Box>
        <Box p={1} display='flex' >
            <ButtonBase className={classes.card} style={{ backgroundColor: '#FD6C9422' }} onClick={goto("/me/timeline")}>
                <img
                    className={classes.cardIcon}
                    src={window.ossUrl + '/app/ic_time_base1212%402x.png'}/>
                <Box p={0.5} fontSize={15}>时间轴</Box>
            </ButtonBase>
            <Box width={12}></Box>
            <ButtonBase className={classes.card} style={{ backgroundColor: '#EA87FF22' }} onClick={goto("app://footprint")}>
                <img
                    className={classes.cardIcon}
                    src={window.ossUrl + '/app/ic_browse_records11111%402x.png'}/>
                <Box p={0.5} fontSize={15}>足迹</Box>
            </ButtonBase>
        </Box>
        <Box p={1} display='flex' >
            <ButtonBase className={classes.card} style={{ backgroundColor: '#328BF822' }} onClick={goto("app://funUser")} >
                <img
                    className={classes.cardIcon}
                    src={window.ossUrl + '/app/ic_friend121212%402x.png'}/>
                <Box p={0.5} fontSize={15}>趣友</Box>
            </ButtonBase>
            <Box width={12}></Box>
            <ButtonBase
                style={{ backgroundColor: '#5DFFA222' }}
                className={classes.card}
                onClick={goto('/my_orders')}>
                <img
                    className={classes.cardIcon}
                    src={window.ossUrl + '/app/ic_list12121%402x.png'}/>
                <Box p={0.5} fontSize={15}>订单</Box>
            </ButtonBase>
        </Box>

        {/*临时关闭入口<IconSectionRow title='出行' />*/}
        <IconSectionRow title='我的兑换' onClick={goto("/cards")} />
        <IconSectionRow title='今日待办' onClick={goto("/me/todo")} />

    </Box>)
}

export default MyLocationPage

function IconSectionRow({ title, onClick }) {
    const classes = useStyles()
    return <ButtonBase className={classes.button} style={{ width: 'calc(100% - 16px)' }} onClick={onClick}>
        <Box fontSize={15} p={1} flex='1' textAlign='left'>
            {title}
        </Box>
        <ArrowForwardIosIcon style={{ color: '#aaa', fontSize: 18 }} />
    </ButtonBase>

}
