
import React, { useEffect, useState } from 'react';
import { makeStyles, Box, List, ListItem } from '@material-ui/core'
import { useAppHistory, useNavigationBar } from "utils/bridge";
import { licenseList } from './interface.ts'

const useStyles = makeStyles({
  factory_store_row: {
    width: 'auto',
    background: '#f8f8f8',
    marginTop: '-23px',
    borderRadius: '3px',
    "&.MuiBox-root nav": {
      padding: '10px 0',
      borderRadius: '3px',
      marginBottom: '10px',
      background: '#fff'
    },
    "& ::-webkit-input-placeholder": {
      color: ' #BABABA',
      textAlign: 'right',
      padding: '0 6px',
      fontSize: '14px',
    },
    "& textarea": {
      boxSizing: 'border-box',
      maxWidth: '300px',
      padding: '6px 0',
      fontSize: '14px',
      marginBottom: '-8px',
      textAlign: 'right',
      color: '#3F3F3F',
      width: 0,
      minWidth: '130px',
      flexGrow: 1,
      border: 0,
      margin: 0,
      outline: 0,
      resize: 'none'
    }
  },
  btn: {
    position: 'fixed',
    bottom: '25px',
    width: '100%',
    maxWidth: '840px',
    "& button": {
      fontSize: 20,
      color: 'white',
      width: '100%',
      padding: 8,
      background: 'linear-gradient(90deg, #FDAE33, #FDC233)',
      borderRadius: '100px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    }
  },
  topBg: {
    color: '#fff',
    fontSize: '16px',
    padding: '22px 15px 42px 15px'
  }
})

export default function MyLicense(props) {
  const history = useAppHistory()
  const classes = useStyles()
  const [auth, setAuth] = useState([]) // 身份认证状态 state: null 去认证, false 去修改， true 去查看
  const [store, setStore] = useState([]) // 店铺认证 state: null 去认证, false 去修改， true 去查看

  useNavigationBar({
    title: "我的认证",
  })

  const authFn = async () => {
    const { data } = await licenseList(0, 0) // type 身份证 0， 店铺 1，服务 2， 车主 3
    if (data) {
      setAuth(data)
    }
  }
  const storeFn = async () => {
    const { data } = await licenseList(0, 1) // type 身份证 0， 店铺 1，服务 2， 车主 3
    if (data) {
      setStore(data)
    }
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    authFn()
    storeFn()
  }, [])

  const itemList = [
    { title: '身份证', img: 'https://202108251114.oss-cn-shanghai.aliyuncs.com/file/3180163592971625034194.000.png', status: auth?.[0]?.state, href: '/my_authentication' },
    { title: '店铺认证', img: 'https://202108251114.oss-cn-shanghai.aliyuncs.com/file/3613163592976056278576.000.png', status: store?.[0]?.state, href: '/store_certification' },
  ]
  useNavigationBar({
    title: '我的认证',
  })
  const statusText = status => status === false ? '去修改' : status === true ? '去查看' : '去认证'
  return (
    <div style={{ background: '#F8F8F8', height: '100%' }}>
      <Box className={classes.topBg} style={{ background: 'url(https://202108251114.oss-cn-shanghai.aliyuncs.com/file/38161635928322412110376.300.png)' }}>
        <text style={{ display: 'block' }}>尊敬的用户</text>
        <text>需要您完善下面的信息 </text>
      </Box>
      <Box sx={{ width: '100%', bgcolor: 'background.paper' }}
        mr={2} ml={2}
        classes={{ root: classes.factory_store_row }}>
        {itemList.map(item => (
          <nav aria-label="secondary mailbox folders">
            <List onClick={() => { history.push(`${item.href}`) }}>
              <ListItem disablePadding>
                <span style={{ flex: '1 1 auto' }}><img src={item.img} alt='' style={{ width: '25px', height: '25px', marginRight: '5px', verticalAlign: '-7px' }} />{item.title}</span>
                <span style={{
                  fontSize: '12px', marginRight: '5px', background: 'linear-gradient(45deg, rgba(253,174,51,1) 0%, rgba(253,194,51,1) 100%)', color: '#fff', padding: '4px 11px', borderRadius: '26px',
                }}>{statusText(item.status)}</span>
              </ListItem>
            </List>
          </nav>
        ))}

      </Box>
    </div >
  )
}

