import { Box, makeStyles, Button, Grid, Input, Checkbox } from '@material-ui/core'
import { useNavigationBar, useAppHistory, refreshPage } from "utils/bridge";
import { useState, useRef } from 'react'
import { UploadOnly, UploadVideo } from '../components/base';
import { produce } from 'immer'
import CloseIcon from '@material-ui/icons/Close';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import { postDynamic } from 'service/goods'
import qs from 'qs';
import { useForm, Controller } from "react-hook-form"
import InputAddress from '../components/inputAddress'

// 发布圈子动态
const useStyle = makeStyles(theme => ({

    inputPanel: {
        backgroundColor: 'white',
        marginTop: '16px',
        display: 'flex',
        flexDirection: 'column',
        padding: 12,
    },
    input: {
        border: 'none',
        outlineStyle: 'none',
        textAlign: 'right',
        fontSize: '14px',
    },
    mutliInput: {
        border: 'none',
        outlineStyle: 'none',
        fontSize: '14px',
    }
}))


const ReleaseSquarePage = ({ location }) => {
    const classes = useStyle()
    const { type } = qs.parse(location.search, { ignoreQueryPrefix: true })
    useNavigationBar({ title: '发圈子' })
    const inputImageRef = useRef(null);
    const [urls, setUrls] = useState([])

    const [chooseLocation, setChooseLocation] = useState(false)
    const [address, setAddress] = useState({})

    const onChooseVideo = (url) => {
        setUrls([url])
    }
    const onChooseImage = (url) => {
        setUrls(produce(draft => { draft.push(url) }));
    }
    const onDeleteImg = (index) => {
        setUrls(produce(draft => { draft.splice(index, 1) }))
    }
    const onChooseAddress = (address) => {
        console.log(address)
        if (address) {
            const location = address.location.split(',')
            const addr = {
                place: address.name,
                longitude: location[0],
                latitude: location[1]
            }
            setAddress(addr)
        }

        setChooseLocation(false)
    }

    const history = useAppHistory()
    const { register, control, handleSubmit, formState: { errors } } = useForm()

    const publish = async requestData => {
        const { syncShare, ...rest } = requestData
        const fileContent = urls.join(",")
        const body = {
            fileContent,
            mediaType: type === "image" ? 1 : 2,
            type: 0,
            ...address,
            ...rest
        }
        const { data } = await postDynamic(body)
        // 刷新flutter页面
        refreshPage("page_moment_list", "moment")
        history.pop()
    }

    return <form onSubmit={handleSubmit(publish)}>
        <Box display='flex' flexDirection='column' height='100%'>

            <Box className={classes.inputPanel} mx={2} borderRadius={6}>
                <textarea className={classes.mutliInput} name="Text1" rows="4"
                    {...register("textContent")}
                    placeholder="有趣的动态从这里发布..." />
            </Box>
            {
                type === 'image' ?
                    <Box mt={2} mx={2} display='flex' flexDirection='column'>
                        <Grid container spacing={1}>
                            {
                                urls?.map((url, index) => <Grid item xs={4} key={url}>
                                    <Box width='100%' height='110px'
                                        borderRadius={8}
                                        style={{ background: `url(${url}) center `, backgroundSize: 'cover' }}>

                                        <CloseIcon onClick={() => {
                                            onDeleteImg(index)
                                        }} style={{
                                            float: 'left', position: 'relative',
                                            top: '5px', right: '-65%'
                                        }} />
                                    </Box>
                                </Grid>)
                            }
                            {urls?.length < 9 && <Grid item xs={4} key={-1}>
                                <UploadOnly size={110} onUpload={onChooseImage} inputImageRef={inputImageRef} />
                            </Grid>
                            }
                        </Grid>
                    </Box>
                    :
                    <Box display="flex" flexDirection='column' mt mx={2}>
                        <div style={{ marginBottom: 8 }}>上传视频</div>
                        <UploadVideo height={180} onUpload={onChooseVideo} />
                    </Box>
            }

            <Box className={classes.inputPanel} mx={2} borderRadius={6}>
                <Controller
                    name="tag"
                    control={control}
                    defaultValue=""
                    render={({ field }) => <Input placeholder='输入标签' {...field} />}
                />
            </Box>

            <Box m={2} display='flex' alignItems='center' color='#999' justifyContent='start' clone>
                <Button onClick={() => {
                    setChooseLocation(true)
                }}>
                    <LocationOnIcon style={{ color: '#999' }} />
                    {address?.place ?? "选择地点"}
                </Button>
            </Box>
            <Box my={2} mx={2} display='flex' justifyContent="flex-end" height='50%' flexDirection='column'>
                <Button type="submit" variant='contained' color='primary' fullWidth style={{ borderRadius: '20px' }}>
                    <div style={{ color: 'white' }}>发布</div>
                </Button>
            </Box>
            <InputAddress
                visible={chooseLocation}
                onClose={() => setChooseLocation(false)}
                onChoose={onChooseAddress} />
        </Box>
    </form>
}


export default ReleaseSquarePage