import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardMedia from '@material-ui/core/CardMedia';
import { Box, ButtonBase, Grid, makeStyles, } from "@material-ui/core";
import { useAppHistory } from "utils/bridge";
import { priceCast } from '../utils/price'

const useStyles = makeStyles((theme) => ({
    titleBorder: {
        borderRadius: 4,
        borderColor: '#e9097a',
        border: '1px',
        borderStyle: 'solid',
    },
    itemRoot: {
        display: 'flex',
        width: '100%',
    },
    itemImage: {
        width: 100,
        height: 100,
        minWidth: 100,
        margin: 10,
        borderRadius: 10,
        display: 'inline',
        objectFit: 'cover',
    },
    itemContent: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        flexGrow: 1,
        padding: 12,
    },
    itemTag: {
        border: '1px solid',
        borderRadius: 4,
        fontSize: 9,
        color: theme.palette.secondary.main,
        padding: '1px 4px',
        marginRight: '4px',
    },
    itemTitle: {
        overflow: 'hidden',
        fontSize: 16,
        fontWeight: '500',
        textOverflow: 'ellipsis',
        wordWrap: 'break-word',
        lineHeight: '1.5rem',
        maxHeight: '3rem',
        textAlign: 'left',
    }
}))
export const GoodsGrid = ({ goodList }) => {
    return <Grid container spacing={1} style={{ padding: 8, }}>
        {
            // 创意为您推荐item  
            goodList?.map(item =>
                <Grid item xs={6} sm={4} md={3} key={item.id}>
                    <GoodsItem item={item} />
                </Grid>
            )
        }
    </Grid>
}

/**
 * 商品网格形式的item
 * @param {*} param0
 * @returns
 */
export const GoodsItem = ({ item }) => {
    const classes = useStyles()
    const history = useAppHistory()

    const gotoSearch = () => {
        history.push(`/goods/${item.id}`)
    }

    const variant = item.productVariants?.[0] || item.productVariants || item

    const img = variant?.thumb
    return <Card >
        <CardActionArea
            onClick={gotoSearch} >
            <CardMedia
                style={{ height: 200 }}
                image={img}
                title="Contemplative Reptile"
            />

            <Box minHeight={100} bgcolor='#fff' display='flex' flexDirection='column'>
                <Box pl={1} color='black' fontSize={16}>
                    {item.name}
                </Box>

                <Box display='flex' alignItems='center' pl={1} >
                    <Box display="flex" fontSize={13} alignItems='center' pr={2} mt={0.5} >
                        {
                            item.tag?.split(',')?.map(tag => (
                                <Box px={0.5} mr={1} className={classes.titleBorder}  >
                                    <span style={{ fontSize: 9, color: '#c90154' }}>{tag}</span>
                                </Box>
                            ))
                        }
                    </Box>
                </Box>
                <Box display='flex' alignItems='center' px={1} py={0.5} >
                    <span style={{ fontSize: 12, color: "#FF3150", marginTop: 3, }}>¥</span>
                    <span style={{ fontSize: 16, color: "#FF3150", }}>{variant.price / 100}</span>
                    <Box flex={1}></Box>
                    <span style={{ fontSize: 11, color: "#B1B1B1", }}>{variant.sellCount}</span>
                    <span style={{ fontSize: 11, color: "#B1B1B1", }}>人付款</span>
                </Box>

                <Box display='flex' alignItems='center' px={1} py={0.5} >
                    <span style={{ fontSize: 11, color: "#787878", }}>{item.storeName}</span>
                    <Box flex={1}></Box>
                    <span style={{ fontSize: 11, color: "#787878", }}>{item.area}</span>
                </Box>
            </Box>
        </CardActionArea>
    </Card>
}


/**
 * 商品的列表形式item
 * @param {*} param0 
 * @returns 
 */
export const GoodsListItem = ({ item, onClick }) => {
    const classes = useStyles()
    // debugger
    const history = useAppHistory()
    const variant = item.productVariants ?? item.productVariant
    const eventHandler = onClick ?? (() => {
        history.push('/goods/' + item.id)
    })
    return <Box className={classes.itemRoot} clone>
        <ButtonBase onClick={eventHandler}>
            <img className={classes.itemImage} src={variant?.thumb ?? item.thumb ?? item.img} ></img>
            <Box className={classes.itemContent}>
                <Box className={classes.itemTitle} color='primary'>{item.name}</Box>
                <Box>
                    {
                        item.tag?.split(",").map(i =>
                            <Box className={classes.itemTag} component="span">{i}</Box>
                        )
                    }
                </Box>
                <Box color='red' my={1} fontSize={18}>¥{priceCast(variant?.price??item.price)}</Box>
                <Box flexGrow={1}></Box>
                <Box width={1}>
                    <span style={{ float: 'left' }}>{item.storeName}</span>
                    <span style={{ float: 'right' }}>{item.area}</span>
                </Box>
            </Box>
        </ButtonBase>
    </Box>
}