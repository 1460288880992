import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import reducer from './reducers'
import { composeWithDevTools } from 'redux-devtools-extension'
import { loadState, saveState } from "../utils/localstorage";

const persistedState = loadState();

const middleware = [thunk]

const store = createStore(reducer, persistedState, composeWithDevTools(applyMiddleware(...middleware)))

store.subscribe(() => {
    saveState(store.getState())
})

export default store