import React, { useEffect, useState } from 'react'
import { Box, ButtonBase, makeStyles, Avatar } from "@material-ui/core"

import { setNavigationBar, useAppHistory } from 'utils/bridge'
import { ServiceTab, SearchBar } from './base'
import { groupbuyingList } from 'service/goods'

import { priceCast } from 'utils/price'
import { CountDown } from '../../components/base'

const useStyle = makeStyles(theme => ({
    deamandItem: {
        boxShadow: '0px 4px 12px 0px rgba(0, 0, 0, 0.05)',
        margin: '8px 16px',
        padding: theme.spacing(1),
        height: 130,
        minHeight: 130,
        backgroundColor: 'white',
        borderRadius: '4px',
        display: 'flex',
        flexDirection: 'row',
    },
    itemTag: {
        borderRadius: '4px',
        fontSize: '9px',
        color: '#2584F7',
        border: '1px solid #2584F7',
        padding: '2px 6px',
        marginTop: '2px',
    },
    itemTagContained: {
        color: 'white',
        padding: '2px 6px',
        borderRadius: '4px',
        fontSize: '9px',
        background: 'linear-gradient(-45deg, #5BA0F4, #2584F7)',
    },
    price: {
        color: '#e12',
        fontSize: '16px',
        fontWeight: 500,
        '&::before': {
            content: '"¥"',
            fontSize: '12px',
        }
    },
    originPrice: {
        color: '#888',
        fontSize: '14px',
        marginLeft: '4px',
        textDecoration: 'line-through',
        '&::before': {
            content: '"¥"',
            fontSize: '10px',
        }
    },
    buyButton: {
        float: 'right',
        background: '#f15a49',
        color: 'white',
        right: '0',
        padding: '6px 10px',
        borderRadius: '4px',
        fontSize: 14,
        boxShadow: '0 3px 5px 2px #f15a4943',
        '&::before': {
            content: '""',
            flex: 1,
            width: '20px',
        }
    }
}))

const GroupBuyingPage = (props) => {
    const [goodsList, setGoodsList] = useState([])
    const [category, setCategory] = useState(null)

    setNavigationBar({
        title: '限时团',
        darkBar: true,
        bgColor: '#2584F7',
    })
    const [currentIndex, setIndex] = useState(0)
    const onChoose = (i) => {
        setCategory(i)
    }
    useEffect(async () => {
        console.log('load some data')
        const { data } = await groupbuyingList(category?.id)
        if (data && data.data) {
            setGoodsList(data.data)
        }
    }, [category])

    return <Box display='flex' height={1} flexDirection='column'>
        <SearchBar />

        <ServiceTab onChange={onChoose} />
        <Box flex={1} overflow='scroll' display='flex' flexDirection='column' >
            {
                goodsList.map(item => <GroupbuyingItem key={item.id} {...item} />)
            }
        </Box>

    </Box>
}

const GroupbuyingItem = ({ price, actPrice, endTime, productName, thumb, productId }) => {
    const classes = useStyle()
    const history = useAppHistory()
    // getGoodsDetail
    // const timeText = currentHour === hour ? "正在抢购中" : ":00 开始抢购"
    const gotoDetail = () => {
        history.push('/goods/' + productId)
    }
    return <ButtonBase
        onClick={gotoDetail}
        className={classes.deamandItem}>
        <Box marginLeft={1}>
            <Avatar variant='rounded' style={{ width: 100, height: 100 }} src={thumb} />
        </Box>
        <Box fontSize={15} flex='1 0 0'
            textAlign='left' p={1}
            alignItems='flex-start'
            display='flex' flexDirection='column' height='100%'>
            <Box maxHeight='1.2rem'
                lineHeight='1.2rem'
                overflow='hidden'
                textOverflow='ellipsis'>
                {productName}
            </Box>
            <Box display='flex' alignItems='center'>
                <Box className={classes.itemTag} >类别</Box>
            </Box>
            <Box flex='1' />
            <Box display='flex' alignItems='flex-end' width='100%'>
                <Box>
                    <Box fontSize={13} >
                        <Box className={classes.price} component='span'>{priceCast(actPrice)}</Box>
                        <Box className={classes.originPrice} component='span'>{priceCast(price)}</Box>
                    </Box>
                    <Box style={{ float: 'right' }} className={classes.itemTagContained} component='span'>
                        距离结束: <CountDown endTime={new Date(endTime)} />
                    </Box>
                </Box>
                <Box flex="1" />
                <Box className={classes.buyButton}>去抢购</Box>
            </Box>
        </Box>
    </ButtonBase>
}

export default GroupBuyingPage