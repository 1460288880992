import { useEffect, useState, useMemo } from "react";
import { Box, ButtonBase, withStyles, Tabs, Tab, makeStyles } from "@material-ui/core"
import Search from '@material-ui/icons/Search'
import LocationIcon from '@material-ui/icons/LocationCity';
import ShopIcon from '@material-ui/icons/ShoppingCart';
import ArrowIcon from '@material-ui/icons/ArrowRight';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';

import TextTab from 'components/textTab'
import { useAppHistory } from "utils/bridge";
import { getCategoryByPid } from 'service/goods'
import { getJobCategory } from 'service/goods'


import { createTheme, ThemeProvider } from '@material-ui/core';
import { useHistory } from "react-router";

export const serviceTheme = createTheme({
    palette: {
        primary: {
            main: '#2584F7',
        }
    }
});

export const wrapBlueTheme = (Commpent) => props => {
    return <ThemeProvider theme={serviceTheme}>
        <Commpent {...props} />
    </ThemeProvider>
}

//投资专家发布按钮绿色主题
export const expertTheme = createTheme({
    palette: {
        primary: {
            main: '#62d385',
        }
    }
});

export const wrapGreenTheme = (Commpent) => props => {
    return <ThemeProvider theme={expertTheme}>
        <Commpent {...props} />
    </ThemeProvider>
}
// 此主题结束

//悦分享红色主题
export const expertYueTheme = createTheme({
    palette: {
        primary: {
            main: '#E9097A',
        }
    }
});

export const wrapYueTheme = (Commpent) => props => {
    return <ThemeProvider theme={expertYueTheme}>
        <Commpent {...props} />
    </ThemeProvider>
}
// 此主题结束


//橙黄色主题
export const expertOrangeTheme = createTheme({
    palette: {
        primary: {
            main: '#fdc133',
        }
    }
});

export const wrapOrangeTheme = (Commpent) => props => {
    return <ThemeProvider theme={expertOrangeTheme}>
        <Commpent {...props} />
    </ThemeProvider>
}
// 此主题结束
export const useStyles = makeStyles(theme => ({
    searchMallPanel1: {
        width: '100%',
        height: 70,
        background: theme.palette.primary.main,
        padding: '16px',
    },
    searchBar: {
        width: '100%',
        height: 44,
        backgroundColor: 'white',
        borderRadius: 22,
        textAlign: 'left',
        color: '#999',
        fontSize: '16px',
        paddingLeft: '16px',
        justifyContent: 'start'
    },
}))
export const styles = {
    searchPanel: {
        width: '100%',
        height: 70,
        background: 'linear-gradient(180deg, rgba(37,132,247,1) 0%, #7bb2f5 100%)',
        padding: '16px',
    },
    searchMallPanel: {
        width: '100%',
        height: 70,
        background: 'radial-gradient(circle, rgba(201,1,84,1) 0%, rgba(233,9,122,1) 100%)',
        padding: '16px',
    },

    searchBarSmall: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        borderRadius: '25px',
        backgroundColor: 'white',
        padding: '4px',
        width: 'calc(100% - 40px)'
    },
    searchButton: {
        background: 'linear-gradient(45deg, #2584F7 30%, #5BA0F4 90%)',
        borderRadius: 15,
        border: 0,
        color: 'white',
        height: 30,
        padding: '0 10px',
        boxShadow: '0 3px 5px 2px #5BA0F433',
        textAlign: 'center',
        lineHeight: '1.75rem',
        fontSize: '0.875rem',
    },
    searchHeader: {
        textAlign: 'center',
        height: 80,
        position: 'relative'
    },
    groupButton: {
        borderRadius: 4,
        backgroundColor: 'white',
        width: 'calc(50% - 10px)',
        textAlign: 'left',
    },
    groupBuyImg: {
        width: 55,
        height: 55,
    },
    groupBuyPrice: {
        color: '#e12',
        fontSize: '16px',
        '&::before': {
            content: '"¥"',
            fontSize: '12px',
        }
    },
    groupBuyOriginPrice: {
        color: '#888',
        fontSize: '14px',
        marginLeft: '4px',
        textDecoration: 'line-through',
        '&::before': {
            content: '"¥"',
            fontSize: '10px',
        }
    },
    cardButton: {
        backgroundColor: 'white',
        display: 'flex',
        flexGrow: 1,
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 4,
        width: '100%',
        fontSize: 18,
        fontWeight: 500,
    },
    halfCard: {
        //bgcolor='#F9FCFF' borderRadius='4px' p={2} width=
        borderRadius: 4,
        padding: '16px 0px 16px 16px',
        width: 'calc(50% - 10px)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'start',
    },
    itemTitle: {
        fontSize: 18,
        fontWeight: 500,
    },
}

export const SearchBar = (props) => {

    const classes = useStyles()
    return <Box style={styles.searchPanel}>
        <ButtonBase className={classes.searchBar}>
            <Search />
            搜索你想要的...
        </ButtonBase>
    </Box>
}

// 商城限时团搜索栏
export const MallSearchBar = (props) => {
    const history = useAppHistory()
    const classes = useStyles()
    return <Box className={classes.searchMallPanel1}>
        <ButtonBase className={classes.searchBar} onClick={() => { history.push('/service/search') }}>
            <Search />
            搜索你想要的...
        </ButtonBase>
    </Box>
}


export const SearchPanel = (props) => {
    const history = useAppHistory()
    const h5History = useHistory()
    const city = useMemo(() => JSON.parse(localStorage.getItem('city') ?? '{}'), [])

    return <div style={styles.searchHeader}>
        <div style={{
            width: '100%',
            height: '80px',
            position: 'absolute',
            backgroundImage: 'url('+window.ossUrl+'/app/mall_bg.png)',
            backgroundSize: '100% 80px'
        }}>
        </div>
        <Box display='flex' flexDirection='row' alignItems='center' p={2}>
            <Box display='flex' color='white' minWidth='80px' zIndex='10'
                onClick={() => h5History.push('/choose_city')}>
                <LocationIcon style={{ marginRight: "4px", }} />{city.name}</Box>
            <Box style={styles.searchBarSmall} clone>
                <ButtonBase onClick={() => { history.push('/service/search') }}>
                    <Search style={{ color: '#5BA0F4', marginLeft: '8px' }} />
                    <Box flex='1' color='grey.500' mx={1}>搜索服务</Box>
                    <Box variant='contained' style={styles.searchButton} >搜索</Box>
                </ButtonBase>
            </Box>
        </Box>
    </div>
}

export const GroupBuying = (props) => {
    const history = useAppHistory()
    return (<Box m={2} display='flex' flexDirection='row' height='156px'>
        <ButtonBase
            onClick={() => history.push('/service/groupbuying')}
            // onClick={() => history.push('/flash_sale')}
            style={styles.groupButton}>
            <Box bgcolor='white' width='100%' padding={2} borderRadius='4px' >
                <Box fontSize={16} fontWeight='500'>限时团</Box>
                <Box fontSize='14px' color='#888'>组团包月超实惠</Box>
                <Box display='flex' flexDirection='row' mt={1}>
                    <Box flexGrow={1}>
                        <img style={styles.groupBuyImg} src='https://202108251114.oss-cn-shanghai.aliyuncs.com/tmp/21e621b6c1fef0b4.jpg' />
                        <Box display='flex' flexDirection='row' >
                            <Box style={styles.groupBuyPrice}>22</Box>
                            <Box style={styles.groupBuyOriginPrice}>28</Box>
                        </Box>
                    </Box>
                    <Box>
                        <img style={styles.groupBuyImg} src='https://202108251114.oss-cn-shanghai.aliyuncs.com/tmp/a71d9012c8dfe368.jpg' />
                        <Box display='flex' flexDirection='row' >
                            <Box style={styles.groupBuyPrice}>22</Box>
                            <Box style={styles.groupBuyOriginPrice}>28</Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </ButtonBase>
        <Box width='16px' />
        <Box
            display='flex'
            flexDirection='column'
            style={styles.itemTitle}
            width='calc(50% - 10px)' >
            <ButtonBase style={styles.cardButton}
                onClick={() => history.push('/service/welfare')}>
                福利空间 <ArrowIcon />
            </ButtonBase>
            <Box height='16px' />
            <ButtonBase style={styles.cardButton}
                onClick={() => history.push('/service/comment')}>
                精选购推荐
                <ShopIcon style={{ color: '#2584F7', marginLeft: '8px' }} />
            </ButtonBase>
        </Box>
    </Box>
    )
}

export const DemandAndOnlineService = (props) => {
    const history = useAppHistory()
    return (
        <Box m={2} display='flex' flexDirection='row' height='126px'>
            <ButtonBase
                style={{
                    ...styles.halfCard,
                    backgroundColor: '#F9FCFF',
                    backgroundImage: "url('https://202108251114.oss-cn-shanghai.aliyuncs.com/app/img_xuqiuguangchang1%402x.png')",
                    backgroundSize: '100px 50px',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'bottom right'
                }}
                onClick={() => history.push('/service/demand')}
            >
                <Box style={styles.itemTitle} >需求广场</Box>
                <Box color='#aaa'>寻找你自己的需求</Box>
            </ButtonBase>
            <Box width='16px' />
            <ButtonBase
                style={{
                    ...styles.halfCard,
                    backgroundColor: '#FFFDF6',
                    backgroundImage: "url('https://202108251114.oss-cn-shanghai.aliyuncs.com/app/img_fuwudaren1%402x.png')",
                    backgroundSize: '80px 70px',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'bottom right'
                }}
                /*onClick={() => history.push('/service/forthwith')}*/
                onClick={() => history.push('/online')}
            >
                <div style={styles.itemTitle} >大咖讲座</div>
                <Box color='#aaa'>服务在你身边</Box>
            </ButtonBase>
        </Box>)
}

const goodsStyles = {
    itemTitle: {
        fontSize: 18,
        fontWeight: 500,
    },
    goodsItem: {
        margin: '8px',
    },
    goodsItemChip: {
        borderRadius: '9px',
        fontSize: '9px',
        color: '#2584F7',
        border: '1px solid #2584F7',
        padding: '2px 6px',
        marginTop: '2px',
    },
    goodsItemPrice: {
        color: '#e13',
        fontSize: '20px',
        marginTop: '10px',
        '&::before': {
            width: '20px',
            height: '20px',
            content: '"¥"',
            fontSize: '12px',
        }
    },
}
export const GoodsItem = (props) => {
    return <Card style={goodsStyles.goodsItem}>
        <CardActionArea>
            <CardMedia
                component='img'
                height='170'
                title='优质咖啡'
                image='https://202108251114.oss-cn-shanghai.aliyuncs.com/tmp/dad2437ff5ac959d.png'
            />
            <CardContent>
                <Box fontSize="16" fontWeight='400'>意大利原装进口咖啡粉，冷</Box>
                <Box style={goodsStyles.goodsItemChip} component='span'>
                    品质好
                </Box>
                <Box mt={1}>
                    <span className='price' style={goodsStyles.goodsItemPrice} >19.9</span>
                </Box>
            </CardContent>
        </CardActionArea>
    </Card>
}

/**
 * 商城限时团tab， 分装了请求一级分类的接口
 * @param {选tab响应事件} param0
 * @returns
 */
export const ServiceTab = ({ onChange, id = 11 }) => {
    const [currentIndex, setIndex] = useState(-1)
    const [tabs, setTabs] = useState([])
    const onChoose = (i) => {
        setIndex(i)
        onChange(tabs[i])
    }
    useEffect(async () => {
        const { data } = await getCategoryByPid(id)
        if (data) {
            const categoryList = data.map(i => i.category)
            setTabs(categoryList)
            setIndex(0)
            onChange(categoryList[0])
        }
    }, [])
    return <TextTab current={currentIndex} onChoose={onChoose} items={tabs} />
}

/**
 * 职业荟tab， 分装了请求一级分类的接口
 * @param {选tab响应事件} param0
 * @returns
 */
export const JobTab = ({ onChange }) => {
    const [currentIndex, setIndex] = useState(-1)
    const [tabs, setTabs] = useState([])
    const onChoose = (i) => {
        setIndex(i)
        onChange(tabs[i])
    }
    useEffect(async () => {
        const { data } = await getJobCategory()
        if (data) {
            setTabs(data)
            setIndex(0)
            onChange(data[0])
        }
    }, [])
    return <TextTab current={currentIndex} onChoose={onChoose} items={tabs} />
}


// 限时团tab开始=========================================
const StyledTabs = withStyles((theme) => ({
    root: {
        color: '#333',
        background: theme.palette.primary.main,
        position: 'sticky',
    },
    indicator: {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: 'transparent',
        '& > span': {
            maxWidth: 40,
            width: '100%',
            backgroundColor: 'white',
        },
    },
}))((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);


const StyledTab = withStyles((theme) => ({
    root: {
        textTransform: 'none',
        color: 'white',
        fontWeight: theme.typography.fontWeightRegular,
        fontSize: theme.typography.pxToRem(15),
        marginRight: theme.spacing(1),
        '&:focus': {
            color: 'white',
            opacity: 1,
        },
        '&:hover': {
            color: 'white',
            opacity: 1,
        },
        '&$selected': {
            color: 'white',
            fontWeight: theme.typography.fontWeightMedium,
        },

    },
}))((props) => <Tab disableRipple {...props} />);

// 限时团tab结束=========================================


/**
 * 商城限时团tab， 分装了请求一级分类的接口
 * @param {选tab响应事件} param0
 * @returns
 */
export const MallTab = ({ onChange,pid=10 }) => {
    const [tabs, setTabs] = useState([])
    const [value, setValue] = useState(0)


    const onChoose = (i, newValue) => {
        console.log(i, newValue)
        setValue(newValue);
        onChange(tabs[newValue])
    }
    useEffect(async () => {
        const { data } = await getCategoryByPid(pid)
        if (data) {
            const categoryList = data.map(i => i.category)
            const allTab = { id: pid, name: "全部" }
            setTabs([allTab, ...categoryList])
            onChange(allTab)
        }
    }, [pid])
    // return <TextTab current={currentIndex} onChoose={onChoose} items={tabs} />
    return <StyledTabs value={value} onChange={onChoose} variant={"scrollable"} >

        {
            tabs.map(item => <StyledTab label={item.name} {...item} />)
        }

    </StyledTabs>

}
