import { useState, useEffect, useCallback } from 'react'
import {
  Box, Card, Avatar, Grid, Divider,
  makeStyles, ButtonBase, RadioGroup, Radio, FormControlLabel
} from '@material-ui/core'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import InfoIcon from '@material-ui/icons/Info'
import { getCategoryByPid } from 'service/goods'
import ChooseListDialog from 'components/chooseListDialog'
import { useNavigationBar, useAppHistory, chooseLocation } from 'utils/bridge'
import { Upload } from "components/base"
import { useForm } from "react-hook-form"
import { joinStore } from './interface'
import { Toast } from 'antd-mobile'

const useStyles = makeStyles(() => ({
  row: {
    background: 'linear-gradient(90deg, #C90154, #E9097A)',
    height: '156px',
    borderBottomLeftRadius: '28px',
    borderBottomRightRadius: '28px',
    fontSize: 13,
    paddingTop: '1px',
    '& .MuiCard-root': {
      boxShadow: '0 0 5px rgb(0 0 0 / 5%) !important',
      paddingBottom: '80px !important'
    }
  },
  item: {
    borderBottom: '1px solid #DDDDDD',
    lineHeight: '30px',
    padding: '21px 0 8px 0',
    fontSize: '13px',
    '& .MuiGrid-grid-xs-4': {
      maxWidth: '28%'
    },
    '& input, input:focus': {
      border: 'none',
      outlineStyle: 'none',
    },
    '& .MuiInputBase-root': {
      fontSize: '13px'
    },
    '& .MuiFormGroup-root': {
      '& .MuiSvgIcon-root': {
        fontSize: '18px'
      },
      '& .MuiTypography-root': {
        fontSize: '13px'
      }
    },
    '& .MuiButtonBase-root': {
      margin: '0 !important',
      padding: '5px !important',
      fontSize: '13px !important',
    }
  },
  btn: {
    position: 'fixed',
    bottom: '25px',
    width: '94%',
    maxWidth: '819px',
    marginLeft: '-30px',
    "& button": {
      fontSize: 20,
      color: 'white',
      width: '100%',
      padding: 8,
      background: 'linear-gradient(90deg, #C90154, #E9097A)',
      borderRadius: '100px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    }
  }
}))

export default function ApplicationForPlatform() {

  const [categoryList, setCategoryList] = useState([])
  const [showDialog, setShowDialog] = useState(false)
  const [storeType, setStoreType] = useState('0')
  const [category, setCategory] = useState()
  const classes = useStyles()
  const history = useAppHistory()



  /**
   * 关闭经营范围类型弹窗并且更新所选项
   * @param {*} value 
   */
  const handleClose = (value) => {
    setShowDialog(false)
    if (value) {
      setCategory(value)
      setValue('category', value)
      clearErrors('category') // 清除错误提示
    }
  }


  /**
   * 表单
   */
  const { register, handleSubmit, formState: { errors }, setValue, watch, clearErrors } = useForm()
  const avatarUrl = watch("storeLogo", false);
  /**
   * 表单提交
   * @param {*} reqData 
   */
  const onSubmit = async reqData => {
    const { data } = await joinStore(reqData)
    if (data.code === "200") {
      history.push(`/upload_credentials/${data?.id}`)
    } else {
      Toast.show({
        icon: 'fail',
        content: "申请出错" + data.msg
      })
    }
  };


  /**
   * 店铺类型选择
   * @param {*} 
   */
  const storeTypeFn = (title, id) => {
    clearErrors('category') // 清除错误提示
    setValue('category', id)
    setStoreType(`${id}`)
  }
  const uploadFn = (value, url) => {
    setValue(value, url)
    clearErrors(value) // 清除错误提示
  }

  // 错误提示语
  const errorsHtml = (register, message) =>
    ((register && message) &&
      (<p style={{ textAlign: 'right', marginRight: '20px', color: '#ea4949', fontSize: '12px', margin: '0 0 0 93px' }}>
        <InfoIcon style={{ color: '#ea4949', fontSize: 14, verticalAlign: '-2px', marginRight: '3px' }} />
        {message}
      </p>))


  useNavigationBar({
    title: '入驻申请',
  })

  // 店铺类型文案
  const textStore = [
    { id: 1, title: '景点' },
    { id: 2, title: '工厂' },
    { id: 3, title: '餐馆' },
    { id: 0, title: '其他' },
  ]

  /**
   * 经营范围类型列表根据不同店铺类型获取
   * @param {*} id 
   */
  const categoryListFn = async (id) => {
    const pidMap = {
      0: 10,
      1: 15,
      2: 10,
      3: 10,
      10: 11
    }
    const { data: result } = await getCategoryByPid(pidMap[id])
    if (result) {
      const categoryList = result.map(i => i?.category)
      setCategoryList(categoryList)
    }
  }

  const callChooseAddress = () => {
    chooseLocation()
  }
  // 公司地址定位获取经纬度
  const onCompanyAddress = useCallback(({ latitude, longitude, address, name }) => {
    // 获得经纬度数据回写表单 
    setValue('latitude', latitude)
    setValue('longitude', longitude)
    setValue('storeAddress', address + ' ' + name, { shouldDirty: true })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
     // 调用原生获得经纬度或地址名称
    window.onChooseAddress = onCompanyAddress
    return () => {
      console.log('dispose 入驻申请 page');
      window.onChooseAddress = undefined
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Box className={classes.row}>
        <Card style={{ margin: '25px 15px', padding: '27px' }}>
          <Box style={{ textAlign: 'center', position: 'relative', marginBottom: '5px' }}>
          <Upload borderRadius={100} width={80} height={80}
              {...register("storeLogo", { required: "请上传店铺头像" })}
              onUpload={url => {
                uploadFn('storeLogo', url)
              }}>
            <Avatar style={{ width: '80px', height: '80px' }} src={avatarUrl} />
            <AddCircleIcon style={{
              color: '#E9097A',
              position: 'absolute',
              right: '-11px', bottom: 0
            }} />
            </Upload>
            <text style={{ fontSize: '13px', lineHeight: '36px' }}>店铺头像</text>
            {errorsHtml(errors?.storeLogo, errors?.storeLogo?.message)}
          </Box>


          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container className={classes.item}>
              <Grid xs={4}>
              店铺名称
              </Grid>
              <Grid xs={8}>
              <input type="text" {...register("storeName", { required: "请输入您的店铺名称" })} placeholder="请输入您的店铺名称" />
              </Grid>

              {errorsHtml(errors?.storeName, errors?.storeName?.message)}
            </Grid>

            <Grid container className={classes.item}>
              <Grid xs={4}>
              公司地址
              </Grid>
              <Grid xs={7}>
              <input style={{ width: '100%' }} type="text" {...register("storeAddress", { required: "请输入您的公司地址" })} placeholder="请输入您的公司地址" />
              </Grid>
              <Grid xs={1}>
              <LocationOnIcon />
              </Grid>

              {errorsHtml(errors?.storeAddress, errors?.storeAddress?.message)}
            </Grid>

            <Grid container className={classes.item}>
              <Grid xs={4}>
                店铺类型
            <Divider orientation="vertical" variant="middle" style={{ display: 'inline-block', height: '10px' }} />
              </Grid>
              <Grid xs={8}>
              {/** // 店铺分类  0;其他 1:景点 2:工厂 3:餐馆 4:非遗 */}

              <RadioGroup value={storeType} style={{ fontSize: '13px' }}
                row aria-label="gender" name="row-radio-buttons-group">
                  {textStore?.map(el => (
                    <FormControlLabel
                      {...register("category", { required: "请选择店铺类型" })}
                      value={`${el?.id}`} key={el?.id}
                      onClick={() => storeTypeFn(el?.title, el?.id)}
                      control={<Radio />} label={el?.title} />
                  ))}
                </RadioGroup>
              </Grid>

            {errorsHtml(errors?.category, errors?.category?.message)}
            </Grid>

            <Grid container className={classes.item}>
              <Grid xs={4}>
              联系电话
              </Grid>
              <Grid xs={8}>
              <input style={{ width: '100%', fontSize: '13px' }} type="text"
                {...register("storePhone", { required: "请输入联系电话" })} placeholder="请输入联系电话" />
              </Grid>

              {errorsHtml(errors?.storePhone, errors?.storePhone?.message)}
            </Grid>

            <Box style={{ padding: '15px' }} className={classes.btn} >
              <ButtonBase type="submit" >下一步</ButtonBase>
            </Box>
          </form>
        </Card>
        <ChooseListDialog
          open={showDialog}
          onClose={handleClose}
          options={categoryList}
          value={category}
      />
    </Box>
  )
}