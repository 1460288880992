import { requestX } from '../../utils/request'

// 获取精选清单列表
export async function selectList(pageNo: number = 0, pageSize: number = 40) {
  return requestX.get(`/bazaar/dynamic/getDynamic`, {
    params: {
      pageNo: pageNo,
      pageSize: pageSize,
      type: 5
    }
  })
}

// 点赞
export async function thumbsUp(bdId: any) {
  return requestX.post(`/bazaar/dynamic/${bdId}/thumb`)
}

// 取消点赞
export async function thumbsCancel(bdId: any) {
  return requestX.put(`/bazaar/dynamic/${bdId}/thumb`)
}

// 获取详情信息
export async function detail(bdId: any) {
  return requestX.get(`/bazaar/dynamic/${bdId}`)
}


// 添加精选清单列表
export async function postDynamic(content: any) {
  return requestX.post('/bazaar/choice', content)
}