import React from 'react'
import { Box, ListItem, ListItemAvatar, ListItemText, List, Button, makeStyles } from '@material-ui/core'
import useFetch from "../../utils/useFetch"
import { useNavigationBar, useAppHistory } from "utils/bridge"
import { StatusBox } from '../../components/empty'

const useStyles = makeStyles(() => ({
  listbtn: {
    '& .MuiListItemText-primary': {
      fontSize: '14px'
    },
    '& .MuiTypography-colorTextSecondary': {
      fontSize: '12px'
    },
    '& .MuiList-padding, .MuiButton-text': {
      padding: 0
    },
    '& .MuiButton-root': {
      width: '100%'
    }
  }
}))
export default function SelectAfterSaleType({ match }) {
  const { id } = match.params
  const classes = useStyles()
  const history = useAppHistory()


  /**
   * 页面标题导航
   */
  useNavigationBar({
    title: '选择售后类型',
  })

  const { loading, data, error } = useFetch('/mall/order/' + id)
  const { data: order } = data ?? {}
  return (
    <Box>
      <Box>
        <StatusBox loading={loading} error={error} empty={data}>
          {order?.orderItems?.map((item, index) => (
            <React.Fragment key={index}>
              <ListItem alignItems="flex-start" style={{ background: '#fff', marginBottom: '10px' }}>
                <ListItemAvatar>
                  <img width={93} height={93} style={{ marginRight: '10px' }} alt="Remy Sharp" src={item?.thumb} />
                </ListItemAvatar>
                <ListItemText
                  primary={

                    <Box style={{ marginTop: '5px' }}>
                      <text style={{ fontSize: '14px', color: '#000', lineHeight: '17px', display: 'block', marginBottom: '4px' }}>订单：{id}</text>
                      <Box
                        p={2}
                        overflow='hidden'
                        height={33}
                        style={{ fontSize: '13px', lineHeight: '16px', display: '-webkit-box', WebkitLineClamp: 2, lineClamp: 2, WebkitBoxOrient: 'vertical', overflow: 'hidden', padding: 0 }}
                      >
                        {item?.name}
                      </Box>
                      <Box style={{ fontSize: '12px', color: '#747474', lineHeight: '17px', display: 'block' }}>x {item?.amount}</Box>
                      <text style={{ fontSize: '14px', color: '#FF0000', lineHeight: '17px', display: 'block', marginTop: 0 }}>¥ {item?.price / 100}</text>
                    </Box>
                  }
                />
              </ListItem>
            </React.Fragment>
          ))}
        </StatusBox>
      </Box>
      <Box style={{ background: '#fff', marginBottom: '10px', padding: 0 }} className={classes.listbtn}>
        <List>
          <ListItem disablePadding>
            <Button component="a" onClick={() => history.push(`/apply_for_refund/${id}`)}>
              <ListItemText primary="我要退款" secondary="商品货物丢失，或者服务类型，到店消费的产品。" />
            </Button>
          </ListItem>
          <ListItem disablePadding>
            <Button component="a" onClick={() => history.push(`/apply_for_return_goods/${id}`)}>
              <ListItemText primary="我要退货" secondary="已经收到货了，需要自行寄回商品。" />
            </Button>
          </ListItem>
        </List>
      </Box>
    </Box>
  )
}