import { Avatar, Box, Tabs, Tab, Grid, ButtonBase } from '@material-ui/core'
import { ImageButton } from 'components/base'
import { useState, useEffect } from 'react'
import { useNavigationBar, useAppHistory } from 'utils/bridge'
import { wrapBlueTheme } from './base'
import { getCategoryByPid, shareList, pickCoupons } from 'service/goods'
import dateformat from 'dateformat'
import { useToast } from 'models/toast'

const WelfareSpacePage = (props) => {
    useNavigationBar({ title: '福利空间' })
    const history = useAppHistory()
    const [type, setType] = useState(0)
    const [tabs, setTabs] = useState([])
    const [list, setList] = useState([])

    useEffect(async () => {
        const { data } = await getCategoryByPid(11)
        if (data) {
            const categoryList = data.map(i => i.category)
            setTabs(categoryList)
        }
    }, [])

    useEffect(async () => {
        if (tabs.length > 0) {
            const category = tabs[type].id
            const { data } = await shareList({
                type: 2,
                subType: category,
            })
            if (data) {
                setList(data)
            }
        }
    }, [tabs, type])

    const imageStyle = { flex: 1 }
    return <Box bgcolor='white' height='100%'>
        <Box display='flex'>
            <ImageButton name="免费抢" style={imageStyle} size={80}
                onClick={() => history.push('/free_rob')}
                icon={window.ossUrl+'/app/ic_mianfeiqiang%402x.png'} />
            <ImageButton name="优惠券" style={imageStyle} size={80}
                onClick={() => history.push('/service/coupons')}
                icon={window.ossUrl+'/app/ic_youhuiquan%402x.png'} />
            <ImageButton name="服务项解读" style={imageStyle} size={80}
                onClick={() => history.push('/service/specifications')}
                icon={window.ossUrl+'/app/ic_fuwuxiangmujiedu%402x.png'} />
        </Box>
        <Box fontSize={18} fontWeight={600} px={2} marginTop={2} >近期福利</Box>

        <Tabs indicatorColor="primary" textColor="primary"
            value={type} onChange={(_, i) => setType(i)} variant='scrollable'>
            {
                tabs.map(item => <Tab key={item.id} label={item.name} />)
            }
        </Tabs>

        <Box height={8} bgcolor='#efefef' />
        {
            list.map(item => {
                if (item.html) {
                    return <WelfareArticleItem key={item.id} {...item} />
                } else if (item.textContent && item.fileContent) {
                    return <WelfarePictureItem key={item.id} {...item} />
                } else {
                    return <WelfareCouponsItem key={item.id} {...item} />
                }
            })
        }
    </Box>
}

const StoreInfo = ({ storeLogo, storeName }) => {
    return <Box display='flex' alignItems='center' mt={1} fontSize='14px' color='#444'>
        <Avatar variant='rounded' src={storeLogo} style={{ width: 24, height: 24, marginRight: 8 }} />
        {storeName}
        <img src='https://202108251114.oss-cn-shanghai.aliyuncs.com/app/ic_coupons_1.png'
            style={{ width: 24, height: 24, marginLeft: 8 }} />
    </Box>
}

const WelfareArticleItem = (props) => {
    const { id, textContent, fileContent, json } = props
    const store = JSON.parse(json)
    const picList = fileContent.split(',')
    const history = useAppHistory()

    return <div>
        <ButtonBase style={{ textAlign: 'left', width: '100%' }}
            onClick={() => history.push('/service/welfare/' + id)}>
            <Box p={2} display='flex' alignItems='center' width='100%'>
                <Box flex='1' >
                    <Box color='#222' fontSize='16px' marginRight={1}>
                        {textContent}
                    </Box>
                    <StoreInfo {...store} />
                </Box>
                <img src={picList[0]} style={{ width: 120, height: 98, objectFit: 'cover', borderRadius: 8 }} />
            </Box>
        </ButtonBase>
        <Box mx={2} height='1px' bgcolor='#eee' />
    </div>
}


export const WelfarePictureItem = (props) => {
    const { id, textContent, fileContent, json } = props
    const store = JSON.parse(json)
    const picList = fileContent.split(',')
    const history = useAppHistory()
    return <div>
        <ButtonBase
            onClick={() => history.push('/service/welfare_pic/' + id)}
            style={{ padding: '16px' }}>
            <Box color='#222' fontSize='16px' textAlign='left'>
                {textContent}
                <Box height={8} />
                <Grid container spacing={1} >
                    {
                        // list all of picList
                        picList.map(item =>
                            <Grid item key={item} xs={4}>
                                <img src={item} style={{
                                    background: 'grey',
                                    height: 110,
                                    width: '100%',
                                    borderRadius: '4px',
                                    objectFit: 'cover'
                                }} />
                            </Grid>
                        )
                    }
                </Grid>
                <StoreInfo {...store} />
            </Box>
        </ButtonBase>
        <Box mx={2} height='1px' bgcolor='#eee' />
    </div>
}

export const WelfareCouponsItem = (props) => {
    const { json } = props;
    const store = JSON.parse(json)
    return <CouponsItem coupon={store} />
}

export const CouponsItem = ({ coupon }) => {

    const [available, setAvailable] = useState(true)
    const toast = useToast()
    const history = useAppHistory()
    const time = dateformat(new Date(coupon.startTime), 'yyyy.mm.dd')
        + '-' + dateformat(new Date(coupon.endTime), 'yyyy.mm.dd')

    const drawCoupons = async () => {
        if (available) {
            try {
                const { code } = await pickCoupons(coupon.id)
                if (code === '200') {
                    toast.showToast('toast.success', '领取成功')
                    setAvailable(false)
                }
            } catch (err) {
                if (err.response) {
                    console.error(err.response)
                    const { code, msg } = err.response.data
                    if (code === "1226") {
                        history.push('/shop/' + coupon.id)
                    } else {
                        toast.showToast('toast.error', msg)
                        setAvailable(false)
                    }
                }
            }
        } else {
            history.push('/shop/' + coupon.id)
        }
    }
    return <div>
        <Box p={2} height={130} bgcolor='white'>
            <ButtonBase
                onClick={drawCoupons}
                style={{
                    width: '100%',
                    backgroundImage: 'url('+window.ossUrl+'/app/ic_youhuiquan1111%402x.png)',
                    height: '100%',
                    backgroundSize: '100% 100%',
                    color: 'white',
                    display: 'flex',
                    textAlign: 'left',
                    opacity: available ? '1' : '0.5'
                }}>
                <Box width='70%' p={1}>
                    <Box display='flex'>
                        {/* <Avatar variant='rounded' src={store.storeLogo} /> */}
                        <div style={{
                            marginLeft: 8, fontSize: 16,
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis'
                        }}>
                            <div >{coupon?.productName}</div>
                            {coupon?.title}
                        </div>
                    </Box>
                    <div style={{
                        marginTop: 8,
                        fontSize: '12px',
                        borderRadius: 4,
                        border: '1px solid',
                        width: 'fit-content',
                        padding: '3px 10px'
                    }}>
                        {time}
                    </div>
                </Box>
                <Box width='30%' color='primary.main' textAlign='center'>
                    {available ? '领取' : '已领取'}
                </Box>
            </ButtonBase>
        </Box>
        <Box mx={2} height='1px' bgcolor='#eee' />
    </div>
}

export default wrapBlueTheme(WelfareSpacePage)