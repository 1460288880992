import { Box, Button, IconButton, makeStyles } from '@material-ui/core'
import { useState } from 'react'
import MenuIcon from '@material-ui/icons/Menu';

const useStyle = makeStyles(theme => ({
    root: {
        position: 'relative',
        top: 0,
        height: 52,
        zIndex: 10,
    },
    container: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        background: 'white',
        padding: '6px 0px',
    },
    normal: {
        border: '1px solid #F5F5F5',
        height: 30,
        borderRadius: 15,
        background: '#F5F5F5',
        color: '#999',
        fontSize: 12,
        margin: '4px 8px',
        flexShrink: 0,
    },
    selected: {
        border: '1px solid ' + theme.palette.primary.main,
        height: 30,
        borderRadius: 15,
        color: theme.palette.primary.main,
        background: 'white',
        fontSize: 12,
        margin: '4px 8px',
        flexShrink: 0,
        '&:hover': {
            background: 'white',
        }
    },
    menu: {
        height: 36,
        position: 'absolute',
        right: 0,
        background: 'white',
        borderRadius: 0,
        '&:hover': {
            background: 'white',
        }
    },
    morePanel: {
        position: 'absolute',
        top: 50,
        background: 'white',
        padding: '6px',
    }
}))

type Item = {
    id: number,
    name: string
}

type ChipTabProp = {
    selected: Item,
    items: Item[],
    onChange: (item: Item) => void,
}

const ChipTab: React.FC<ChipTabProp> = ({ items, selected, onChange }) => {
    const [openDialog, setOpenDialog] = useState(false)
    const classes = useStyle()
    const displayList = items

    console.info("display list empty", displayList, selected)
    if (!displayList || !selected) {
        return null
    }
    const onSelected = (e: Item) => {
        if (selected.id !== e.id) {
            onChange(e)
        }
    }
    const containerClip = openDialog ? {} : { maxHeight: 47, overflow: 'hidden' }
    return <div className={classes.root}>
        <div className={classes.container} style={containerClip}>
            {
                displayList.map(i => {
                    if (!i || !selected) {
                        return null
                    }
                    const style = i.id === selected.id ? classes.selected : classes.normal
                    return <Button className={style}
                        key={i.id}
                        title={i.name}
                        onClick={() => onSelected(i)}
                    >
                        {i.name}
                    </Button>
                })
            }
            <IconButton className={classes.menu}
                onClick={() => setOpenDialog(!openDialog)}
            >
                <MenuIcon />
            </IconButton>
        </div>
    </div>
}

export default ChipTab