import {
    submitOrder,
    loadOrders as loadOrdersApi,
    updateOrder,
    callAliPay,
    updateOrderSingle, submitOrderSingle
} from "../service/goods"
import produce from 'immer'
import { callPay } from "../utils/bridge"
import { isInIOSContainer, isInAndroidContainer } from "../utils/webkit"

const initalState = {
    loading: true,
    orderList: [],
    orderCount: [0, 0, 0, 0],
    order: {},
    preOrder: null,
}

const Types = Object.freeze({
    PreOrder: 'order.place',
    ListOrder: 'order.list',
    OrderCount: 'order.count',
    CreateOrder: 'order.create',
    SubmitOrder: 'order.submit'
})

export const orderReducer = produce((draft, action) => {
    switch (action.type) {
        case Types.ListOrder:
            draft.loading = false
            draft.orderList = action.payload
            break
        case Types.PreOrder:
            // draft[type === 'cart' ? 'orderCart' : 'orderProduct'] = action.payload
            draft.preOrder = action.payload
            draft.order = null
            break
        case Types.SubmitOrder:
            draft.order = action.payload

            break
        case Types.CreateOrder:
            draft.order = action.payload
            break
    }
}, initalState)

/**
 * 查询订单列表, state和shipped.只查询一个
 * @param {*} state 0 未支付, 1 已支付, 2 已退款, 3 支付错误, 4 已关闭/已完成,
 * @param {*} shipped 0 未发货， 1 已发货
 * @param {*} page
 * @returns
 */
const loadOrdersAction = (state, shipped, page) => async dispatch => {
    try {
        const res = await loadOrdersApi({ shipped: shipped, state: state }, page)
        console.info(res.data)
        dispatch({
            type: Types.ListOrder,
            payload: [...res.data]
        })
    } catch (e) {
        console.error('加载订单列表出错 error. ' + e)
    }
}

/**
 * 修改了地址或者优惠券后更新订单
 * @param {type} items 订单或者购物车
 * @returns 
 */
const updateOrderAction = ({ type, orderCart, orderProduct }, couponId) => async dispatch => {
    if (type === 'cart') {
        const cartItemList = orderCart.map(i => ({ cartItemId: i.id }))
        const resp = await updateOrder(cartItemList, couponId)
        dispatch({
            type: Types.CreateOrder,
            payload: resp.data
        })
    } else {
        const resp = await updateOrderSingle({ amount: orderProduct.amount, productVariantId: orderProduct.id, buyerId: couponId })
        dispatch({
            type: Types.CreateOrder,
            payload: resp.data
        })
    }
}

const submitDirectOrderAction = (product) => async dispatch => {
    try {
        const { data: order } = await submitOrderSingle(product)
        if (isInIOSContainer || isInAndroidContainer) {
            // payChannel: 0 微信 , 1 支付宝
            callPay({ id: order.id, price: order.totalPrice, payChannel: product.payChannel })
        } else {
            // pc网页
            const { data } = await callAliPay(order.id, order.totalPrice)
            console.log('order sign:', data);
            window.location.href = data.sign
        }
        dispatch({
            type: Types.SubmitOrder,
            payload: order
        })
    } catch (e) {
        dispatch({
            type: Types.SubmitOrder,
            payload: e
        })
    }
}

const submitOrderAction = (data) => async dispatch => {
    try {
        const { data: order } = await submitOrder(data)
        if (isInIOSContainer || isInAndroidContainer) {
            // payChannel: 0 微信 , 1 支付宝
            callPay({ id: order.id, price: order.totalPrice, payChannel: data.payChannel })
        } else {
            // pc网页
            const { data } = await callAliPay(order.id, order.totalPrice)
            console.log('order sign:', data);
            window.location.href = data.sign
        }
        dispatch({
            type: Types.SubmitOrder,
            payload: order
        })
    } catch (e) {
        dispatch({
            type: Types.SubmitOrder,
            payload: e
        })
    }
}

/**
 * 预下单，进入创建订单页面
 * @param {Product} item 商品/购物城item
 * @param type cart/product 区分是购物城还是商品直接结算的
 * @returns 
 */
export const preOrderAction = (type, item) => ({
    type: Types.PreOrder,
    payload: {
        type: type,
        [type === 'cart' ? 'orderCart' : 'orderProduct']: item
    }
})

export default {
    loadOrdersAction,
    submitOrderAction,
    submitDirectOrderAction,
    updateOrderAction,
}