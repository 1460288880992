import { requestX } from '../../utils/request'

// 申请退款退货
export async function applyReturnRefund(params: any) {
  return requestX.post(`/mall/order/post/sale/0`, {
    ...params
  })
}

// 退货退款信息
export async function refundInfo(orderId: any) {
  return requestX.post(`/mall/order/post/sale/${orderId}`)
}

// 发物流，填写快递单号
export async function listLogisticsFn(params: any) {
  return requestX.post(`/mall/order/post/sale/2`, {
    ...params
  })
}


// 售后单列表
export async function afterSaleList(params: any, pageNo: number = 0, pageSize: number = 40) {
  return requestX.get(`/mall/order/post/sale`, {
    params: {
      pageNo,
      pageSize,
      ...params,
    }
  })
}


// 购物评价
export async function shpppingComment(params: any) {
  return requestX.post(`/mall/comment `, {
    ...params
  })
}