import ServiceMallPage from './serviceMall'
import ServiceCategoryPage from './serviceCategory'
import ServiceSubMallPage from './serviceSubMall'
import DemandPage from './demand'
import ServiceForthwithPage from './serviceForthwith'
import GroupBuyingPage from './groupBuy'
import RecommendCommentPage from './comment'
import PublishDemandPage from './publishDemand'
import CommentDetailPage from './commentDetail'
import WelfarePage from './welfareSpace'
import WelfareArticlePage from './welfareArticle'
import WelfarePicturePage from './welfarePicture'
import CouponListPage from './couponList'
import SpecificationListPage from './document'
import MyServicePage from './myService'
import MyServiceShopInfo from './myServiceInfo'
import ShopOrderList from './shopOrder'
import FundPage from './fund'
import ShopClubPage from './shopClub'

import { wrapBlueTheme } from './base'
import { SearchPage } from './search'
import { ServiceList } from './serviceList'
import {VideoSearchPage} from "./videoSearch";

export default {
    SearchPage,
    VideoSearchPage,
    ServiceList,
    ServiceMallPage,
    ServiceCategoryPage,
    ServiceSubMallPage,
    DemandPage: wrapBlueTheme(DemandPage),
    ServiceForthwithPage,
    GroupBuyingPage,
    RecommendCommentPage,
    PublishDemandPage,
    CommentDetailPage,
    WelfarePage,
    WelfareArticlePage,
    WelfarePicturePage,
    CouponListPage,
    SpecificationListPage,
    MyServicePage,
    MyServiceShopInfo,
    ShopOrderList,
    FundPage,
    ShopClubPage,
}