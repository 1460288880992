import { Box, Button, IconButton, makeStyles } from "@material-ui/core";
import { Edit } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { StatusBox } from '../components/empty';
import { setNavigationBar, useAppHistory, useNavigationBar } from "../utils/bridge";
import { connect } from "react-redux";
import * as AddressActions from '../models/address'
import AddressEdit from "./addressEdit";

const useStyle = makeStyles((theme) => ({
    buttomButton: {
        borderRadius: 20,
        position: 'fixed',
        bottom: theme.spacing(4),
        left: theme.spacing(1),
        right: theme.spacing(1),
        width: 'calc(100% - 16px)'
    },
    addressItem: {
        background: 'white',
        borderRadius: 6,
        display: 'flex',
        flexDirection: 'row',
        padding: theme.spacing(1),
        marginTop: theme.spacing(2),
    },
    itemEditButton: {
        alignSelf: 'start'
    }
}))

const AddressItem = (props) => {
    const classes = useStyle()
    const {
        address,
        area,
        city,
        province,
        name,
        phone,
    } = props.item
    return (
        <Box className={classes.addressItem}>
            <Box flex={1} p={1}>
                <Box fontSize={12} color='#999'>
                    {province} {city} {area}
                </Box>
                <Box fontSize={18} lineHeight='1.5rem' my={1}>
                    {address}
                </Box>
                <Box fontSize={12} color='#999'>
                    {name} {phone}
                </Box>
            </Box>
            <IconButton className={classes.itemEditButton} onClick={props.onClick}>
                <Edit />
            </IconButton>
        </Box>)
}

const AddressListPage = ({ allAddress, getAllAddress }) => {
    const classes = useStyle()
    const [showEdit, setShowEdit] = useState()
    /// 保存待编辑的地址
    const [pendingEdit, setPendingEdit] = useState()
    useNavigationBar({
            bgColor: '#FFFFFF',
            title: '地址管理',
            darkBar: false,
    })
    useEffect(() => {
        getAllAddress()
    }, [])

    const onAddEvent = () => {
        setPendingEdit(null)
        setShowEdit(true)
    }
    const onCreateAddress = () => {
        setShowEdit(false)
    }

    return (<Box height={1}>
        <StatusBox
            error={false}
            emptyTitle="还没有收货地址，快去添加一个吧"
            empty={!allAddress} >
            <Box p={2}>
                {

                    allAddress.map(item => <AddressItem key={item.id} item={item} onClick={() => {
                        setPendingEdit(item)
                        setShowEdit(true)
                    }} />)
                }
            </Box>
        </StatusBox>
        <Button variant='contained'
            className={classes.buttomButton}
            color='primary'
            onClick={onAddEvent} >添加收货地址</Button>
        <AddressEdit show={showEdit} onClose={onCreateAddress} item={pendingEdit} />
    </Box>)
}

const propsMap = ({ address: { allAddress } }) => ({ allAddress })

export default connect(propsMap, AddressActions)(AddressListPage)