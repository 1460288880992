import { useState, useEffect } from "react";
import { AxiosRequestConfig } from "axios";
import request from './request'

// custom hook for performing GET request
/**
 * @deprecated 使用代替https://ahooks.js.org/zh-CN/hooks/async代替
 */
const useFetch = (url: string, config?: AxiosRequestConfig) => {
    const [data, setData] = useState();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false)
    useEffect(() => {
        const copyConfig = { ...config, url: url }
        const fetchData = async function () {
            try {
                setLoading(true);
                const response = await request.request(copyConfig);
                if (response.status === 200) {
                    setData(response.data);
                }
            } catch (error) {
                console.log(error)
                setError(true)
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, [url]);
    return { loading, error, data };
};

export default useFetch;