
import React, { useState, useEffect } from 'react';
import { makeStyles, Box, List, ListItem, TextareaAutosize, ButtonBase, Grid } from '@material-ui/core'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import InfoIcon from '@material-ui/icons/Info'
import { useAppHistory, useNavigationBar } from "utils/bridge";
import { Upload } from '../../components/base';
import { useForm } from "react-hook-form";
import { businessLicense, businessLicenseModify, licenseList } from './interface.ts';
import { useToast } from "../../models/toast";
import qs from 'qs'

const useStyles = makeStyles({
  titleIcon: {
    width: '6px',
    height: '20px',
    verticalAlign: '-3px',
    marginRight: 5,
    display: 'inline-block',
    background: 'linear-gradient(0deg, #FDAE33, #FDC233)'
  },
  business_license: {
    "&.MuiBox-root nav": {
      background: '#F8F8F8',
      fontSize: '15px'
    },
    "& ::-webkit-input-placeholder": {
      color: ' #BABABA',
      textAlign: 'right',
      padding: '0 6px',
      fontSize: '14px',
    },
    "& textarea": {
      boxSizing: 'border-box',
      maxWidth: '300px',
      padding: '6px 0',
      fontSize: '14px',
      marginBottom: '-8px',
      textAlign: 'right',
      color: '#3F3F3F',
      width: 0,
      minWidth: '130px',
      flexGrow: 1,
      border: 0,
      margin: 0,
      outline: 0,
      resize: 'none',
      background: '#F8F8F8'
    }
  },
  input: {
    border: 'none',
    outlineStyle: 'none',
    textAlign: 'right',
    fontSize: '14px',
    minWidth: '280px',
    background: '#F8F8F8'
  },
  uploadBtn: {
    background: '#FDBA33',
    color: '#fff',
    fontSize: '14px',
    borderRadius: '5px',
    padding: '5px 90px',
    margin: '10px'
  },
  btn: {
    position: 'fixed',
    bottom: '25px',
    width: '100%',
    maxWidth: '840px',
    "& button": {
      fontSize: 20,
      color: 'white',
      width: '100%',
      padding: 8,
      background: 'linear-gradient(90deg, #FDAE33, #FDC233)',
      borderRadius: '100px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    }
  },
  btnGray: {
    "& button": {
      background: '#ddd',
    }
  },
})

export default function BusinessLicense(props) {
  const query = qs.parse(window.location.search, { ignoreQueryPrefix: true })
  const { current_page, status } = query //  status: null 去认证, false 去修改， true 去查看
  const toast = useToast()
  const [businessList, setBusinessList] = useState({})
  const [detail, setDetail] = useState({})
  const [statusValue, setStatusValue] = useState(`${status}`)
  const [currentPage, setCurrentPage] = useState(current_page ? Number(current_page) : 1) // 默认1为营业执照认证页，0为身份认证页
  const history = useAppHistory()
  const classes = useStyles()
  const { register, setValue, handleSubmit, formState: { errors }, clearErrors } = useForm()
  const onSubmit = async reqData => {
    const { data } =
      statusValue === 'false' //  status: null 去认证, false 去修改， true 去查看
        ? await businessLicenseModify({ ...reqData, type: 1, udid: businessList?.ud_id, id: businessList?.id })
        : await businessLicense({ ...reqData, type: 1, udid: businessList?.ud_id, id: businessList?.id })
    if (data) {
      history.pop()
    }
  };
  const onSubmitLicense = async reqData => {
    const { data } =
      statusValue === 'false' //  status: null 去认证, false 去修改， true 去查看
        ? await businessLicenseModify({ ...reqData, type: 1, udid: businessList?.ud_id, id: businessList?.id })
        : await businessLicense({ ...reqData, type: 1, udid: businessList?.ud_id, id: businessList?.id })
    if (data) {
      setCurrentPage(1) // 法人身份认证返回营业执照认证页
      setValue('corporateCard', 1) // 表示法人身份证已提交mark
      toast.showToast("toast.success", "操作成功")
    }
  };

  // 错误提示语
  const errorsHtml = (register, message) =>
    ((register && message) &&
      (<p style={{ textAlign: 'right', marginRight: '20px', color: '#ea4949', fontSize: '12px' }}>
        <InfoIcon style={{ color: '#ea4949', fontSize: 14, verticalAlign: '-2px', marginRight: '3px' }} />
        {message}
      </p>))

  const rulesList = [
    {
      img: '/file/6626163575929571541634.600.png',
      icon: '/file/09571635759033591568218.200.png',
      text: '标准'
    },
    {
      img: '/file/91551635759199966105488.000.png',
      icon: '/file/65261635759219640125161.500.png',
      text: '边框缺失'
    },
    {
      img: '/file/99031635758996946531566.000.png',
      icon: '/file/65261635759219640125161.500.png',
      text: '照片模糊'
    },
    {
      img: '/file/3999163575932398269897.900.png',
      icon: '/file/65261635759219640125161.500.png',
      text: '闪光强烈'
    }
  ]
  const uploadFn = (value, url) => {
    setValue(value, url)
    clearErrors(value) // 清除错误提示
  }
  // 获取营业执照初始数据
  const businessFn = async () => {
    const { data } = await licenseList(0, 1)
    if (data) {
      setDetail(JSON.parse(data?.[0]?.detail))
      setBusinessList(data?.[0])
      const { companyInfo, companyName, companyCode, companyPhone, legalFront, legalBack } = JSON.parse(data?.[0]?.detail) || {}

      // 初始化初始数据
      setValue('companyInfo', companyInfo)
      setValue('companyName', companyName)
      setValue('companyCode', companyCode)
      setValue('companyPhone', companyPhone)
      setValue('front', data?.[0]?.front)
      setValue('legalBack', legalBack)
      setValue('legalFront', legalFront)
      setStatusValue(`${data?.[0]?.state}`)
    }
  }
  useNavigationBar(
    currentPage
      ?
      {
        title: '营业执照',
      }
      : {
        title: '法人代表人身份认证',
      })

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    businessFn()
  }, [])
  return (
    <div style={{ background: '#F8F8F8', height: '100%' }} >
      {currentPage
        ? <React.Fragment >
          <form onSubmit={handleSubmit(onSubmit)} >
            <Box mt={2} ml={2} fontSize={15}>
              <span className={classes.titleIcon} /><text style={{ fontSize: '15px', fontWeight: 'bold' }}>上传营业执照</text>
              <Box mt style={{ textAlign: 'center', marginTop: '20px' }}>
                <Upload onUpload={url => {
                  uploadFn('front', url)
                }}
                  width={123}
                  height={82}
                  borderRadius={0}
                  imgBg={(businessList?.front === 'null' ? window?.ossUrl + '/file/14291635752374279101445.600.png' : businessList?.front) || window?.ossUrl + '/file/14291635752374279101445.600.png'}
                  customize={<ButtonBase className={classes.uploadBtn} >营业执照上传</ButtonBase>}
                />
                <textarea style={{ opacity: 0, position: 'absolute' }} type="text" {...register("front", { required: "上传营业执照" })} placeholder="上传营业执照" />
              </Box>

              <p style={{ padding: '0 73px', margin: '0 auto', width: '274px' }}>{errorsHtml(errors?.front, errors?.front?.message)}</p>
            </Box>
            <Box sx={{ width: '100%', bgcolor: 'background.paper' }}
              classes={{ root: classes.business_license }}>

              <nav aria-label="main mailbox folders">
                <List>
                  <ListItem disablePadding>
                    <span style={{ flex: '1 1 auto' }}>企业信息</span>
                    <Box style={{ fontSize: 14, color: '#3F3F3F' }}>
                      <TextareaAutosize
                        aria-label="填写您的企业信息"
                        minRows={1}
                        placeholder="填写您的企业信息"
                        {...register("companyInfo", { required: "填写您的企业信息" })}
                        defaultValue={detail?.companyInfo}
                      />
                    </Box>
                  </ListItem>
                  {errorsHtml(errors?.companyInfo, errors?.companyInfo?.message)}
                </List>
              </nav>

              <nav aria-label="main mailbox folders">
                <List>
                  <ListItem disablePadding>
                    <span style={{ flex: '1 1 auto' }}>企业名称</span>
                    <input className={classes.input} defaultValue={detail?.companyName} type="text" {...register("companyName", { required: "填写您的企业名称" })} placeholder="填写您的企业名称" />
                  </ListItem>
                  {errorsHtml(errors?.companyName, errors?.companyName?.message)}
                </List>
              </nav>

              <nav aria-label="main mailbox folders">
                <List>
                  <ListItem disablePadding>
                    <span style={{ flex: '1 1 auto' }}>组织结构代码</span>
                    <input className={classes.input} defaultValue={detail?.companyCode} type="text" {...register("companyCode", { required: "填写您的组织结构代码" })} placeholder="填写您的组织结构代码" />
                  </ListItem>
                  {errorsHtml(errors?.companyCode, errors?.companyCode?.message)}
                </List>
              </nav>

              <nav aria-label="secondary mailbox folders">
                <List onClick={() => setCurrentPage(0)}>
                  <ListItem disablePadding>
                    <span style={{ flex: '1 1 auto' }}>法定代表人身份证</span>
                    <span style={{ color: '#3F3F3F', fontSize: 14, marginRight: '5px' }}>去认证</span><ArrowForwardIosIcon style={{ color: '#aaa', fontSize: 16 }} />
                  </ListItem>
                  <input style={{ opacity: 0, position: 'absolute' }} defaultValue={1} type="text" {...register("corporateCard", { required: "去认证法定代表人身份证" })} placeholder="去认证法定代表人身份证" />
                  {errorsHtml(errors?.corporateCard, errors?.corporateCard?.message)}
                </List>
              </nav>

              <nav aria-label="main mailbox folders" style={{ paddingBottom: '100px' }}>
                <List>
                  <ListItem disablePadding>
                    <span style={{ flex: '1 1 auto' }}>联系电话</span>
                    <input className={classes.input} defaultValue={detail?.companyPhone} type="number" {...register("companyPhone", { required: "填写您的电话号码" })} placeholder="填写您的电话号码" />
                  </ListItem>
                  {errorsHtml(errors?.companyPhone, errors?.companyPhone?.message)}
                </List>
              </nav>

            </Box>
            {statusValue === 'true' ?
              <Box style={{ padding: '15px' }} className={classes.btnGray + ' ' + classes.btn} ><ButtonBase >仅允许查看</ButtonBase> </Box>
              : <Box style={{ padding: '15px' }} className={classes.btn} ><ButtonBase type="submit" >完成</ButtonBase> </Box>
            }
          </form>
        </React.Fragment>

        // 身份认证
        : <React.Fragment >
          <form onSubmit={handleSubmit(onSubmitLicense)}>
            <Box mt={2} ml={2} mr={2} fontSize={15}>
              <span className={classes.titleIcon} /><text style={{ fontSize: '15px', fontWeight: 'bold' }}>上传身份证照片</text>
              <Box mt style={{ textAlign: 'center', marginTop: '20px' }}>
                <Upload onUpload={url => {
                  uploadFn('legalFront', url)
                }}
                  width={123}
                  height={82}
                  borderRadius={0}
                  imgBg={detail?.legalFront || window?.ossUrl + '/file/14291635752374279101445.600.png'}
                  customize={<ButtonBase className={classes.uploadBtn} >身份证人像面</ButtonBase>}
                />
                <textarea style={{ opacity: 0, position: 'absolute' }} type="text" {...register("legalFront", { required: "上传身份证人像面" })} placeholder="上传身份证人像面" />
              </Box>
              <p style={{ padding: '0 60px', margin: '0 auto', width: '274px' }}>{errorsHtml(errors?.legalFront, errors?.legalFront?.message)}</p>

              <Box mt style={{ textAlign: 'center', marginTop: '20px' }}>
                <Upload onUpload={url => {
                  uploadFn('legalBack', url)
                }}
                  width={123}
                  height={82}
                  borderRadius={0}
                  imgBg={detail?.legalBack || window?.ossUrl + '/file/56631635755861527255264.400.png'}
                  customize={<ButtonBase className={classes.uploadBtn} >身份证国徽面</ButtonBase>}
                />
                <textarea style={{ opacity: 0, position: 'absolute' }} type="text" {...register("legalBack", { required: "上传身份证国徽面" })} placeholder="上传身份证国徽面" />
              </Box>
              <p style={{ padding: '0 60px', margin: '0 auto', width: '274px' }}>{errorsHtml(errors?.legalBack, errors?.legalBack?.message)}</p>
              <p style={{ fontSize: '15px', fontWeight: 'bold' }}>拍摄身份证要求</p>
              <text style={{ fontSize: '13px', color: '#A3A3A3', display: 'block' }}>大陆公民持有的本人有效二代身份证 </text>
              <text style={{ fontSize: '13px', color: '#A3A3A3', display: 'block' }}>拍摄时确保身份证<text style={{ color: '#FF0000' }}>边框完整，字体清晰；亮度均匀；</text></text>

              <Grid container spacing={2}>
                {rulesList.map(el => (
                  <Grid item xs={3}>
                    <Box style={{ position: 'relative', textAlign: 'center' }} mt={2}>
                      <img src={window?.ossUrl + el.img} alt='' width={80} height={47} />
                      <img src={window?.ossUrl + el.icon} alt='' width={20} height={20} style={{ position: 'absolute', left: '30px', top: '34px' }} />
                      <text style={{ color: '#A3A3A3', fontSize: '12px', lineHeight: '30px' }}>{el.text}</text>
                    </Box>
                  </Grid>
                ))}

              </Grid>
            </Box>

            {statusValue === 'true' ?
              <Box style={{ padding: '15px' }} className={classes.btnGray + ' ' + classes.btn} ><ButtonBase >仅允许查看</ButtonBase> </Box>
              : <Box style={{ padding: '15px' }} className={classes.btn} ><ButtonBase type="submit" >提交</ButtonBase> </Box>
            }

          </form>
        </React.Fragment>
      }
    </div >
  )
}

