import { requestX } from '../../utils/request'
/**
 * 营业执照页面 | 店铺申请页面 ｜ 对公账户页面 ｜ 企业地址页面 ｜ 身份认证
 * 表单提交接口
 */
const paramsList = (params: any) => ({
  type: params.type,
  // type 0 我的身份证信息, type 1 字段front则是营业执照
  front: params.front,
  back: params.back,
  udid: params.udid,
  id: params.id,
  detail: ({ // JSON.stringify
    // 营业执照
    companyInfo: params.companyInfo,
    companyName: params.companyName,
    companyCode: params.companyCode,
    companyPhone: params.companyPhone,
    // 法人身份证信息
    legalFront: params.legalFront,
    legalBack: params.legalBack,
    // 店铺申请
    intro: params.intro,
    storeName: params.storeName,
    storeLogo: params.storeLogo,
    category: params.category,
    type: params.storeType,
    // 对公账户
    corporateAccount: params.corporateAccount,
    bank: params.bank,
    location: params.location,
    bankBranch: params.bankBranch,
    // 企业地址
    companyLocation: params.companyLocation,
    companyAddress: params.companyAddress,
    companyTelephone: params.companyTelephone,
    // 管理人身份证信息
    adminBack: params.adminBack,
    adminFront: params.adminFront
  }),
})
export async function businessLicense(params: any) {
  return requestX.put('/credential/info', paramsList(params))
}
export async function businessLicenseModify(params: any) {
  return requestX.put('/credential/info', paramsList(params))
}
export async function licenseList(pageNo: number = 0, type: number = 0) {
  return requestX.get(`/credential/list/${pageNo}?type=${type}`)
}