import produce from "immer"
import {
    sendSms as sendSmsApi,
    setPassword as setPasswordApi,
    getMyUserInfo,
    getBlockList,
    changePhone,
    changeGrid,
    loginWithCode,
    loginWithPassword,
    myMall,
    postBlockUser,
    deleteBlockUser,
    getFocusList, addFocus, deleteFocus
} from "../service/goods";
import Cookies from 'js-cookie'
import { toastLevel } from './toast'
import { toast } from "../utils/bridge";

const initalState = {
    codeState: 0, // 0初始状态，1已发送，2:发送失败
    pwdState: 0,
    phoneState: 0,
    usernameState: 0,
    myself: null,
    store: null,
    userPhone: null,
    login: 0,
    blockList: []
}

const AccountActions = {
    SendCode: 'account.sendSms',
    ChangePassword: 'account.changePassword',
    MyInfo: 'account.myself',
    ChangePhone: 'account.changePhone',
    ChangeUsername: 'account.changeUsername',
    reset: 'account.resetState',
    login: 'account.login',
    block: 'account.blockUser',
    follow: 'account.follow',
}

export const accountReducer = produce((draft, action) => {
    switch (action.type) {
        case AccountActions.SendCode:
            draft.codeState = action.payload
            break
        case AccountActions.ChangePassword:
            draft.pwdState = action.payload
            break
        case AccountActions.ChangePhone:
            draft.phoneState = action.payload.state
            if (action.payload.state === 1) {
                draft.userPhone = action.payload.phone
                draft.myself.phone = action.payload.phone
                draft.myself.username = action.payload.phone
            }
            break
        case AccountActions.MyInfo:
            const { mall, user } = action.payload
            draft.mall = mall
            draft.myself = user
            draft.userPhone = user?.username
            break
        case AccountActions.ChangeUsername:
            draft.changeGrid = action.payload
            break
        case AccountActions.login:
            draft.login = action.payload
        case AccountActions.reset:
            draft.codeState = 0
            draft.pwdState = 0
            draft.phoneState = 0
            draft.usernameState = 0
            break
        case AccountActions.block:
            draft.blockList = action.payload
            break
        case AccountActions.follow:
            draft.followList = action.payload
            break
    }
}, initalState)

// 803929
export const sendSms = (phone, code) => async dispatch => {
    const { data } = await sendSmsApi(phone, code)
    console.log(data);
    let state = 0
    if (data && data.code === '200') {
        state = 1
    } else {
        state = 2
    }
    dispatch({
        type: AccountActions.SendCode,
        payload: state
    })
}

export const setPassword = (phone, code, password) => async dispatch => {
    const { data } = await setPasswordApi(phone, code, password)
    console.log(data);
    let state = 0
    if (data && data.code === '200') {
        state = 1
    } else {
        state = 2
    }
    dispatch({
        type: AccountActions.ChangePassword,
        payload: state
    })
}

export const setPhone = (phone, code) => async dispatch => {
    const { data } = await changePhone(phone, code)
    console.log(data)
    let state = 0
    if (data && data.code === '200') {
        state = 1
        toast("设置成功")
    } else {
        state = 2
        toast(data.msg)
    }
    dispatch({
        type: AccountActions.ChangePhone,
        payload: {
            state,
            phone
        },
    })
}

export const getUserInfo = () => async dispatch => {
    return loadMyself(dispatch)
}

export const refreshProfile = async dispatch => {
    const { data: userInfo } = await getMyUserInfo()
    dispatch({
        type: AccountActions.MyInfo,
        payload: {
            user: userInfo,
        }
    })
}

export const loadMyself = async dispatch => {
    const { data: userInfo } = await getMyUserInfo()
    const resp = await myMall().catch(e => console.warn(e))
    const mall = resp?.data
    loadBlockUser(dispatch)
    //加载关注列表
    loadFollowUser(dispatch)
    console.log('load block user');
    dispatch({
        type: AccountActions.MyInfo,
        payload: {
            mall,
            user: userInfo,
        }
    })
}

const loadBlockUser = async (dispatch) => {
    const { data: blockList } = await getBlockList()
    if (Array.isArray(blockList)) {
        dispatch({
            type: AccountActions.block,
            payload: blockList,
        })
    }
}

export const blockUser = async (id, dispatch) => {
    const { data } = await postBlockUser(id)
    if (data) {
        loadBlockUser(dispatch)
    }
}

export const removeBlockUser = async (id, dispatch) => {
    const { data } = await deleteBlockUser(id)
    if (data) {
        loadBlockUser(dispatch)
    }
}

const loadFollowUser = async (dispatch) => {
    const { data: followList } = await getFocusList(0,0,{page:false})
    dispatch({
        type: AccountActions.follow,
        payload: followList,
    })
}

export const followUser = async (id, dispatch) => {
    const { data } = await addFocus(0,id)
    console.log('data1',data)
    if (data) {
        loadFollowUser(dispatch)
    }
}

export const removeFollowUser = async (id, dispatch) => {
    const { data } = await deleteFocus(id)
    console.log(data)
    if (data) {
        loadFollowUser(dispatch)
    }
}

export const changeUserName = (grid) => async dispatch => {
    const { data } = await changeGrid(grid)
    console.log(data);
    let state = 0
    if (data && data.code === '200') {
        state = 1
        localStorage.setItem('my_userinfo', JSON.stringify(data.data))
    } else {
        state = 2
    }
    dispatch({
        type: AccountActions.ChangeUsername,
        payload: state
    })
}
export const loginEvent = (phone, code) => async dispatch => {
    try {
        const { data } = await loginWithCode(phone, code)
        const { accessToken, refreshToken: rToken } = data;
        Cookies.set('token', accessToken);
        Cookies.set('refreshToken', rToken);

        dispatch({
            type: AccountActions.login,
            payload: 1,
        })
        // 获取用户信息
        const { data: userInfo } = await getMyUserInfo()
        if (data) {
            localStorage.setItem('my_userinfo', JSON.stringify(userInfo))
            window.location.href = "/main/home"
        }
        dispatch({
            type: AccountActions.MyInfo,
            payload: userInfo
        })
    } catch (error) {
        console.log(error)
        // toast.showToast(toastLevel.ToastError, "登录失败")
    }
}

export const loginPassword = (username, password, toast) => async dispatch => {
    try {
        const { data } = await loginWithPassword(username, password)
        const { accessToken, refreshToken: rToken } = data;
        Cookies.set('token', accessToken);
        Cookies.set('refreshToken', rToken);

        dispatch({
            type: AccountActions.login,
            payload: 1,
        })
        // 获取用户信息
        const { data: userInfo } = await getMyUserInfo()
        if (data) {
            localStorage.setItem('my_userinfo', JSON.stringify(userInfo))
            window.location.href = "/main/home"
        }
        dispatch({
            type: AccountActions.MyInfo,
            payload: userInfo
        })
    } catch (error) {
        console.log(error)
        toast.showToast(toastLevel.ToastError, "登录失败")
    }
}

export const reset = () => {
    return ({
        type: AccountActions.reset,
        payload: 0
    })
}