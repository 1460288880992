import React, { useCallback, useEffect, useState } from 'react'
import {
  Box, ListItem, ListItemText, ListItemAvatar, Typography,
  Dialog, DialogTitle, List, Avatar,
  ButtonBase, makeStyles, Stepper, StepLabel, Step
} from '@material-ui/core'
import { useNavigationBar, useAppHistory, scan } from "utils/bridge"
import useFetch from "../../utils/useFetch"
import { refundInfo, listLogisticsFn } from './interface'
import { StatusBox } from '../../components/empty'
import InputTextDialog from "../../components/inputText"
import { useToast } from "../../models/toast"
import CropFreeIcon from '@material-ui/icons/CropFree';
import CreateIcon from '@material-ui/icons/Create';

const useStyles = makeStyles(() => ({
  stepRow: {
    '& .MuiStepIcon-root.MuiStepIcon-active, .MuiStepLabel-active, .MuiStepLabel-active + text, .MuiStepLabel-completed, .MuiStepIcon-root.MuiStepIcon-completed,  .MuiStepIcon-completed, .MuiStepLabel-completed + text': {
      color: '#D2035F !important'
    },
    '& .MuiStepIcon-root, .MuiStepLabel-label, .MuiStepLabel-label + text': {
      color: '#D8D8D8',
    }
  },
  btn: {
    position: 'fixed',
    bottom: '25px',
    width: '100%',
    maxWidth: '840px',
    "& button": {
      fontSize: 20,
      color: 'white',
      width: '100%',
      padding: 8,
      background: 'linear-gradient(90deg, #C90154, #E9097A)',
      borderRadius: '100px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    }
  },
}))

export default function RefundProgress({ match }) {
  const { id } = match.params
  const classes = useStyles()
  const [info, setInfo] = useState()
  const [openInput, setOpenInput] = useState(false)
  const [openChoose, setOpenChoose] = useState(false)
  const history = useAppHistory()
  const toast = useToast()

  useNavigationBar({
    title: info?.type ? '退货进度' : '退款进度',
  })

  // 同意退款文本
  const steps = [
    { label: '申请退款', optional: '您的申请已经提交，商家同意后为您退款。' },
    { label: '商家同意', optional: '已经为你取消订单，并退款。' },
    { label: '退款中', optional: '您的退款讲在1-7个工作日退回到自己的支付账户。' },
    { label: '完成', optional: '您的退款已经退回原支付账户。' },
  ]

  // 不同意退款文本
  const stepsDisagree = [
    { label: '申请退款', optional: '用户的申请已经提交，商家同意后为您退款。' },
    { label: '商家不同意', optional: '商家不同意退款，请联系商家或平台客服协商。' },
  ]

  // 同意退货退款文本
  const productSteps = [
    { label: '申请退款', optional: '用户的申请已经提交，商家同意后为您退款。' },
    { label: '商家同意', optional: '已经取消订单，并退款。' },
    { label: '退货中', optional: '用户已经寄出购买的商品。' },
    { label: '商家确认收到', optional: '货物已经收到，您的退款我们将在1-7个工作日退回' },
    { label: '退款中', optional: '退款将在1-7个工作日退回到用户的支付账户。' },
    { label: '完成', optional: '用户的退款已经退回原支付账户。' },
  ]

  // 不同意退货退款文本
  const productStepsDisagree = [
    { label: '申请退款', optional: '您的申请已经提交，商家同意后为您退款。' },
    { label: '商家不同意', optional: '商家不同意退货退款，请联系商家或平台客服协商。' },
  ]


  /**
   * 联系平台客服
   */
  const gotoCS = () => {
    history.push('app://cart/10000')
  }


  // 联系卖家
  const chat = () => {
    history.push(`app://chat/${order.store.userId}`)
  }

  // 发物流
  const onSendComment = useCallback(async (e) => {
    setOpenInput(false)
    const { data } = await listLogisticsFn({ orderId: id, trackingNumber: e })
    if (data) {
      toast.showToast("toast.success", "操作成功")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    // type： 0 退款， 1 退货
    const { data } = await refundInfo(id)
    if (data) {
      setInfo(data)
    }
    window.onScanCode = onSendComment
    return () => {
      window.onScanCode = undefined
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onChooseInputSource = e => {
    if (e === 'scan') {
      scan()
    } else {
      setOpenInput(true)
    }
    setOpenChoose(false)
  }
  // 获取页面订单id查询接口生成商品信息
  const { loading, data, error } = useFetch('/mall/order/' + id)
  const { data: order } = data ?? {}
  return (
    <Box>
      <StatusBox loading={loading} error={error} empty={data}>
        <Box>
          {order?.orderItems?.map((item, index) => (
            <React.Fragment key={index}>
              <ListItem alignItems="flex-start" style={{ background: '#fff', marginBottom: '10px' }}>
                <ListItemAvatar>
                  <img width={93} height={93} style={{ marginRight: '10px' }} alt="Remy Sharp" src={item?.thumb} />
                </ListItemAvatar>
                <ListItemText
                  primary={

                    <Box style={{ marginTop: '5px' }}>
                      <text style={{ fontSize: '14px', color: '#000', lineHeight: '17px', display: 'block', marginBottom: '4px' }}>订单：{id}</text>
                      <Box
                        p={2}
                        overflow='hidden'
                        height={33}
                        style={{ fontSize: '13px', lineHeight: '16px', display: '-webkit-box', WebkitLineClamp: 2, lineClamp: 2, WebkitBoxOrient: 'vertical', overflow: 'hidden', padding: 0 }}
                      >
                        {item?.name}
                      </Box>
                      <Box style={{ fontSize: '12px', color: '#747474', lineHeight: '17px', display: 'block' }}>x {item?.amount}</Box>
                      <text style={{ fontSize: '14px', color: '#FF0000', lineHeight: '17px', display: 'block', marginTop: 0 }}>¥ {item?.price / 100}</text>
                    </Box>
                  }
                />
              </ListItem>
            </React.Fragment>
          ))}
        </Box>
      </StatusBox>

      <Box style={{ background: '#fff', marginBottom: '10px' }} p={2}>
        <Typography variant="body1" style={{ marginBottom: '10px' }}>
          {info?.type ? '退货进度' : '退款进度'}
        </Typography>
        <Stepper activeStep={info?.step} orientation="vertical" style={{ padding: 0 }} className={classes.stepRow}>
          {(info?.type ? (info?.agree === false ? productStepsDisagree : productSteps) : (info?.agree === false ? stepsDisagree : steps))?.map((item, index) => (
            <Step key={index}>
              <StepLabel optional={<text style={{ fontSize: '11px' }}>{item?.optional}</text>}>{item?.label}</StepLabel>
            </Step>))}
        </Stepper>
      </Box>

      <Box style={{ padding: '15px' }} className={classes.btn} >
        {
          info?.agree === false
            ?
            (<>
              {/* 不同意退款按钮 */}
              < ButtonBase onClick={chat} style={{ color: '#C90154', background: '#F1F1F2', border: '1px solid #C90154', margin: '10px 0' }} >联系卖家</ButtonBase>
              <ButtonBase onClick={() => gotoCS()}>联系平台客服</ButtonBase>

            </>)
            : info?.type ? <ButtonBase onClick={() => setOpenChoose(true)} >填写快递单号</ButtonBase> : <ButtonBase onClick={() => history.pop()} >好的</ButtonBase>
        }
      </Box>


      <InputTextDialog
        open={openInput}
        title="填写快递单号"
        multiLine={false}
        onCancel={() => setOpenInput(false)}
        onFinish={onSendComment}
      />
      <ChooseDialog
        open={openChoose}
        handleClose={() => setOpenChoose(false)}
        onChoose={onChooseInputSource}
      />
    </Box >
  )
}

const ChooseDialog = ({ open, handleClose, onChoose }) => {
  return <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
    <DialogTitle id="simple-dialog-title">选择输入订单的方式</DialogTitle>
    <List>
      <ListItem button onClick={() => onChoose('scan')} key='scan'>
        <ListItemAvatar>
          <Avatar >
            <CropFreeIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary="扫一扫" />
      </ListItem>
      <ListItem autoFocus button onClick={() => onChoose('pen')}>
        <ListItemAvatar>
          <Avatar>
            <CreateIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary="手动输入" />
      </ListItem>
    </List>
  </Dialog>
}
