import { Route, Switch } from 'react-router-dom';
import AddressEditPage from './addressEdit';
import AddressListPage from './addressList';
import FilterPage from './filter';
import GoodsPage from './goods';
import GoodsList from './goodslist';
import MerchantPage from './merchant';
import SearchPage from './search';
import SettingsPage from './mallSettings';
import ShopPage from './shop';
import OrderPage from './orderDetail'
import ChooseOrderPage from './chooseOrder'
import MyCouponPage from './myCoupon'
import { MyOrderPage } from './myOrder'
import MyArticlePage from './myArticle'
import MyLocationPage from './myLocation'
import Service from './service'
import Editor from './editor'
import PublishArticle from './publishArticle'
import ShopCoupons from './couponList'
import * as Settings from './settings'
import Marked from './markedList'

import { ReportPage } from './report'
import SpecialtyPage from './content/specialty'
import ReceiveOrderPage from './receiveOrder'

// 电商活动
import FreeRobPage from './mallAct/freeRob';
import StockUpPage from './mallAct/stockUp';
// 认证系列
import FactoryStore from './myCertification/factory_store'
import StoreCertification from './myCertification/store_certification'
import BusinessLicense from './myCertification/business_license'
import BusinessAddress from './myCertification/business_address'
import CorporateAccount from './myCertification/corporate_account'
import ManageAuthentication from './myCertification/manage_authentication'
import MyAuthentication from './myCertification/my_authentication'

// 入驻申请
import ApplicationForPlatform from './applicationForPlatform/application_for_join'
import UploadCredentials from './applicationForPlatform/upload_credentials'

// 封面美照系列
import MyLicense from './myCertification/my_license'

// 精选清单系列
import { SelectList } from './carefullySelectionList/select_list'

// 售后系列
import AfterSaleList from './afterSale/after_sale_list'
import SelectAfterSaleType from './afterSale/select_after_sale_type'
import ApplyForRefund from './afterSale/apply_for_refund'
import ApplyForReturnGoods from './afterSale/apply_for_return_goods'
import RefundProgress from './afterSale/refund_progress'
import RecommendedShopping from './afterSale/recommended_shopping'

// B2C系列
import List from './b2c/workbench/list'
import ProductsManage from './b2c/workbench/products_manage'
import ShopInfo from './b2c/workbench/shopInfo'
import BatchManage from './b2c/workbench/batch_manage'
import OrdersManage from './b2c/workbench/orders_manage'
import RefundDetail from './b2c/workbench/refund_detail'
import CouponManage from './b2c/workbench/couponManager'
import CreateCouponPage from './b2c/workbench/createCoupon'
// 支付
import PaymentSuccessful from './payment_successful'


import DynamicPage from '../jwl/dynamic'
import NewProductPage from 'jwl/newProduct'
import FlashSalePage from 'jwl/flashSale'
import GoodsListAdapterPage from 'jwl/goodListAdapter'
import StoreListAdapterPage from 'jwl/storeListAdapter'
import MyFansPage from 'jwl/myFans';

import MyBlacklist from "../jwl/myBlacklist";

import PublicVideo from "../jwl/publish_video";
import QrCodePage from "./qrCode";
import submitOrder from './submitOrder';
import ReleaseQuestion from '../jwl/releaseQuestion';
import ReleaseSquarePage from '../jwl/releaseSquare';
import ReleaseTourPage from '../jwl/releaseTour';
import MySquarePage from '../jwl/mySquare';
import ScenerySpotPage from './sceneryspot';
import RecommendCommentPage from './service/comment';
import CouponListPage from './service/couponList';
import PublishShareList from './carefullySelectionList/publish_share_list';
import CarefullyDetail from './carefullySelectionList/carefully_detail';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    GoodsPage,
    ShopPage,
    MerchantPage,
    SearchPage,
    AddressListPage,
    AddressEditPage,
}

export const SwitchContent = ({ children }) => (<Switch>
    <Route path="/goods/:id" component={GoodsPage} />,
    <Route path="/goods_list" component={GoodsList} />,
    <Route path="/shop/:id" component={ShopPage} />,
    <Route path="/merchant" component={MerchantPage} />,
    <Route path="/settings" component={SettingsPage} />,
    <Route path="/search" component={SearchPage} />,
    <Route path="/filter" component={FilterPage} />,
    <Route path="/address_list" component={AddressListPage} />,
    <Route path="/address_edit" component={AddressEditPage} />,
    <Route path="/order/:id" component={OrderPage} />,
    <Route path='/publish_article' component={PublishArticle} />,
    <Route path='/coupons/:shop' component={ShopCoupons} />,

    <Route path='/report' component={ReportPage} />,
    <Route path="/submit" component={submitOrder} />,

    // 我的
    <Route path='/my_article' component={MyArticlePage} />,
    <Route path='/my_location' component={MyLocationPage} />,
    <Route path="/my_orders" component={MyOrderPage} />,
    <Route path='/change_password' component={Settings.ChangePasswordPage} />,
    <Route path='/change_phone' component={Settings.ChangePhonePage} />,
    <Route path='/change_username' component={Settings.ChangeUserName} />,
    <Route path='/join_platform' component={Settings.JoinPlatform} />,
    <Route path='/login' component={Settings.LoginPage} />,
    <Route path='/my_journal' component={Settings.JournalListPage} />,
    <Route path='/my_coupons' component={MyCouponPage} />,
    <Route path='/add_journal' component={Settings.CreateJournalPage} />,
    //我的黑名单
    <Route path='/my_blacklist' component={MyBlacklist} />,
    // 钱包卡券
    // 我收藏的店铺,商品,选择商品
    <Route path='/marked_shops' component={Marked.MarkedShopList} />,
    <Route path='/marked_goods' component={Marked.MarkedGoodsList} />,
    <Route path='/choose_goods' component={Marked.ChoooseMarkedGoodsList} />,
    // 选择订单
    <Route path='/choose_order' component={ChooseOrderPage} />,

    <Route path="/editor" component={Editor} />,
    <Route path='/shop_order' component={Service.ShopOrderList} />,
    <Route path='/shop_fund' component={Service.FundPage} />,

    <Route path='/mall/video/search' component={Service.VideoSearchPage} />,
    <Route path='/dynamic/:id' component={DynamicPage} />,

    // 精选清单
    <Route path='/select_list' component={SelectList} />,
    <Route path='/carefully_detail/:id' component={CarefullyDetail} />,
    <Route path='/publish_select' component={PublishShareList} />,


    // 朋友-推荐
    <Route path='/comment/:id' component={RecommendCommentPage} />,
    // 限时团
    <Route path='/flash_sale/:place' component={FlashSalePage} />,
    // 新品
    <Route path='/new_product/:place' component={NewProductPage} />,

    // 通用商品列表
    <Route path='/goods_adapter/:category' component={GoodsListAdapterPage} />,
    // 通用店铺列表
    <Route path='/store_adapter/:category' component={StoreListAdapterPage} />,
    // 优惠券
    <Route path='/vouchers' component={CouponListPage} />,
    //免费抢
    <Route path='/free_rob' component={FreeRobPage} />,
    <Route path='/stockup' component={StockUpPage} />,

    //我的粉丝
    <Route path='/my_fans/:place' component={MyFansPage} />,

    // 我的认证系列=======================
    // 工厂店
    <Route path='/factory_store' component={FactoryStore} />,
    <Route path='/store_certification' component={StoreCertification} />,
    <Route path='/business_license' component={BusinessLicense} />,
    <Route path='/business_address' component={BusinessAddress} />,
    <Route path='/corporate_account' component={CorporateAccount} />,
    <Route path='/manage_authentication' component={ManageAuthentication} />,
    <Route path='/my_authentication' component={MyAuthentication} />,
    <Route path='/my_license' component={MyLicense} />,

    // 申请入驻系列========================
    <Route path='/application_for_join' component={ApplicationForPlatform} />,
    <Route path='/upload_credentials/:id' component={UploadCredentials} />,


    // B端管理后台系列=======================
    //工作台
    <Route path='/b2c/workbench/list' component={List} />,
    <Route path='/b2c/workbench/shop' component={ShopInfo} />,
    // 商品管理
    <Route path='/b2c/workbench/products_manage' component={ProductsManage} />,
    // 批量管理
    <Route path='/b2c/workbench/batch_manage' component={BatchManage} />,
    // 订单管理
    <Route path='/b2c/workbench/orders_manage' component={OrdersManage} />,
    <Route path='/b2c/workbench/refund_detail/:id' component={RefundDetail} />,
    // 优惠券管理 
    <Route path='/b2c/workbench/coupons_manage' component={CouponManage} />,
    <Route path='/b2c/workbench/create_coupons' component={CreateCouponPage} />,
    // 售后列表
    <Route path='/after_sale_list' component={AfterSaleList} />,
    <Route path='/select_after_sale_type/:id' component={SelectAfterSaleType} />,
    <Route path='/apply_for_refund/:id' component={ApplyForRefund} />,
    <Route path='/apply_for_return_goods/:id' component={ApplyForReturnGoods} />,
    <Route path='/refund_progress/:id' component={RefundProgress} />,
    <Route path='/recommended_shopping/:id' component={RecommendedShopping} />,

    // 支付
    <Route path='/payment_successful/:id' component={PaymentSuccessful} />,

    //发布视频购
    <Route path='/public/video' component={PublicVideo} />,
    //二维码核销
    <Route path='/qr/:code' component={QrCodePage} />,
    /// 收到的订单
    <Route path='/recevive_order/:id' component={ReceiveOrderPage} />,

    // 提问题
    <Route path='/release_question' component={ReleaseQuestion} />,
    //发布广场
    <Route path='/release_square' component={ReleaseSquarePage} />,
    //我的广场
    <Route path='/my_square' component={MySquarePage} />,
    // 特产详情
    <Route path='/specialty/:id' component={SpecialtyPage} />,
    // 景区
    <Route path='/scenery_sport/:id' component={GoodsPage} />,
    // 景点打卡
    <Route path='/release_tour' component={ReleaseTourPage} />,
    {children}


</Switch>)
