import { Box } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import { SwitchContent } from './pages'
import { useAccount } from './utils/hooks';
import { useEffect } from 'react';
import { loadMyself } from './models/account';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import 'moment/locale/zh-cn';

const homeContainerStyle = {
    // height: 'calc(100% - 56px)',
    height: '100%',
    overflowX: 'hidden',
    overflowY: 'scroll',
}

const Main = () => {
    const { pathname } = useLocation()
    const dispatch = useDispatch()
    const account = useAccount()

    useEffect(() => {
        moment.locale('zh-cn');
        if (pathname === '/login') {
            return
        }
        if (!account?.myself) {
            loadMyself(dispatch)
        }
    }, [account?.myself, pathname])
    return <Box label='home-container' display='flex' flexDirection='column' style={homeContainerStyle}>
        <SwitchContent />
    </Box>
}

export default function AppRouter() {
    return (
        <Main />
    )
}