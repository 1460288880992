import { Box, Button, ButtonGroup, makeStyles, Paper } from "@material-ui/core";
import { ImageButton } from "./base";
import { config } from '../utils/bridge'

const useStyles = makeStyles((theme) => ({
    root: {
        top: 'auto',
        bottom: '0',
        display: 'flex',
        position: 'fixed',
        width: '100%',
        flexDirection: 'row',
        borderRadius: 0,
        maxWidth: config.maxWidth,
    },
    buyButton: {
        flex: 2,
        minWidth: 100,
        borderRadius: 20,
        margin: 10,
    },
    iconButton: {
        minWidth: 80,
    },
    group: {
        width: '80%',
        paddingBottom: theme.spacing(1),
    },
    groupButton: {
        width: '50%',
        borderRadius: 20,
        padding: theme.spacing(1),
        flexGrow: 1,
    }
}));

const CartToolBar = (props) => {
    const classes = useStyles()
    return (
        <Paper className={classes.root} >
            <Box display='flex' justifyContent='space-arround'>
                <ImageButton name="店铺" onClick={props.store}
                    icon={window.ossUrl+'/resource/ic_dianpu.png'} />
                <ImageButton name="客服" onClick={props.go_cs}
                    icon={window.ossUrl + '/resource/ic_kefu.png'} />
            </Box>
            <Button className={classes.buyButton} variant="outlined" color="primary" onClick={props.cart} >加入购物车</Button>
            <Button className={classes.buyButton} variant="contained" color="primary" onClick={props.buy} >立即购买</Button>
        </Paper>
    )
}

export const SimpleCartBar = (props) => {
    const classes = useStyles()
    return (
        <Box display='flex' justifyContent='center' >
            <ButtonGroup aria-label="contained primary button group" className={classes.group}>
                <Button className={classes.groupButton}
                    variant="outlined" color="primary" onClick={props.cart} >加入购物车</Button>
                <Button className={classes.groupButton} variant="contained" color="primary" onClick={props.buy}>立即购买</Button>
            </ButtonGroup>
        </Box>
    )
}

export default CartToolBar