import { getGoodsComment, getGoodsDetail } from '../service/goods'
import * as ActionType from "./types"

const initalState = {
    goods: undefined,
    loading: true,
}

export const goodsReducer = (state = initalState, action) => {
    switch (action.type) {
        case ActionType.GET_GOODS:
            return {
                ...state,
                loading: false,
                goods: action.payload,
                variant: action.payload.productVariantDetails.filter(i => i.live === 1)[0],
            }
        case ActionType.GET_COMMENTS:
            return {
                ...state,
                comments: action.payload,
            }
        case ActionType.CHOOSE_VARIANT:
            return {
                ...state,
                variant: action.payload,
            }
        default:
            return state
    }
}

/** 获取商品详情 */
export const getGoodsDetailAction = (id) => async dispatch => {
    try {
        const res = await getGoodsDetail(id);
        dispatch({
            type: ActionType.GET_GOODS,
            payload: res.data,
        })
    } catch (e) {
        console.error("加载出错", e)
    }

}

export const getCommentAction = (id) => async dispatch => {
    const res = await getGoodsComment(id, 1)
    if (res && res.data) {
        dispatch({
            type: ActionType.GET_COMMENTS,
            payload: res.data
        })
    }
}

export const selectGoodsVariant = (variant) => (dispatch) => {
    dispatch({
        type: ActionType.CHOOSE_VARIANT,
        payload: variant
    })
}

export const setnumber = (number) => (dispatch) => {
    dispatch({
        type: ActionType.SET_NUMBER,
        payload: number
    })
}
