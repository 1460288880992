import { Box, makeStyles, Button, Grid, Input } from '@material-ui/core'
import { useNavigationBar, useAppHistory, toast } from "utils/bridge";
import { useState, useRef } from 'react'
import { UploadOnly } from '../components/base';
import { produce } from 'immer'
import CloseIcon from '@material-ui/icons/Close';
import { postDynamic } from 'service/goods'
import qs from 'qs';
import { useForm } from "react-hook-form"

// 发布圈子动态
const useStyle = makeStyles(theme => ({

    inputPanel: {
        backgroundColor: 'white',
        marginTop: '16px',
        display: 'flex',
        flexDirection: 'column',
        padding: 12,
    },
    input: {
        border: 'none',
        outlineStyle: 'none',
        textAlign: 'right',
        fontSize: '14px',
    },
    mutliInput: {
        border: 'none',
        outlineStyle: 'none',
        fontSize: '14px',
    }
}))


const ReleaseSquarePage = ({ location }) => {
    const classes = useStyle()
    const { storeId, product } = qs.parse(location.search, { ignoreQueryPrefix: true })
    useNavigationBar({ title: '打卡' })

    const inputImageRef = useRef(null);
    const [urls, setUrls] = useState([])

    const onChooseImage = (url) => {
        setUrls(produce(draft => { draft.push(url) }));
    }
    const onDeleteImg = (index) => {
        setUrls(produce(draft => { draft.splice(index, 1) }))
    }

    const history = useAppHistory()
    const { register, control, handleSubmit, formState: { errors } } = useForm()

    const publish = async requestData => {
        const fileContent = urls.join(",")
        const body = {
            fileContent,
            type: 8,
            subType: storeId,
            ...requestData
        }
        const { data } = await postDynamic(body)
        toast("打卡成功")
        history.pop()
    }

    return <form onSubmit={handleSubmit(publish)}>
        <Box display='flex' flexDirection='column' height='100%'>

            <Box className={classes.inputPanel} mx={2} borderRadius={6}>
                <textarea className={classes.mutliInput} name="Text1" rows="4"
                    {...register("textContent")}
                    placeholder="说一说到这里的心情吧..." />
            </Box>

            <Box mt={2} mx={2} display='flex' flexDirection='column'>
                <Grid container spacing={1}>
                    {
                        urls?.map((url, index) => <Grid item xs={4} key={url}>
                            <Box width='100%' height='110px'
                                borderRadius={8}
                                style={{ background: `url(${url}) center `, backgroundSize: 'cover' }}>

                                <CloseIcon onClick={() => {
                                    onDeleteImg(index)
                                }} style={{
                                    float: 'left', position: 'relative',
                                    top: '5px', right: '-65%'
                                }} />
                            </Box>
                        </Grid>)
                    }
                    {urls?.length < 9 && <Grid item xs={4} key={-1}>
                        <UploadOnly size={110} onUpload={onChooseImage} inputImageRef={inputImageRef} />
                    </Grid>
                    }
                </Grid>
            </Box>

            <Box my={2} mx={2} display='flex' justifyContent="flex-end" height='50%' flexDirection='column'>
                <Button type="submit" variant='contained' color='primary' fullWidth style={{ borderRadius: '20px' }}>
                    <div style={{ color: 'white' }}>发布</div>
                </Button>
            </Box>

        </Box></form>
}


export default ReleaseSquarePage