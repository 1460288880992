import { Avatar, Box, Button, makeStyles } from "@material-ui/core";
import PersonPinCircleIcon from '@material-ui/icons/PersonPinCircle';
import { StatusBoxPro } from "../components/empty";
import { useAppHistory, useNavigationBar, toast } from "../utils/bridge";
import { priceCast } from '../utils/price';
import dateformat from 'dateformat'
import { orderDetailByQr, costsByCode } from '../service/goods'
import { useCallback } from 'react'

const useStyle = makeStyles((theme) => ({
    topPanel: {
        backgroundImage: 'linear-gradient(#6ecca1, #f5f5f5)',
        backgroundSize: '100% 30%',
        backgroundRepeat: 'no-repeat',
        height: '100%',
    },
    roundButton: {
        borderRadius: 20,
        padding: '8px 40px',
        width: '80vw',
        color: 'white',
    },
    toolbar: {
        position: 'fixed',
        bottom: 10,
        width: '100%',
        padding: '9px 16px',
        display: 'flex',
        justifyContent: 'center',
    },
}))

const Panel = ({ title, children, hidden }) => hidden ? null : (<Box m={2} p={2} bgcolor='white' borderRadius='8px'>
    <Box fontSize={15} fontWeight='800'>{title}</Box>
    {children}
</Box>)

const Line = ({ title, content, right }) => {
    const contentStyle = {
        float: right ? 'right' : 'none',
        fontWeight: 800,
    }
    return (<Box pt={2} fontSize={14}>
        {title}
        <Box style={contentStyle} pl={3} component='span'>{content}</Box>
    </Box>)
}

const payMethod = (type) => {
    switch (type) {
        case 0: return '支付宝'
        case 1: return '微信'
        default: return '未支付'
    }
}

const payTime = (time) => {
    if (time) {
        return dateformat(new Date(time * 1000), 'yyyy-mm-dd HH:MM:ss')
    } else {
        return ''
    }
}

const shippingMethod = (method) => {
    if (method === 2) {
        return "自提"
    } else {
        return "物流"
    }
}

const OrderDetail = ({ match }) => {
    const { code } = match.params
    useNavigationBar({
        title: '订单详情',
        bgColor: '#6ecca1',
        darkBar: true
    })

    const OrderUi = useCallback((data) => <OrderDetailUI order={data} />, [code])
    return (<StatusBoxPro
        request={orderDetailByQr(code)}
        component={OrderUi}
    />)
}

const OrderDetailUI = ({ order }) => {
    const classes = useStyle()
    const { id } = order
    if (order.code === '1009') {
        return <Box display='flex' flexDirection='column' alignItems='center' mt={5} p={2}>
            <img src='https://h5.qihuaitech.com/files/permission_required.png' />
            <Box mt={1} fontSize={18}>无权查看此订单</Box>
        </Box>
    }

    return <Box className={classes.topPanel}>
        <Box height={15} />

        <Panel title='收件人'>
            <Box mt={2} display='flex' alignItems='center'>
                <PersonPinCircleIcon color="disabled" fontSize="small" />
                <Box>{order?.name}</Box>
            </Box>
            <Box mt={1}>{order?.phone}</Box>
            <Box mt={1}>{order?.address}</Box>

        </Panel>
        <Panel title='商品信息'>
            {
                order?.orderItems?.map(item => (
                    <Box key={item.id} mt={1} display='flex' flex={1} flexDirection='row'>
                        <Avatar src={item.thumb} variant='rounded' style={{ 'width': '88px', height: '88px' }} />
                        <Box ml={1.5} flex={1} textAlign='left'>
                            <Box mt={0.4} fontWeight='600' fontSize={17}> {item.name}</Box>
                            <Box mt={2} color='#888'>{item.color + ' x' + item.amount + ' ' + item.model}</Box>
                            <Box mt={0.6} fontSize={16} >¥{priceCast(item.price)}</Box>
                        </Box>
                    </Box>
                ))
            }
        </Panel>
        <Panel title='商品金额'>
            <Line title="商品总额" right content={'¥' + priceCast(order?.totalCartPrice)} />
            <Line title="运费" right content={'¥' + priceCast(order?.totalCargoPrice)} />
            <Line title="优惠" right content={'¥' + priceCast(order?.totalDiscountPrice)} />
            <Box height={40}>
                <Box fontSize={14} mt={2} style={{ float: 'right' }}> 实付款：
                    <Box component='span' fontSize={20} color='#d20'>{'¥' + priceCast(order?.totalPrice)}</Box>
                </Box>
            </Box>
        </Panel>
        <Panel title='订单信息'>
            <Line title="订单编号" content={id} />
            <Line title="支付方式" content={payMethod(order?.payType)} />
            <Line title="支付时间" content={payTime(order?.payTime)} />
            <Line title="配送方式" content={shippingMethod(order?.shippingMethod)} />
        </Panel>
        <Box height='60px' />
        <VenderToolBar order={order} />
    </Box>
}

const VenderToolBar = ({ order }) => {
    const classes = useStyle()
    const history = useAppHistory()

    const finishOrder = async () => {
        /// 确认收货
        const result = await costsByCode(order?.code).catch(e => {
            const { data } = e.response
            toast("核销失败, " + data?.msg)
            console.log('核销失败', data);
        })
        if (result) {
            toast("已核销")
            history.pop()
        }
        console.log(result);
    }

    return <Box className={classes.toolbar}>
        <Button variant='contained'
            color='primary'
            className={classes.roundButton}
            onClick={finishOrder}>
            确认核销</Button>
    </Box>
}

export default OrderDetail