import { Box, ButtonBase, withStyles } from "@material-ui/core"

import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

const Arrow = withStyles({
    root: {
        color: '#aaa', fontSize: 18
    }
})(ArrowForwardIosIcon)

export function SectionRow({ title, onClick, children, arrow = true }) {
    return <ButtonBase
        style={{
            backgroundColor: 'white',
            borderRadius: 8,
            padding: 8,
            margin: 8,
            display: 'flex',
            alignItems: 'center',
            fontSize: '15px',
            width: 'calc(100% - 16px)'
        }}
        disableRipple={onClick === undefined}
        onClick={onClick}>
        <Box p={1} flex='1' textAlign='left'>
            {title}
        </Box>
        {children}
        {arrow ? <Arrow /> : null}
    </ButtonBase>
}