import React, { useEffect } from 'react';
import {
    Box, Typography, FormControl, InputLabel,
    IconButton,
    Input, InputAdornment, TextField, Button
} from '@material-ui/core';
import * as Actions from '../../models/account';
import { connect } from 'react-redux';
import { useToast } from '../../models/toast';

import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { useAppHistory } from '../../utils/bridge';

const ChangePasswordPage = ({ codeState, userPhone: username, pwdState, sendSms, setPassword, reset, getUserInfo }) => {
    const toast = useToast()

    const history = useAppHistory()

    const [values, setValues] = React.useState({
        phone: username,
        password: '',
        code: '',
        showPassword: false,
    });

    const [vaild, setVaild] = React.useState({
        phone: false,
        code: false,
        password: false
    })

    if (codeState === 2) {
        toast.showToast('toast.error', '验证码发送失败')
    }
    if (pwdState === 2) {
        toast.showToast('toast.error', '设置密码失败')
    } else if (pwdState === 1) {
        if ('pop' in history) {
            history.pop()
        }
    }

    useEffect(() => {
        getUserInfo()
        reset()
    }, [])

    const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    const [timeLeft, setTimeLeft] = React.useState(-2)

    useEffect(() => {
        if (timeLeft === -2) {
            return
        }
        const timer = setTimeout(() => {
            setTimeLeft(timeLeft - 1)
        }, 1000);
        return () => clearTimeout(timer)
    })

    const sendCode = () => {
        if (!values.phone.match(/^[1][3,4,5,7,8]\d{9}$/)) {
            toast.showToast('toast.error', "手机号格式错误")
            setVaild({ ...vaild, phone: true })
            return
        } else {
            setVaild({ ...vaild, phone: false })
        }
        //发送验证码
        setTimeLeft(60)
        sendSms(values.phone, 803929)
    }
    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    const clickSetPassword = () => {
        let error = {
            phone: !values.phone.match(/^[1][3,4,5,7,8]\d{9}$/),
            code: !values.code.match(/^\d{4,6}$/),
            password: !values.password.match(/^\w{6,18}$/)
        }
        if (error.phone) {
            toast.showToast('toast.error', "手机号格式错误")
        }
        if (error.code) {
            toast.showToast('toast.error', '验证码格式错误')
        }
        if (error.password) {
            toast.showToast('toast.error', '密码格式错误，需要6-18位的字母数字')
        }
        setVaild(error)
        if (error.phone || error.code || error.password) {
            return
        }
        setPassword(values.phone, values.code, values.password)
    }

    return (
        <Box p={2}>
            <Typography
                variant='h3'
                component='h3'
            >修改密码</Typography>

            <Box m={5}></Box>

            <FormControl fullWidth >
                <InputLabel htmlFor="standard-adornment-amount">手机号</InputLabel>
                <Input
                    id="standard-adornment-amount"
                    type='number'
                    value={values.phone}
                    error={vaild.phone}
                    onChange={handleChange('phone')}
                    disabled={username !== null}
                    startAdornment={<InputAdornment position="start">+86</InputAdornment>}
                />
            </FormControl>

            <Box mt={2} display='flex' alignItems='center'>
                <Box flex='1' paddingRight={1}>
                    <TextField
                        id="standard-code-input"
                        label="验证码"
                        error={vaild.code}
                        value={values.code}
                        onChange={handleChange('code')}
                        fullWidth
                        type='number'
                        autoComplete="current-password"
                    />
                </Box>
                <Button variant='outlined' onClick={sendCode} disabled={timeLeft > 0}>
                    {timeLeft === -2 ? '发送验证码' : `剩余${timeLeft}秒`}
                </Button>
            </Box>
            <Box mt={2}>
                <FormControl fullWidth >
                    <InputLabel htmlFor="standard-password-input">设置密码</InputLabel>
                    <Input
                        id="standard-password-input"
                        label="设置密码"
                        fullWidth
                        error={vaild.password}
                        value={values.password}
                        onChange={handleChange('password')}
                        type={values.showPassword ? 'text' : 'password'}
                        autoComplete="current-password"
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                >
                                    {values.showPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                </FormControl>
            </Box>
            <Box mt={14}>
                <Button disableElevation variant='contained'
                    onClick={clickSetPassword}
                    color='primary' fullWidth >
                    确认
                </Button>
            </Box>
        </Box>)

}


const propsMap = ({ account }) => (account)


export default connect(propsMap, Actions)(ChangePasswordPage)