import { useEffect, useState } from 'react'
import { Box } from "@material-ui/core"
import TextTab from 'components/textTab'
import { loadOrders } from '../../service/goods'
import Cookies from 'js-cookie'
import { OrderItem, ServiceOrderItem } from '../orderList'

const TabLabels = ["待付款", "待确认", "进行中", "购推荐"]

const state_data = [
    { shipped: 0, state: 0 },
    { shipped: 0, state: 1 },
    { shipped: 1, state: 1 },
    { shipped: 1, state: 5 },
    { shipped: 1, state: 6 },
]
/**
 * 店铺订单list.
 */
const ShopOrderList = props => {
    const category = 2
    const [index, setIndex] = useState(-1)
    const [orderList, setOrderList] = useState([])

    useEffect(async () => {
        setIndex(0)
    }, [])

    useEffect(async () => {
        const { data } = await loadOrders({
            storeId: Cookies.get('storeId'),
            ...state_data[index]
        }, 0, 1)
        if (data) {
            setOrderList(data)
        }
    }, [index])

    return <Box>
        <Box bgcolor='white'>
            <TextTab current={index} items={TabLabels} onChoose={i => { setIndex(i) }} />
        </Box>
        {
            orderList.map(order => category === 1 ?
                <OrderItem key={order.id} {...order} asSeller />
                : <ServiceOrderItem key={order.id} {...order} asSeller />)
        }
    </Box>
}

export default ShopOrderList