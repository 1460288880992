import { useMemo } from "react";
import { Avatar, Box, IconButton } from "@material-ui/core";
import { StatusBoxPro } from "../components/empty";
import { getStoreOutlet } from '../service/goods'
import Cookies from 'js-cookie';
import { caculateDistance } from 'utils/price'
import RoomIcon from '@material-ui/icons/Room';
import { openLocation } from "../utils/bridge";

export const OutletItem = ({ item }) => {
    let location = useMemo(() => Cookies.get('location'), [])
    let distance = null
    if (location) {
        let lon = location.split(",")[0]
        let lat = location.split(",")[1]
        distance = caculateDistance(lat, lon, item.latitude, item.longitude).toFixed(1)
    }
    const pushToMap = () => {
        // todo 调用地图导航 
        openLocation(item.latitude, item.longitude, item.storeName)
    }

    return <Box p={1} color='#777' display='flex' >
        <Box flex={1} pb={1} >
            <Box display="flex" alignItems='center' mb={1}>
                <Avatar variant="rounded" style={{ width: 25, height: 25 }} src={item.storeLogo}></Avatar>
                <Box fontSize={15} ml={1} color="#222" fontWeight='500' >
                    {item.storeName}
                </Box>
            </Box>
            <Box fontSize={14}>联系电话:{item.storePhone}</Box>
            <Box fontSize={14} >营业时间：{item.openingHours}</Box>
            <Box fontSize={12} color='#333' >{item.storeAddress}</Box>
        </Box>
        <Box display='flex' flexDirection='column' alignItems='center'>
            <IconButton onClick={pushToMap}>
                <RoomIcon />
            </IconButton>
            {
                distance && <Box fontSize={13} fontWeight='400'>{distance}公里</Box>
            }
        </Box>
    </Box>
}
/// 经销商的门店列表
const OutletList = ({ storeId }) => {
    const outletItems = useMemo(() => res => {
        return res.data.map(item => <Box key={item.id} bgcolor='white' borderRadius='8px' m='8px'>
            <OutletItem item={item} />
        </Box>)
    }, [])
    return <Box >
        <Box bgcolor='white' lineHeight='26px' textAlign='center' p={1} fontSize={17} color='#333' fontWeight='500'>适用门店</Box>
        <StatusBoxPro request={() => getStoreOutlet(storeId)}
        component={outletItems}
    />
    </Box>
}


export default OutletList