// 特产详情 

import { Box } from "@material-ui/core"
import { useRequest } from "ahooks"
import { useEffect } from "react"
import { StatusBox } from "../../components/empty"
import { getSpecialtyInfo } from "../../service/goods"
import { setNavigationBar } from "../../utils/bridge"

const SpecialtyInfo = ({ match }) => {
    const { id } = match.params
    const { data, loading, error } = useRequest(() => getSpecialtyInfo(id), {
        formatResult: res => res.data
    })
    useEffect(() => {
        console.log(data);
        if (data && data) {
            setNavigationBar({ title: data.specialtyName })
        }
    }, [data])
    return <StatusBox
        loading={loading} error={error}>
        <Box
            bgcolor='white'>
            <Box
                className="article"
                p={1}
            dangerouslySetInnerHTML={{ __html: data?.html }} />
        </Box>
    </StatusBox>
}

export default SpecialtyInfo;