import { useState } from "react"
import { useAppHistory, useNavigationBar } from 'utils/bridge'
import { InputItem, InputItemRef } from "components"
import { Box, Button, TextField, makeStyles } from "@material-ui/core"
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { createCoupons } from 'service/goods'
import { useToast } from 'models/toast'
import { useAccount } from "utils/hooks";
import { useForm } from "react-hook-form";
import moment from 'moment'

const useStyle = makeStyles(theme => ({
    inputItem: {
        backgroundColor: 'white',
        marginTop: '16px',
        borderRadius: 8,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: 12,
    },
    error: {
        fontSize: 12,
        color: 'red',
        textAlign: 'right',
    },
    publish: {
        marginTop: 22,
        borderRadius: 24,
    }
}))

const Condition = ({ show, component }) => {
    if (show)
        return component()
    else
        return null
}

const CreateCouponPage = props => {
    const [type, setType] = useState(0)

    const toast = useToast()
    const classes = useStyle()
    const history = useAppHistory()
    const { register, unregister, handleSubmit, formState: { errors } } = useForm();

    useNavigationBar({ title: '创建优惠券' })
    const { mall } = useAccount()
    const onTypeChange = (e) => {
        setType(e.target.value)
        switch (e.target.value) {
            case 0:
                unregister('reduce_price')
                unregister('metPrice')
                break
            case 1:
                unregister('discountPercent')
                unregister('metPrice')
                break
            case 2:
                unregister('discountPercent')
                break
        }
    }
    console.log('err', errors);

    const onSubmit = async params => {
        console.log('onsubmit', params, mall)

        //    const dateTime = new Date(e.target.value).getTime()
        const {
            metPrice, reduce_price, begindate, enddate
        } = params
        console.log(begindate, enddate)

        if (mall) {
            const { data: result } = await createCoupons(mall.id, {
                ...params,
                metPrice: metPrice * 100,
                reduce_price: reduce_price * 100,
                validTimeStart: new Date(begindate).getTime(),
                validTimeEnd: new Date(enddate).getTime(),
                type,
            })
            console.log(result)
            if (result) {
                toast.showToast('toast.success', '创建成功')
            }
            history.pop()
        }
    }

    return <Box p={1}>
        <form onSubmit={handleSubmit(onSubmit)}>
            <InputItemRef title="优惠券名称" placeHolder="输入优惠券名称"
                {...register("title", { required: true, maxLength: 20 })}
            />
            <Box className={classes.error}>
                {errors.title && "请输入优惠券名称（长度不超过20）"}
            </Box>
            <InputItemRef title="优惠券数量" type='number'
                {...register('surplus', { required: true, min: 1, max: 999, valueAsNumber: true, })}
                placeHolder="输入优惠券总数" />
            <Box className={classes.error}>
                {errors.surplus && "请输入优惠券总数（最多999个）"}
            </Box>
            <Box className={classes.inputItem} >
                开始日期
                <Box flex='1' />
                <TextField
                    id="begindate"
                    type="date"
                    defaultValue={moment().format('yyyy-MM-DD')}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    {...register('begindate', {
                        valueAsDate: true,
                        required: true,
                    })}
                />
            </Box>

            <Box className={classes.inputItem} >
                结束日期
                <Box flex='1' />
                <TextField
                    id="enddate"
                    type="date"
                    defaultValue={moment().add(7, 'days').format('yyyy-MM-DD')}
                    InputLabelProps={{
                        shrink: true,
                        required: true,
                    }}
                    {...register('enddate', {
                        valueAsDate: true,
                        required: true,
                    })}
                />
            </Box>
            <Box className={classes.inputItem}>
                优惠券类型
                <Box flex='1' />
                <Select
                    defaultValue={0}
                    onChange={onTypeChange}>
                    <MenuItem value={0}>折扣</MenuItem>
                    <MenuItem value={1}>立减</MenuItem>
                    <MenuItem value={2}>满减</MenuItem>
                </Select>
            </Box>
            <Condition show={type === 0} component={() => (<>
                <InputItemRef title="优惠折扣" placeHolder="输入优惠折扣(%)" type='number'
                    {...register("discountPercent", { required: true, min: 1, max: 99 })} />
                <Box className={classes.error}>
                    {errors.discountPercent && "请输入优惠折扣, 范围（1-99）"}
                </Box>
            </>)} />

            <Condition show={type === 2} component={() => (<>
                <InputItemRef title="优惠满减条件" placeHolder="输入优惠满减条件(元)" type='number'
                    {...register("metPrice", { required: true, min: 1, max: 99999 })}
                />
                <Box className={classes.error}>
                    {errors.metPrice && "优惠满减条件"}
                </Box>
            </>)} />

            <Condition show={type === 1 || type === 2} component={() => (<>
                <InputItemRef title="优惠金额" placeHolder="输入优惠金额(元)" type='number'
                    {...register("reduce_price", { required: true, min: 1, max: 9999 })}
                />
                <Box className={classes.error}>
                    {errors.reduce_price && "请输入优惠金额"}
                </Box>
            </>)} />

            <Button className={classes.publish} type="submit" color='primary'
                fullWidth variant='contained'>
                <Box color='white'>
                    发布
                </Box>
            </Button>
        </form>
    </Box>
}

export default CreateCouponPage