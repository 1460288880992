import { useEffect, useState } from 'react'
import { getShareItem } from 'service/goods'
import { setShareInfo, useNavigationBar } from 'utils/bridge'
import { WelfareCouponsItem } from './welfareSpace'
import { Button } from '@material-ui/core'

const WelfareArticlePage = ({ match, location }) => {
    const id = match.params.id
    const [item, setItem] = useState()
    useNavigationBar({ title: '福利详情' })

    useEffect(async () => {
        const { data } = await getShareItem(id)
        if (data) {
            setItem(data)
            setShareInfo({
                type: 2,
                jsonType: 1,
                content: data.json,
                json: {
                    path: location.pathname
                }
            })
        }
    }, [])

    if (item) {
        return <div style={{ padding: '8px' }}>
            <h1>{item.textContent}</h1>
            <div dangerouslySetInnerHTML={{
                __html: item.html
            }} />
            <WelfareCouponsItem json={item.json} />
        </div>
    } else {
        return <div />
    }
}

export default WelfareArticlePage