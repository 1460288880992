import { CircularProgress, makeStyles, TextField, Typography } from "@material-ui/core";
import ButtonBase from "@material-ui/core/ButtonBase";
import Box from '@material-ui/core/Box';
import Chip from '@material-ui/core/Chip';
import React, { ChangeEventHandler, useRef, useState } from "react";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import Badge from '@material-ui/core/Badge'
import StarIcon from '@material-ui/icons/Star';
import StarBorderIcon from '@material-ui/icons/StarBorder';

import { styled } from '@material-ui/core/styles';
import { requestX } from "../utils/request";
import moment from "moment";

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        padding: 8,
        borderRadius: 12,
    },
    image: {
        width: 22,
        height: 22,
    },
    name: {
        color: '#444',
        fontSize: 12,
    },
    linear: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        width: '100%',
        alignItems: 'center',
        padding: 8
    },
    imageChoose: {
        width: 100,
        height: 140,
        backgroundColor: '#f9f9f9',
        borderColor: '#eee',
        border: '1px solid',
        borderRadius: 8,
        color: '#999',
    },
    inputImage: {
        width: 100,
        height: 140,
        borderRadius: 8,
        objectFit: 'cover',
    },
    inputVideo: {
        borderRadius: 8,
        width: 'inherit',
        height: 'inherit',
        objectFit: 'cover',
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: 8,
        marginRight: 8,
        width: 200,
    },
}));


export const ButtonBox = styled(ButtonBase)({
    width: '100%',
    padding: '12px',
    background: 'white',
    borderRadius: '6px',
    display: 'inline-flex',
});

type ImageButtonProps = {
    name: string;
    icon: string;
    size?: number;
    onClick: () => void;
}

export const ImageButton: React.FC<ImageButtonProps> = ({ size, icon, name, onClick, ...props }) => {
    const iconSize = size ?? 22
    const classes = useStyles()
    return (
        <ButtonBase
            focusRipple
            className={classes.root}
            onClick={onClick}
            {...props}
        >
            <img src={icon} style={{ width: iconSize + 'px', height: iconSize + 'px' }} alt="button-icon" />
            <Typography
                className={classes.name}
            >{name}</Typography>
        </ButtonBase>
    )
}

type LinearLayoutProps = {
    children: React.ReactNode
}

export const ButtonLinearLayout = (props: LinearLayoutProps) => {
    const classes = useStyles()
    return (
        <ButtonBase className={classes.linear} {...props}>
            {props.children}
        </ButtonBase>
    )
}

export const DividerBox: React.FC = () => <Box height='1rem' bgcolor='grey.200' />
export const DividerLine: React.FC = () => <Box height='1px' bgcolor='grey.200' />

interface PanelHeaderProps {
    title: string,
    children?: React.ReactNode
}

export const PanelHeader = (props: PanelHeaderProps) => {
    return (
        <Box display='flex' flexDirection='row' alignItems='center' p={1}  >
            <Box p={1} className='item-title' flexGrow={1}>{props.title}</Box>
            {props.children}
        </Box>
    )
}

type RadioChipProps = {
    checked: boolean,
    label: string,
    value: any,
    onChange: (value: any) => void;
}

export const RadioChip: React.FC<RadioChipProps> = ({ checked, label, value, onChange }) => {
    const handleClick = () => {
        onChange(value);
    }
    const color = checked ? 'primary' : 'default'
    // const variant = checked ? 'default' : 'outlined'
    return (
        <Box mr={1} mb={1} >
            <Chip variant='outlined' label={label} clickable onClick={handleClick} color={color} />
        </Box>
    )
}


type NumberInputProps = {
    init: number | undefined;
    max: number | undefined;
    min?: number;
    onChange: (value: number) => void;
}

const numberStyle = makeStyles(() => ({
    numberContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        border: '1px solid #ddd',
        borderRadius: 18,
    },
    number: {
        width: 28,
        textAlign: 'center',
        lineHeight: 1,
        fontSize: 14,
        border: '1px #ccc',
        borderStyle: 'none solid',
    },
    button: {
        padding: 7,
    },
    buttonIcon: {
        width: 12,
        height: 12,
        color: '#aaa',
    }
}))

export const NumberInput: React.FC<NumberInputProps> = ({ init = 1, onChange, min = 1, max = 9999, ...props }) => {
    const [value, setValue] = React.useState<number>(init)
    const classes = numberStyle()
    const onIncrease = () => {
        const result = Math.min(max, value + 1)
        setValue(result)
        onChange(result)
    }
    const onDecrease = () => {
        const result = Math.max(min, value - 1)
        setValue(result)
        onChange(result)
    }
    return (<Box className={classes.numberContainer} {...props}>
        <IconButton className={classes.button} onClick={onDecrease} disabled={value === min} aria-label="decrease goods number">
            <RemoveIcon className={classes.buttonIcon} />
        </IconButton>
        <Box className={classes.number} >{value}</Box>
        <IconButton className={classes.button} onClick={onIncrease} aria-label="increase goods number">
            <AddIcon className={classes.buttonIcon} />
        </IconButton>
    </Box>)
}


export const MyIconButton: React.FC<{ image: string, title: string, count: number, type: number, onClick: VoidFunction }> = ({ image, count, title, type, onClick }) => {

    return (
        <Box display='flex' flexDirection='column' alignItems='center' clone>
            <ButtonBase disableRipple onClick={onClick}>
                <Badge badgeContent={count} color='secondary'>
                    <img src={image} style={{ width: 30, height: 30 }} />
                </Badge>
                <Box color='#555' my={1} fontSize={14}>{title}</Box>
            </ButtonBase>
        </Box>
    )
}


export const NumberButton: React.FC<{ num: string, title: string, onClick: VoidFunction }> = ({ num, title, onClick }) => {
    return <ButtonBase onClick={onClick} style={{ flex: 1, borderRadius: '8px', padding: '8px 0' }}>
        <Box display='flex' flexDirection='column' alignItems='center'>
            <Box fontSize='1.3rem'>{num}</Box>
            <Box height={16} />
            <Box>{title}</Box>
        </Box>
    </ButtonBase>
}

function getInterval(start: number, end: number) {
    // 两个日期对象，相差的毫秒数
    var interval = end - start;
    // 求 相差的天数/小时数/分钟数/秒数
    var day, hour, minute, second;

    // 两个日期对象，相差的秒数
    // interval = interval / 1000;
    interval /= 1000;

    day = Math.round(interval / 60 / 60 / 24);
    hour = Math.round(interval / 60 / 60 % 24);
    minute = Math.round(interval / 60 % 60);
    second = Math.round(interval % 60);

    return {
        day: day,
        hour: hour,
        minute: minute,
        second: second
    }
}
export const CountDown: React.FC<{ endTime: Date }> = ({ endTime }) => {
    const time = getInterval(Date.now(), endTime.getTime())
    let displayTime = ""
    if (time.day) {
        displayTime += `${time.day}天 `
    }
    if (time.hour) {
        displayTime += `${time.hour}小时 `
    }
    if (time.minute) {
        displayTime += `${time.minute}分钟 `
    }
    return <span>
        {displayTime}
    </span>
}

export const UploadButton: React.FC<{ event: () => void }> = ({ children }) => {
    const classes = useStyles()
    const inputImageRef = useRef<HTMLInputElement>(null);
    const [img, setImg] = useState<string>()

    const triggerChoose = () => {
        inputImageRef.current?.click()
    }
    const fileChange: ChangeEventHandler<HTMLInputElement> = (event) => {
        if (event && event.target) {
            const files = event.target.files
            if (files && files.length > 0) {
                const url = URL.createObjectURL(files[0])
                setImg(url)
            }
        }
    }
    const buttonStyle = {
    }
    return <div>
        <ButtonBase style={buttonStyle} onClick={triggerChoose}>
            <input type="file" id="avatar" name="avatar"
                style={{ display: 'none' }}
                ref={inputImageRef}
                onChange={fileChange}
                accept="image/png, image/jpeg"></input>
            {children}
        </ButtonBase>
    </div>

}

type UploadProps = {
    origin?: string,
    size?: number,
    width?: number,
    height?: number,
    borderRadius?: number,
    type?: string,
    imgBg?: string,
    onUpload: (url: String) => void,
    customize?: HTMLBaseElement
}

type onChoose = (url: string) => void

type OnError = ((err: any) => void) | null

async function uploadFile(type: string, event: React.ChangeEvent<HTMLInputElement>, onChoose: onChoose, onError: OnError = null) {
    if (event && event.target) {
        const files = event.target.files
        if (files && files.length > 0) {
            const url = URL.createObjectURL(files[0])
            onChoose(url)
            let formData = new FormData()
            const file = files[0]
            const index = file.name.lastIndexOf(".")
            const suffix = file.name.substr(index)
            const name = Date.now() + event.timeStamp.toFixed(3) + suffix
            let f = new File([file], name)
            formData.append("file", f)
            if (type === 'image') {
                formData.append("compress", 'true')
            }
            try {
                const { data } = await requestX.post(`file/uploadOssFile/${type}`, formData, {
                    headers: { 'Content-Type': 'multipart/form-data' }
                })
                console.log('upload result', data);
                return data.url
            } catch (e) {
                onError?.(e)
            }
        }
    }
}

export const UploadVideo: React.FC<UploadProps> = ({ height, onUpload }) => {
    const classes = useStyles()
    const inputImageRef = useRef<HTMLInputElement>(null);
    const [file, setFile] = useState<string>()
    const [uploading, setUploading] = useState<boolean>()
    const [error, setError] = useState<boolean>(false)
    const triggerChoose = () => {
        inputImageRef.current?.click()
    }
    const fileChange: ChangeEventHandler<HTMLInputElement> = async (event) => {
        const result = await uploadFile('video', event, (url) => setUploading(true), (error) => {
            setError(true)
            setUploading(false)
            setFile(undefined)
        })
        if (result) {
            setFile(result + ".jpg")
            onUpload(result)
            setUploading(false)
        }
    }

    let placeHolder = <Box>选择视频上传</Box>
    if (error) {
        placeHolder = <Box>上传错误</Box>
    }
    if (uploading) {
        placeHolder = <CircularProgress />
    }
    if (file) {
        placeHolder = <img className={classes.inputVideo} src={file} />
    }


    return <ButtonBase className={classes.imageChoose} onClick={triggerChoose}
        style={{
            width: '-webkit-fill-available',
            height: height + 'px'
        }} >
        <input type="file" id="avatar" name="avatar"
            style={{ display: 'none' }}
            ref={inputImageRef}
            onChange={fileChange}
            accept={'video/*'}></input>
        {placeHolder} 
    </ButtonBase>
}

export const Upload: React.FC<UploadProps> = ({ origin, size, height, width, borderRadius, imgBg, type = "image", onUpload, children, customize }) => {
    const classes = useStyles()
    const inputImageRef = useRef<HTMLInputElement>(null);
    const [img, setImg] = useState<string | undefined>(origin)

    const triggerChoose = () => {
        inputImageRef.current?.click()
    }
    const fileChange: ChangeEventHandler<HTMLInputElement> = async (event) => {
        const result = await uploadFile('image', event, (url) => setImg(url))
        if (result) {
            onUpload(result)
        }
    }
    const imageSize = {
        width: (size || width) ?? 100 + 'px',
        height: (size || height) ?? 100 + 'px',
        borderRadius
    }
    const content = children ? children : '选择图片'

    return (
        <div>
            {
                customize
                    ? (
                        <div onClick={triggerChoose}>
                            <input type="file" id="avatar" name="avatar"
                                style={{ display: 'none' }}
                                ref={inputImageRef}
                                onChange={fileChange}
                                accept={`${type}/*`}></input>
                            <img className={classes.inputImage} style={imageSize} src={img || imgBg} />
                            <Box>{customize}</Box>
                        </div>
                    )
                    : (
                        <ButtonBase className={classes.imageChoose} style={imageSize} onClick={triggerChoose}>
                            <input type="file" id="avatar" name="avatar"
                                style={{ display: 'none' }}
                                ref={inputImageRef}
                                onChange={fileChange}
                                accept={`${type}/*`}></input>
                            {
                                type === "image" && (img ? <img className={classes.inputImage} style={imageSize} src={img} /> : <Box>{content}</Box>)
                            }
                        </ButtonBase>
                    )
            }
        </div>
    )
}


export const UploadOnly: React.FC<UploadProps & { inputImageRef: React.RefObject<HTMLInputElement> }> = ({ size, inputImageRef, onUpload, borderRadius }) => {
    const classes = useStyles()

    const triggerChoose = () => {
        inputImageRef?.current?.click()
    }
    const fileChange: ChangeEventHandler<HTMLInputElement> = async (event) => {
        const result = await uploadFile('image', event, () => { })
        onUpload(result)
    }
    const imageSize = {
        width: size ?? 90 + 'px',
        height: size ?? 90 + 'px',
        borderRadius
    }
    const obj = window as any;
    const ossUrl = obj.ossUrl

    return <div>
        <ButtonBase className={classes.imageChoose} style={imageSize} onClick={triggerChoose}>
            <input type="file" id="avatar" name="avatar"
                style={{ display: 'none' }}
                ref={inputImageRef}
                onChange={fileChange}
                accept="image/*" />
            <img width={20} src={ossUrl + '/image/ic_map_add.png'} />
        </ButtonBase>
    </div>
}

/**
 * 5分制的得分组件
 * @param {分数} num 
 */
export const StarScore: React.FC<{ num: number }> = ({ num }) => {
    if (num > 5 || num < 0) {
        return <div>分数格式不支持</div>
    }

    return <div>
        {
            Array(num).fill(0).map((it) => <StarIcon color='primary' />)
        }
        {
            Array(5 - num).fill(0).map((it) => <StarBorderIcon />)
        }
    </div>
}

export const DatePicker: React.FC<{ onChoose: (time: number) => void }> = ({ onChoose }) => {
    const classes = useStyles()
    const [date, setDate] = useState<string>()
    const now = moment()

    return (
        <form className={classes.container} noValidate>
            <TextField
                id="date"
                type="datetime-local"
                value={date}
                onChange={(e: any) => {
                    let time = moment(e.target.value);
                    setDate(time.format("YYYY-MM-DDTHH:mm"));
                    onChoose(time.toDate().getTime())
                }}
                defaultValue={now.format("YYYY-MM-DDTHH:mm")}
                className={classes.textField}
                InputLabelProps={{
                    shrink: true,
                }}
            />
        </form>
    );
}