import React, { useState, useRef, useEffect } from 'react'
import { useTheme, Box, ListItem, ListItemAvatar, ListItemText, Typography, Grid, FormControl, Select, ButtonBase, OutlinedInput, MenuItem, makeStyles, TextareaAutosize } from '@material-ui/core'
import { useForm } from "react-hook-form"
import { produce } from 'immer'
import { UploadOnly } from '../../components/base'
import CloseIcon from '@material-ui/icons/Close'
import InfoIcon from '@material-ui/icons/Info'
import { applyReturnRefund, refundInfo } from './interface'
import { useToast } from "../../models/toast"
import { useNavigationBar, useAppHistory } from "utils/bridge"
import useFetch from "../../utils/useFetch"
import { StatusBox } from '../../components/empty'

const useStyles = makeStyles(() => ({
  selected: {
    float: 'right',
    marginRight: '-7px',
    "& ::-webkit-input-placeholder": {
      color: ' #BABABA',
      textAlign: 'right',
      padding: '0 6px',
      fontSize: '14px',
      background: '#fff'
    },
    '& .PrivateNotchedOutline-root-10': {
      border: 'none'
    },
    '& .makeStyles-input-85': {
      background: '#fff'
    },
    '& .MuiSelect-select:focus': {
      background: '#fff'
    },
    '& .MuiSelect-select.MuiSelect-select': {
      paddingRight: '24px',
      color: '#3F3F3F',
      fontSize: '13px',
      margin: '-16px 0'
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#fff'
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: '#fff'
    }
  },
  evidenceRow: {
    "& textarea": {
      color: '#3F3F3F',
      width: '100%',
      borderColor: '#eee',
      marginTop: '10px',
      resize: 'none',
      outline: 0,
      padding: '11px',
      fontSize: '12px',
      background: '#f9f9f9',
      minHeight: '100px'
    },

  },
  btn: {
    position: 'fixed',
    bottom: '25px',
    width: '100%',
    maxWidth: '840px',
    "& button": {
      fontSize: 20,
      color: 'white',
      width: '100%',
      padding: 8,
      background: 'linear-gradient(90deg, #C90154, #E9097A)',
      borderRadius: '100px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    }
  },
}))

export default function ApplyForReturnGoods({ match }) {
  const { id } = match.params
  const [personName, setPersonName] = useState([])
  const [detail, setDetail] = useState({})
  const theme = useTheme()
  const classes = useStyles()
  const { register, setValue, handleSubmit, formState: { errors }, clearErrors } = useForm()
  const inputImageRef = useRef(null);
  const [urls, setUrls] = useState([])
  const toast = useToast()
  const history = useAppHistory()

  /**
   * 上传图片
   * @param {*} url 
   */
  const onChooseImage = (url) => {
    setUrls(produce(draft => { draft.push(url) }))
    setValue('multiView', [...urls, url])
    clearErrors('multiView') // 清除错误提示
  }
  /**
   * 删除图片
   * @param {*} index 
   */
  const onDeleteImg = (index) => {
    setUrls(produce(draft => { draft.splice(index, 1) }))
  }

  /**
   * 页面标题导航
   */
  useNavigationBar({
    title: '申请退货',
  })


  /**
   * 退货原因选项
   * @param {*} event 
   */
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    clearErrors('reason') // 清除错误提示
    setValue('reason', value)
    setPersonName(
      // On autofill we get a the stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  /**
   * 退货理由列表
   */
  const reasonList = [
    { name: '7天无理由退货' },
    { name: '货物丢失' },
    { name: '运输损毁' },
    { name: '假冒伪劣产品' },
    { name: '发货未按约定时间' },
    { name: '虚假宣传，与产品描述不符' },
    { name: '发货错误，尺码大小错误' }
  ]

  /**
   * 退货理由小气泡窗口选中标识样式
   * @param {*} name 
   * @param {*} personName 
   * @param {*} theme 
   */
  function getStyles(name, personName, theme) {
    return {
      fontWeight:
        personName.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }

  const ITEM_HEIGHT = 68;
  const ITEM_PADDING_TOP = 0;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  // 错误提示语
  const errorsHtml = (register, message) =>
    ((register && message) &&
      (<p style={{ margin: '5px 0', color: '#ea4949', fontSize: '12px' }}>
        <InfoIcon style={{ color: '#ea4949', fontSize: 14, verticalAlign: '-2px', marginRight: '3px' }} />
        {message}
      </p>))

  /**
   * 表单提交
   * @param 
   * {type: 0:退款 1:退货}  
   */
  const onSubmit = async reqData => {
    const { data } = await applyReturnRefund({ ...reqData, orderId: id, type: 1, multiView: reqData?.multiView?.toString() })
    if (data) {
      toast.showToast("toast.success", "操作成功")
      // 跳转到我的订单可以查看售后列表情况
      history.push('/my_orders?type=0')
    }
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    const { data } = await refundInfo(id)
    if (data) {
      setDetail(data)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // 获取页面订单id查询接口生成商品信息
  const { loading, data, error } = useFetch('/mall/order/' + id)
  const { data: order } = data ?? {}
  return (
    <Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <StatusBox loading={loading} error={error} empty={data}>
          <Box>
            {order?.orderItems?.map((item, index) => (
              <React.Fragment key={index}>
                <ListItem alignItems="flex-start" style={{ background: '#fff', marginBottom: '10px' }}>
                  <ListItemAvatar>
                    <img width={93} height={93} style={{ marginRight: '10px' }} alt="Remy Sharp" src={item?.thumb} />
                  </ListItemAvatar>
                  <ListItemText
                    primary={

                      <Box style={{ marginTop: '5px' }}>
                        <text style={{ fontSize: '14px', color: '#000', lineHeight: '17px', display: 'block', marginBottom: '4px' }}>订单：{id}</text>
                        <Box
                          p={2}
                          overflow='hidden'
                          height={33}
                          style={{ fontSize: '13px', lineHeight: '16px', display: '-webkit-box', WebkitLineClamp: 2, lineClamp: 2, WebkitBoxOrient: 'vertical', overflow: 'hidden', padding: 0 }}
                        >
                          {item?.name}
                        </Box>
                        <Box style={{ fontSize: '12px', color: '#747474', lineHeight: '17px', display: 'block' }}>x {item?.amount}</Box>
                        <text style={{ fontSize: '14px', color: '#FF0000', lineHeight: '17px', display: 'block', marginTop: 0 }}>¥ {item?.price / 100}</text>
                      </Box>
                    }
                  />
                </ListItem>
              </React.Fragment>
            ))}
          </Box>
        </StatusBox>

        <Box style={{ background: '#fff', marginBottom: '10px' }} p={2}>
          <Typography variant="body1">
            退货原因：
          <FormControl className={classes.selected}>
              <Select
                // multiple
                displayEmpty
                value={personName}
                onChange={e => handleChange(e)}
                input={<OutlinedInput />}
                renderValue={(selected) => {

                  if (selected.length === 0) {
                    setValue('reason', detail?.reason)
                    return !reasonList.find(el => el.name === detail?.reason)?.name ? <span style={{ color: '#BABABA' }}>选择退货原因</span> : reasonList.find(el => el.name === detail?.reason)?.name;
                  }

                  return selected.join(', ');
                }}
                MenuProps={MenuProps}
                inputProps={{ 'aria-label': 'Without label' }}
              >
                {reasonList.map((el) => (
                  <MenuItem
                    key={el.name}
                    value={el.name}
                    style={getStyles(el.name, personName, theme)}
                  >
                    {el.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <textarea style={{ opacity: 0, position: 'absolute' }} type="text" {...register("reason", { required: "选择退货原因" })} placeholder="选择退货原因" />
            <text style={{ textAlign: 'right' }}> {errorsHtml(errors?.reason, errors?.reason?.message)}</text>
          </Typography>
        </Box>

        <Box style={{ background: '#fff', marginBottom: '10px' }} p={2} className={classes.evidenceRow}>
          <Typography variant="body1">
            补充证据
        </Typography>
          <Box style={{ fontSize: 14, color: '#3F3F3F' }}>
            <TextareaAutosize
              aria-label="请填写更详细的说明，帮助商家处理售后问题"
              minRows={1}
              placeholder="请填写更详细的说明，帮助商家处理售后问题"
              {...register("supplement", { required: "请填写更详细的说明，帮助商家处理售后问题" })}
              defaultValue={detail?.supplement}
            />
          </Box>
          {errorsHtml(errors?.supplement, errors?.supplement?.message)}

          <Box mt={1} display='flex' flexDirection='column'>
            <Grid container spacing={4}>
              {urls?.map((url, index) =>
                <Grid item xs={2} key={url}>
                  <Box
                    width='59px'
                    height='59px'
                    style={{ background: `url(${url}) center `, backgroundSize: 'cover' }}
                  >
                    <CloseIcon
                      onClick={() => {
                        onDeleteImg(index)
                      }}
                      style={{
                        float: 'left',
                        position: 'relative',
                        top: '5px',
                        right: '-69%',
                        fontSize: '15px'
                      }}
                    />
                  </Box>
                </Grid>
              )}
              {urls?.length < 3 && <Grid item xs={3} key={-1}>
                <UploadOnly size={59} onUpload={onChooseImage} inputImageRef={inputImageRef} borderRadius={0} />
                <text style={{ fontSize: '12px', padding: '0 5px' }}>上传图片</text>
              </Grid>
              }
            </Grid>
            <input style={{ opacity: 0, position: 'absolute' }} type="text" {...register("multiView", { required: "请选择图片上传" })} placeholder="请选择图片上传" />
          </Box>
          {errorsHtml(errors?.multiView, errors?.multiView?.message)}

        </Box>

        <Box style={{ padding: '15px' }} className={classes.btn} ><ButtonBase type="submit" >提交</ButtonBase> </Box>
      </form>
    </Box>
  )
}