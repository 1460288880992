import { Box, Avatar, makeStyles, ButtonBase, LinearProgress } from "@material-ui/core";
import { useRequest } from "ahooks";
import { getGoodsComment } from "service/goods";
import { useNavigationBar, useAppHistory } from "utils/bridge";
import { Error } from "../../components/empty";
import { Swiper, ImageViewer } from 'antd-mobile'
import { useState } from "react";

const useStyles = makeStyles(theme => ({
    card: {
        margin: '10px',
        borderRadius: '10px',
        background: 'white',
        display: 'block',
        width: '-webkit-fill-available',
        boxShadow: '2px 2px 13px #eee',
    },
    cardMedia: {
        width: '100%',
        height: 210,
        borderRadius: '10px 10px 0 0',
        objectFit: 'cover',
    },
    cardTitle: {
        fontSize: 13,
        lineHeight: '1rem',
        maxHeight: '2.2rem',
        padding: '4px 8px 0 8px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        textAlign: 'left',
        color: 'grey',
    }
}))

/// 精选购推荐（商品评价)
const RecommendCommentPage = ({ goodsId, match }) => {
    const id = goodsId ?? match.params.id
    const { data, loading, error } = useRequest(() => getGoodsComment(id, 0), {
        formatResult: res => res.data
    })
    if (loading) {
        return <LinearProgress />
    }
    if (error) {
        return <Error />
    }
    return <div>
        <Comments comments={data} />
    </div>

}

const Comments = ({ comments }) => {
    if (comments.length === 0) {
        return <Box height='80px' p={2} color='#777'>
            还没有人发布评价哦
        </Box>
    }
    return <Box py={1}>
        {comments?.map(item => {
            return <CommentItem key={item.id} {...item} />
        })}
    </Box>
}

const CommentItem = ({ file, text, nickname, avatar }) => {
    const classes = useStyles()
    const [visible, setVisible] = useState(false)
    if (!file) {
        return <div />
    }
    const cover = file.split(',')
    console.log(cover);
    return <><ButtonBase className={classes.card} onClick={() => setVisible(true)}>
        <Swiper>
            {
                cover.map(image => <Swiper.Item key={image}>
                    <img className={classes.cardMedia} src={image} />
                </Swiper.Item>)
            }
        </Swiper>
            <Box className={classes.cardTitle}>
            {text}
            </Box>
            <Box p={1} display='flex' alignItems='center'>
            <Avatar src={avatar} style={{ width: 24, height: 24 }} />
                <Box fontSize={11} px={1} color='#777'>
                    {nickname}
                </Box>
            </Box>
    </ButtonBase>
        <ImageViewer.Multi
            images={cover}
            visible={visible}
            onClose={() => {
                setVisible(false)
            }}
        />
    </>
}


export default RecommendCommentPage