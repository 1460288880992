import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { isInAndroidContainer, isInIOSContainer, postMessage } from './webkit'


const jump = (path: string) => {
    postMessage('navigation', path)
}

export const setTitle = (title: string) => {
    postMessage('setTitle', title)
    document.title = title
}

type NavigationConfig = {
    title: string,
    bgColor: string,
    darkBar?: boolean
}

type ShareConfig = {
    type: number,
    content: BasicContent | CouponContent,
    jsonType: number,
    json: JsonData | string,
}

type BasicContent = {
    showPlayer?: Boolean,
    image: string,
    title: string,
    subTitle?: string,
    tag?: string,
    price?: number,
    source: string,
}

type CouponContent = {
    id: number,
    title: string,
    storeLogo: string,
    storeName: string,
    startTime: number,
    endTime: number,
}

type JsonData = {
    path: string,
    id?: string,
}

type Order = {
    id: number,
    price: number,
    payChannel: number
}

export const config = {
    maxContentWidth: 980,
    maxWidth: 820,
}

export const useNavigationBar = (config: NavigationConfig) => {
    useEffect(() => {
        setNavigationBar(config)
    }, [config])
}

export const setNavigationBar = (config: NavigationConfig) => {
    postMessage('setNavigation', JSON.stringify(config))
    document.title = config.title
}
/**
 * 设置分享内容，用于点击原生界面的右上角分享按钮
 * @param config 分享内容
 */
export const setShareInfo = (config: ShareConfig) => {
    const { content, json, type, jsonType } = config

    postMessage('setShare', JSON.stringify({
        type: String(type),
        jsonType: String(jsonType),
        content: typeof content === "string" ? content : JSON.stringify(content),
        json: typeof json === "string" ? json : JSON.stringify(json)
    }))
}

/**
 * 直接调用原生应用的分享界面
 * @param config 分享的内容体
 */
export const postShareInfo = (config: ShareConfig) => {
    const { content, json, type, jsonType } = config
    postMessage('share', JSON.stringify({
        type: String(type),
        jsonType: String(jsonType),
        content: typeof content === "string" ? content : JSON.stringify(content),
        json: typeof json === "string" ? json : JSON.stringify(json)
    }))
}

/**
 * 点击查看大图，调用原生查看大图操作
 * @param index 当前点击的图片在列表中的索引
 * @param images 全部图片列表（url数组）
 */
export const viewImage = (index: number, images: string[]) => {
    console.log(index, images, 'asss')
    postMessage('viewImage', JSON.stringify({
        index, images
    }))
}

export const callPay = (order: Order) => {
    postMessage('callPay', JSON.stringify(order))
}

export const callPaySdk = (orderInfo: string) => {
    postMessage('callPaySdk', orderInfo)
}

export const chooseObject = (key: string, item: any) => {
    console.log('onChoose', key, item);
    postMessage('choose-' + key, JSON.stringify(item))
}

export const log = (message: string) => {
    postMessage('log', message)
}

export const chooseLocation = () => {
    postMessage('location', "")
}

export const openLocation = (lat: string, lon: string, name: string) => {
    postMessage('openLocation', `${lon},${lat},${name}`)
}

export const scan = () => {
    postMessage('scan', 'bar')
}

export const toast = (message: string) => {
    postMessage("toast", message)
    console.log('toast', message)
}

export const isMobile = () => {
    return isInAndroidContainer || isInIOSContainer
}

/** 刷新原生的页面 */
export const refreshPage = (page: string, key?: string, params?: Map<String, String>) => {
    postMessage("refresh", JSON.stringify({
        page, key, params
    }))
}

export const useAppHistory = () => {
    const history = useHistory()
    if (isInIOSContainer || isInAndroidContainer) {
        return {
            push: (path: string) => {
                jump(path)
            },
            pop: () => {
                postMessage('dismiss', "nothing")
            }
        }
    } else {
        return {
            ...history,
            pop: () => {
                history.goBack()
            }
        }
    }
}

function onHandleMessage(key: string, message: string) {
    //处理native回调
    console.log('onHandleMessage', key, message);
    if (key === 'qr') {
        window.onScanCode?.(message)
    } else if (key === 'city') {
        if (localStorage.getItem('city') == null) {
            localStorage.setItem('city', message)
        }
    } else if (key === 'address') {
        const obj = JSON.parse(message)
        window.onChooseAddress?.(obj)
    }
    return 'ok'
}

window.onHandleMessage = onHandleMessage