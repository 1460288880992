import React, { useEffect, useState } from 'react'
import {Box, ButtonBase, makeStyles, Avatar, Fab, Button} from "@material-ui/core"
import EmojiEventsIcon from '@material-ui/icons/EmojiEvents';
import AddIcon from "@material-ui/icons/Add";
import { setNavigationBar, useAppHistory } from 'utils/bridge'
import {ServiceTab, SearchBar, MallSearchBar} from './base'
import { getDeamandList } from "service/goods";
import { priceCast } from '../../utils/price';
import {useHistory} from "react-router-dom";

const useStyle = makeStyles(theme => ({
    demandItem: {
        boxShadow: '0px 4px 12px 0px rgba(0, 0, 0, 0.05)',
        margin: '8px 16px',
        padding: theme.spacing(1),
        height: 130,
        minHeight: 130,
        backgroundColor: 'white',
        borderRadius: '4px',
        display: 'flex',
        flexDirection: 'row',
    },
    itemTag: {
        borderRadius: '4px',
        fontSize: '9px',
        color: '#2584F7',
        border: '1px solid #2584F7',
        padding: '2px 6px',
        marginTop: '2px',
    },
    price: {
        color: '#e12',
        fontSize: '16px',
        fontWeight: 500,
        '&::before': {
            content: '"¥"',
            fontSize: '12px',
        }
    },
    fab: {
        position: 'fixed',
        bottom: '20px',
        right: '20px',
    }
}))

const DemandPage = (props) => {
    const [deamandList, setDeamandList] = useState([])
    const [category, setCategory] = useState(null)
    const classes = useStyle()
    const history = useAppHistory()

    setNavigationBar({
        title: '需求广场',
        darkBar: true,
        bgColor: '#2584F7',
    })
    const onChoose = (i) => {
        console.log('onChoose', i)
        setCategory(i)
    }
    useEffect(async () => {
        console.log('load some data', category)
        if (category) {
            const { data } = await getDeamandList(category.id, 0, 0)
            if (data && data.data) {
                console.log(data.data)
                setDeamandList(data.data)
                return
            }
        }
        setDeamandList([])
    }, [category])


    return <Box display='flex' height={1} flexDirection='column'>
        {/*临时关闭入口<SearchBar />*/}
        <MallSearchBar />

        <ServiceTab onChange={onChoose} />
        <Box flex={1} overflow='scroll' display='flex' flexDirection='column' >
            {
                deamandList.map(item => <DemandItem key={item} {...item} />)
            }
        </Box>
            <Fab color="primary" className={classes.fab} onClick={() => history.push('/service/demand/add')}>
                <AddIcon style={{ color: 'white' }} />
            </Fab>
    </Box>

}

const DemandItem = ({ title, img, category, urgent, name, reward, unit ,content ,registerId}) => {
    const classes = useStyle()
    const history = useHistory();
    const gotoCS = () => {
        history.push('app://chat/' + registerId)
    }
    return <ButtonBase className={classes.demandItem} onClick={gotoCS}>
        <Box color='#444' fontSize={17} display='flex' flexDirection='column' alignItems='center'>
            <Avatar variant='rounded' src={img} style={{ width: 62, height: 62 }} />
            <Box display='flex' alignItems='center' m={1}>
                {name}
                {/*<EmojiEventsIcon />*/}</Box>
        </Box>
        <Box fontSize={18} flex='1' textAlign='left' p={1} display='flex' flexDirection='column' height='100%'>
            {title}
            <Box display='flex' alignItems='center'>
                <Box fontSize={12} color={'#888'}>{content}</Box>
                <Box width={4} />
                {
                    urgent ?
                        <img src='https://202108251114.oss-cn-shanghai.aliyuncs.com/app/ic_hurry.png' style={{ width: 20, height: 20 }} /> : null
                }
            </Box>

            <Box flex='1' />
            <Box fontSize={13} ><Box className={classes.price} component='span'>{priceCast(reward)}</Box>
                {unit ? `/${unit}` : ''}
                <Box color={"#2584F7"} style={{borderRadius:"25px" ,fontSize:"12px",border:"#2584F7 1px solid",float:"right" ,padding:"6px"}}>立即接单</Box>
            </Box>
        </Box>
    </ButtonBase>
}

export default DemandPage