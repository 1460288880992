import { Box, Button, makeStyles, TextField } from "@material-ui/core"
import produce from "immer"
import { useEffect, useState } from "react"
import { setNavigationBar, useAppHistory } from 'utils/bridge'
import { postJournal } from "service/goods"

const useStyle = makeStyles({
    saveButton: {
        borderRadius: 20,
    }
})


export const CreateJournalPage = () => {
    const classes = useStyle()
    const history = useAppHistory()
    useEffect(() => {
        setNavigationBar({ title: '写日记' })
    }, [])

    const [journal, setJournal] = useState({
        title: '',
        content: ''
    })

    const creatJournal = async () => {
        if (journal.title && journal.content) {
            const { data } = await postJournal(journal.title + '\n' + journal.content)
            console.log(data)
            if (data.code === "200") {
                history.pop()
            }
        }
    }
    return <Box
        padding={2}
        bgcolor='white' height='100%' display='flex' flexDirection='column'>
        <TextField fullWidth title="标题"
            placeholder="输入一个标题"
            value={journal.title}
            onChange={(e) => setJournal(produce(draft => {
                draft.title = e.target.value
            }))}
            style={{ paddingTop: 20, marginBottom: 20 }}
        />

        <Box flex='1'>
            <TextField title="标题"
                fullWidth
                placeholder="输入一个标题"
                multiline
                variant="outlined"
                maxRows={20}
                minRows={5}
                value={journal.content}
                onChange={(e) => setJournal(produce(draft => {
                    draft.content = e.target.value
                }))}
            />
        </Box>

        <Box mx={1} marginBottom={4}>
            <Button variant='contained' color='primary'
                onClick={creatJournal}
                disabled={!journal.title || !journal.content}
                fullWidth className={classes.saveButton}
            >保存</Button>
        </Box>
    </Box>
}