import { Box, Typography, ButtonBase } from '@material-ui/core'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import { useNavigationBar, useAppHistory } from "utils/bridge"

export default function PaymentSuccessful({ match }) {
  const { id } = match.params
  const history = useAppHistory()

  useNavigationBar({
    title: '支付成功',
  })
  return (
    <Box style={{ textAlign: 'center', background: '#fff', height: '100%' }}>

      <Box style={{ textAlign: 'center', background: 'linear-gradient(-45deg, #E9097A,#c90154)', color: '#fff' }} padding={8}>
        <CheckCircleIcon style={{ fontSize: '30px', color: '#fff' }} />
        <Typography style={{ fontSize: '16px', marginTop: '5px' }} >支付成功</Typography>
        <Typography style={{ fontSize: '14px', marginTop: '15px' }} >您的商品已经支付成功。</Typography>
      </Box>

      <ButtonBase onClick={() => history.push(`/order/${id}`)} style={{ borderRadius: '100px', background: 'linear-gradient(-45deg, #E9097A,#c90154)', color: '#fff', padding: '12px 60px', fontSize: '14px', marginTop: '100px' }}>查看订单详情</ButtonBase>
    </Box>
  )
}