import React, { useState } from 'react'
// 引入编辑器组件
import BraftEditor from 'braft-editor'
// 引入编辑器样式
import 'braft-editor/dist/index.css'
import Album from '@material-ui/icons/Image'
import { UploadButton } from './base'
import { Button, Dialog, DialogTitle } from "@material-ui/core";

export default function EditorDialog({ open, onClose, onFinish }) {
    const [editorState, setEditorState] = useState(BraftEditor.createEditorState(''))


    async function componentDidMount() {
        // 假设此处从服务端获取html格式的编辑器内容
        const htmlContent = "" // await fetchEditorContent()
        // 使用BraftEditor.createEditorState将html字符串转换为编辑器需要的editorStat
        this.setState({
            editorState: BraftEditor.createEditorState(htmlContent)
        })
    }

    const submitContent = async () => {
        // 在编辑器获得焦点时按下ctrl+s会执行此方法
        // 编辑器内容提交到服务端之前，可直接调用editorState.toHTML()来获取HTML格式的内容
        const htmlContent = this.state.editorState.toHTML()
        console.log(htmlContent)
        // const result = await saveEditorContent(htmlContent)
    }

    const handleEditorChange = (state) => {
        setEditorState(state)
    }

    const controls = ['headings', 'bold', 'italic', 'underline', 'text-color', 'separator', 'list-ul',]
    const extendControls = [
        {
            key: 'antd-uploader',
            type: 'component',
            component: (
                <UploadButton
                    accept="image/*"
                    showUploadList={false}
                >
                    {/* 这里的按钮最好加上type="button"，以避免在表单容器中触发表单提交，用Antd的Button组件则无需如此 */}
                    <button type="button" className="control-item button upload-button" data-title="插入图片">
                        <Album />
                    </button>
                </UploadButton>
            )
        }
    ]
    return (
        <Dialog
            title="写文章"
            fullScreen={true}
            open={open}
        >
            <div className="my-component" style={{ backgroundColor: 'white' }}>
                <BraftEditor
                    controls={controls}
                    extendControls={extendControls}
                    value={editorState}
                    onChange={handleEditorChange}
                    onSave={submitContent}
                />
            </div>
            <Button variant='contained' color='primary' onClick={() => {
                const htmlContent = editorState.toHTML()
                onFinish(htmlContent)
            }}>
                完成
            </Button>

        </Dialog>
    )


}