
import { useState, useEffect } from "react"
import {
    Box, ButtonBase, Divider, makeStyles, withStyles,
} from "@material-ui/core"
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos"
import { setNavigationBar, useAppHistory } from 'utils/bridge'
import { myMall, setMall, getCategoryByPid } from 'service/goods'
import { Upload } from "components/base"
import InputTextDialog from "components/inputText"
import ChooseListDialog from 'components/chooseListDialog'
import { useToast } from "models/toast"
import produce from "immer";

const useStyles = makeStyles((theme) => ({
    buttonGroup: {
        borderRadius: theme.spacing(1),
        backgroundColor: 'white',
        margin: theme.spacing(1),
        display: 'flex',
        flexDirection: 'column',
    },
    buttonItem: {
        display: 'flex',
        fontSize: '15px',
        padding: theme.spacing(1),
        paddingLeft: '16px',
        textAlign: 'left',
        minHeight: '54px',
        alignItems: 'center',
    }
}))

const Arrow = withStyles({
    root: {
        color: '#aaa', fontSize: 18
    }
})(ArrowForwardIosIcon)

function SectionRow({ title, onClick, children }) {
    return <ButtonBase style={{
        backgroundColor: 'white',
        borderRadius: 8,
        padding: 8,
        margin: 8,
        display: 'flex',
        alignItems: 'center',
        fontSize: '15px',
        width: 'calc(100% - 16px)'
    }} onClick={onClick}>
        <Box p={1} flex='1' textAlign='left'>
            {title}
        </Box>
        {children}
        <Arrow />
    </ButtonBase>
}

const SHOP_NAME = "店铺名称"
const SHOP_INTRO = "店铺简介"

const MyShopInfo = props => {
    const classes = useStyles()
    const [open, setOpen] = useState(false)
    const [target, setTarget] = useState(SHOP_NAME)
    const [mallInfo, setMallInfo] = useState(null)

    const [categoryList, setCategoryList] = useState([])
    const [category, setCategory] = useState(-1)
    const [showDialog, setShowDialog] = useState(false)
    const toast = useToast()

    const openDialog = () => {
        if (categoryList) {
            setShowDialog(true)
        }
    }
    const handleClose = (value) => {
        setShowDialog(false)
        console.log(value)
        if (value) {
            setCategory(value)
            update("category", Number(value))
        }
    }

    useEffect(async () => {
        setNavigationBar({ title: '店铺信息' })
        const { data } = await myMall()
        if (data) {
            setMallInfo(data)
        }
        //0;线上 1:线下 2:工厂 3:二手 10;无实体店服务 11:有实体店服务
        const pidMap = {
            0: 10,
            1: 15,
            2: 10,
            3: 10,
            10: 11
        }
        const { data: result } = await getCategoryByPid(pidMap[data.type])
        if (result) {
            const categoryList = result.map(i => i.category)
            setCategoryList(categoryList)
        }
    }, [])

    async function update(key, value) {
        const { error } = await setMall({
            id: mallInfo.id,
            [key]: value
        })
        if (!error) {
            toast.showToast('toast.success', '更新成功')
            setMallInfo(produce((draft) => {
                draft[key] = value
            }))
        }
    }

    if (!mallInfo) {
        return <div />
    }

    return <Box paddingTop={1}>
        <Box className={classes.buttonGroup}>
            <div
                className={classes.buttonItem}>
                <Box >店头像</Box>
                <div style={{ flex: 1 }} />
                <Upload size={40} origin={mallInfo.storeLogo}
                    onUpload={url => {
                        update("storeLogo", url)
                    }}
                />
            </div>
            <Divider style={{ backgroundColor: '#eee', height: '2px' }} />
            <ButtonBase
                onClick={() => {
                    setTarget(SHOP_NAME)
                    setOpen(true)
                }}
                className={classes.buttonItem}>
                名称
                <div style={{ flex: 1 }} />
                {mallInfo.storeName}
                <Arrow />
            </ButtonBase>
            <Divider style={{ backgroundColor: '#eee', height: '2px' }} />
            <ButtonBase
                onClick={() => {
                    setTarget(SHOP_INTRO)
                    setOpen(true)
                }}
                className={classes.buttonItem}>
                店简介
                <div style={{ flex: 1 }} />
                {mallInfo.intro}
                <Arrow />
            </ButtonBase>
            <Divider style={{ backgroundColor: '#eee' }} />

        </Box>

        <SectionRow title="店铺分类" onClick={openDialog}>
            {category < 0 ? '选择分类' : categoryList.find(i => i.id == category)?.name}
        </SectionRow>

        <SectionRow title="认证" />

        <ChooseListDialog
            open={showDialog}
            onClose={handleClose}
            options={categoryList}
            value={category}
        />

        <InputTextDialog open={open}
            title={target}
            hint={target === SHOP_INTRO ? "填写 店铺简介,\n店铺经营时间 等内容" : "填写店铺名称"}
            multiLine={target === SHOP_INTRO}
            onCancel={() => setOpen(false)}
            onFinish={(value) => {
                console.log(value)
                update(target === SHOP_INTRO ? "intro" : "storeName", value)
                setOpen(false)
            }}
        />
    </Box>
}

export default MyShopInfo