import React from 'react'
import { Box, ButtonBase, makeStyles, Button } from '@material-ui/core'
import { setNavigationBar, useAppHistory } from "utils/bridge";
import { Upload } from '../../components/base';
import { getCategoryByPid, postDemand } from '../../service/goods';
import { wrapBlueTheme } from './base';
import ChooseListDialog from 'components/chooseListDialog'
import NumberFormat from 'react-number-format'
import produce from 'immer'

const useStyle = makeStyles(theme => ({
    imageChoose: {
        width: 100,
        height: 100,
        backgroundColor: 'white',
        borderRadius: 8,
        color: '#999',
    },
    inputItem: {
        backgroundColor: 'white',
        marginTop: '16px',
        borderRadius: 8,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: 12,
    },
    inputPanel: {
        backgroundColor: 'white',
        marginTop: '16px',
        borderRadius: 8,
        display: 'flex',
        flexDirection: 'column',
        padding: 12,
    },
    input: {
        border: 'none',
        outlineStyle: 'none',
        textAlign: 'right',
        fontSize: '14px',
    },
    mutliInput: {
        border: 'none',
        outlineStyle: 'none',
        fontSize: '14px',
    }
}))


const PublishDemandPage = () => {
    const history = useAppHistory()
    const classes = useStyle()
    const [category, setCategory] = React.useState(-1)
    const [showDialog, setShowDialog] = React.useState(false)
    const [categoryList, setCategoryList] = React.useState([])

    const [form, setForm] = React.useState({
        registrant: 0,
        register_id: 1
    })

    const openDialog = () => {
        if (categoryList) {
            setShowDialog(true)
        }
    }
    const handleClose = (value) => {
        setShowDialog(false)
        console.log(value)
        if (value) {
            setCategory(value)
            setForm(produce((draft) => {
                draft.category = value
            }))
        }
    }

    React.useEffect(async () => {
        setNavigationBar({
            title: '发布我的需求',
            darkBar: true,
            bgColor: '#2584F7',
        })

        const { data } = await getCategoryByPid(11)
        if (data) {
            const list = data.map(i => i.category)
            setCategoryList(list)
        }
    }, [])

    const publish = async () => {
        // form
        await postDemand(form)
        history.pop()
    }
    return <Box p={2}>
        <Upload onUpload={url => {
            setForm(produce((draft) => {
                draft.img = url
            }))
        }} />

        <Box className={classes.inputItem}>
            标题
            <Box flex='1' />
            <input className={classes.input} type="text" id="fname" name="firstname"
                placeholder="输入需求标题"
                onChange={(e) => setForm(produce((draft) => {
                    draft.title = e.target.value
                }))}
            ></input>
        </Box>

        <Box className={classes.inputItem} fontSize={14}>
            需求分类
            <Box flex='1' />
            <ButtonBase disableRipple onClick={openDialog}>
                {category < 0 ? '选择分类' : categoryList.find(i => i.id == category)?.name}
            </ButtonBase>
        </Box>

        <Box className={classes.inputPanel}>
            需求详情描述
            <Box height={8} />
            <textarea className={classes.mutliInput} name="Text1" rows="5"
                onChange={(e) => setForm(produce((draft) => {
                    draft.content = e.target.value
                }))}
                placeholder="输入需求标题"></textarea>
        </Box>

        <Box className={classes.inputItem} pattern="[0-9]*">
            意向金
            <Box flex='1' />
            <NumberFormat
                onValueChange={(values) => {
                    setForm(produce((draft) => {
                        draft.reward = values.value
                    }))
                }}
                className={classes.input}
                isNumbericString
                placeholder="输入我的预算"
                prefix={'¥'}
            />
        </Box>

        <Box height={30} />

        <Button variant='contained' color='primary' fullWidth
            onClick={publish}
        >发布</Button>

        <ChooseListDialog
            open={showDialog}
            onClose={handleClose}
            options={categoryList}
            value={category}
        />
    </Box>
}


export default wrapBlueTheme(PublishDemandPage)