import React from 'react'
import { Box, Button } from '@material-ui/core'
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';

export default function ChooseListDialog(props) {
    const { onClose, value: valueProp, options, open, ...other } = props
    const [value, setValue] = React.useState(valueProp);
    const radioGroupRef = React.useRef(null);

    React.useEffect(() => {
        if (!open) {
            setValue(valueProp);
        }
    }, [open])

    const handleEntering = () => {
        if (radioGroupRef.current != null) {
            radioGroupRef.current.focus()
        }
    }

    const handleCancel = () => {
        onClose()
    }

    const handleOk = () => {
        onClose(value)
    }

    const handleChange = (event) => {
        setValue(event.target.value)
    }

    return (
        <Dialog
            maxWidth="lg"
            onEntering={handleEntering}
            aria-labelledby="confirmation-dialog-title"
            open={open}
            {...other}
        >
            <DialogTitle id="confirmation-dialog-title">请选择</DialogTitle>
            <DialogContent dividers>
                <Box minWidth="280px">
                    <RadioGroup
                        ref={radioGroupRef}
                        name="category"
                        value={value}
                        onChange={handleChange} >
                        {
                            options.map((option) => <FormControlLabel
                                value={String(option.id)}
                                key={option.id}
                                control={<Radio />}
                                label={option.name} />
                            )
                        }
                    </RadioGroup>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={handleCancel} color="primary">
                    取消
                </Button>
                <Button onClick={handleOk} color="primary">
                    确认
                </Button>
            </DialogActions>
        </Dialog>
    )
}