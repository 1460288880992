import React, { useEffect } from 'react';
import Box from '@material-ui/core/Box';
import { ButtonBase,Grid } from '@material-ui/core';
import {getGoodsByStore} from '../service/goods';

export default function StoreProductItem({ id }) {
    const [productList, setProductList] = React.useState([])

    useEffect(async () => {
        const { data } = await getGoodsByStore(id)
        setProductList(data.data)
    }, [])

    return (
        <ButtonBase width='100%' disableRipple >
            <Grid container spacing={1} flexDirection='row'>
                {
                    productList?.slice(0, 3).map(item => <Grid item xs={4}>
                        <img width='100%' height='110px' style={{fitObject: 'cover', borderRadius: 8, marginTop: 12}} src={item.productVariants.thumb}/>
                    </Grid>)
                }
            </Grid>
        </ButtonBase>
    );
}