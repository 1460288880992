import { Box, Button, ButtonBase, makeStyles } from "@material-ui/core"
import { useEffect, useState } from "react"
import { setNavigationBar } from "../../utils/bridge"

const useStyle = makeStyles({
    bottomContainer: {
        position: 'fixed',
        bottom: 10,
        padding: 10,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        left: 0
    },
    monthCard: {
        width: 80,
        height: 80,
        minWidth: 80,
        fontSize: 25,
        borderRadius: 10,
        marginRight: 10,
    }
})

const MonthCard = ({ month, active, onClick }) => {
    const classes = useStyle()
    const style = active === month ? { backgroundColor: "#FDAE33", color: 'white' }
        : { backgroundColor: "white", color: '#666' }

    return <ButtonBase
        onClick={onClick(month)}
        style={style}
        className={classes.monthCard}>
        {month}月
    </ButtonBase>
}

export const BillListPage = (props) => {
    const classes = useStyle()
    const [month, setMonth] = useState(8)

    useEffect(() => {
        setNavigationBar({ title: "账单" })
    }, [])

    const onChooseMonth = (e) => {
        setMonth(e)
    }
    return <Box padding={1}>
        <Box overflow="scroll" display="flex">
            <MonthCard month={8} active={month} onClick={onChooseMonth} />
            <MonthCard month={7} active={month} onClick={onChooseMonth} />
            <MonthCard month={6} active={month} onClick={onChooseMonth} />
            <MonthCard month={5} active={month} onClick={onChooseMonth} />
            <MonthCard month={4} active={month} onClick={onChooseMonth} />
            <MonthCard month={3} active={month} onClick={onChooseMonth} />
            <MonthCard month={2} active={month} onClick={onChooseMonth} />
        </Box>
        <Box color='white' >

        </Box>

        <Box className={classes.bottomContainer}>
            <Button color='primary' variant='contained' fullWidth
                style={{ maxWidth: 500, borderRadius: 25 }}>
                记一笔
            </Button>
        </Box>
    </Box>
}
