import {Box, Button, Grid, List} from "@material-ui/core"
import qs from 'qs'
import { StatusBox } from '../components/empty'
import { useNavigationBar } from "../utils/bridge"
import React from "react";
import {getGoodsList, getProductByStoreType, groupBuyingList, shareDynamicList} from "../service/goods"
import { useRequest } from 'ahooks'
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { GoodsListItem } from "../components/goodsItem";
import {GroupbuyingItem} from "../jwl/flashSale";
import RecipeReviewCard from "../components/shareItem";

const GoodsList = ({ location, ...props }) => {
    const query = qs.parse(location.search, { ignoreQueryPrefix: true })
    const { category } = query
    const [type, setType] = React.useState(9);
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose=(value) => () => {
        setAnchorEl(null);
        if (type === -1 || type === value ){
            return
        }
        setType(value)
    };

    let request ;
    if (type === 9){
        request = getGoodsList({ pageNo: 0, pageSize: 20, category: category })
    }else if (type === 8){
        request = getGoodsList({ pageNo: 0, pageSize: 20, category: category ,dateCreated:true})
    }else if (type === 12){
        request = groupBuyingList(category)
    }else if (type === 13){
        request = shareDynamicList({ type: 1, subType: category })
    }else{
        request = getProductByStoreType(null,null,null,type,0,20)
    }

    useNavigationBar({ title: '商品列表' })

    const { mutate,data, loading, error } = useRequest(request, {
        refreshDeps: [category,type],
        formatResult: resp => resp.data
    })

    console.log('data',data)

    const listStyle = { overflow: 'scroll', height: '100%', padding: '0' }

    const changeType =(value) => () => {
        if (value === type){
            return
        }
        setType(value)
        mutate(null)
    }
    return (<Box height={1}>
        <Box height={48} display='flex' bgcolor='white' alignItems='center' justifyContent='space-between'>
            <Button onClick={changeType(9)} >全部</Button>
            <Button onClick={changeType(8)} >新品</Button>
            <Button onClick={changeType(12)}>限时团</Button>
            <Button onClick={changeType(13)}>悦分享</Button>
            <Button onClick={handleClick} >类别</Button>
        </Box>
        <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose(-1)}
        >
            <MenuItem onClick={handleClose(0)}>线上店</MenuItem>
            <MenuItem onClick={handleClose(1)}>实体店</MenuItem>
            <MenuItem onClick={handleClose(2)}>工厂</MenuItem>
            <MenuItem onClick={handleClose(3)}>闲置</MenuItem>
        </Menu>
        <StatusBox loading={loading} error={error} empty={data}>

            {type < 10
                && <List height='100%' style={listStyle}>
                {
                    data?.map(item =>
                        <GoodsListItem item={item} key={item.productVariants?.id || 0}/>
                    )
                }
                </List>
            }
            {type === 12
                && <List height='100%' style={listStyle}>
                        {
                            data?.map(item =>
                                <GroupbuyingItem {...item} key={item}/>
                            )
                        }
                    </List>
            }
            {type === 13
            &&

            <Grid container spacing ={1} >
                {
                    data?.map(item =>
                        <Grid item  xs={6} key={item.id}>
                            <RecipeReviewCard {...item} key={item}/>
                        </Grid>
                    )
                }
            </Grid>
            }


        </StatusBox>
    </Box>)
}

export default GoodsList