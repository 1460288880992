import { Box, Switch } from '@material-ui/core'
import { useMemo, useState } from 'react'

import {
    Form, Input, Cascader, Button, Popup, TextArea
} from 'antd-mobile'
import { connect } from "react-redux";
import * as AddressActions from '../models/address'
import json from '../models/city.json'
import { useAccount } from '../utils/hooks';

const castCityData = (data) => ({
    label: data.label,
    value: data.label,
    children: data.children?.map(castCityData)
})

const options = json.map(castCityData)

const AddressEditPage = ({ show, onClose, addAddress, item }) => {
    const [cascaderVisiable, setCascaderVisiable] = useState(false)

    const { myself } = useAccount()
    const initialValues = useMemo(() => {
        if (!item) {
            return {}
        }
        const { province, city, area, ...rest } = item
        return {
            city: [province, city, area],
            ...rest
        }
    }, [item])

    const submit = (values) => {
        const { city, ...reset } = values
        console.log(city);
        addAddress({
            province: city[0],
            city: city[1],
            area: city[2],
            userId: myself.id,
            ...reset
        })
        onClose()
    }

    return (
        <Popup
            visible={show}
            onMaskClick={onClose}
        >
            <Box key={item} bgcolor='white' borderRadius='10px' m={2}>
                <Form
                    layout='horizontal' mode='card'
                    onFinish={submit}
                    initialValues={initialValues}
                    footer={
                        <Button
                            block
                            color='primary'
                            type='submit'>
                            保存
                        </Button>
                    }
                >
                    <Form.Header>填写收件人信息</Form.Header>
                    <Form.Item name='name' label='姓名' rules={[{ required: true }]}>
                        <Input placeholder='请输入收件人姓名' />
                    </Form.Item>
                    <Form.Item name='phone' label='手机号'
                        rules={[{ required: true }, { pattern: /[1]\d{10}/, message: '手机号格式不正确' }]}>
                        <Input placeholder='请输入收件人手机号码' maxLength={11} />
                    </Form.Item>
                    <Form.Item
                        name='city'
                        label='城市'
                        trigger='onConfirm'
                        rules={[{ required: true }]}
                        onClick={() => {
                            setCascaderVisiable(true)
                        }}
                    >
                        <Cascader
                            options={options}
                            visible={cascaderVisiable}
                            onClose={() => {
                                setCascaderVisiable(false)
                            }}
                        >
                            {(prop) => <Box textAlign='right' p={1}>{prop.map(i => i.label).join("-")}</Box>}
                        </Cascader>
                    </Form.Item>
                    <Form.Item name='address' label='地址' help='详情地址'
                        rules={[{ required: true }]}>
                        <TextArea
                            placeholder='请输入地址'
                            maxLength={100}
                            rows={2}
                            showCount
                        />
                    </Form.Item>
                    <Form.Item
                        name='preset'
                        label='设为默认'
                        childElementPosition='right'
                    >
                        <Switch />
                    </Form.Item>
                </Form >
            </Box >
        </Popup>)
}

const propsMap = ({ address }) => address

export default connect(propsMap, AddressActions)(AddressEditPage)