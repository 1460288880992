import { Box, ButtonBase, makeStyles, Grid, Button, IconButton } from "@material-ui/core"

import Avatar from '@material-ui/core/Avatar';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import MessageIcon from '@material-ui/icons/Message';
import { useAppHistory, viewImage } from 'utils/bridge';
import moment from "moment";
import MoreHoriz from "@material-ui/icons/MoreHoriz";
import React, { useState } from "react";
import { ReportMenuList } from "./report";
import { useAccount } from "../../utils/hooks";
import {useToast} from "../../models/toast";
import {useDispatch} from "react-redux";
import {followUser, removeFollowUser} from "../../models/account";
import { ImageViewer, Swiper } from 'antd-mobile'

const useStyles = makeStyles((theme) => ({
    textMinutes: {
        color: '#CACACA',
        fontSize: 11,
    },
    btBorder: {
        height: 26,
        width: 62,
        borderRadius: 14,
        borderColor: '#fdae33',
        border: '1px',
        borderStyle: 'solid',
        fontSize: 12,
    },
    picItem: {
        backgroundColor: '#eaeaea',
        width: '100%',
        borderRadius: '4px',
        objectFit: 'contain'
    }
}))

//图片item
export const TopicPicItem = ({ fileContent, height = 110, grid = 4 }) => {
    const classes = useStyles()
    const [visible, setVisible] = useState(false)
    const [index, setIndex] = useState(0)
    const imgs = fileContent?.split(",") ?? []
    if (imgs.length === 0) {
        return <div />
    }
    const onClickImg = (index) => {
        setIndex(index)
        setVisible(true)
    }
    return <>
        <Swiper>
            {
                imgs.map((item, index) =>
                    <Swiper.Item item xs={grid} key={item.id}>
                        <img src={item}
                            onClick={() => onClickImg(index)}
                            className={classes.picItem}
                            style={{ objectFit: 'cover' }} />
                    </Swiper.Item>
                )
            }
        </Swiper>
        <ImageViewer.Multi
            images={imgs}
            visible={visible}
            defaultIndex={index}
            onClose={() => {
                setVisible(false)
            }}
        />
    </>
}

export const DynamicItem = ({ item }) => {
    const classes = useStyles();
    const {
        id,
        udId,
        file,
        text,
        avatar,
        nickname,
        tag,
        comments,
        thumbs,
        createTime,
    } = item
    const [openMenu, setOpenMenu] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null)
    const dispatch = useDispatch();
    const toast = useToast();
    const { myself } = useAccount()

    const showMenu = (e) => {
        setOpenMenu(true)
        setAnchorEl(e.currentTarget);
        e.stopPropagation()
    }

    const history = useAppHistory()
    return <Box my={1}>
        <Box display="flex">
            <Avatar alt="火" src={avatar} />

            <Box display="flex" flexDirection="column" style={{ marginLeft: 10, }}>
                <Box display="flex" flexDirection="row" alignItems='center' color='#333' fontSize={15}>
                    {nickname}
                </Box>
                <div className={classes.textMinutes} >{moment(createTime).fromNow()}</div>
            </Box>
            <Box flex={1} />
            {
                myself?.id !== udId && <IconButton onClick={showMenu}> <MoreHoriz /></IconButton>
            }
        </Box>
        {
            tag ? <Box fontSize={14} my={1}  onClick={() => history.push(`/dynamic/${id}`)}>
                <span style={{ color: "#FDB133", }}>#{tag}# </span>
                <span style={{ color: "#000", marginLeft: 5, }}>{text}</span>
            </Box> : <Box fontSize={14} my={1} onClick={() => history.push(`/dynamic/${id}`)}>{text}</Box>
        }
        <Box className={classes.textShow} bgcolor="#f8f8f8"
            borderRadius={4} p={1} fontSize={10}
            onClick={() => history.push(`/dynamic/${id}`)}
            my={1}>
            <TopicPicItem fileContent={file} height='140' />
        </Box>

        <Box hidden display="flex" flexDirection='row' mt={1}>

            <ButtonBase style={{ color: "#888888", fontSize: 12, marginLeft: 30, }} p={1} onClick={() => history.push(`/dynamic/${id}`)}>
                <FavoriteBorderIcon color="disabled" fontSize="small"></FavoriteBorderIcon>
                <div style={{ color: "#888888", fontSize: 12, marginLeft: 2, }}>{thumbs}</div>
            </ButtonBase>

            <ButtonBase style={{ color: "#888888", fontSize: 12, marginLeft: 30, }} p={1} onClick={() => history.push(`/dynamic/${id}`)}>
                <MessageIcon color="disabled" fontSize="small"></MessageIcon>
                <div style={{ color: "#888888", fontSize: 12, marginLeft: 2, }}>{comments}</div>
            </ButtonBase>
        </Box>

        <ReportMenuList
            type="2"
            user={udId}
            id={id}
            open={openMenu}
            anchorEl={anchorEl}
            handleClose={() => setOpenMenu(false)}
        />
    </Box>
}

export const isVideo = (url) => {
    if (url) {
        return url.endsWith("mp4") || url.endsWith('MOV')
    } else {
        return false
    }
}

//视频item
export const VideoItem = ({ item }) => {
    const classes = useStyles();
    const history = useAppHistory()
    const { id, firstImg,
        tag, nickname,
        textContent,
        thumbs, comments,
        fileContent, createTime } = item
    const toast = useToast();
    const { myself,followList } = useAccount()
    const dispatch = useDispatch();

    const [openMenu, setOpenMenu] = useState(false)
    const [anchorEl, setAnchorEl] = React.useState(null);
    const showMenu = (e) => {
        setOpenMenu(true)
        setAnchorEl(e.currentTarget);
        e.stopPropagation()
    }

    const focusText = followList?.map(i => i.user)?.includes(item.udId) ? "已关注" : "关注"

    const postFocus = async () => {
        if(focusText == '关注'){
            await followUser(item.udId,dispatch)
            toast.showToast('toast.success','已关注')
        }else{
            await removeFollowUser(item.udId,dispatch)
            toast.showToast('toast.success','已取关')
        }
    }

    return <Box my={1}>
        <Box display="flex" alignItems='center'>
            <Avatar alt="火" src={firstImg} />

            <Box display="flex" flexDirection="column" style={{ marginLeft: 5, }}>
                <Box display="flex" flexDirection="row" alignItems='center' fontSize={15}>
                    <div style={{ color: "#FDB133" }}>{nickname}</div>
                    <img height={24} width={24} src={window.ossUrl + '/image/ic_grade%402x.png'}></img>
                </Box>
                <div className={classes.textMinutes} >{moment(createTime * 1000).fromNow()}</div>
            </Box>

            <Box flex='1' />
            <ButtonBase className={classes.btBorder} onClick={postFocus}>
                <div fontSize={13} style={{ color: "#FDB133"}}>{focusText}</div>
            </ButtonBase>
            {
                myself?.id !== item.udId && <IconButton onClick={showMenu}>
                    <MoreHoriz />
                </IconButton>
            }
        </Box>
        <Box fontSize={14} mt={1} onClick={() => history.push(`/dynamic/${id}`)} >
            {tag && <span style={{ color: "#FDB133", marginLeft: 5, }}>#{tag}# </span>}
            <span style={{ color: "#000", marginLeft: 5, }}>{textContent}</span>
        </Box>



        <Box mt={0.5} bgcolor='#e9e9e9' display='flex' alignItems='center' justifyContent='center'>
            <video height={220}
                style={{ borderRadius: 8, objectFit: 'cover', width: '100%' }}
                controls
                controlsList='nodownload  noremoteplayback'
                preload="none"
                poster={`${fileContent}.jpg`}>
                <source src={fileContent} />
            </video>
        </Box>

        <Box display="flex" fontSize={12} color='#888' flexDirection='row' mt={1}>

            <ButtonBase >
                <FavoriteBorderIcon color="disabled" fontSize="small"></FavoriteBorderIcon>
                <div style={{ color: "#888888", fontSize: 12, marginLeft: 2, }}>{thumbs}</div>
            </ButtonBase>

            <ButtonBase style={{ marginLeft: 30, }}>
                <MessageIcon color="disabled" fontSize="small"></MessageIcon>
                <div style={{ color: "#888888", fontSize: 12, marginLeft: 2, }}>{comments}</div>
            </ButtonBase>

            <ReportMenuList
                type="2"
                user={item.udId}
                id={id}
                open={openMenu}
                anchorEl={anchorEl}
                handleClose={() => setOpenMenu(false)}
            />
        </Box>

    </Box>
}

//文章item
export const ArticleItem = ({ item }) => {
    const classes = useStyles();
    const history = useAppHistory()
    const { id, firstImg, nickname, textContent, createTime,
        fileContent } = item
    return <Box my={1}>
        <Box display="flex" >
            <Avatar alt="火" src={firstImg} />

            <Box display="flex" flexDirection="column" style={{ marginLeft: 5, }}>
                <Box display="flex" flexDirection="row" alignItems='center' fontSize={15}>
                    <div style={{ color: "#FDB133" }}>{nickname}</div>
                    <img height={24} width={24} src={window.ossUrl + '/image/ic_grade%402x.png'} />
                </Box>
                <div className={classes.textMinutes} >{moment(createTime * 1000).fromNow()}</div>
            </Box>
        </Box>

        <ButtonBase style={{ textAlign: 'left', width: '100%' }}
            onClick={() => history.push('/dynamic/' + id)}>
            <Box p={1} display='flex' width='100%'>
                <Box flex='1' >
                    <Box color='#222' fontSize='15px' marginRight={1}>
                        {textContent}
                    </Box>
                </Box>
                <img src={fileContent}
                    style={{ width: 120, height: 98, objectFit: 'cover', borderRadius: 8 }} />
            </Box>
        </ButtonBase>
        <Box display="flex" flexDirection='row' mt={1}>

            {/* 临时关闭入口<ButtonBase style={{ color: "#888888", fontSize: 12, }} p={1}>
                <ShareIcon color="disabled" fontSize="small" ></ShareIcon>
                <div style={{ color: "#888888", fontSize: 12, marginLeft: 2, }}>转发</div>
            </ButtonBase>*/}

            <ButtonBase style={{ color: "#888888", fontSize: 12, marginLeft: 30, }} p={1}>
                <FavoriteBorderIcon color="disabled" fontSize="small"></FavoriteBorderIcon>
                <div style={{ color: "#888888", fontSize: 12, marginLeft: 2, }}>123</div>
            </ButtonBase>

            <ButtonBase style={{ color: "#888888", fontSize: 12, marginLeft: 30, }} p={1}>
                <MessageIcon color="disabled" fontSize="small"></MessageIcon>
                <div style={{ color: "#888888", fontSize: 12, marginLeft: 2, }}>123</div>
            </ButtonBase>
        </Box>

    </Box>
}


export function CommentItem(props) {
    const [openMenu, setOpenMenu] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null)

    const showMenu = (e) => {
        setOpenMenu(true)
        setAnchorEl(e.currentTarget);
        e.stopPropagation()
    }
    const { id, user, firstImg, text, nickname, createTime } = props
    return <Box>
        <Box display='flex' alignItems='center'>
            <Avatar style={{ width: 30, height: 30 }} src={firstImg} />
            <Box mx={1}>
                <Box fontSize={13} color='#333'>{nickname}</Box>
                <Box fontSize={11} color='#999'>{moment(createTime).fromNow()}</Box>
            </Box>
            <Box flex="1" />
            <IconButton onClick={showMenu}>
                <MoreHoriz style={{ color: '#aaa' }} />
            </IconButton>
        </Box>
        <Box mb={1} color='#555' fontSize={14}>{text}</Box>
        <ReportMenuList
            type="3"
            user={user}
            id={id}
            block={false}
            open={openMenu}
            anchorEl={anchorEl}
            handleClose={() => setOpenMenu(false)}
        />
    </Box>
}
