import { Box, Button, Dialog, makeStyles, DialogTitle, Checkbox } from "@material-ui/core"
import FormControl from '@material-ui/core/FormControl';
import React, { useState, useEffect, useCallback } from "react";
import Slide from '@material-ui/core/Slide';

import { getCategoryByPid, getFocusList } from "service/goods";
import { postDynamic } from './interface'
import { SectionRow } from "components/section"
import ChooseListDialog from "components/chooseListDialog";
import { StatusBoxPro } from "components/empty";
import { GoodsListItem } from "../../components";
import { toast, useAppHistory, useNavigationBar } from "../../utils/bridge";
import CancelIcon from '@material-ui/icons/Cancel';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
    root: {
        width: '-webkit-fill-available',
        overflowY: 'scroll',
    },
    dialogRoot: {
        alignItems: 'flex-end',
        '& .MuiIconButton-label:hover, .MuiIconButton-root': {
            background: '#fff !important'
        },
    },
    dialogPaper: {
        margin: 0,
        width: '100%',
        maxHeight: '70%',
    },
    bottom: {
        borderRadius: 108,
        height: 44,
        margin: theme.spacing(1),
        background: 'linear-gradient(90deg, #C90154, #E9097A)',
        fontSize: '16px',
        marginTop: '50px'
    },
    textContent: {
        backgroundColor: 'white',
        padding: 16,
        marginBottom: 8,
        display: 'flex',
        alignItems: 'start',
        fontSize: '15px',
        flexDirection: 'column',
    }
}))

const PublishShareList = (props) => {
    const classes = useStyles()
    const history = useAppHistory()

    const [showDialog, setShowDialog] = useState(false)
    const [showCouponDialog, setCouponsDialog] = useState(false)

    const [category, setCategory] = useState(-1)
    const [categoryList, setCategoryList] = useState([])
    const [goods, setGoods] = useState([])
    const [title, setTitle] = useState()

    useNavigationBar({ title: '发布精选清单' })

    const handleClose = (value) => {
        setShowDialog(false)
        if (value) {
            setCategory(value)
        }
    }
    const onChoose = (checked, item, goods) => {

        checked ? setGoods([...goods, ...[item]]) : setGoods(goods?.filter(el => el?.id !== item?.id))

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(async () => {
        async function loadCategory() {
            const { data: result } = await getCategoryByPid(10)
            if (result) {
                const categoryList = result.map(i => i.category)
                setCategoryList(categoryList)
            }
        }
        loadCategory()
    }, [])

    const publish = async () => {
        const body = {
            textContent: title,
            type: 1,
            jsonType: 1,
            json: JSON.stringify(goods),
        }
        const { data } = await postDynamic(body)
        console.log(data)
        toast("发布成功")
        history.pop()
    }

    return <FormControl className={classes.root}>

        <Box className={classes.textContent} >
            <Box marginBottom={1}>标题</Box>
            <textarea placeholder='请填写你的标题' onChange={e => setTitle(e.target.value)} style={{ width: '100%', height: '200px' }} />
        </Box>

        <SectionRow title="选择商品" onClick={() => setCouponsDialog(true)}>
            <text style={{ width: '70%' }}> {goods?.map(el => el?.name)}</text>
        </SectionRow>

        <Button className={classes.bottom}
            onClick={publish}
            variant='contained' color='primary'>
            发布
        </Button>

        <ChooseListDialog
            open={showDialog}
            onClose={handleClose}
            options={categoryList}
            value={category}
        />

        <ChoooseGoodsList
            open={showCouponDialog}
            onChoose={onChoose}
            goods={goods}
            setGoods={setGoods}
            onClose={() => setCouponsDialog(false)} />
    </FormControl >
}


const ChoooseGoodsList = ({ open, onClose, onChoose, goods }) => {
    const classes = useStyles()
    const goodsGrid = useCallback((result) => <Box bgcolor='white'>
        <Box height="16px" bgcolor='#eee' />
        {
            result?.map(item =>
                <Box style={{ display: 'flex' }}>
                    <Checkbox checked={goods?.find(el => el.id === item?.id)} onClick={e => onChoose(e.target.checked, item, goods)} />
                    <GoodsListItem
                        item={item} key={item.productVariants?.id || item.id}
                    />
                </Box>
            )
        }</Box>, [goods, onChoose])

    return <Dialog
        maxWidth="lg"
        TransitionComponent={Transition}
        onClose={onClose}
        fullWidth
        aria-labelledby="coupons-dialog"
        open={open}
        classes={{
            container: classes.dialogRoot,
            paper: classes.dialogPaper,
        }} >
        <DialogTitle id="dialog-title" style={{ borderBottom: '1px solid #eee' }}>
            我关注的商品
            <Button style={{ position: 'absolute', right: '16px', top: '16px', margin: 0, color: '#208cea', border: '1px solid #208cea' }} onClick={() => onClose()}>确认</Button>
        </DialogTitle>

        <Box style={{ overflow: 'auto' }}>
            <StatusBoxPro
                request={async () => getFocusList(2, 0, { page: false })}
                emptyTitle="还未关注任何商品哦，快去商城逛一逛吧"
                component={goodsGrid}
            />
        </Box>
    </Dialog>
}


export default PublishShareList