import { Card, CardContent, Box, Grid, Typography, makeStyles } from '@material-ui/core'
import { useAppHistory } from "utils/bridge"
import useRequest from '@ahooksjs/use-request'
import { storeData, storefundData } from './interface'
import qs from 'qs'
import { myMall } from 'service/goods'

const useStyles = makeStyles(() => ({
  topTitle: {
    background: '#A786E8',
    color: '#fff',
    fontSize: '15px',
    padding: '15px 20px 45px 20px',
    marginBottom: '-28px'
  },
  manageContent: {
    '& .MuiPaper-elevation1': {
      boxShadow: 'none'
    },
    '& .MuiCard-root': {
      marginBottom: '8px'
    },
    '& .MuiGrid-root': {
      textAlign: 'center',
      paddingTop: '10px',
    },
    '& text': {
      display: 'block',
      fontSize: '12px',
      paddingTop: '8px'
    },
  }
})
)

export default function List() {
  // const { mall } = useAccount()
  const classes = useStyles()
  const history = useAppHistory()
  const query = qs.parse(window.location.search, { ignoreQueryPrefix: true })
  const { type } = query //  type: 0;线上 1:线下 2:工厂 3:二手

  const manageEntryList = [
    {
      title: '店铺',
      img: 'https://202108251114.oss-cn-shanghai.aliyuncs.com/file/0631163643849252827358.800.png',
      color: '#FD8271',
      url: `/b2c/workbench/shop`
    },
    {
      title: '商品管理',
      img: 'https://202108251114.oss-cn-shanghai.aliyuncs.com/file/56511636438865236400008.100.png',
      color: '#0EC6FD',
      url: '/b2c/workbench/products_manage'
    },
    {
      title: '订单管理',
      img: 'https://202108251114.oss-cn-shanghai.aliyuncs.com/file/97001636439049984584806.700.png',
      color: '#FFC32F',
      url: '/b2c/workbench/orders_manage',
    },
    {
      title: '仓库管理',
      img: 'https://202108251114.oss-cn-shanghai.aliyuncs.com/file/84631636439100609635373.400.png',
      color: '#FD8271',
      url: '',
      display: type === '4' ? 'block' : 'none',
    },
    {
      title: '优惠券管理',
      img: 'https://202108251114.oss-cn-shanghai.aliyuncs.com/file/61231636439012892547716.800.png',
      color: '#0EC6FD',
      url: `/b2c/workbench/coupons_manage`
    },
    {
      title: '购推荐管理',
      img: 'https://202108251114.oss-cn-shanghai.aliyuncs.com/file/57371636438970314505080.100.png',
      color: '#FFC32F',
      url: ''
    }
  ]

  const { data: mall } = useRequest(() => myMall(), {
    formatResult: res => res.data
  })

  /**
   * 列表接口
   */
  const { data } = useRequest(() => storeData(mall?.id), {
    formatResult: res => res.data,
    refreshDeps: [mall]
  })

  const { data: fundData } = useRequest(() => storefundData(mall?.id), {
    formatResult: res => res.data,
    refreshDeps: [mall]
  })

  // 店铺数据
  const storeDataList = [
    {
      title: '今日订单数',
      data: data?.[0] || 0
    },
    {
      title: '退款中',
      data: data?.[1] || 0
    },
    {
      title: '待发货',
      data: data?.[2] || 0
    },
    {
      title: '出售商品',
      data: data?.[3] || 0
    }
  ]
  const fundDataList = [
    {
      title: '今日成交金额',
      data: fundData?.dailySum || 0
    },
    {
      title: '成交总额',
      data: fundData?.cashSum || 0
    },
    // {
    //   title: '可提现金额',
    //   data: fundData?.realCash || 0
    // }
  ]
  return (
    <Box>
      <Box className={classes.topTitle}>
        {mall?.storeName} <small>id:{mall?.id}</small> 
      </Box>
      <Box mr={2} ml={2} className={classes.manageContent}>

        <Card>
          <CardContent>
            <Grid container spacing={2}>
              {manageEntryList?.map(el => (
                <Grid item xs={3} onClick={() => { history.push(el?.url) }} style={{ display: el?.display }}>
                  <img src={el?.img} alt='' width={39} height={39} style={{ boxShadow: `0 0 6px ${el?.color}`, borderRadius: '6px', background: el?.color }} />
                  <text>{el?.title}</text>
                </Grid>
              ))}
            </Grid>
          </CardContent>
        </Card>

        <Card>
          <CardContent>
            <Typography style={{ fontSize: 14 }} color="text.secondary" gutterBottom>
              店铺数据
            </Typography>
            <Grid container spacing={2}>
              {storeDataList?.map(el => (
                <Grid item xs={3}>
                  <text style={{ color: '#000', fontSize: '14px' }}>{el?.data}</text>
                  <text style={{ color: '#8F8F8F', fontSize: '12px' }}>{el?.title}</text>
                </Grid>
              ))}
            </Grid>
          </CardContent>
        </Card>

        <Card>
          <CardContent>
            <Typography style={{ fontSize: 14 }} color="text.secondary" gutterBottom>
              资金数据
            </Typography>
            <Grid container spacing={2}>
              {fundDataList?.map(el => (
                <Grid item xs={4}>
                  <text style={{ color: '#000', fontSize: '14px' }}>{el?.data}</text>
                  <text style={{ color: '#8F8F8F', fontSize: '12px' }}>{el?.title}</text>
                </Grid>
              ))}
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </Box>
  )
}