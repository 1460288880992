import { Box, Dialog, IconButton, ButtonBase, LinearProgress, makeStyles, Slide } from '@material-ui/core';
import { StarOutlined } from '@material-ui/icons';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import RoomIcon from '@material-ui/icons/Room';

import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from "react-redux";
import { ButtonLinearLayout, DividerBox, ImageButton } from "../components/base";
import CartToolBar from "../components/cartToolBar";
import ChooseVersionPanel from "../components/chooseVariantPanel";
import { addToCartAction } from "../models/cart";
import { getCommentAction, getGoodsDetailAction, selectGoodsVariant } from "../models/goods";
import { Empty } from '../components/empty'
import { setShareInfo, useAppHistory, useNavigationBar, openLocation, toast } from '../utils/bridge';
import { priceCast } from '../utils/price'

import AddressChoosePage from "./addressChoose";
import { setDefault } from '../models/address'
import { addFocus, checkFocus, deleteFocus, getAllCoupons, getProductActivity, pickCoupons } from "../service/goods";
import { refreshProfile } from '../models/account';
import RecommendCommentPage from './service/comment';
import { caculateDistance } from '../utils/price';
import Cookies from 'js-cookie';
import RecommendPage from '../jwl/recommend';
import { Grid, Tabs, Popup, Swiper, Space, NavBar, Toast } from 'antd-mobile';
import NearbyShop from './nearbyShop'
import { useRequest } from 'ahooks';
import dateformat from 'dateformat'
import { CouponItem1 } from './myCoupon';

const detailContent = (imgs) => imgs.map(src => <img key={src} style={{ maxWidth: '100%' }} src={src} />)


const useStyles = makeStyles(theme => ({
    buyPrice: {
        color: '#e12',
        fontSize: '20px',
        '&::before': {
            content: '"¥"',
            fontSize: '12px',
        }
    },
    originPrice: {
        color: '#888',
        fontSize: '14px',
        marginLeft: '4px',
        textDecoration: 'line-through',
        '&::before': {
            content: '"¥"',
            fontSize: '10px',
        }
    },
    card: {
        width: '-webkit-fill-available',
        background: 'white',
        borderRadius: 4,
        display: 'block',
    },
    cardMedia: {
        width: '100%',
        height: 210,
        borderRadius: '4px 4px 0 0',
        objectFit: 'cover',
    },
    cardTitle: {
        fontSize: 13,
        lineHeight: '1rem',
        maxHeight: '2.2rem',
        padding: '4px 8px 0 8px',
        fontWeight: 500,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        textAlign: 'left',
    },
    funUser: {
        fontSize: 10,
        color: 'white',
        padding: 2,
        position: 'fixed',
        left: 0,
        bottom: '240px',
        background: 'linear-gradient(-45deg, #E9097A, #C90154)',
        borderRadius: '0px 40px 40px 0px',
        display: 'flex',
        flexDirection: 'column',
        minWidth: 50,
        alignItems: 'center',
    },
    chatRoom: {
        fontSize: 10,
        color: 'white',
        padding: 2,
        minWidth: 70,
        position: 'fixed',
        left: 0,
        bottom: '190px',
        background: 'linear-gradient(-45deg, #FDAE33, #FDC233)',
        borderRadius: '0px 40px 40px 0px',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
    },
    pageIndicator: {
        background: '#000000a3',
        textAlign: 'center',
        fontSize: 12,
        position: 'relative',
        width: '30px',
        float: 'right',
        right: '20px',
        bottom: '40px',
        color: 'white',
        borderRadius: 12,
    },
    arcHead: {
        height: 20,
        bottom: 10,
        width: '100%',
        borderRadius: '10px',
        position: 'relative',
        backgroundColor: '#F8F8F8',
    },
    punch: {
        backgroundColor: 'white',
        flex: 1,
        width: '100%',
        marginTop: 20,
        fontSize: 14,
        borderRadius: 8,
        height: 40,
    },
    originPrice: {
        textDecoration: 'line-through',
        fontSize: 12,
        color: 'white',
        marginRight: 12,
    },
    flashBox: {
        backgroundImage: 'url("https://h5.qihuaitech.com/files/img_price_flash.png")',
        backgroundSize: '100% 100%',
        width: '110px',
        display: 'flex',
        padding: '4px',
        flexDirection: 'column',
        alignItems: 'center',
        fontSize: 12,
    },
    couponPage: {
        overflow: 'scroll',
        padding: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        backgroundColor: '#eee',
        height: '100%',
    },
    couponBox: {
        padding: '11px 13px',
        display: 'flex',
        alignItems: 'center',
    },
    couponCell: {
        color: '#D22020',
        backgroundColor: '#FFD5D5',
        padding: '5px 7px',
        fontSize: 10,
        borderRadius: 5,
    },
    aquireCoupon: {
        backgroundColor: '#D22020',
        color: 'white',
        borderRadius: 12,
        fontSize: 10,
        padding: '4px 7px',
    },
    customIndicator: {
        position: 'absolute',
        bottom: 16,
        right: 6,
        background: 'rgba(0, 0, 0, 0.3)',
        padding: '4px 8px',
        color: '#ffffff',
        borderRadius: '4px',
        userSelect: 'none',
    }
}))

const PriceBox = ({ variant }) => {
    const classes = useStyles()
    const box = variant.actPrice ?
        <>
            <Box color='red' className={classes.buyPrice} fontSize={22} fontWeight='fontWeightBold' component='span'>{priceCast(variant.actPrice)}</Box>
            <Box color='red' className={classes.originPrice} fontSize={22} fontWeight='fontWeightBold' component='span'>{priceCast(variant.price)}</Box>
        </>
        :
        <Box color='red' className={classes.buyPrice} fontSize={22} fontWeight='fontWeightBold' component='span'>{priceCast(variant.price)}</Box>
    return box
}

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

//1：预售 2：囤货 3：限时团
const actTypeStr = (type) => {
    switch (type) {
        case 1: return "预售"
        case 2: return "囤货"
        case 3: return "限时团"
    }
}

const actTimeStr = (activity) => {
    return '可提货时间：' + dateformat(new Date(activity.usageTime * 1000), 'yyyy.mm.dd')
}

function addZero(i) {
    let x = parseInt(i)
    return x < 10 ? "0" + x : x + "";
}

const countDownStr = (time) => {
    if (!time) {
        return ""
    }
    let lefttime = (time - Date.now()) / 1000
    var d = addZero(lefttime / (24 * 60 * 60))
    var h = addZero(lefttime / (60 * 60) % 24);
    var m = addZero(lefttime / 60 % 60);
    var s = addZero(lefttime % 60);
    return `${d}天 ${h}时 ${m}分`
}

function GoodsPcHeader({ variant, goods }) {
  const classes = useStyles();
  const [star, setStar] = useState("#999");
  const [showCoupons, setShowCoupons] = useState(false);

  let covers;
  if (variant?.multiView) {
    covers = variant?.multiView.split(",").map((src) => {
      return (
        <Swiper.Item key={src}>
          <img
            src={src}
            alt="cover-image"
            style={{
              objectFit: "cover",
              width: "100%",
              height: "100vw",
              maxHeight: "500px",
            }}
          />
        </Swiper.Item>
      );
    });
  } else {
    covers = (
      <img
        width="100%"
        src="https://via.placeholder.com/400x350/8f8e94/FFFFFF?text=loading.."
        height="350"
      />
    );
  }

  const goodsId = goods.id;
  const { data: activity } = useRequest(() => getProductActivity(goodsId), {
    formatResult: (res) => res.data?.[0],
    refreshDeps: [goodsId],
  });
  const { data: coupons } = useRequest(() => getAllCoupons(0, null, goodsId), {
    formatResult: (res) => res.data,
    refreshDeps: [goodsId],
  });

  useEffect(async () => {
    const { data } = await checkFocus(goods.id);
    if (data > 0) {
      setStar("rgb(255 69 0)");
    }
  }, []);

  // 添加标签
  const Tag = ({ title }) => {
    return (
      <Box
        px={0.5}
        marginRight={1}
        color="#fdc133"
        style={{
          border: "1px solid #fdc133",
          borderRadius: 12,
          fontSize: "11px",
          width: "fit-content",
        }}
      >
        {title}
      </Box>
    );
  };

  const focus = async () => {
    if (star == "rgb(255 69 0)") {
      await deleteFocus(goods.id);
      Toast.show({
        icon: "success",
        content: "已取消收藏",
      });
      setStar("#999");
    } else {
      await addFocus(2, goods.id);
      Toast.show({
        icon: "success",
        content: "已加入收藏",
      });
      setStar("rgb(255 69 0)");
    }
  };

  /// 领取优惠券
  const onChoose = async (coupon) => {
    const { data } = await pickCoupons(coupon.id);
    if (data?.code) {
      Toast.show({
        content: data?.msg ?? `领取失败(${data?.code})`,
      });
    } else {
      Toast.show({
        icon: "success",
        content: "领取成功",
      });
    }
    console.log(data);
  };

  return (
    <Box>
      <Swiper
        indicator={(total, current) => (
          <div className={classes.customIndicator}>
            {`${current + 1} / ${total}`}
          </div>
        )}
      >
        {covers}
      </Swiper>
      {activity && (
        <Box
          hidden={true}
          bgcolor="#D22020"
          color="white"
          height={64}
          marginTop="-5px"
          width="100%"
          display="flex"
        >
          <Box flex="1" padding={1}>
            <Box>
              <Box
                className="price"
                mr={1}
                fontSize="20px"
                color="white"
                component="span"
              >
                {priceCast(activity?.price)}
              </Box>
              原价:
              <Box className={classes.originPrice} component="span">
                ¥{priceCast(variant.price)}
              </Box>
            </Box>
            {actTimeStr(activity)}
          </Box>
          <Box className={classes.flashBox}>
            <Box fontSize={11} textAlign="center">
              <Box fontWeight="500" fontSize={18}>
                {actTypeStr(activity?.actType)}
              </Box>
              <Box>活动剩余</Box>
              <Box fontSize={11}>{countDownStr(activity?.endTime)}</Box>
            </Box>
          </Box>
        </Box>
      )}
      {coupons?.length !== 0 && (
        <Box className={classes.couponBox} onClick={() => setShowCoupons(true)}>
          <Space>
            {coupons?.map((item) => (
              <Box key={item.id} className={classes.couponCell}>
                {item.title}
              </Box>
            ))}
          </Space>
          <Box flex="1"></Box>
          <Box className={classes.aquireCoupon}>
            领券
            <ArrowForwardIosIcon style={{ color: "#fff", fontSize: 10 }} />
          </Box>
        </Box>
      )}
      <Box m={1} marginTop="10px">
        <Box mb={1} fontSize={16} fontWeight={500}>
          {goods.name}
        </Box>
        <Box fontWeight="fontWightLight" mb={1} fontSize={14} color="#bbb">
          {goods.describe}
        </Box>
        <Box display="flex" alignItems="center" my={1}>
          <PriceBox variant={variant} />
          <Box flex={1} />
          <IconButton onClick={focus}>
            <StarOutlined style={{ color: star }} />
          </IconButton>
        </Box>
        <Box display="flex" marginTop={1}>
          {goods.tag?.split(",").map((tag) => (
            <Tag title={tag} />
          ))}
        </Box>
      </Box>
      <Popup
        visible={showCoupons}
        onMaskClick={() => {
          setShowCoupons(false);
        }}
        bodyStyle={{ height: "70vh" }}
      >
        <NavBar
          onBack={() => {
            setShowCoupons(false);
          }}
        >
          优惠券
        </NavBar>
        <Box className={classes.couponPage}>
          {coupons?.map((item) => (
            <CouponItem1
              key={item.id}
              coupon={item}
              isSelected={false}
              onChoose={onChoose}
            />
          ))}
        </Box>
      </Popup>
    </Box>
  );
}

/** 景点照片 */
function SceneryHeader({ goods, variant }) {
  const classes = useStyles();
  const [showNearby, setShowNearby] = useState(false);
  const store = goods.store;
  const history = useAppHistory();

  let covers;
  if (variant?.multiView) {
    covers = variant.multiView.split(",").map((src) => {
      return (
        <Swiper.Item key={src}>
          {" "}
          <img
            src={src}
            alt="cover-image"
            style={{
              objectFit: "cover",
              width: "100%",
              height: 300,
            }}
          />
        </Swiper.Item>
      );
    });
  } else {
    covers = (
      <img
        width="100%"
        src="https://via.placeholder.com/400x350/8f8e94/FFFFFF?text=loading.."
        height="350"
      />
    );
  }
  const location = Cookies.get("location");

  const pushToMap = () => {
    // 调用地图导航
    openLocation(store.latitude, store.longitude, store.storeName);
  };
  const pushToPunch = () => {
    if (location) {
      // 打卡
      const [lon, lat] = location.split(",");
      const distance = caculateDistance(
        lat,
        lon,
        store.latitude,
        store.longitude
      );
      if (distance > 2) {
        toast(`距离(${distance.toFixed()}km)太远啦，无法打卡`);
      } else {
        history.push(`/release_tour?storeId=${store.id}`);
      }
    } else {
      toast(`未获得定位权限`);
    }
  };

  return (
    <Box>
      <Box position="abstract" bgcolor="#F8F8F8">
        <Swiper
          indicator={(total, current) => (
            <div className={classes.customIndicator}>
              {`${current + 1} / ${total}`}
            </div>
          )}
        >
          {covers}
        </Swiper>
        <Box className={classes.arcHead}></Box>
        <Box padding="0px 14px 14px 10px" mt={-1} zIndex={10} borderRadius={8}>
          <Box mb={1} fontSize={16} fontWeight={500}>
            {goods.name}
          </Box>
          <Box fontSize={14} color="#777">
            <Box>{store.intro}</Box>
            <Box display="flex" alignItems="center" mt={1}>
              <RoomIcon style={{ color: "#777" }} />
              <Box flex={1}>{store.storeAddress} </Box>
              <ImageButton
                onClick={pushToMap}
                icon="https://h5.qihuaitech.com/files/ic_ditu@2x.png"
                name="地图"
              />
            </Box>
          </Box>
          <Grid columns={2} gap={8}>
            <Grid.Item>
              <ButtonBase onClick={pushToPunch} className={classes.punch}>
                <img
                  src="https://h5.qihuaitech.com/files/ic_zuji@2x.png"
                  style={{ width: 30, height: 30, marginRight: 8 }}
                />
                <Box>足迹打卡</Box>
              </ButtonBase>
            </Grid.Item>
            <Grid.Item>
              <ButtonBase
                className={classes.punch}
                onClick={() => setShowNearby(true)}
              >
                <img
                  src="https://h5.qihuaitech.com/files/ic_fujin1.png"
                  style={{ width: 28, height: 28, marginRight: 8 }}
                />
                <Box>附近推荐</Box>
              </ButtonBase>
            </Grid.Item>
          </Grid>
        </Box>
      </Box>
      <Popup
        visible={showNearby}
        onMaskClick={() => {
          setShowNearby(false);
        }}
        bodyStyle={{ height: "70vh" }}
      >
        <NearbyShop
          exclude={store.id}
          longitude={store.longitude}
          latitude={store.latitude}
        />
      </Popup>
    </Box>
  );
}

function GoodsPage(props) {
    const { goods, loading, variant, location } = props;
    const [openAddress, setOpenAddress] = React.useState(false);
    const [openChoose, setOpenChoose] = React.useState(false);


    const goodsId = props.match.params.id
    useNavigationBar({ title: '商品详情' })
    const history = useAppHistory()

    React.useEffect(() => {
        props.getGoodsDetailAction(goodsId)
    }, [])

    const dispatch = useDispatch()
    let variantlist = goods?.productVariantDetails?.filter(i => i.live === 1)

    React.useEffect(() => {
        if (goods) {
            const tag = goods.tag?.split(',')?.[0]
            // 分享参数
            setShareInfo({
                type: 1,
                jsonType: 1,
                content: {
                    title: goods.name,
                    price: variant.price / 100,
                    image: variant.image || variant.thumb,
                    source: '商品',
                    tag: tag
                },
                json: {
                    path: location.pathname
                }
            })
            refreshProfile(dispatch)
        }
    }, [goods, variant])

    if (!variant) {
        console.log({ goods, variant });
    }

    const closeChoosePanel = () => {
        setOpenChoose(false);
    }
    const handleChooseVariant = () => {
        if (goods.isVirtual) {
            return
        }
        let token = Cookies.get('token')
        if (token) {
            setOpenChoose(true);
        } else {
            history.push('app://login')
        }
    }

    const goToCart = () => {
        let token = Cookies.get('token')
        if (token) {
            history.push('/main/cart')
        } else {
            history.push('app://login')
        }
    }
    const goToShop = () => {
        let token = Cookies.get('token')
        if (token) {
            history.push(`/shop/${goods.store.id}`)
        } else {
            history.push('app://login')
        }
    }
    const handleCloseAddress = () => {
        setOpenAddress(false);
    };
    const onChooseAddress = (address) => {
        // setAddress(address)
        setOpenAddress(false);
        dispatch(setDefault(address.id))
    }

    if (loading) {
        return <LinearProgress />
    }
    const gotoCS = () => {
        let token = Cookies.get('token')
        if (token) {
            history.push('app://chat/526869747724104')
        } else {
            history.push('app://login')
        }
    }

    let detail
    if (goods.details) {
        detail = detailContent(goods.details.split(','))

    } else {
        detail = <Empty title="商品未上线，敬请期待" />
    }

    return (
        <Box bgcolor='white' style={{ overflowY: 'scroll', overflowX: 'hidden' }} height={1}>
            {goods.category.id != 20 ?
                <GoodsBody goods={goods} variant={variant}
                    detail={detail}
                    handleChooseVariant={handleChooseVariant} /> :
                <SceneryBody goods={goods} variant={variant}
                    detail={detail}
                    handleChooseVariant={handleChooseVariant} />
            }

            <ChooseVersionPanel open={openChoose}
                closeChoosePanel={closeChoosePanel}
                current={variant}
                variantlist={variantlist}
                setVariant={props.selectGoodsVariant}
                addToCart={props.addToCartAction}
            />
            <Box hidden={goods.isVirtual}>
                <CartToolBar go_cs={gotoCS} cartList={goToCart} goods={goods}
                    store={goToShop}
                    cart={handleChooseVariant} buy={handleChooseVariant} />
            </Box>
            <Dialog maxWidth='xl' open={openAddress} onClose={handleCloseAddress}
                TransitionComponent={Transition}>
                <AddressChoosePage
                    onChooseAddress={onChooseAddress}
                    handleCloseAddress={handleCloseAddress} />
            </Dialog>
        </Box>
    )
};

/** 普通商品body */
const GoodsBody = ({ variant, goods, detail, handleChooseVariant }) => {
    const goodsId = goods.id
    return <>
        <GoodsPcHeader variant={variant} goods={goods} handleChooseVariant={handleChooseVariant} />
        <DividerBox />
        <ButtonLinearLayout onClick={handleChooseVariant}>
            <Box display='flex' alignItems='center' width="100%" fontSize={14} height="40px">
                选择
                <Box mx={2} color="grey.400">{variant?.model} {variant?.color}</Box>
                <Box flex={1} />
                <Box>
                    库存 {variant?.stock}
                </Box>
                <ArrowForwardIosIcon style={{ color: '#aaa', fontSize: 18 }} />
            </Box>
        </ButtonLinearLayout>
        <Box mx={2} height="1px" width="100%" bgcolor='grey.200' />
        <DividerBox />
        <Tabs
            style={{ '--title-font-size': '14px' }}
            defaultActiveKey="detail" >
            <Tabs.Tab title="商品详情" key="detail" ><Box>{detail} </Box> </Tabs.Tab>
            <Tabs.Tab title="购推荐" key="comments" > <RecommendCommentPage goodsId={goodsId} /></Tabs.Tab>
        </Tabs>
        <ButtonLinearLayout>
            <Box component="span" className='item-title'>售后保障</Box>
            <Box flex={1} />
        </ButtonLinearLayout>
        <Box pb={16} color='grey.400' p={2}>
            所有商品信息、客户评价、商品咨询、网友讨论等内容，是我们的重要的经营资源，未经许可，禁止非法转载使用。
            <br />
            注：本站商品信息均来自于合作方，其真实性、准确性和合法性由信息拥有者（合作方）负责。本站不提供任何保证，并不承担任何法律责任。
        </Box>
    </>
}

/** 景区body */
const SceneryBody = ({ variant, goods, detail, handleChooseVariant }) => {
    return <>
        <SceneryHeader variant={variant} goods={goods} handleChooseVariant={handleChooseVariant} />
        <Tabs
            style={{
                backgroundColor: '#F8F8F8',
                position: 'sticky',
                '--title-font-size': '14px',
                top: 0
            }}
            defaultActiveKey="detail"
        >
            <Tabs.Tab title="景区介绍" key="detail">
                <Box>{detail} </Box>
            </Tabs.Tab>
            <Tabs.Tab title="打卡动态" key="commends">
                <RecommendPage storeId={goods.store.id} />
            </Tabs.Tab>
        </Tabs>

        <ButtonLinearLayout>
            <Box component="span" className='item-title'>售后保障</Box>
            <Box flex={1} />
        </ButtonLinearLayout>
        <Box pb={16} color='grey.400' p={2}>
            所有商品信息、客户评价、商品咨询、网友讨论等内容，是我们的重要的经营资源，未经许可，禁止非法转载使用。
            <br />
            注：本站商品信息均来自于合作方，其真实性、准确性和合法性由信息拥有者（合作方）负责。本站不提供任何保证，并不承担任何法律责任。
        </Box>
    </>
}

const mapStateToProps = ({ goodsDetail: item }) => {
    return {
        goods: item.goods, loading: item.loading,
        variant: item.variant, comments: item.comments
    }
}

export default connect(mapStateToProps, {
    getGoodsDetailAction,
    getCommentAction,
    selectGoodsVariant,
    addToCartAction
})(GoodsPage)