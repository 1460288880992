import { isMobile, toast } from 'utils/bridge';
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";

const ToastNone = 'toast.none'
const ToastSuccess = 'toast.success'
const ToastWarning = 'toast.warning'
const ToastError = 'toast.error'

/** 通知的级别，none，错误，成功，警告 */
type Level = typeof ToastSuccess | typeof ToastWarning | typeof ToastError

type ToastAction = {
    type: string,
    payload?: string
}

/** toast消息 */
export const showToast = (dispatch: Dispatch<ToastAction>) => (level: Level, message: string) => {
    dispatch({
        type: level,
        payload: message
    })
}

/** 关闭toast */
export const closeToast = (dispatch: Dispatch<ToastAction>) => () => {
    dispatch({
        type: ToastNone,
        payload: undefined,
    })
}

type ToastHookType = {
    showToast: (level: Level, message: string) => void,
    closeToast: () => void,
}

export const useToast: () => ToastHookType = () => {
    const dispatch = useDispatch()
    const toastFunc = isMobile() ? () => toast : showToast(dispatch)
    return {
        showToast: toastFunc,
        closeToast: closeToast(dispatch)
    }
}


export const toastLevel = {
    ToastNone, ToastSuccess, ToastWarning, ToastError
}

const initalState = {
    level: null,
    message: "",
}

export const toastReducer = (state = initalState, action: ToastAction) => {
    if (!action) {
        return state
    }
    switch (action.type) {
        case ToastWarning:
        case ToastSuccess:
        case ToastError:
            return {
                ...state,
                level: action.type,
                message: action.payload
            }
        case ToastNone:
            return {
                ...state,
                level: null,
            }
        default: return state
    }
}