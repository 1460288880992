import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import Cookies from 'js-cookie';

const { REACT_APP_HOST } = process.env;
// const BASE_URL = "http://localhost:8080/giraffe/"
const BASE_URL = REACT_APP_HOST ?? "https://home.qihuaitech.com:666/rang/"
// baseURL: '',

/**
 * @deprecated 使用requestX代替，可以去掉reponse.data.data两层，脱掉皮改为一层
 */
const request = axios.create({
    baseURL: BASE_URL,
    headers: { 'ContentType': 'appliction/json' }
})

const requestX = axios.create({
    baseURL: BASE_URL,
    validateStatus: (status) => status < 500,
    headers: { 'ContentType': 'appliction/json' }
})

function getRandomColor() {
    var letters = '0123456789ABCDEF';
    var color = '#';
    for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
}

const tokenInterceptor: (requset: AxiosRequestConfig) => AxiosRequestConfig = request => {
    if (request.url === 'auth/refresh') {
        console.log(request.url)
        return request
    }
    let token = Cookies.get('token')
    if (token) {
        request.headers.Authorization = `Bearer ${token}`
    }
    return request
}

requestX.interceptors.request.use(tokenInterceptor)
request.interceptors.request.use(tokenInterceptor)

requestX.interceptors.response.use(
    response => {
        print(response);
        if ('data' in response.data && !('pageNo' in response.data)) {
            if (response.data.code === "200") {
                const result = response.data.data || response.data
                const { data, ...rest } = response
                return { ...rest, data: result }
            }
        } else {
            return response
        }
    },
    async error => {
        if (error.response && error.response.status === 401) {
            var config = error.config;
            // retry和retryDelay 可以在传的参数里面携带
            if (config.headers && !config.headers.retry) {
                config.headers.retry = 3;//设置默认重复次数
            }
            config.headers.__retryCount = config.headers.__retryCount || 0;
            if (config.headers.__retryCount >= config.headers.retry) {
                console.error("重取token： 网络错误");
                return Promise.reject(error);
            }
            config.headers.__retryCount += 1;
            let refreshToken = Cookies.get('refreshToken')
            if (refreshToken) {
                await tryRefreshToken(refreshToken);
            } else {
                window.location.href = "/login"
            }
            return axios(config)
        } else {
            return Promise.reject(error)
        }

    })

request.interceptors.response.use(
    response => {
        print(response);
        return response
    },
    async error => {
        if (error.response && error.response.status === 401) {
            var config = error.config;
            // retry和retryDelay 可以在传的参数里面携带
            if (config.headers && !config.headers.retry) {
                config.headers.retry = 3;//设置默认重复次数
            }
            config.headers.__retryCount = config.headers.__retryCount || 0;
            if (config.headers.__retryCount >= config.headers.retry) {
                console.error("重取token： 网络错误");
                return Promise.reject(error);
            }
            config.headers.__retryCount += 1;

            let refreshToken = Cookies.get('refreshToken')
            if (refreshToken) {
                await tryRefreshToken(refreshToken);
            } else {
                window.location.href = "/login"
            }
            return axios(config)
        } else {
            // return error;
            return Promise.reject(error);
        }
    })

async function tryRefreshToken(refresh: string) {
    const { data, status } = await request.post('auth/refresh', { refreshToken: refresh });
    if (status === 200 && 'data' in data) {
        const { accessToken, refreshToken: rToken } = data.data;
        Cookies.set('token', accessToken);
        Cookies.set('refreshToken', rToken);
    }
    await new Promise(r => setTimeout(r, 1000));
}

function print(response: AxiosResponse) {
    var randomColor = getRandomColor();
    console.log(
        "%c┍------------------------------------------------------------------┑",
        `color:${randomColor};`
    );
    console.log("| 请求地址：", response.config.url);
    // console.log("| 请求参数：", qs.parse(response.config.data));
    console.log("| 返回数据：", response.data);
    console.log(
        "%c┕------------------------------------------------------------------┙",
        `color:${randomColor};`
    );
}

/**
 * 全局请求接口
 */
export default request;

export { requestX }
