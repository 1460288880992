import { Box, ButtonBase, makeStyles } from '@material-ui/core'
import Avatar from '@material-ui/core/Avatar';
import { useNavigationBar, useAppHistory } from '../utils/bridge';
import { ButtonLinearLayout } from "../components/base";
import React from 'react';
import 'App.js';
import {useAccount} from "../utils/hooks";
// 我的广场
const useStyles = makeStyles((theme) => ({
    iconButton: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        minWidth: 70,

    },
    imgAt: {
        width: theme.spacing(6),
        height: theme.spacing(6),

    },
    lineSty: {
        backgroundColor: 'white',
        marginTop: '12px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',

    },
}))

const MySquarePage = (props) => {
    const classes = useStyles()
    const history = useAppHistory()
    const {myself} = useAccount();
    useNavigationBar({ title: '我的广场' })
    return (<Box display='flex' flexDirection='column'>
        <TopItem />
        <Box display='flex' flexDirection='row' justifyContent='space-around' mt={2} alignItems='center' bgcolor='white' mx={1.5} py={1.5}>
            <ButtonBase>
                <Box display='flex' flexDirection='column' alignItems='center'>
                    <Box fontSize={18} color='black'> 1 </Box>
                    <Box fontSize={13} color='black' mt>发布</Box>
                </Box>
            </ButtonBase>
            <ButtonBase onClick={() => history.push('/my_fans/yue_share_follows')}>
                <Box display='flex' flexDirection='column' alignItems='center'>
                    <Box fontSize={18} color='black'> 2 </Box>
                    <Box fontSize={13} color='black' mt>关注</Box>
                </Box>
            </ButtonBase>
            <ButtonBase onClick={() => history.push('/my_fans/yue_share_fans')}>
                <Box display='flex' flexDirection='column' alignItems='center'>
                    <Box fontSize={18} color='black'> 3 </Box>
                    <Box fontSize={13} color='black' mt>粉丝</Box>
                </Box>
            </ButtonBase>
            <ButtonBase>
                <Box display='flex' flexDirection='column' alignItems='center'>
                    <Box fontSize={18} color='black'> 4 </Box>
                    <Box fontSize={13} color='black' mt>话题</Box>
                </Box>
            </ButtonBase>
            <ButtonBase>
                <Box display='flex' flexDirection='column' alignItems='center'>
                    <Box fontSize={18} color='black'> 5 </Box>
                    <Box fontSize={13} color='black' mt>职业荟</Box>
                </Box>
            </ButtonBase>
        </Box>

        <Box display='flex' flexDirection='row' mt={1.5} mx={1.5}
            alignItems='center' py={1} justifyContent='space-around' bgcolor='white'>
            <IconButton title="我的相册" image={window.ossUrl + '/image/ic_photo_album%402x.png'} onClick={() => history.push('/industry')}/>
            <IconButton title="我的文章" image={window.ossUrl + '/image/ic_essay%402x.png'} onClick={() => history.push('/my_article')}/>
            <IconButton title="我的收藏" image={window.ossUrl + '/image/ic_collect_more%402x.png'} onClick={() => history.push('/')}/>
            <IconButton title="浏览记录" image={window.ossUrl + '/image/ic_browse_records%402x.png'} onClick={() => history.push('/')}/>
        </Box>
        <Box className={classes.lineSty} mx={1.5} display='flex'>
            <ButtonLinearLayout >
                <img width={22} height={20} src={window.ossUrl + '/image/ic_visit%402x.png'} />
                <text style={{ fontSize: 13, marginLeft: 12 }}>来访的人</text>
                <Box flex={1} />
                <img width={15} height={15} src={window.ossUrl + '/image/ic_right%402x.png'} />
            </ButtonLinearLayout>
        </Box>

        <Box className={classes.lineSty} mx={1.5} display='flex'>
            <ButtonLinearLayout >
                <img width={22} height={20} src={window.ossUrl + '/image/ic_guestbook.png'} />
                <text style={{ fontSize: 13, marginLeft: 12 }}>留言板</text>
                <Box flex={1} />
                <img width={15} height={15} src={window.ossUrl + '/image/ic_right%402x.png'} />
            </ButtonLinearLayout>
        </Box>
    </Box >)
}
const IconButton = ({ title, image, ...props }) => {
    const classes = useStyles()

    return (
        <Box  {...props}>
            <ButtonBase className={classes.iconButton}  >

                <img src={image} style={{ width: 40, height: 40, }} />
                <Box minWidth={40} fontSize={13} >{title}</Box>
            </ButtonBase>

        </Box>
    )
}
// 顶部
const TopItem = (props) => {
    const classes = useStyles();
    const history = useAppHistory()
    const {myself} = useAccount();
    return (<Box display='flex' flexDirection='column'>
        <Box style={{
            backgroundImage: 'url(' + window.ossUrl + '/8.11/img_bg121%40new2x.png)',
            backgroundSize: '100% 100%',
            width: '100%',
            height: 180,
            background: 'cover',
            display: 'flex',
            flexDirection: 'column'
        }} >
            <Box display='flex' >
                <Box flex={1}></Box>
                <ButtonBase style={{ marginTop: 10, marginRight: 10 }} >
                    <Box m={1}><img width={25} height={25} src={window.ossUrl + '/image/ic_share%402x.png'} /></Box>
                </ButtonBase>
            </Box>
            <Box display='flex' flexDirection='row' ml={2} color='white' alignItems='center'>
                <Avatar className={classes.imgAt} alt="火" src={myself?.firstImg} />
                <text style={{ fontSize: 15, marginLeft: 8 }}>{myself?.nickname}</text>
            </Box>

        </Box>

    </Box>)
}

export default MySquarePage