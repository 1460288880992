import { Box, ButtonBase, makeStyles, Avatar, Divider } from '@material-ui/core'
import { useNavigationBar} from '../utils/bridge';
import { ButtonLinearLayout } from "../components/base";
import { useAccount } from "../utils/hooks";
import {removeBlockUser} from "../models/account";
import {useDispatch} from "react-redux";
// 我的粉丝
const useStyles = makeStyles((theme) => ({
    header: {
        background: ''
    }
}))

const MyBlacklist = ({ match }) => {
    const classes = useStyles()
    const title = '我的黑名单'
    useNavigationBar({
        title: title
       
    })
    const list = useAccount().blockList;
    console.log(list)
    return (<Box bgcolor='white' height='100%'>
        <Box className={classes.header}>
            {
                //创投为您推荐item
                list?.map(item =>
                    <BlackItem  key={item} {...item}/>
                )
            }
        </Box>
    </Box>)
}
const BlackItem = (props) => {
    const dispatch = useDispatch()

    const remove = () => {
        removeBlockUser(props.tid,dispatch)
    }
    
    return <Box display='flex' flexDirection='column'>
        <ButtonLinearLayout style={{ textAlign: 'left' }}>
            <Box display='flex' flexDirection='row' mx={1} mt={2} width={'-webkit-fill-available'} >
                <Avatar src={props.timg}/>
                <Box display='flex' flexDirection='column' mx={1}>
                    <Box fontSize={17}>{props.tnickname}</Box>
                </Box>
                <Box flex={1}></Box>
                <ButtonBase onClick={remove}>
                    <Box display='flex' alignItems='center' justifyContent='center' ml={0.5} style={{
                        width: 60, height: 26, borderRadius: 4,
                        borderColor: '#b2b2b2', color: '#b2b2b2',
                        borderStyle: 'solid',
                        borderWidth: '1px',
                    }}>
                        <text style={{ fontSize: 13, }}>取消拉黑</text>
                    </Box>
                </ButtonBase>
            </Box>

        </ButtonLinearLayout>
        <Divider style={{ marginLeft: 8 }} />
    </Box>
}
export default MyBlacklist