import { Box, makeStyles, InputBase, Button } from "@material-ui/core"
import { useEffect, useState } from "react"
import { setNavigationBar, useNavigationBar } from "../../utils/bridge"
import SearchIcon from '@material-ui/icons/Search';
import { searchProduct } from "../../service/goods";
import { GoodsGrid } from 'components';

const useStyle = makeStyles((theme) => ({
    searchBar: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        borderRadius: '25px',
        backgroundColor: '#FFF',
        padding: theme.spacing(0.5),
        margin: '8px 8px',
        width: 'calc(100% - 40px)',
    },
    searchButton: {
        background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
        borderRadius: 15,
        border: 0,
        color: 'white',
        height: 30,
        padding: '0px 10px',
        boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
}))

export const SearchPage = (props) => {
    const classes = useStyle()
    const [value, setValue] = useState()
    const [result, setResult] = useState([])

    useNavigationBar({ title: '搜索' })

    const search = async () => {
        const { data } = await searchProduct(value)
        setResult(data)
    }

    const onTextChange = (e) => {
        console.log(e.target.value);
        setValue(e.target.value)
    }

    return <Box>
        <Box className={classes.searchBar} >
            <SearchIcon style={{ color: '#2584F7', marginLeft: '8px' }} />
            <InputBase
                className={classes.input}
                placeholder="搜索产品"
                onChange={onTextChange}
                inputProps={{ 'aria-label': 'search goods' }}
            />
            <Button variant='contained' className={classes.searchButton} onClick={search}>搜索</Button>
        </Box>
        <GoodsGrid goodList={result} />
    </Box >

}