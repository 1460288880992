import { Box, ButtonBase, makeStyles, Grid } from '@material-ui/core'
import { useNavigationBar, useAppHistory } from '../../utils/bridge';
import React, { useEffect, useState } from 'react'
import { getFreeProductList } from "../../service/goods";
import { useRequest } from "ahooks";
import { Ellipsis } from 'antd-mobile';
// 免费抢
const FreeRobPage = (props) => {
    useNavigationBar({ title: '0元购' })

    const { data } = useRequest(getFreeProductList({
        pageNo: 0
    }), {
        formatResult: resp => resp.data.data
    })

    return (<Box display="flex" flexDirection="column" mx={1} mt>
        <Grid container spacing={1} style={{ padding: 8, }}>
            {
                data?.map(item =>
                    <Grid item xs={6} sm={4}> <FreeItem key={item} {...item} /></Grid>
                )
            }
        </Grid>

    </Box>)
}

const FreeItem = (props) => {
    const history = useAppHistory()
    return <ButtonBase style={{
        textAlign: 'left', backgroundColor: 'white',
        borderRadius: '8px', width: '-webkit-fill-available'
    }}
        onClick={() => {
            history.push(`/goods/${props.pId}`)
        }}>
        <Box display="flex" flexDirection="column" width='-webkit-fill-available' >

            <Box mt={0.5} height={180} bgcolor='#e9e9e9' display='flex' style={{
                backgroundImage: `url(${props.image})`,
                backgroundSize: '100% 100%',
                background: 'cover'
            }}
            >
            </Box>

            <Box display='flex' flexDirection='column'>

                <Box px={1} mt={1} height='40px'>
                    <Ellipsis rows={2} content={props.name}></Ellipsis>
                </Box>

                <Box display='flex' alignItems='center' px={1} >
                    <div style={{ fontSize: 12, color: "#A8A8A8", marginTop: 2, textDecoration: 'line-through' }}>
                        原价:¥{props.price / 100}</div>
                </Box>

                <Box display='flex' alignItems='center' px={1} pb={1} >
                    <div style={{ fontSize: 12, color: "#F43939", marginTop: 1, }}>
                        现价:¥</div>
                    <div style={{ fontSize: 16, marginLeft: 4, color: "#F43939", marginTop: 2, fontWeight: 'bold' }}>
                        0</div>
                    <Box flex={1} />
                    <Box bgcolor='#5Ba0f4' color="white" display='flex' fontSize={11}
                        height='20px' ml={1} mt={1} textAlign='center' alignItems='center'
                        px={1} borderRadius={8} >
                        限购{props.num} 件
                    </Box>
                </Box>

            </Box>
        </Box>
    </ButtonBase >
}

export default FreeRobPage