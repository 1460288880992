import { combineReducers } from "redux";
import { goodsReducer } from '../goods'
import { cartReducer } from '../cart'
import { toastReducer } from '../toast'
import { categoryReducer } from "../category";
import { addressReducer } from "../address";
import { orderReducer } from "../order";
import { accountReducer } from '../account'

export default combineReducers({
    toast: toastReducer,
    goodsDetail: goodsReducer,
    cart: cartReducer,
    category: categoryReducer,
    address: addressReducer,
    order: orderReducer,
    account: accountReducer,
})
