import { requestX } from '../../../utils/request'
/**
 * 工作台
 */

// 获取店铺数据
export async function storeData(storeId: any) {
  return requestX.get(`/mall/${storeId}/order/counts`)
}


// 获取店铺的资金数据
export async function storefundData(storeId: any) {
  return requestX.get(`/mall/${storeId}/cash/counts`)

}

// 商品管理
export async function productsManageList(params: any, pageNo: number = 0, pageSize: number = 40) {
  return requestX.get(`/mall/product`, {
    params: {
      pageNo,
      pageSize,
      ...params,
    }
  })
}

// 批量管理-商品列表接口
export async function batchManageList() {
  return requestX.get(``)
}

// 批量上架
export async function batchUpperShelf(params: any) {
  return requestX.put(`/mall/product/on/live`, {
    ids: params
  })
}

// 批量下架
export async function batchLowerShelf(params: any) {
  return requestX.put(`/mall/product/off/live`, {
    ids: params
  })
}

// 批量删除
export async function batchDelete(params: any) {
  return requestX.put(`/mall/product/del/variant`, {
    ids: params
  })
}

// 修改库存
export async function modifyInventory(params: any) {
  return requestX.put(`/mall/product/variant`, {
    ...params
  })
}


// 订单列表管理
export async function orderManageList(params: any, pageNo: number = 0, pageSize: number = 40) {
  return requestX.get(`/mall/order`, {
    params: {
      pageNo,
      pageSize,
      ...params,
    }
  })
}

// 订单列表管理
export async function orderSoldList(params: any, pageNo: number = 0, pageSize: number = 40) {
  return requestX.get(`/mall/order/post/sale`, {
    params: {
      pageNo,
      pageSize,
      ...params,
    }
  })
}

// 同意退款退货
export async function agreeReturnRefund(params: any) {
  return requestX.post(`/mall/order/post/sale/1`, {
    ...params
  })
}
// 取消订单
export async function cancelOrder(params: any) {
  return requestX.put(`/mall/order/cancel/${params?.orderId}`)
}

// 发物流
export async function listLogisticsFn(params: any) {
  return requestX.put(`/mall/order`, {
    ...params
  })
}


// 退货退款信息
export async function refundInfo(orderId: any) {
  return requestX.post(`/mall/order/post/sale/${orderId}`)
}

