import { useNavigationBar } from "../utils/bridge"
import qs from 'qs';
import {
    Box, Button, ButtonBase, Dialog, DialogTitle,
    DialogContent, DialogActions, makeStyles
} from "@material-ui/core";
import { useState } from "react";
import { reportContent } from "../service/goods";

const useStyles = makeStyles({
    item: {
        display: 'block',
        padding: 20,
        textAlign: 'left',
        width: '100%'
    }
})
/**
 * 举报页面
 *  todo
 * @returns
 */
export const ReportPage = ({ location }) => {
    const classes = useStyles()
    useNavigationBar({ title: "举报" })
    const { id, type, user } = qs.parse(location.search, { ignoreQueryPrefix: true })
    const [showResult, setShowResult] = useState(false)
    const [reason, setReason] = useState('')

    const select = () => async (e) => {
        console.log(e.target.innerText);
        const { data } = await reportContent({
            type: type, reasons: e.target.innerText, informants: user, service_id: id
        })
        console.log(data);
        setReason(e.target.innerText)
        setShowResult(true)
    }
    const handleClose = () => {
        setShowResult(false)
    }
    return <Box bgcolor='white' height='100%'>
        <Box fontSize={25} p={2}>
            举报
        </Box>
        <Box fontSize={12} color='#aaa' mx={2}>
            请选择举报原因，举报内容我们将在24小时内处理。
        </Box>
        <Box marginTop={2}>
            <ButtonBase className={classes.item} onClick={select(0)}>
                发布违法违禁内容对我造成骚扰
            </ButtonBase>
            <ButtonBase className={classes.item} onClick={select(1)}>发布赌博内容对我造成骚扰</ButtonBase>
            <ButtonBase className={classes.item} onClick={select(2)}>发布其他违规内容对我造成骚扰</ButtonBase>
            <ButtonBase className={classes.item} onClick={select(3)}>存在欺诈骗钱行为</ButtonBase>
            <ButtonBase className={classes.item} onClick={select(4)}>此账号可能被盗用</ButtonBase>
            <ButtonBase className={classes.item} onClick={select(5)}>存在侵权行为</ButtonBase>
            <ButtonBase className={classes.item} onClick={select(6)}>发布仿冒品信息</ButtonBase>
        </Box>
        <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={showResult}>
            <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                举报成功
            </DialogTitle>
            <DialogContent dividers>
                <Box mb={2}>
                    举报原因：{reason}
                </Box>
                我们已经接受到您的举报信息，届时会有审查人员对此用户的内容进行审核，如果违反了相关法律法规，或者含有令人反感的内容，我们将立即删除，并将用户进行封号处理。
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={handleClose} color="primary">
                    好的，我明白了
                </Button>
            </DialogActions>
        </Dialog>

    </Box>
}