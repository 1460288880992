import React, { useEffect, useState } from 'react'
import { makeStyles, Box, ListItem, ListItemAvatar, ListItemText, Typography, Stepper, Step, StepLabel, Grid, Button } from '@material-ui/core'
import { useNavigationBar, useAppHistory } from "utils/bridge"
import useFetch from "../../../utils/useFetch"
import { refundInfo, agreeReturnRefund } from './interface'
import { StatusBox } from '../../../components/empty'
import { useToast } from "../../../models/toast"

const useStyles = makeStyles(() => ({
  content: {
    '& .MuiStepIcon-root.MuiStepIcon-active, .MuiStepLabel-active, .MuiStepLabel-active + text, .MuiStepLabel-completed, .MuiStepIcon-root.MuiStepIcon-completed,  .MuiStepIcon-completed, .MuiStepLabel-completed + text': {
      color: '#A786E8 !important'
    },
    '& .MuiStepIcon-root, .MuiStepLabel-label': {
      color: '#D8D8D8',
    }
  },
  btn: {
    textAlign: 'center',
    padding: '20px 0',
    background: '#F1F1F2',
    position: 'fixed',
    bottom: 0,
    maxWidth: '840px',
    '& button': {
      color: '#A786E8',
      border: '1px solid #A786E8',
      borderRadius: '100px',
      textAlign: 'center',
      padding: '8px 24px',
    },
    '& .MuiGrid-root:nth-child(3) button': {
      color: '#fff',
      background: '#A786E8'
    }
  }
}))


export default function RefundDetail({ match }) {
  const { id } = match.params
  const classes = useStyles()
  const history = useAppHistory()
  const toast = useToast()
  const [detail, setDetail] = useState()

  useNavigationBar({
    title: '退款详情',
  })

  const steps = [
    { label: '买家申请退款', optional: '用户发起退款' },
    { label: '商家同意', optional: '已经为你取消订单，并退款。' },
    { label: '退款中', optional: '您的退款讲在1-7个工作日退回到自己的支付账户。' },
    { label: '完成', optional: '您的退款已经退回原支付账户。' },
  ]
  // 不同意退款文本
  const stepsDisagree = [
    { label: '申请退款', optional: '您的申请已经提交，商家同意后为您退款。' },
    { label: '商家不同意', optional: '商家不同意退款，请联系商家或平台客服协商。' },
  ]


  // 联系卖家
  const chat = () => {
    history.push(`app://chat/${order.store.userId}`)
  }

  // 拒绝 | 同意 退款
  const actionFn = async (params) => {
    const { data } = await agreeReturnRefund({ orderId: id, ...params })
    if (data) {
      toast.showToast("toast.success", "操作成功")
      history.pop()
    }
  }


  // 获取页面订单id查询接口生成商品信息
  const { loading, data, error } = useFetch('/mall/order/' + id)
  const { data: order } = data ?? {}

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    const { data } = await refundInfo(id)
    if (data) {
      setDetail(data)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Box className={classes.content} style={{ background: '#F1F1F2', height: '100%' }}>
      <StatusBox loading={loading} error={error} empty={data}>
        <Box>
          {order?.orderItems?.map((item, index) => (
            <React.Fragment key={index}>
              <ListItem alignItems="flex-start" style={{ background: '#fff', marginBottom: '10px' }}>
                <ListItemAvatar>
                  <img width={93} height={93} style={{ marginRight: '10px' }} alt="Remy Sharp" src={item?.thumb} />
                </ListItemAvatar>
                <ListItemText
                  primary={

                    <Box style={{ marginTop: '5px' }}>
                      <text style={{ fontSize: '14px', color: '#000', lineHeight: '17px', display: 'block', marginBottom: '4px' }}>订单：{id}</text>
                      <Box
                        p={2}
                        overflow='hidden'
                        height={33}
                        style={{ fontSize: '13px', lineHeight: '16px', display: '-webkit-box', WebkitLineClamp: 2, lineClamp: 2, WebkitBoxOrient: 'vertical', overflow: 'hidden', padding: 0 }}
                      >
                        {item?.name}
                      </Box>
                      <Box style={{ fontSize: '12px', color: '#747474', lineHeight: '17px', display: 'block' }}>x {item?.amount}</Box>
                      <text style={{ fontSize: '14px', color: '#FF0000', lineHeight: '17px', display: 'block', marginTop: 0 }}>¥ {item?.price / 100}</text>
                    </Box>
                  }
                />
              </ListItem>
            </React.Fragment>
          ))}
        </Box>
      </StatusBox>

      <Box style={{ background: '#fff', marginBottom: '10px' }} p={2}>
        <Typography variant="body1" gutterBottom>
          退款原因：{detail?.reason}
        </Typography>
        <Typography variant="body1">
          买家描述：{detail?.supplement}
        </Typography>
      </Box>

      <Box style={{ background: '#fff', marginBottom: '10px' }} p={2}>
        <Typography variant="body1" gutterBottom>买家举证 </Typography>
        {(detail?.multiView?.split(','))?.map(item => <img width={100} key={item} height={100} style={{ marginRight: '16px' }} alt="Remy Sharp" src={item} />)}
      </Box>

      <Box style={{ background: '#fff', paddingBottom: '100px' }} p={2}>
        <Typography variant="body1" gutterBottom>退款进度 </Typography>
        <Stepper activeStep={detail?.step} orientation="vertical" style={{ padding: 0 }}>
          {(detail?.agree === false ? stepsDisagree : steps)?.map((item) => (
            <Step key={item?.label}>
              <StepLabel optional={<text style={{ fontSize: '11px', color: '#D8D8D8' }}>{item?.optional}</text>}>{item?.label}</StepLabel>
            </Step>))}
        </Stepper>
      </Box>

      {!(detail?.step === 1 || detail?.step === 2 || detail?.step === 3) && (
        <Grid container className={classes.btn} >
          <Grid item xs={4}>
            <Button onClick={() => chat()}>联系卖家</Button>
          </Grid>
          <Grid item xs={4}>
            <Button onClick={() => actionFn({ agree: false })}>拒绝退款</Button>
          </Grid>
          <Grid item xs={4}>
            <Button onClick={() => actionFn({ agree: true })}>同意退款</Button>
          </Grid>
        </Grid>
      )}


    </Box>
  )
}