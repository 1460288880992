import { Box, ButtonBase, makeStyles, Paper } from "@material-ui/core"
import React, { useMemo, useState } from 'react'
import Avatar from '@material-ui/core/Avatar'
import { StatusBoxPro } from "../components/empty"
import { getShareItem, postDynamicComment, updateThumb, loadAllComments } from "../service/goods"
import { CommentItem, isVideo, TopicPicItem } from "./moment/base"
import InputTextDialog from '../components/inputText'

import { useToggle } from 'ahooks'
import moment from "moment"
import { ReportMenuList } from "./moment/report"
import { useAccount } from "../utils/hooks"
import { refreshPage } from "../utils/bridge"

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    bottom: {
        padding: theme.spacing(1),
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        display: 'flex',
        alignItems: 'center',
    },
    comment: {
        height: 40,
        borderRadius: 20,
        backgroundColor: '#eee',
        marginRight: 4,
        flex: 1,
    },

}))

const styles = {
    timeText: {
        color: '#CACACA',
        fontSize: 11,
    }
}

/**
 * 动态详情
 * @param {动态id} match.id 
 * @returns 
 */
const DynamicPage = ({ item }) => {
    const classes = useStyles()
    const {
        id,
        fileContent,
        textContent,
        html,
        firstImg,
        udId,
        nickname,
        tag,
        commentList,
        createTime,
    } = item
    const videoContent = isVideo(fileContent)
    const [open, { toggle }] = useToggle()
    const [openMenu, setOpenMenu] = useState(false)
    const [anchorEl, setAnchorEl] = React.useState(null)

    const [thumbs, setThumbs] = useState(item.thumbs)
    const [comments, setComments] = useState([...commentList])

    const onSendComment = async (text) => {
        toggle()
        const { data } = await postDynamicComment(id, text)
        if (data) {
            // 发表评论成功。
            loadComment()
            refreshPage("page_moment_list", "moment")
        }
    }

    const { blockList } = useAccount()
    const blockIds = blockList?.map(i => i.tid) ?? []

    const loadComment = async () => {
        const { data } = await loadAllComments(id)
        const cm = data.filter(i => !blockIds.includes(i.user))
        setComments(cm)
    }

    return <Box bgcolor='white' height='100%' >
        {
            !html && <Box display="flex" padding={1} >
            <Avatar src={firstImg} />
            <Box display="flex" flexDirection="column" style={{ marginLeft: 5, }}>
                <Box display="flex" flexDirection="row" alignItems='center' fontSize={15}>
                    <div style={{ color: "#FDB133" }}>{nickname}</div>
                    <img height={24} width={24} src={window.ossUrl + '/image/ic_grade%402x.png'} />
                </Box>
                <div style={styles.timeText} >{moment(createTime).fromNow()}</div>
            </Box>
                <Box flex={1} />
            </Box>
        }
        <DynamicBody
            html={html}
            textContent={textContent}
            tag={tag}
            videoContent={videoContent}
            fileContent={fileContent} />

        <Box bgcolor='#eee' height="12px" />
        <Box p={1} bgcolor='white'>
            <Box fontSize={16} fontWeight='500' mb={1}>
                评论
            </Box>
            {
                comments?.map(item => {
                    return <CommentItem key={item.id} {...item} />
                })
            }
            <Box height={60} />
        </Box>
        <ReportMenuList
            type="2"
            user={udId}
            id={id}
            open={openMenu}
            anchorEl={anchorEl}
            handleClose={() => setOpenMenu(false)}
        />

        <Paper className={classes.bottom}>
            <ButtonBase className={classes.comment}
                onClick={() => toggle()} >
                评论
            </ButtonBase>
        </Paper>
        <InputTextDialog
            open={open}
            title="输入评论"
            multiLine={true}
            onCancel={() => toggle()}
            onFinish={onSendComment}
        />
    </Box>
}

const DynamicBody = ({ html, textContent, fileContent, videoContent, tag }) => {
    if (html) {
        return <Box
            p={1}
            className="article"
            borderRadius='8px'
            bgcolor='white'
            dangerouslySetInnerHTML={{ __html: html }} />
    } else {
        return <Box color='#222' fontSize='15px' m={1}>
            <Box fontSize={14} my={1}>
                {tag && <span style={{ color: "#FDB133", }}>#{tag}# </span>}
                {textContent}
            </Box>
            {
                videoContent && <video height={290} width={160}
                    style={{ objectFit: 'cover', width: '100%' }}
                    controls controlsList='nodownload  noremoteplayback' disablePictureInPicture>
                    <source src={fileContent} />
                </video>
            }
            {
                !videoContent && !html && <TopicPicItem fileContent={fileContent} grid={6} height={200} />
            }
        </Box>
    }
}

export default function ({ match }) {
    const id = match.params.id
    const component = useMemo(() => item => <DynamicPage item={item} />)
    return <StatusBoxPro
        request={() => getShareItem(id)}
        component={component} />
}