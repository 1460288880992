import { Box, ButtonBase } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles";
import { useMemo } from "react";

const useStyles = makeStyles(theme => ({
    tab: {
        padding: '0 13px',
        fontWeight: 500,
        fontSize: 14,
        height: 20,
        flexShrink: 0,
    },
    animatedItem: {
        animation: `$myEffect 200ms ${theme.transitions.easing.easeInOut}`,
        transform: 'scale(1.4)',
        color: theme.palette.primary.main,
    },
    animatedItemExit: {
        animation: `$myEffectExit 200ms ${theme.transitions.easing.easeInOut}`,
        transform: 'scale(1)',
        color: theme.palette.grey[800],
    },
    "@keyframes myEffect": {
        from: {
            transform: 'scale(1)'
        },
        to: {
            transform: 'scale(1.4)',
            color: theme.palette.primary.main,
        }
    },
    "@keyframes myEffectExit": {
        from: {
            transform: 'scale(1.4)',
            color: theme.palette.primary.main,
        },
        to: {
            transform: 'scale(1)',
            color: theme.palette.grey[800]
        }
    },
}))

type TabProps = {
    current: number,
    items: Array<string | { name: string }>,
    onChoose: (item: number) => void
}

/// 纯文本的tab， 选中是颜色和字体变化
const TextTab: React.FC<TabProps> = ({ current, items, onChoose }) => {
    const classes = useStyles()
    return <Box p={2} overflow='scroll' display='flex' height={52}>
        {
            items.map((item, index) => {
                const title = typeof (item) === "string" ? item : item.name
                return <ButtonBase
                    disableRipple
                    onClick={() => onChoose(index)}
                    key={index}
                    className={classes.tab} >
                    <TabAnimate checked={index === current} title={title} />
                </ButtonBase>
            })
        }
    </Box>
}

const TabAnimate: React.FC<{ checked: boolean, title: string }> = ({ checked, title }) => {
    const classes = useStyles()
    const style = useMemo(() => {
        return checked ? classes.animatedItem : classes.animatedItemExit
    }, [checked])
    return <div
        className={style}
    >
        {title}
    </div>
}

export default TextTab