
import React, { useState, useEffect } from 'react'
import { Box, ListItem, Tabs, Tab, ListItemAvatar, ListItemText, List, makeStyles } from '@material-ui/core'
import { useNavigationBar, useAppHistory } from "utils/bridge"
import { afterSaleList } from './interface'
import moment from 'moment'
import { StatusBox } from '../../components/empty'
import { loadOrders } from '../../service/goods'

const useStyles = makeStyles(() => ({
  containerNav: {
    textAlign: 'center',
    padding: '0 16%',
    '& button': {
      width: '50%',
      textAlign: 'center',
    },
    '& .Mui-selected .MuiTab-wrapper': {
      color: '#D2035F'
    },
    '& .MuiTabs-indicator': {
      margin: '0 19%',
      maxWidth: '10%'
    },
    '& .MuiTab-wrapper': {
      color: '#999'
    }
  }
}))

export default function AfterSaleList() {
  const [value, setValue] = useState(0)
  const [data, setData] = useState([])
  const [dataDone, setDataDone] = useState([])
  const [loading, setLoading] = useState(false)
  const classes = useStyles()
  const history = useAppHistory()

  useNavigationBar({
    title: '售后列表',
  })

  const TabPanel = (props) => {
    const { children, value, index, ...other } = props

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box >
            {children}
          </Box>
        )}
      </div>
    );
  }


  const handleChange = (event, newValue) => {
    setValue(newValue)
  }


  /**
   * 
      0 未支付,
      1 已支付, 
      2 已退款, 22：申请退款 
      3 支付错误, 
      4 已关闭(超过30分钟未支付),
      5 确认收货,
      6 兑换券已消费完成
   */
  const stateType = [

    { key: 1, name: '待收货', shipped: 1 },
    { key: 1, name: '待发货', shipped: 0 },

    { key: 0, name: '未支付' },
    { key: 1, name: '已支付' },
    { key: 3, name: '支付错误' },
    { key: 4, name: '已关闭' },
    { key: 5, name: '确认收货' },
    { key: 6, name: '兑换券已消费完成' },

    { key: 2, name: '已退款' },
    { key: 21, name: '申请退货' },
    { key: 22, name: '申请退款' },
    { key: 23, name: '等待商家同意' },
    { key: 24, name: '商家不同意退款' },
    { key: 25, name: '等待买家退货' },
    { key: 26, name: '商家待收货' }
  ]


  // 列表信息
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    setLoading(true)
    const { data } = await afterSaleList({})
    if (data) {
      setData(data?.filter(el => el?.state !== 2))
    }
    const { data: orders } = await loadOrders({ state: 2 }, 0, false)
    if (orders) {
      setDataDone(orders)
    }
    setLoading(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const htmlContent = data => (
    <StatusBox empty={data} loading={loading}>{
      data?.map((el, index) => (
        <Box key={index}>
          {el?.orderItems?.map((item, index) => (
            <React.Fragment key={index}>

              <List
                style={{ background: '#fff', marginTop: '10px', padding: '15px' }}
                component="nav"
                aria-labelledby="nested-list-subheader"
                subheader={
                  <div container>
                    <text style={{ fontSize: '15px', color: '#000', lineHeight: '17px', display: 'block', marginBottom: '4px', float: 'left' }}>订单：{el?.id}</text>
                    <text style={{ fontSize: '12px', color: '#D2035F', lineHeight: '17px', display: 'block', marginBottom: '4px', float: 'right' }}>{stateType?.find(i => ((i?.shipped === 0 || i?.shipped === 1) ? (i?.key === el?.state) && (i?.shipped === el?.shipped) : i?.key === el?.state))?.name}</text>
                  </div>
                }
                onClick={() => history.push(`/refund_progress/${el?.id}`)}
              >
                <ListItem alignItems="flex-start" style={{ padding: 0 }}>

                  <ListItemAvatar>
                    <img width={75} height={75} style={{ marginRight: '10px' }} alt="Remy Sharp" src={item?.thumb} />
                  </ListItemAvatar>
                  <ListItemText
                    primary={

                      <Box style={{ marginTop: '5px' }}>
                        <Box
                          p={2}
                          overflow='hidden'
                          height={33}
                          style={{ fontSize: '13px', lineHeight: '16px', display: '-webkit-box', WebkitLineClamp: 2, lineClamp: 2, WebkitBoxOrient: 'vertical', overflow: 'hidden', padding: 0 }}
                        >
                          {item?.name}
                        </Box>
                        <Box style={{ fontSize: '13px', color: '#747474', lineHeight: '17px', display: 'block' }}>x {item?.amount}</Box>
                        {/* <text style={{ fontSize: '13px', color: '#FF0000', lineHeight: '17px', display: 'block', marginTop: 0 }}>¥ {item?.price / 100}</text> */}
                        <text style={{ fontSize: '13px', color: '#747474' }}> {moment(el?.date).format("YYYY-MM-DD")}</text>
                      </Box>
                    }
                  />
                </ListItem>
                <List component="div" disablePadding style={{ textAlign: 'right', fontSize: '13px' }}>
                  {el?.state === 22 ? '等待商家同意' : ''}
                </List>
              </List>
            </React.Fragment>
          ))}
        </Box>
      ))
    }
    </StatusBox>
  )

  return (
    <Box style={{ background: '#F1F1F2', height: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider', position: 'sticky', top: '0px', zIndex: 99, background: '#fff', boxShadow: '#f3f3f3 0px 2px 8px' }} className={classes.containerNav}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="进行中" />
          <Tab label="已完成" />
        </Tabs>
      </Box>

      <TabPanel value={value} index={0}>
        {htmlContent(data)}
      </TabPanel>
      <TabPanel value={value} index={1}>
        {htmlContent(dataDone)}
      </TabPanel>
    </Box>
  )
}