import React, { useEffect, useState } from 'react'
import { Box, ButtonBase, makeStyles, LinearProgress, List, } from "@material-ui/core"
import { createTheme, ThemeProvider } from '@material-ui/core';
import { useNavigationBar, useAppHistory, setTitle } from '../utils/bridge'
import { MallTab, MallSearchBar } from 'pages/service/base'
import { priceCast } from 'utils/price'
import useFetch from '../utils/useFetch'
import { Empty } from '../components/empty'
import {useRequest} from "ahooks";
import {getGoodsList} from "../service/goods";

//商城--新品

const useStyle = makeStyles(theme => ({

    price: {
        color: '#e12',
        fontSize: '16px',
        fontWeight: 500,
        '&::before': {
            content: '"¥"',
            fontSize: '12px',
        }
    },


    itemRoot: {
        display: 'flex',
        width: '100%',
    },
    itemImage: {
        width: 120,
        height: 120,
        minWidth: 120,
        margin: 10,
        borderRadius: 10,
        display: 'inline',
        objectFit: 'cover',
    },
    itemContent: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        flexGrow: 1,
        padding: 12,
    },
 
    itemTitle: {
        overflow: 'hidden',
        fontSize: 16,
        fontWeight: '500',
        textOverflow: 'ellipsis',
        wordWrap: 'break-word',
        lineHeight: '1.5rem',
        maxHeight: '3rem',
        textAlign: 'left',
    }
}))
const colorMap = {
    "shopping": "#fdc233",
    "home": "#e9097a",
    "eating": "#fdc233",
    "staying": "#fdc233",
}

const categoryMap = {
    "shopping": 150083,
    "home": 10,
    "eating": 150084,
    "staying": 1500850221,
}

const NewProductPage = ({ match }) => {
    const color = colorMap[match.params.place] ?? '#FFFFFF'
    const topCategory = categoryMap[match.params.place] ?? 10

    const [category, setCategory] = useState(topCategory)
    const onChoose = (i) => {
        setCategory(i.id)
    }
    useNavigationBar({
        title: '新品',
        darkBar: true,
        bgColor: color,
    })

    const expertYueTheme = createTheme({
        palette: {
            primary: {
                main: color,
            }
        }
    });

    return <ThemeProvider theme={expertYueTheme}>

        <Box display='flex' height={1} flexDirection='column'>
            <MallSearchBar />

            <MallTab onChange={onChoose} pid={topCategory} />

            <GoodsList category={category}></GoodsList>
        </Box></ThemeProvider>
}

const GoodsListItem = ({ item }) => {
    const classes = useStyle()
    // debugger
    const history = useAppHistory()
    const variant = item.productVariants
    return <Box className={classes.itemRoot} clone>
        <ButtonBase onClick={() => {
            history.push('/goods/' + item.id)
        }}>
            <img className={classes.itemImage} src={variant.thumb} ></img>
            <Box className={classes.itemContent}>
                <Box className={classes.itemTitle} color='primary'>{item.name}</Box>
                <Box className={classes.itemTag}>品质好</Box>
                <Box color='red' my={1} fontSize={18}>¥{priceCast(variant.price)}</Box>
                <Box flexGrow={1}></Box>
                <Box width={1}>
                    <span style={{ float: 'left' }}>艾迪森旗舰店</span>
                    <span style={{ float: 'right' }}>上海</span>
                </Box>
            </Box>
        </ButtonBase>
    </Box>
}
export const GoodsList = ({ category, ...props }) => {

    const { data, loading, error } = useRequest(getGoodsList({
        pageNo: 0,
        pageSize: 20,
        category: category
    }),  {
        refreshDeps: category
    })
    React.useEffect(() => {
        setTitle("分类列表")
    }, []);

    if (loading) {
        return <LinearProgress />
    }
    if (error) {
        console.error(error)
        return <div>error</div>
    }
    if (data.data.length === 0) {
        return <Empty title="当前分类没有商品" />
    }
    // debugger
    console.log(data.data)
    const listStyle = { overflow: 'scroll', height: '100%', padding: '0' }
    return (<Box height={1}>
        <List height='100%' style={listStyle}>
            {
                data.data.map(item =>
                    <GoodsListItem item={item} key={item.productVariants.id} />
                )
            }
        </List>
    </Box>)
}
export default NewProductPage










