import { useNavigationBar, useAppHistory } from '../utils/bridge';
import { Box, ButtonBase, makeStyles } from "@material-ui/core";


const useStyles = makeStyles((theme) => ({
    articleItem: {
        padding: theme.spacing(1),
        display: 'flex',
        color: '#444',
        backgroundColor: '#fff',
        textAlign: 'left',
        width: '100%',
    },
    cover: {
        width: 110,
        minWidth: 110,
        height: 70,
        borderRadius: 4,
    }
}))

const MyArticlePage = (props) => {
    useNavigationBar({
        title: '我的文章'
    })
    return (<Box>
        <Box p={1}>10月</Box>
        <ArticleItem title='上海警方抓69名“情感挽回大师”，查实五百余案涉七百万元'
            img={`${window.ossUrl}/file/00531635404581727339498.600.jpg`}
        />
        <ArticleItem
            title=' 中国游泳队公布东京奥运名单 叶诗文、傅园慧无缘'
            img={`${window.ossUrl}/file/71861635404697108454888.400.jpg`}
        />
        <Box p={1}>9月</Box>
        <ArticleItem
            title='东至县张溪镇沉团村种种事件是否合规？'
            img='https://r.sinaimg.cn/images/tc2.service.jpg'
        />
    </Box>)
}

export default MyArticlePage

function ArticleItem({ title, img }) {
    const classes = useStyles()
    return <Box className={classes.articleItem} clone>
        <ButtonBase>
            <Box p={0.5} fontSize={14} flex={1}>
                {title}
            </Box>
            <img className={classes.cover} src={img}></img>
        </ButtonBase>
    </Box>;
}
