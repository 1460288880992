
import { Box, Fab, IconButton, makeStyles } from "@material-ui/core";
import More from "@material-ui/icons/MoreVert";
import Label from '@material-ui/icons/Label';
import Delete from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import { useEffect, useState } from "react";
import { setNavigationBar, useAppHistory } from 'utils/bridge'
import { getMyJournal } from "service/goods";

import InputTextDialog from "components/inputText"
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'

const useStyle = makeStyles({
    tag: {
        fontSize: 10,
        backgroundColor: '#4ea24e',
        borderRadius: 9,
        lineHeight: '16px',
        display: 'inline',
        color: 'white',
        marginTop: '10px',
        padding: '2px 8px',
    }
})
export const JournalListPage = () => {
    const history = useAppHistory()
    const [list, setList] = useState([])

    useEffect(() => {
        setNavigationBar({ title: '我的日记' })
        async function loadJournalList() {
            const { data } = await getMyJournal()
            const result = data.map(i => {
                let index = i.textContent.indexOf('\n')
                if (index) {
                    return {
                        id: i.id,
                        title: i.textContent.substring(0, index),
                        content: i.textContent.substring(index + 1),
                    }
                } else {
                    return {
                        id: i.id,
                        title: i.textContent,
                    }
                }
            })
            setList(result)
        }
        loadJournalList()
    }, [])

    return <Box padding={1} height='100%' overflow='scroll'>
        {
            list.map(item => <JournalItem key={item.id} {...item} />)
        }
        <Fab color="primary" aria-label="add"
            onClick={() => history.push('/add_journal')}
            style={{
                position: 'fixed',
                right: '40px',
                bottom: '40px'
            }}>
            <AddIcon />
        </Fab>
    </Box>
}

const JournalItem = ({ title, content }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const classes = useStyle()
    const [open, setOpen] = useState(false)

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    }

    const handleClose = () => {
        setAnchorEl(null);
    }

    return <Box m={1} borderRadius={8} display='flex' alignItems='start' bgcolor='white' padding={2} onClick={() => console.log('abc')}>
        <Box flex={1}>
            <Box fontSize={18}>{title}</Box>
            <Box fontSize='14px' color='#888' my={1}>
                {content}
            </Box>
            <Box className={classes.tag}>收藏</Box>
        </Box>
        {/*临时关闭入口<IconButton onClick={handleClick}>
            <More />
        </IconButton>*/}
        <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
        >
            <MenuItem onClick={() => {
                setAnchorEl(null)
                setOpen(true)
            }}>
                <Label style={{ color: '#8b8', marginRight: '10px' }} />标签</MenuItem>
            <MenuItem onClick={handleClose}>
                <Delete style={{ color: '#e00', marginRight: '10px' }} />删除</MenuItem>
        </Menu>

        <InputTextDialog open={open}
            title="输入标签"
            hint={"填写标签名称"}
            multiLine={false}
            onCancel={() => setOpen(false)}
            onFinish={(value) => {
                console.log(value)
                setOpen(false)
            }}
        />
    </Box>
}