import { useState, useEffect } from 'react'
import { Box, Card, makeStyles, ButtonBase, Typography, Stepper, Step, StepLabel } from '@material-ui/core'
import { useAppHistory, useNavigationBar } from 'utils/bridge'
import { getStoreInfo } from 'service/goods'
import { Upload } from "components/base"
import { useForm } from "react-hook-form"
import InfoIcon from '@material-ui/icons/Info'
import { businessLicense, businessLicenseModify, licenseList } from '../myCertification/interface.ts';


const useStyles = makeStyles(() => (
  {
    row: {
      background: 'linear-gradient(90deg, #C90154, #E9097A)',
      height: '156px',
      borderBottomLeftRadius: '28px',
      borderBottomRightRadius: '28px',
      paddingTop: '1px',
      '& .MuiCard-root': {
        boxShadow: '0 0 5px rgb(0 0 0 / 5%) !important',
      },
      '& .MuiStepIcon-root.MuiStepIcon-completed': {
        color: '#E9097A'
      }
    },
    faildRow: {
      '& .MuiStep-root:nth-child(3) .MuiStepIcon-root.MuiStepIcon-completed, .MuiStep-root:nth-child(3) .MuiTypography-root': {
        color: '#ddd'
      },
      '& .MuiStep-root:nth-child(2) .MuiStepIcon-root.MuiStepIcon-completed': {
        color: '#f44336'
      },
      '& .MuiStepIcon-root.MuiStepIcon-completed': {
        color: '#E9097A'
      }
    },
    uploadBtn: {
      background: 'linear-gradient(90deg, #C90154, #E9097A)',
      color: '#fff',
      textAlign: 'center',
      padding: '5px',
      marginTop: '10px',
      width: '100%'
    },
    btn: {
      position: 'fixed',
      bottom: '25px',
      width: '94%',
      maxWidth: '819px',
      marginLeft: '-50px',
      "& button": {
        fontSize: 20,
        color: 'white',
        width: '100%',
        padding: 8,
        background: 'linear-gradient(90deg, #C90154, #E9097A)',
        borderRadius: '100px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }
    }
  }
))
export default function UploadCredentials({ match }) {
  const shopId = match.params.id
  const classes = useStyles()
  const [store, setStore] = useState()
  const [statusValue, setStatusValue] = useState()
  const [detail, setDetail] = useState({})
  const [state, setState] = useState(1) // 1 上传证件页面，2 入驻需知页，3 提交成功页，4审核失败页
  const [businessList, setBusinessList] = useState({})

  const uploadFn = (value, url) => {
    setValue(value, url)
    clearErrors(value) // 清除错误提示
  }

  // 错误提示语
  const errorsHtml = (register, message) =>
    ((register && message) &&
      (<p style={{ textAlign: 'right', marginRight: '20px', color: '#ea4949', fontSize: '12px', margin: '0 0 0 93px' }}>
        <InfoIcon style={{ color: '#ea4949', fontSize: 14, verticalAlign: '-2px', marginRight: '3px' }} />
        {message}
      </p>))


  // 提交成功后，待审核文案
  const steps = [
    '基础信息',
    '资质认证',
    '待审核'
  ]

  // 认证失败文案
  const stepsFail = [
    '基础信息',
    '资质认证失败',
    '审核'
  ]

  /**
   * 表单
   */
  const { register, handleSubmit, formState: { errors }, setValue, clearErrors } = useForm()

  /**
   * 提交营业执照及身份证信息
   * @param {*} reqData 
   */
  const onSubmit = async reqData => {
    const { data } =
      statusValue === 'false' //  status: null 去认证, false 去修改， true 去查看
        ? await businessLicenseModify({ ...reqData, type: 1, udid: businessList?.ud_id, id: businessList?.id })
        : await businessLicense({ ...reqData, type: 1, udid: businessList?.ud_id, id: businessList?.id })
    if (data) {
      setState(2)
    }
  }

  useNavigationBar({
    title: '入驻申请',
  })

  // 获取营业执照初始数据
  const businessFn = async () => {
    const { data } = await licenseList(0, 1)
    if (data) {
      setDetail(JSON.parse(data?.[0]?.detail))
      setBusinessList(data?.[0])
      const { legalFront, legalBack } = JSON.parse(data?.[0]?.detail) || {}

      // 初始化初始数据
      setValue('front', data?.[0]?.front)
      setValue('legalBack', legalBack)
      setValue('legalFront', legalFront)
      setStatusValue(`${data?.[0]?.state}`)
    }
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    businessFn()
    const { data } = await getStoreInfo(shopId)
    if (data && data.data) {
      setStore(data.data)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <>
      {state === 1
        ? (
          <Box className={classes.row}>
            <Card style={{ margin: '25px 15px', padding: '26px 47px' }}>
              <form onSubmit={handleSubmit(onSubmit)}>

                <Typography style={{ textAlign: 'center', padding: '8px', fontSize: '14px' }}>营业执照副本</Typography>
                <Box
                  style={{
                    textAlign: 'center',
                    background: '#F4F8FE',
                    padding: '15px'
                  }}>
                  <Upload onUpload={url => {
                    uploadFn('front', url)
                  }}
                    width={123}
                    height={82}
                    borderRadius={0}
                    imgBg={(businessList?.front === 'null' ? window?.ossUrl + '/file/32231640425012678316929.500.png' : businessList?.front) || window?.ossUrl + '/file/32231640425012678316929.500.png'}
                    customize={<></>}
                  />
                </Box>

                <textarea style={{ opacity: 0, position: 'absolute' }} type="text" {...register("front", { required: "上传营业执照" })} placeholder="上传营业执照" />
                {errorsHtml(errors?.front, errors?.front?.message)}


                <Typography style={{ textAlign: 'center', padding: '10px', fontSize: '14px', marginTop: '20px' }}>法人身份证</Typography>
                <Box
                  style={{
                    textAlign: 'center',
                    background: '#F4F8FE',
                    paddingTop: '15px'
                  }}>
                  <Upload onUpload={url => {
                    uploadFn('legalFront', url)
                  }}
                    width={123}
                    height={82}
                    borderRadius={0}
                    imgBg={(detail?.legalFront === 'null' ? window?.ossUrl + '/file/14291635752374279101445.600.png' : detail?.legalFront) || window?.ossUrl + '/file/14291635752374279101445.600.png'}
                    customize={<ButtonBase className={classes.uploadBtn} >身份证人像面</ButtonBase>}
                  />
                </Box>

                <textarea style={{ opacity: 0, position: 'absolute' }} type="text" {...register("legalFront", { required: "上传身份证人像面" })} placeholder="上传身份证人像面" />
                {errorsHtml(errors?.legalFront, errors?.legalFront?.message)}


                <Box mt style={{
                  textAlign: 'center',
                  background: '#F4F8FE',
                  paddingTop: '15px',
                  marginTop: '35px'
                }}>
                  <Upload onUpload={url => {
                    uploadFn('legalBack', url)
                  }}
                    width={123}
                    height={82}
                    borderRadius={0}
                    imgBg={(detail?.legalBack === 'null' ? window?.ossUrl + '/file/56631635755861527255264.400.png' : businessList?.legalBack) || window?.ossUrl + '/file/56631635755861527255264.400.png'}
                    customize={<ButtonBase className={classes.uploadBtn} >身份证国徽面</ButtonBase>}
                  />
                </Box>


                <textarea style={{ opacity: 0, position: 'absolute' }} type="text" {...register("legalBack", { required: "上传身份证国徽面" })} placeholder="上传身份证国徽面" />
                {errorsHtml(errors?.legalBack, errors?.legalBack?.message)}

                <Box style={{ padding: '15px' }} className={classes.btn} >
                  <ButtonBase type="submit" >下一步</ButtonBase>
                </Box>
              </form>

            </Card>
          </Box>
        )
        :
        state === 2
          ?
          (
            <Box style={{ background: '#fff', height: '100%', padding: '16px' }}>
              <Typography>
                入驻须知，持续更新中。。。
              </Typography>


              <Box style={{ padding: '15px', marginLeft: '-5px' }} className={classes.btn} >
                <ButtonBase onClick={() => setState(1)} style={{ background: '#ddd', marginBottom: '10px' }} >不同意</ButtonBase>
                <ButtonBase onClick={() => setState(3)}>同意并提交</ButtonBase>
              </Box>
            </Box>
          )
          : state === 3
            ? (
              <Box className={classes.row}>
                <Card style={{ margin: '25px 15px', padding: '26px' }}>
                  <Typography style={{ textAlign: 'center', fontSize: '13px' }}>
                    <img src={window?.ossUrl + '/file/39281640429190553392733.900.png'} alt='' width={55} height={55} />
                    <text style={{ display: 'block' }}>提交成功</text>
                  </Typography>
                  <Card style={{ margin: '25px 15px' }}>
                    <Stepper activeStep={2} alternativeLabel>
                      {steps.map((label) => (
                        <Step key={label}>
                          <StepLabel ><text style={{ fontSize: '10px' }}>{label}</text></StepLabel>
                        </Step>
                      ))}
                    </Stepper>
                  </Card>
                  <Typography style={{ textAlign: 'center', fontSize: '10px' }}>提示：如果遇到认证没成功的，请及时重新认证</Typography>
                </Card>
              </Box>
            )
            : (
              <Box className={classes.row}>
                <Card style={{ margin: '25px 15px', padding: '16px' }}>
                  <Typography style={{ textAlign: 'center', fontSize: '13px' }}>
                    <img src={window?.ossUrl + '/file/33521640430210554110147.000.png'} alt='' width={55} height={55} />
                    <text style={{ display: 'block' }}>资质认证失败</text>
                  </Typography>
                  <Card style={{ margin: '25px 15px' }}>
                    <Stepper activeStep={3} alternativeLabel className={classes.faildRow}>
                      {stepsFail.map((label, index) => {
                        const labelProps = {};
                        if (index === 1) {
                          labelProps.optional = (
                            <Typography color="error">
                            </Typography>
                          );

                          labelProps.error = true;
                        }

                        return (
                          <Step key={label}>
                            <StepLabel {...labelProps}><text style={{ fontSize: '10px' }}>{label}</text></StepLabel>
                          </Step>
                        );
                      })}
                    </Stepper>
                  </Card>
                  <Typography style={{ textAlign: 'center', fontSize: '10px' }}>提示：如果遇到认证没成功的，请及时重新认证</Typography>
                </Card>

                <Box style={{ padding: '15px' }} className={classes.btn} >
                  <ButtonBase>重新申请</ButtonBase>
                </Box>
              </Box>
            )
      }
    </>

  )
}