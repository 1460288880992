import { Avatar, Box, Button, IconButton } from '@material-ui/core'
import React from 'react'
import { callPay, useAppHistory, toast, postShareInfo, refreshPage } from '../utils/bridge'
import { ButtonBox } from '../components/base'
import { addToCart as addToCartApi, confirmOrder, cancelOrder, presentCoupons } from '../service/goods'
import { priceCast } from '../utils/price'
import dateformat from 'dateformat'
import { ChoosePayChannelDialog } from './orderDetail'

export const orderStateText = (order) => {
    const { state, shipped, payer, uid } = order
    // 已经送人了
    const presented = payer != uid
    const statusLabel = presented ? "已赠送" : statusStr(state, shipped)
    return statusLabel
}

const statusStr = (status, shipped) => {
    switch (status) {
        case 0: return "未支付"
        case 1:
            if (shipped === 0) {
                return "待发货"
            } else if (shipped === 1) {
                return "待收货"
            } else if (shipped === 2) {
                return "待自提"
            }
            return "待货"
        case 2: return "已退款"
        case 3: return "支付错误"
        case 4: return "交易关闭"
        case 5: return "已完成"
        case 6: return "已消费完成"

        case 21: return "申请退货"
        case 22: return "申请退款"
        case 23: return "等待商家同意退款"
        case 24: return "商家不同意退款"
        case 25: return "退货：等待买家退货"
        case 26: return "退货：商家待收货"
        case 29: return "退款失败"
        default: return ""
    }
}

const serviceStatusStr = (status, shipped) => {
    switch (status) {
        case 0: return "未支付"
        case 1:
            return shipped === 1 ? "待确认" : "待接单"
        case 2: return "已退款"
        case 3: return "支付错误"
        case 4: return "交易关闭"
        case 5: return "已完成"
        default: return ""
    }
}


/// 服务订单项
export const ServiceOrderItem = ({ id, orderItems, date, totalPrice, state, shipped, store, asSeller }) => {
    const history = useAppHistory()
    const item = orderItems[0]
    const pay = () => {
        callPay({ id: id, price: totalPrice })
    }
    const { storeName } = store
    const gotoDetail = () => {
        history.push(`/order/${id}`)
    }

    const cancel = async () => {
        const { data } = await cancelOrder(id)
        if (data.code === '200') {
            toast("订单已取消")
        }
    }
    const finishOrder = async () => {
        /// 确认收货
        const { data } = await confirmOrder(id)
        if (data.code === "200") {
            gotoDetail()
        } else {
            toast("确认收货失败")
        }
    }
    return (
        <Box mx={1} marginTop={1} bgcolor='white' borderRadius={6}>
            <Box paddingX={1.5} paddingTop={1.5} display='flex' flexDirection='row' alignItems='center' fontSize={14}>
                <img src='https://202108251114.oss-cn-shanghai.aliyuncs.com/app/ic_store.png' style={{ width: '20px', height: '20px' }} />
                <Box fontWeight='600' mx={1}>
                    {storeName}
                </Box>
                <img src='https://202108251114.oss-cn-shanghai.aliyuncs.com/app/right.png' style={{ width: '16px', height: '16px' }} />
                <Box flex={1} />
                <Box textAlign='right' color={state < 2 ? 'red' : '#FFBE33'}>{serviceStatusStr(state, shipped)}</Box>
            </Box>
            <ButtonBox disableRipple style={{ alignItems: 'stretch' }} onClick={gotoDetail} >
                <Avatar variant='rounded' style={{ 'width': '76px', height: '78px' }} src={item.thumb}>a</Avatar>
                <Box ml={1.5} flex={1} textAlign='left'>
                    <Box mt={0.4} fontWeight='600' maxHeight='2.4rem' overflow='hidden' fontSize={14} lineHeight='1.2rem'>{item.name}</Box>
                    <Box>规格：{item.model}</Box>
                    <Box mt={1} color='#888'>{dateformat(new Date(date), 'mm-dd HH:MM')}</Box>
                </Box>
                <Box display='flex' alignItems='flex-end' flexDirection='column'>
                    <Box mt={0.6} fontSize={14}>¥{priceCast(item.price)}</Box>
                    <Box>x{item.amount}</Box>
                    <Box mt={1}>
                        <Box component='span'>实付款</Box>
                        <Box component='span' ml={0.6} color='red'>¥</Box>
                        <Box component='span' fontSize={18} fontWeight={700} color='red'>{priceCast(totalPrice)}</Box>
                    </Box>
                </Box>

            </ButtonBox>
            <Box
                display={asSeller ? 'none' : 'flex'}
                flexDirection='row' px={1.5} pb={1.5} justifyContent='flex-end'>
                <IconButton size='small' style={{ marginRight: 4 }} >
                    <img style={{ width: 21 }} src='https://202108251114.oss-cn-shanghai.aliyuncs.com/app/ic_time_line.png'></img>
                </IconButton>
                <RadiusButton title="取消" display={state === 0} onClick={cancel} rightMargin={4} />
                <RadiusButton title="支付" display={state === 0} onClick={pay} rightMargin={4} />
                <RadiusButton title="确认完成" display={shipped === 1 && state === 1} onClick={finishOrder} rightMargin={4} />
            </Box>
        </Box>)
}

/// 商品订单项
export const OrderItem = ({ id, orderItems, date, totalPrice, state: orderState, shipped, store, expressCompany, payer, uid, trackingNumber }) => {
    const [state, setState] = React.useState(orderState)
    const [openChannel, setOpenChannel] = React.useState(false)
    const history = useAppHistory()
    const item = orderItems[0]

    const { storeName } = store
    const gotoDetail = () => {
        history.push(`/order/${id}`)
    }
    const addToCart = async () => {
        const { data } = await addToCartApi(1, item.productVariantId)
        if (data.code === '200') {
            toast("已加入购物车")
        }

    }
    const cancel = async () => {
        const { data } = await cancelOrder(id)
        if (data.code === '200') {
            setState(4)
            toast("订单已取消")
            refreshPage("page_order", "cancel")
        }
    }
    const finishOrder = async () => {
        /// 确认收货
        const { data } = await confirmOrder(id)
        if (data.code === "200") {
            setState(5)
            gotoDetail()
        } else {
            toast("确认收货失败")
        }
    }
    const express = () => {
        expressCompany = 'yunda'
        trackingNumber = 432297440402043
        if (expressCompany && trackingNumber) {
            history.push(`https://m.kuaidi100.com/app/query/?com=${expressCompany}&nu=${trackingNumber}&coname=giraffe`)
        }
    }

    const shareWechat = async () => {
        await presentCoupons(id).catch(e => {
            console.warn("分享礼券api出错", e);
        })
        postShareInfo({
            type: 1,
            content: {
                title: '您收到一个新到礼券啦',
                subTitle: item.name,
                image: item.thumb + "?x-oss-process=image/resize,w_40,h_40,m_fill",
                source: "订单"
            },
            jsonType: 2,
            json: `https://www.qihuaitech.com/wx/receive?id=${id}`,
        })
    }

    const comment = async () => {
        history.push('/recommended_shopping/' + id)
    }
    // 已经送人了
    const presented = payer != uid
    const statusLabel = presented ? "已赠送" : statusStr(state, shipped)
    return (
        <Box mx={1} marginTop={1} bgcolor='white' borderRadius={6}>
            <Box paddingX={1.5} paddingTop={1.5} display='flex' flexDirection='row' alignItems='center' fontSize={14}>
                <img src='https://202108251114.oss-cn-shanghai.aliyuncs.com/app/ic_store.png' style={{ width: '20px', height: '20px' }} />
                <Box fontWeight='600' mx={1}>
                    {storeName}
                </Box>
                <img src='https://202108251114.oss-cn-shanghai.aliyuncs.com/app/right.png' style={{ width: '16px', height: '16px' }} />
                <Box flex={1} />
                <Box textAlign='right' color={state < 2 ? 'red' : '#FFBE33'}>{statusLabel}</Box>
            </Box>
            <ButtonBox disableRipple style={{ alignItems: 'stretch' }} onClick={gotoDetail} >
                <Avatar variant='rounded' style={{ 'width': '76px', height: '78px' }} src={item.thumb}>a</Avatar>
                <Box ml={1.5} flex={1} textAlign='left'>
                    <Box mt={0.4} fontWeight='600' maxHeight='2.4rem' overflow='hidden' fontSize={14} lineHeight='1.2rem'>{item.name}</Box>
                    <Box>规格：{item.model}</Box>
                    <Box mt={1} color='#888'>{dateformat(new Date(date), 'mm-dd HH:MM')}</Box>
                </Box>
                <Box display='flex' alignItems='flex-end' flexDirection='column'>
                    <Box mt={0.6} fontSize={14}>¥{priceCast(item.price)}</Box>
                    <Box>x{item.amount}</Box>
                    <Box mt={1}>
                        <Box component='span'>实付款</Box>
                        <Box component='span' ml={0.6} color='red'>¥</Box>
                        <Box component='span' fontSize={18} fontWeight={700} color='red'>{priceCast(totalPrice)}</Box>
                    </Box>
                </Box>

            </ButtonBox>
            <Box display='flex' flexDirection='row' px={1} pb={1.5} justifyContent='flex-end'>
                <RadiusButton title="关闭" display={state === 0} onClick={cancel} />
                <RadiusButton title="支付" variant='contained' display={state === 0} onClick={() => setOpenChannel(true)} />
                <RadiusButton title="加入购物车" display={state === 0 || state === 5} onClick={addToCart} />

                <RadiusButton title="查看物流" display={shipped === 1 && state === 1} onClick={express} />
                <RadiusButton title="确认收货" variant='contained' display={shipped === 1 && state === 1}
                    onClick={finishOrder}
                    rightMargin={4} />
                <RadiusButton title="转赠" display={shipped === 2 && state === 1 && !presented} onClick={shareWechat} />
                <RadiusButton title="核销"
                    variant='contained'
                    display={shipped === 2 && state === 1 && !presented}
                    onClick={() => {
                        history.push(`/order/${id}?exchange=true`)
                    }} />
                <RadiusButton title="购推荐" display={state === 5 && !presented} onClick={comment} />
                <RadiusButton title="悦晒礼" display={shipped === 3 && state === 5 && presented} onClick={comment} />
            </Box>
            <ChoosePayChannelDialog
                orderId={id}
                price={totalPrice}
                open={openChannel}
                onClose={() => { setOpenChannel(false) }} />
        </Box>)
}


export const SimpleOrderItem = ({ id, orderItems, date, totalPrice, state: orderState, shipped, store, onClick }) => {
    const [state, setState] = React.useState(orderState)
    const item = orderItems[0]

    const { storeName } = store

    return (
        <Box mx={1} marginTop={1} bgcolor='white' borderRadius={6}>
            <Box paddingX={1.5} paddingTop={1.5} display='flex' flexDirection='row' alignItems='center' fontSize={14}>
                <img src='https://202108251114.oss-cn-shanghai.aliyuncs.com/app/ic_store.png' style={{ width: '20px', height: '20px' }} />
                <Box fontWeight='600' mx={1}>
                    {storeName}
                </Box>
                <Box flex={1} />
                <Box textAlign='right' color={state < 2 ? 'red' : '#FFBE33'}>{statusStr(state, shipped)}</Box>
            </Box>
            <ButtonBox disableRipple style={{ alignItems: 'stretch' }} onClick={onClick} >
                <Avatar variant='rounded' style={{ 'width': '76px', height: '78px' }} src={item.thumb}>a</Avatar>
                <Box ml={1.5} flex={1} textAlign='left'>
                    <Box mt={0.4} fontWeight='600' maxHeight='2.4rem' overflow='hidden' fontSize={14} lineHeight='1.2rem'>{item.name}</Box>
                    <Box>规格：{item.model}</Box>
                    <Box mt={1} color='#888'>{dateformat(new Date(date), 'mm-dd HH:MM')}</Box>
                </Box>
                <Box display='flex' alignItems='flex-end' flexDirection='column'>
                    <Box mt={0.6} fontSize={14}>¥{priceCast(item.price)}</Box>
                    <Box>x{item.amount}</Box>
                    <Box mt={1}>
                        <Box component='span'>实付款</Box>
                        <Box component='span' ml={0.6} color='red'>¥</Box>
                        <Box component='span' fontSize={18} fontWeight={700} color='red'>{priceCast(totalPrice)}</Box>
                    </Box>
                </Box>
            </ButtonBox>

        </Box>)
}

export const RadiusButton = ({ title, onClick, display, rightMargin = 4, variant = "outlined" }) => {
    if (!display) {
        return null
    }
    return <Button
        variant={variant}
        disableElevation={true}
        color='secondary'
        size='small'
        onClick={onClick}
        style={{ borderRadius: 20, marginRight: rightMargin }}> {title}</Button>
}