import ReactDOM from 'react-dom';
import './index.css';
import App from './App'
import store from './models/store'
import { Provider } from 'react-redux'
import { createTheme, ThemeProvider } from '@material-ui/core'

const theme = createTheme({
  palette: {
    primary: {
      main: '#D22820',
      contrastText: "#fff"
    },
    secondary: {
      main: '#D2035F'
    },
    red: {
      main: 'linear-gradient(90deg, #C90154 30%, #E9097A 50%)',
      contrastText: "#fff"
    },
  }
});

ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </Provider>
  , document.getElementById('root')
);
