import { BrowserRouter, Route, Redirect } from 'react-router-dom';
import AppRouter from './route';
import { Box } from '@material-ui/core';
import { connect, useDispatch } from 'react-redux'
import Alert from '@material-ui/lab/Alert'
import Snackbar from '@material-ui/core/Snackbar';
import { toastLevel } from './models/toast';

const Page = ({ level, message }) => {
  const open = level !== null && level !== undefined
  const dispatch = useDispatch()
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch({ type: toastLevel.ToastNone })
  };


  const toast = open ?
    <Snackbar open={open} autoHideDuration={4000} onClose={handleClose}>
      <Alert onClose={handleClose} severity={level.split('.').pop()}>
        {message}
      </Alert>
    </Snackbar> : <Box />
  return <>
    {toast}
    <BrowserRouter>
      <Route exact path="/">
        <Redirect to="/main/home" />
      </Route>
      <AppRouter />
    </BrowserRouter>
  </>
}

window.ossUrl = 'https://202108251114.oss-accelerate.aliyuncs.com'

const App = connect(({ toast: { level, message } }) => ({ level: level, message: message }))(Page)
export default App
