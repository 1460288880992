import React, { useEffect } from 'react';
import {
    Box, Typography, FormControl, InputLabel,
    Input, InputAdornment, TextField, Button
} from '@material-ui/core';
import * as Actions from '../../models/account';
import { connect } from 'react-redux';

import { toast, useAppHistory } from '../../utils/bridge';

const ChangePhonePage = ({ phoneState, sendSms, setPhone, reset, getUserInfo }) => {

    const history = useAppHistory()

    const [values, setValues] = React.useState({
        phone: '',
        code: '',
    });

    const [vaild, setVaild] = React.useState({
        phone: false,
        code: false,
    })

    if (phoneState === 1) {
        if ('pop' in history) {
            history.pop()
        }
    }

    const [timeLeft, setTimeLeft] = React.useState(-2)

    useEffect(() => {
        getUserInfo()
        reset()
    }, [])
    useEffect(() => {
        if (timeLeft === -2) {
            return
        }
        const timer = setTimeout(() => {
            setTimeLeft(timeLeft - 1)
        }, 1000);
        return () => clearTimeout(timer)
    })

    const sendCode = () => {
        if (!values.phone.match(/^[1][3,4,5,6,7,8,9]\d{9}$/)) {
            toast("手机号格式错误")
            setVaild({ ...vaild, phone: true })
            return
        } else {
            setVaild({ ...vaild, phone: false })
        }
        //发送验证码
        setTimeLeft(60)
        sendSms(values.phone, 986227)
    }
    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    const clickSetPassword = () => {
        let error = {
            phone: !values.phone.match(/^[1][3,4,5,6,7,8,9]\d{9}$/),
            code: !values.code.match(/^\d{4,6}$/),
        }
        if (error.phone) {
            toast("手机号格式错误")
        }
        if (error.code) {
            toast('验证码格式错误')
        }
        setVaild(error)
        if (error.phone || error.code || error.password) {
            return
        }
        setPhone(values.phone, values.code)
    }

    return (
        <Box p={2}>
            <Typography variant='h3' component='h3'>修改手机号码</Typography>
            <Typography variant='h5' component='h3'>输入新的手机号</Typography>
            <Box m={5}></Box>

            <FormControl fullWidth >
                <InputLabel htmlFor="standard-adornment-amount">手机号</InputLabel>
                <Input
                    id="standard-adornment-amount"
                    type='number'
                    value={values.phone}
                    error={vaild.phone}
                    onChange={handleChange('phone')}
                    startAdornment={<InputAdornment position="start">+86</InputAdornment>}
                />
            </FormControl>

            <Box mt={2} display='flex' alignItems='center'>
                <Box flex='1' paddingRight={1}>
                    <TextField
                        id="standard-code-input"
                        label="验证码"
                        error={vaild.code}
                        value={values.code}
                        onChange={handleChange('code')}
                        fullWidth
                        type='number'
                        autoComplete="current-password"
                    />
                </Box>
                <Button variant='outlined' onClick={sendCode} disabled={timeLeft > 0}>
                    {timeLeft === -2 ? '发送验证码' : `剩余${timeLeft}秒`}
                </Button>
            </Box>
            <Box mt={14}>
                <Button disableElevation variant='contained'
                    onClick={clickSetPassword}
                    color='primary' fullWidth >
                    确认
                </Button>
            </Box>
        </Box>)
}

const propsMap = ({ account }) => (account)

export default connect(propsMap, Actions)(ChangePhonePage)