

import { useState, useMemo, useCallback } from "react"
import { useRequest } from 'ahooks'
import QRCode from 'qrcode.react'
import { orderDetail, getOutletList, getReclaimPrice, postReclaim } from '../service/goods'
import { caculateDistance, priceCast } from '../utils/price';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core"
import { useNavigationBar, toast, openLocation } from "../utils/bridge";
import { RadiusButton } from "./orderList"
import LocationOn from '@material-ui/icons/Room';
import Cookies from 'js-cookie';
import { StatusBoxPro } from "../components/empty"
import './receiveOrder.css'

const pushToMap = (latitude, longitude, name) => {
    // 调用地图导航 
    openLocation(latitude, longitude, name)
}

const ReceivedOrder = ({ match }) => {
    const { id } = match.params
    const OrderUi = useCallback((data) => <CouponDetail data={data} id={id} />, [id])
    return <StatusBoxPro request={orderDetail(id)} component={OrderUi} />
}

// 用户收到的订单
const CouponDetail = ({ id, data }) => {

    const [reclaimEnable, setReclaimEnable] = useState(true)
    const [showReclaim, setShowReclaim] = useState(false)

    useNavigationBar({ title: "礼券详情" })
    const { data: reclaimPrice } = useRequest(() => getReclaimPrice(id), {
        formatResult: data => priceCast(data)
    })
    const reclaim = async () => {
        const resp = await postReclaim(id)
        if (resp.status === 200) {
            setReclaimEnable(false)
            toast("操作成功")
        } else {
            toast('操作失败:' + resp.data?.msg)
        }
    }

    console.log(data);
    const { store, orderItems, code, state } = data
    const url = `https://home.qihuaitech.com:666/qr/${code}`


    return <div className="detail-page">
        <div style={{ padding: 12 }}>
            <div className="coupon-head">
                {
                    orderItems.map(item => <OrderItem key={item.id} item={item} />)
                }
            </div>
            <div className="coupon-ticket">
                <div className="center-column cd-code">

                    <QRCode value={url} />
                    <h2>{code}</h2>
                    <Box fontSize={12} className="summary">
                        一张可用 | 购买日期 2022年4月8日
                    </Box>
                    <Box fontSize={12} marginTop={1} >
                        请将二维码出示给商家及门店扫码兑换
                    </Box>
                    <Box height={2} marginTop={2} />
                    {
                        state === 1 && <RadiusButton
                            title="回收礼券"
                            display={reclaimEnable}
                            variant='contained'
                            onClick={() => setShowReclaim(true)}
                        />
                    }
                </div>
            </div>
        </div>
        <div className="detail-card">
            <div className="row">
                <img
                    src={store.storeLogo}
                    width={26}
                    height={26}
                    fit='cover'
                    style={{ borderRadius: 20 }}
                />
                <div className="space" />
                <div className="item-h">{store.storeName}</div>
                <div style={{ flex: 1 }} />
                <div className="row" style={{ fontSize: 14, color: '#888' }}
                    onClick={pushToMap}>
                    <LocationOn />
                    <div className="space-s" />
                    导航
                </div>
            </div>
            <div className="space" />
            <div className="item-p">{store.storeAddress}</div>
            <div className="space-s" />
            <div className="item-h2">{store.intro}</div>
            <div className="space-s" />
            <div className="item-h2">电话 {store.storePhone}</div>
        </div>

        <ShopList store={store} />
        <Dialog open={showReclaim} onClose={() => setShowReclaim(false)}  >
            <DialogTitle>确认回收</DialogTitle>
            <DialogContent>
                <div className="item-h1">
                    当前礼券的回收价 ¥{reclaimPrice}
                </div>
                <div className="summary">请添加客服人员微信wly871022，以继续操作</div>
                {/* <div className="summary">费用将直接转到您当前的微信账号上。</div> */}
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setShowReclaim(false)} color="primary">
                    取消
                </Button>
                <Button onClick={reclaim} color="primary" autoFocus>
                    确定
                </Button>
            </DialogActions>
        </Dialog>
        <div className="space" />
    </div>
}

const OrderItem = ({ item }) => {
    return <div className="row">
        <img className="cd-item-thumb" src={item.thumb} alt="product image" />
        <div>
            <div className="item-h">{item.name}</div>
            <div>数量 x{item.amount}</div>
        </div>
    </div>
}

const ShopList = ({ store }) => {
    const { data, loading, error } = useRequest(getOutletList(store.id), {
        formatResult: resp => resp.data.data
    })
    if (loading) {
        return <div />
    } else if (error) {
        return <div />
    }
    console.debug(data);
    return <div className="detail-card">
        <div className="row">
            查看附近门店
        </div>
        {
            data?.map(item => <OutletItem key={item.id} item={item} />)
        }
    </div>
}

export const OutletItem = ({ item, location }) => {
    let token = useMemo(() => Cookies.get('location'), [])
    let distance = null
    if (token) {
        let lon = token.split(",")[0]
        let lat = token.split(",")[1]
        distance = caculateDistance(lat, lon, item.latitude, item.longitude).toFixed(1)
    }
    const pushToMap = () => {
        // todo 调用地图导航 
        openLocation(item.latitude, item.longitude, item.storeName)
    }
    return <div className="row p8" color='#777' >
        <img style={{ width: 40, height: 40, marginTop: 8, borderRadius: 8 }} src={item.storeLogo}></img>
        <div className="column p8 expanded" >
            <div className="row" >
                <div className="item-h2">{item.storeName}</div>
                <Box fontSize={12} color='#444' marginLeft={0.5}>| 营业时间：{item.openingHours}</Box>
                <div className="expanded" />
                <div className="row" style={{ fontSize: 14, color: '#888' }}
                    onClick={pushToMap} >
                    <LocationOn />
                    <div className="space-s" />
                    {
                        distance && <div fontSize={15} fontWeight='400'>{distance}公里</div>
                    }
                </div>
            </div>
            <div fontWeight='300'>
                {item.storePhone}
            </div>

            <Box fontSize={12} >{item.storeAddress}</Box>
        </div>
    </div>
}

export default ReceivedOrder