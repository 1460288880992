import { AppBar, Box, Button, IconButton, Toolbar, Typography, makeStyles, ButtonBase, Paper } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import { connect } from "react-redux";
import * as AddressActions from '../models/address'
import { Empty } from "../components/empty";
import { useMemo, useState } from "react";
import AddressEdit from "./addressEdit";

const useStyle = makeStyles(theme => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
        textAlign: 'center'
    },
    addressItem: {
        background: 'white',
        borderRadius: 6,
        display: 'flex',
        flexDirection: 'row',
        padding: theme.spacing(2),
        marginTop: theme.spacing(2),
        marginLeft: 12,
        marginRight: 12,
        textAlign: 'left',
        width: 'calc(100% - 24px)',
    },
}));

const AddressChoosePage = ({ allAddress, onChooseAddress, handleCloseAddress }) => {
    const classes = useStyle()
    const onCheck = (item) => {
        onChooseAddress(item)
    }
    const [showEdit, setShowEdit] = useState()
    const onAddEvent = () => {
        setShowEdit(true)
    }
    const onCreateAddress = () => {
        setShowEdit(false)
    }
    const addressBox = useMemo(() => {
        if (!allAddress || allAddress.length === 0) {
            return <Empty title="没有收件地址，快去添加吧" />
        } else {
            return allAddress.map(item => <AddressItem
                checked={false}
                key={item.id}
                item={item}
                onCheck={onCheck}
            />)
        }
    }, [allAddress])

    return (<Box >
            <Toolbar>
                <IconButton edge="start" color="inherit" onClick={handleCloseAddress} aria-label="close">
                    <CloseIcon />
                </IconButton>
                <Typography variant="h6" className={classes.title}> 我的收货地址 </Typography>
                <Button autoFocus color="inherit" onClick={handleCloseAddress}></Button>
        </Toolbar>
        {addressBox}
        <Box p={2} marginTop={3}>
            <Button fullWidth color='primary' variant='contained' onClick={onAddEvent}>
                添加收货地址
            </Button>
        </Box>
        <AddressEdit show={showEdit} onClose={onCreateAddress} />
    </Box>)
}

const AddressItem = ({ item, checked, onCheck, ...props }) => {
    const classes = useStyle()
    const {
        address,
        area,
        city,
        province,
        name,
        phone,
    } = item
    return (
        <ButtonBase className={classes.addressItem} onClick={() => onCheck(item)}>
            <Box flex={1} p={1}>
                <Box fontSize={12} color='#999'>
                    {province} {city} {area}
                </Box>
                <Box fontSize={18} color='#232323' lineHeight='1.5rem' my={1}>
                    {address}
                </Box>
                <Box fontSize={12} color='#999'>
                    {name} {phone}
                </Box>
            </Box>
        </ButtonBase>)
}

const propsMap = ({ address }) => address

export default connect(propsMap, AddressActions)(AddressChoosePage)