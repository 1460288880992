import { useEffect, useState } from "react";
import { Avatar, Box, makeStyles, Typography } from '@material-ui/core'
import { setNavigationBar } from "../../utils/bridge";

const useStyle = makeStyles({
    centerAlign: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        fontSize: 13,
    },
    itemHeader: {
        display: 'flex',
        alignItems: 'center',
        fontSize: 14,
    }
})


/**
 * 资金管理
 * @param {*} props 
 */
export default function FundPage(props) {
    const classes = useStyle()
    const [dealList, setDealList] = useState([])
    useEffect(() => {
        setNavigationBar({ title: "资金管理" })
        setDealList([0, 0, 1, 1, 2, 3, 3])
    }, [])

    return <div style={{ padding: 8, height: '100%', overflow: 'scroll' }}>
        <Box borderRadius={8} bgcolor='white' height={120} display='flex'
            justifyContent='space-evenly' alignItems='center'>
            <div className={classes.centerAlign}>
                <div style={{ fontSize: "22px" }}> 1100 </div>
                总收入
            </div>
            <div className={classes.centerAlign}>
                <div style={{ fontSize: "22px" }}> 340 </div>
                待收金
            </div>
        </Box>
        <Box m={1} >查看明细</Box>
        {
            dealList.map(item => {
                return <Box borderRadius={8} bgcolor='white' marginBottom={1} p={1}>
                    <div className={classes.itemHeader}>
                        <Avatar style={{ marginRight: 8 }} />
                        用户名称
                        <Box flex={1} />
                        {
                            item === 0 ?
                                <Typography color='secondary'>待完成</Typography> :
                                <Typography color='primary'>完成</Typography>
                        }
                    </div>
                    <Box marginTop={1} display='flex'>
                        <Avatar style={{ width: 80, height: 80, marginRight: 8 }} variant='rounded' />
                        <Box flex={1}>
                            小学数据课本1节课时
                            <div>x1</div>
                        </Box>
                        <Box display='flex' alignItems='flex-end' flexDirection='column' fontSize="13px" color='#888'>
                            <div>¥100</div>
                            <Box flex={1} />
                            <div>
                                实付款 <span className='price'>100</span>
                            </div>
                        </Box>
                    </Box>
                </Box>
            })
        }
    </div>
}