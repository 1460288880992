import { Box } from "@material-ui/core"
import { useMemo } from 'react'

import { DynamicItem, ArticleItem, VideoItem, isVideo } from './moment/base';
import { StatusBoxPro } from "../components/empty";
import { shareDynamicList } from "../service/goods";

// 景区打卡动态 
const RecommendPage = ({ storeId }) => {
    const List = useMemo(() => list => {
        return <Box flex={1} height="100%" overflow='scroll' display='flex' flexDirection='column' p={2}>
            {
                list.data.map(item => {
                    const isVideoItem = isVideo(item.file)
                    if (isVideoItem) {
                        return <VideoItem key={item.id} item={item} />
                    }
                    if (item.html) {
                        return <ArticleItem key={item.id} item={item} />
                    }
                    if (item.file) {
                        return <DynamicItem key={item.id} item={item} />
                    }
                    return null
                })
            }
        </Box>
    })

    return <Box display='flex' height={1} flexDirection='column'>
        <StatusBoxPro
            request={shareDynamicList({ type: 7, subType: storeId })}
            component={List}
        />
    </Box>
}


export default RecommendPage