import React, { useState, useEffect } from "react"
import { setNavigationBar } from 'utils/bridge'
import {Avatar, Button, Box, makeStyles, LinearProgress} from '@material-ui/core'
import {useRequest} from "ahooks";
import {getMyTimeLine} from "../../service/goods";
import moment from "moment";

const useStyle = makeStyles(theme => ({
    root: {
        padding: 8,
        color: '#888',
        backgroundColor: 'white',
        height: '100%',
    },
    date: {
        backgroundColor: theme.palette.primary.main,
        borderRadius: 2,
        color: 'white',
        margin: '2px 6px',
        padding: '4px',
    },
    tag: {
        color: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
        borderStyle: 'solid',
        borderWidth: '1px',
        fontSize: '12px',
        borderRadius: '16px',
        padding: '4px 8px',
        marginTop: '8px',
        width: 'fit-content',
    }
}))

const Space = ({ width }) => {
    return <Box component='span' width={width} height={1} display='inline-block' />
}

export const TodoPage = (props) => {
    const classes = useStyle()
    const [now, setNow] = useState(new Date())

    useEffect(() => {
        setNavigationBar({ title: '今日代办' })
        let id = setInterval(() => {
            setNow(new Date())
        }, 9000);
        return () => clearInterval(id)
    }, [])

    const {data,loading,error} = useRequest(getMyTimeLine(true,0),{
        formatResult:res => {
            return res.data
        } ,
    })
    if (loading ){
        return <LinearProgress/>
    }
    if (error){
        return <LinearProgress/>
    }

    return <div className={classes.root}>
        <div>
            当前时间
            <Space width={19} />
            <span className={classes.date}>{now.getMonth() + 1}</span>月
            <span className={classes.date}>{now.getDate()}</span>
            <Space width={19} />
            <span className={classes.date}>{now.getHours()}</span>:
            <span className={classes.date}>{now.getMinutes()}</span>
        </div>

        {
            data.map(item => <TodoItem key={item} {...item} />)
        }
        {/*<div>
            <TodoItem fromUser={false} name={'打游戏（王者）'} />
            <TodoItem fromUser={true} name={'三亚7日游'} />
            <TodoItem fromUser={false} name={'线下约玩（次级）'} />
        </div>*/}
    </div>
}

const TodoItem = ({ status, name, fromUser ,id,udId,serviceId,route,title, multiView ,createTime, beginTime, endTime, type, remarks, custom, detail}) => {

    const img = multiView?.split(',')[0]

    const classes = useStyle()
    return <Box display='flex' padding={1} marginTop={3}>
        <Box display='flex' flexDirection='column' alignItems='center' width='90px'>
            <Box>{moment(beginTime).format("MM-D hh:mm")}</Box>
            <Box m={1} bgcolor='#aaa' width='1px' height={30}></Box>
            <Box>{moment(endTime).format("MM-D hh:mm")}</Box>
            <Box bgcolor='primary.main' color='white'
                marginTop='10px'
                padding='6px 15px' textAlign='center' borderRadius="20px" >
                {getState(beginTime,endTime)}
            </Box>
        </Box>
        <Box>
            <Avatar variant='rounded' style={{ width: 140, height: 140 }} src={img}/>
        </Box>
        <Box m={1} color='#444' display='flex' flexDirection='column' flex={1}>
            {name}
            {
                custom ?
                    <Box className={classes.tag}>自建</Box> : null
            }
            <Box flex={1} />
            <Button color='primary' style={{ alignSelf: 'flex-end' }}>去标记 </Button>
        </Box>

    </Box>
}

function getState(beginTime,endTime) {
    const now =  moment();
    if ( beginTime < now && now < endTime){
        return '进行中';
    }
    if ( beginTime > now){
        return '未开始';
    }
    if ( endTime < now){
        return '已完成';
    }
}