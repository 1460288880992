import React, { useState, useEffect } from "react"
import { setNavigationBar } from 'utils/bridge'
import {Avatar, Box, makeStyles, Checkbox, Button, LinearProgress} from '@material-ui/core'
import moment from 'moment'
import { ImageButton } from "../../components/base";
import { SectionRow } from "../../components/section";
import {getMyTimeLine, shareDynamicList} from "../../service/goods";
import {useRequest} from "ahooks";
import {useHistory} from "react-router-dom";


moment.locale('zh-cn');

const useStyle = makeStyles(theme => ({
    root: {
        padding: 12,
        color: '#888',
        backgroundColor: 'white',
        height: '100%',
    },
    date: {
        backgroundColor: theme.palette.primary.main,
        borderRadius: 2,
        color: 'white',
        margin: '2px 6px',
        padding: '4px',
    },
    tag: {
        color: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
        borderStyle: 'solid',
        borderWidth: '1px',
        fontSize: '12px',
        borderRadius: '16px',
        padding: '4px 8px',
        marginTop: '8px',
        width: 'fit-content',
    },
    badge: {
        color: 'white',
        backgroundColor: '#F43737',
        borderRadius: '20px',
        fontSize: '14px',
        float: 'right',
        padding: '1px 5px',
    },

}))

const Space = ({ width }) => {
    return <Box component='span' width={width} height={1} display='inline-block' />
}

export const TimeLinePage = (props) => {
    const classes = useStyle()
    const [chooseMode, setChooseMode] = useState(false)
    const history = useHistory()

    useEffect(() => {
        setNavigationBar({ title: '时间轴' })

    }, [])


    const {data,loading,error} = useRequest(getMyTimeLine(false,0),{
        formatResult:res => {
            return res.data
        } ,
    })
    if (loading ){
        return <LinearProgress/>
    }
    if (error){
        return <LinearProgress/>
    }

    return <div className={classes.root}>
        <Box >
            <SectionRow title="今日代办" onClick={() => history.push('/me/todo')} />
            {/*临时关闭入口<Box color='#333' display='flex' alignItems='center'>*/}
            <Box color='#333' display='none' alignItems='center'>
                时间轴列表
                <Box flex={1} />
                <ImageButton
                    name="事件标记"
                    icon={window.ossUrl+'/app/ic_biaoji%402x.png'} />

                <ImageButton
                    name="编辑手抄"
                    onClick={() => setChooseMode(!chooseMode)}
                    icon={window.ossUrl+'/app/ic_shouchao%402x.png'} />
            </Box>
        </Box>
        {
            data.map(item => <TodoItem key={item} {...item} />)
        }
        {/*<div>
            <TodoItem chooseMode={chooseMode} fromUser={false} name={'打游戏（王者）'} timestamp={1629837470236} />
            <TodoItem chooseMode={chooseMode} fromUser={true} name={'三亚7日游'} timestamp={1628837470236} />
            <TodoItem chooseMode={chooseMode} fromUser={false} name={'线下约玩（次级）'} timestamp={1623837470236} />
            <TodoItem chooseMode={chooseMode} fromUser={false} name={'线下约玩（次级）'} timestamp={1623837470236} />
            <TodoItem chooseMode={chooseMode} fromUser={false} name={'线下约玩（次级）'} timestamp={1623837470236} />
        </div>*/}
        {
            chooseMode ?
                <Box bgcolor='white' position='fixed'
                    display='flex'
                    alignItems='center'
                    boxShadow='1px 1px 15px #aaa'
                    left='0' right='0' bottom='0' padding={1} >
                    <Box color='#444'>
                        创建乐享时间轴
                    </Box>
                    <Box flex='1' />
                    <Button variant='contained' color='primary'>完成</Button>
                </Box> : null
        }
    </div>
}

const TodoItem = ({ timestamp, status, name, fromUser, chooseMode, checked, handleChange,
                      id,udId,serviceId,route,title, multiView ,createTime, beginTime, endTime, type, remarks, custom, detail}) => {

    const img = multiView?.split(',')[0]
    const classes = useStyle()
    return <Box marginTop={3}>

        <Box color="#444" height='36px'>
            {moment(createTime).format("M月D日")}
        </Box>
        <Box display='flex' padding={1}>
            {
                chooseMode ?
                    <Checkbox
                        checked={checked}
                        onChange={handleChange}
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                    /> : null
            }
            <Box display='flex' flexDirection='column' alignItems='flex-start' width='60px'>
                <Box>{moment(createTime).format("hh:mm")}</Box>
            </Box>
            <Box>
                <Avatar variant='rounded' style={{ width: 140, height: 140 }} src={img}/>
            </Box>
            <Box m={1} color='#444' display='flex' flexDirection='column' flex={1}>
                {title}
                {
                    custom ?
                        <Box className={classes.tag}>自建</Box> : null
                }
                <Box flex={1} />
                <Box bgcolor='primary.main' color='white'
                    marginTop='10px' width='fit-content'
                    padding='6px 15px' textAlign='center' borderRadius="20px" >
                    {getState(beginTime,endTime)}
                </Box>
            </Box>

        </Box>
    </Box>
}

function getState(beginTime,endTime) {
    const now =  moment();
    if ( beginTime < now && now < endTime){
        return '进行中';
    }
    if ( beginTime > now){
        return '未开始';
    }
    if ( endTime < now){
        return '已完成';
    }
}