import React, { useState, useEffect } from 'react'
import { Box, Tabs, Tab, makeStyles, Typography, ListItem, ListItemAvatar, ListItemText, Grid, Divider, Button, Dialog, DialogContent, DialogActions } from '@material-ui/core'
import { orderManageList, orderSoldList, agreeReturnRefund, cancelOrder, listLogisticsFn } from './interface'
import { useAccount } from '../../../utils/hooks'
import { StatusBox } from '../../../components/empty'
import InputTextDialog from "../../../components/inputText"
import { useToast } from "../../../models/toast"
import { useNavigationBar, useAppHistory } from "utils/bridge"
import moment from 'moment'

const useStyles = makeStyles(() => ({
  manageContent: {
    background: '#fff',
    height: '100%',
    '& .MuiTabs-flexContainer': {
      borderBottom: '1px solid #EEEEEE'
    },
    '& .MuiTab-textColorInherit': {
      width: '25%',
      color: '#222222'
    },
    '& .MuiTab-textColorInherit.Mui-selected': {
      color: '#A786E8 !important'
    },
    '& .MuiTabs-indicator': {
      background: '#A786E8',
      maxWidth: '10%',
      margin: '0 7%',
    },
    '& .MuiBox-root': {
      padding: 0
    },
    '& ul': {
      borderBottom: '1px solid #eee'
    },
  }

}))
export default function OrdersManage() {
  const [value, setValue] = useState(0)
  const [list, setList] = useState([])
  const [actionOpen, setActionOpen] = useState(false)
  const [actionParams, setActionParams] = useState({})
  const [open, setOpen] = useState(false)
  const classes = useStyles()
  const history = useAppHistory()
  const { mall, myself } = useAccount()
  const toast = useToast()
  const orderParams = value => (value === 0 ? {} : value === 1 ? { state: 1, shipped: 0 } : value === 2 ? { state: 1, shipped: 1 } : {})

  // 打开取消订单｜ 同意退款的确认弹窗
  const actionHandleOpen = (params) => {
    setActionOpen(true)
    setActionParams(params)

  }

  // 点击确定取消订单｜ 同意退款的确认弹窗
  const actionFn = async () => {
    setActionOpen(false)
    const { data } = (actionParams?.agree === true || actionParams?.agree === false) ? await agreeReturnRefund(actionParams) : await cancelOrder(actionParams)
    if (data) {
      toast.showToast("toast.success", "操作成功")
      // 刷新列表
      orderManageListFn(value, orderParams(value))
    }
  }

  // 打开发物流的确认弹窗
  const actionOpenLogistics = (params) => {
    setOpen(true)
    setActionParams(params)

  }

  // 发物流
  const onSendComment = async (e) => {
    setOpen(false)
    const { data } = await listLogisticsFn({ ...actionParams, trackingNumber: e })
    if (data) {
      toast.showToast("toast.success", "操作成功")
      // 刷新列表
      orderManageListFn(value, orderParams(value))
    }
  }

  /**
   * 
   * @param {*} event 
   * @param {*} newValue 
   * state 交易状态:
      0 未支付,
      1 已支付, 
      2 已退款, 22：申请退款 
      3 支付错误, 
      4 已关闭(超过30分钟未支付),
      5 确认收货,
      6 兑换券已消费完成
    shipped 发货状态: 1 已发货，0 待发货
   */
  const handleChange = (event, newValue) => {
    setValue(newValue)
    setList([])
    orderManageListFn(newValue, orderParams(newValue))
  }


  /**
   * 列表接口
   */
  const orderManageListFn = async (newValue, params) => {
    const { data } = newValue === 3 ? await orderSoldList({ ...params, storeId: mall?.id }) : await orderManageList({ ...params, storeId: mall?.id })
    if (data) {
      setList(data)
    }
  }

  useEffect(() => {
    orderManageListFn(value, orderParams(value))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const TabPanel = (props) => {
    const { children, value, index, ...other } = props

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  const statusText = [
    {
      title: '取消订单',
      onclick: (params) => actionHandleOpen(params),
      status: 0, // state 0 未支付订单可取消
    },
    // {
    //   title: '查看物流',
    //   onclick: '',
    //   status: 1
    // },
    {
      title: '同意退款',
      onclick: (params) => actionHandleOpen({ ...params, agree: true }),
      status: 23 // state  23 等待商家同意
    },
    {
      title: '拒绝意退款',
      onclick: (params) => actionHandleOpen({ ...params, agree: false }),
      status: 23 // state  23 等待商家不同意
    },
    {
      title: '发物流',
      onclick: (params) => actionOpenLogistics(params),
      status: 1 // state 1 已支付 + shipped 0 = 待发货
    }
  ]

  /**
   * 
      0 未支付,
      1 已支付, 
      2 已退款, 22：申请退款 
      3 支付错误, 
      4 已关闭(超过30分钟未支付),
      5 确认收货,
      6 兑换券已消费完成
   */
  const stateType = [

    { key: 1, name: '待收货', shipped: 1 },
    { key: 1, name: '待发货', shipped: 0 },

    { key: 0, name: '未支付' },
    { key: 1, name: '已支付' },
    { key: 3, name: '支付错误' },
    { key: 4, name: '已关闭' },
    { key: 5, name: '确认收货' },
    { key: 6, name: '兑换券已消费完成' },

    { key: 2, name: '已退款' },
    { key: 22, name: '申请退款' },
    { key: 23, name: '等待商家同意' },
    { key: 24, name: '商家不同意退款' },
    { key: 25, name: '等待买家退货' },
    { key: 26, name: '商家待收货' }
  ]

  useNavigationBar({
    title: '订单管理',
  })

  const gotoDetail = (order) => () => {
    if (value === 3) {
      /// 售后详情
      history.push(`/b2c/workbench/refund_detail/${order?.id}`)
    } else {
      history.push(`/order/${order?.id}`)
    }
  }
  const TabPanelContent = (list) => (
    <StatusBox empty={list}>
      {list?.map(el => (
        <Box style={{ background: '#fff' }}>
          <Divider orientation='horizontal' variant="small" flexItem style={{ height: 10, background: '#eee' }} />
          <Box style={{ margin: '0 16px' }}>
            <Box style={{ fontSize: '13px', color: '#333' }} mt={2} mb={1}>
              <text>{moment(el?.date).format("YYYY-MM-DD")}</text>
              <text style={{ float: 'right', color: '#A786E8' }}>{stateType?.find(i => ((i?.shipped === 0 || i?.shipped === 1) ? (i?.key === el?.state) && (i?.shipped === el?.shipped) : i?.key === el?.state))?.name}</text>
            </Box>
            {el?.orderItems?.map(item => (
              <React.Fragment>
                <ListItem alignItems="flex-start" style={{ background: '#f8f8f8', marginBottom: '10px' }} onClick={gotoDetail(el)}>
                  <ListItemAvatar>
                    <img width={65} height={65} style={{ borderRadius: '3px', marginRight: '10px' }} alt="Remy Sharp" src={item?.thumb} />
                  </ListItemAvatar>
                  <ListItemText
                    primary={

                      <Grid container style={{ marginTop: '10px' }}>
                        <Grid xs={9}>
                          <Box
                            p={2}
                            overflow='hidden'
                            height={37}
                            style={{ fontSize: '14px', lineHeight: '18px', display: '-webkit-box', WebkitLineClamp: 2, lineClamp: 2, WebkitBoxOrient: 'vertical', overflow: 'hidden', padding: 0 }}
                          >
                            {item?.name}
                          </Box>
                        </Grid>
                        <Grid xs={3} style={{ textAlign: 'right' }}>
                          <text style={{ fontSize: '12px', color: '#999', lineHeight: '17px', display: 'block', marginTop: 0 }}>¥ {item?.price / 100}</text>
                          <text style={{ fontSize: '12px', color: '#999', lineHeight: '17px', display: 'block' }}>x {item?.amount}</text>
                        </Grid>
                      </Grid>
                    }
                  />
                </ListItem>
                <Box style={{ fontSize: '14px', textAlign: 'right' }}>
                  <Box>
                    <text style={{ marginRight: '10px' }}>共 {item?.amount} 件</text>
                    <text>订单总金额:  <text style={{ color: '#FF1717' }}>¥ {el?.totalPrice / 100}</text></text>
                  </Box>
                  <Divider orientation='horizontal' variant="small" flexItem style={{ height: 1, margin: '10px 0px -1px 0px', background: '#eee' }} />
                  {statusText?.map(i => {
                    return (i?.status === 1 ? (i?.status === el?.state && el?.shipped === 0) : i?.status === el?.state) && <Button onClick={() => i?.onclick({ orderId: el?.id })} style={{ color: '#595959', fontSize: '12px', border: '1px solid #999', borderRadius: '100px', padding: '3px 12px', margin: '11px 0 10px 10px' }}>{i?.title} </Button>
                  })}
                </Box>
              </React.Fragment>
            ))}
          </Box>
        </Box>
      ))}
      <Divider orientation='horizontal' variant="small" flexItem style={{ height: 10, background: '#eee' }} />
    </StatusBox>
  )
  return (
    <Box className={classes.manageContent}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider', position: 'sticky', top: '0px', zIndex: 99, background: '#fff', boxShadow: '#f3f3f3 0px 2px 8px' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" variant="fullWidth">
          <Tab label="全部" />
          <Tab label="待发货" />
          <Tab label="待收货" />
          <Tab label="售后" />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        {TabPanelContent(list)}
      </TabPanel>
      <TabPanel value={value} index={1}>
        {TabPanelContent(list)}
      </TabPanel>
      <TabPanel value={value} index={2}>
        {TabPanelContent(list)}
      </TabPanel>
      <TabPanel value={value} index={3}>
        {TabPanelContent(list)}
      </TabPanel>
      <Dialog
        open={actionOpen}
        onClose={() => setActionOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={classes.dialog}
      >
        <Box
          style={{
            padding: '20px',
            borderRadius: '10px !important',
            textAlign: 'center'
          }}
        >
          <DialogContent style={{ paddingTop: 0 }}>
            确定要 {(actionParams?.agree === true) ? '同意退款' : (actionParams?.agree === false) ? '拒绝退款' : '取消该订单'}？
          </DialogContent>
          <DialogActions style={{ justifyContent: 'center', height: '28px' }}>
            <Button onClick={() => setActionOpen(false)} style={{ color: '#595959', fontSize: '14px' }}>取消</Button>
            <Divider orientation="vertical" variant="small" flexItem />
            <Button style={{ color: '#A786E8', fontSize: '14px' }} onClick={() => actionFn()}>
              确定
          </Button>
          </DialogActions>
        </Box>
      </Dialog>

      <InputTextDialog
        open={open}
        title="设置快递单号"
        multiLine={false}
        onCancel={() => setOpen(false)}
        onFinish={onSendComment}
      />
    </Box>
  )
}