import {Button, Box, ButtonBase, makeStyles, useTheme, LinearProgress} from "@material-ui/core"
import { useEffect, useState } from "react"
import { setNavigationBar, useNavigationBar } from "utils/bridge"

import MyCouponPage from '../myCoupon'
import { CouponsIcon, TicketIcon } from 'components/icons'
import {getOrders} from "../../service/goods";
import {useRequest} from "ahooks";
import moment from "moment";

const useStyles = makeStyles(theme => ({
    root: {

    },
    tabs: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-evenly',
        padding: theme.spacing(3),
        backgroundColor: 'white',
    },
    ticketItem: {
        display: 'flex',
        padding: 8,

        '&> img': {
            width: 60,
            height: 60,
            borderRadius: 4
        }
    },

}))

const tabSyles = makeStyles({
    tab: (props) => ({
        display: 'inline-flex',
        alignItems: 'center',
        flexDirection: 'column',
        fontSize: 18,
        padding: 8,
        color: props.color,
        borderRadius: 10,
    }),
})

const CheckButton = ({ checked, onChecked, children }) => {
    const theme = useTheme()
    const classes = tabSyles({ color: checked ? theme.palette.primary.main : '#8a8a8a' })
    return <ButtonBase onClick={onChecked} className={classes.tab}>
        {children}
    </ButtonBase>
}

export const CardPage = () => {
    const classes = useStyles()
    useNavigationBar({ title: '礼品券' })

    return <Box>
        <TicketList />

    </Box>
}

const TicketList = () => {
    const classes = useStyles()
    useEffect(() => {
        console.log('load tickets');
    }, [])

    const { data, loading, error } = useRequest(getOrders({}, 0, null), {
        formatResult:res => {
            return res.data.filter(i => i.code)
        } ,
    })
    if (loading ){
        return <LinearProgress/>
    }
    if (error){
        return <LinearProgress/>
    }

    return <Box>
        {
            data.map(item => <Box key={item} className={classes.ticketItem} >
                <img src={item.orderItems?.[0]?.thumb} />
                {/*<img src={window.ossUrl+/file/1949iShot2021-09-04 17.32.50.png} />*/}
                <Box p={1} flex={1}>
                    <b>{item.orderItems?.[0]?.name} {item.orderItems?.[0]?.model} </b>
                    <div>{moment(item.date).format("YYYY-MM-DD HH:mm:ss")}</div>
                </Box>
                <div>
                    <Button variant='contained' color='primary'
                        style={{ borderRadius: '20px' }}>
                        兑换
                    </Button>
                </div>
            </Box>)
        }
    </Box>
}

const CouponList = () => {
    useEffect(() => {
        console.log('load coupons');
    }, [])
    return <MyCouponPage />
}