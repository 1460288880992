import { ButtonBase, makeStyles } from "@material-ui/core"
import { useAppHistory, useNavigationBar } from "../utils/bridge"
import Box from '@material-ui/core/Box'
import Avatar from '@material-ui/core/Avatar'
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { useAccount } from "../utils/hooks"

const useStyles = makeStyles((theme) => ({
    card: {
        margin: theme.spacing(2),
        background: 'white',
        padding: '12px',
        borderRadius: '4px',
    }
}))

const SingleItem = ({ title, onClick }) => {
    const itemStyle = {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        background: 'white',
        borderRadius: '4px',
        padding: '16px',
    }
    return <Box m={2}>
        <ButtonBase style={itemStyle} onClick={onClick}>
            <Box component="span" className='item-title'>{title}</Box>
            <Box flex={1} />
            <ChevronRightIcon color='disabled' />
        </ButtonBase>
    </Box>
}
const SettingsPage = () => {

    const classes = useStyles()

    useNavigationBar({
        bgColor: '#FFFFFF',
        title: '设置',
        darkBar: false,
    })
    const { myself } = useAccount()

    const history = useAppHistory()
    return <Box paddingTop={1}>
        <Box className={classes.card} display='flex' flexDirection='row' m={12} >
            <Avatar style={{ width: '70px', height: '70px' }} src={myself?.firstImg}></Avatar>
            <Box p={2} fontSize={13} display='flex' flexDirection='column' >
                <Box fontWeight='600' fontSize={18}>{myself?.nickname}</Box>
                <Box mt={1}>信用证：{myself?.id}</Box>
            </Box>
        </Box>
        <SingleItem title="收货地址" onClick={() => history.push('/address_list')} />
        <SingleItem title="账户与安全" />
        <SingleItem title="支付" />
        <SingleItem title="消息通知" />
    </Box>
}

export default SettingsPage