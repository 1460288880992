import { Avatar, Box, Button, makeStyles, Radio, Divider, ButtonBase } from "@material-ui/core";
import qs from 'qs'
import { AliPayIcon, WePayIcon, CheckedIcon } from 'components/icons'
import React, { useEffect, useState } from "react";
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import StorefrontIcon from '@material-ui/icons/Storefront';
import { callPay, useNavigationBar } from "../utils/bridge";
import { getStore, updateOrder, updateOrderSingle, submitOrder, submitOrderSingle, getStoreOutlet } from "../service/goods";
import { useRequest } from "ahooks";
import { ButtonBox, NumberInput } from "../components/base";
import Delivery0 from 'img/delivery0.svg';
import Delivery1 from 'img/delivery1.svg';
import OutletList, { OutletItem } from './outletList';
import AddressChoosePage from "./addressChoose";
import { useAddress } from "../utils/hooks";
import { isInAndroidContainer, isInIOSContainer } from "../utils/webkit";
import { NavBar, Popup, Toast } from 'antd-mobile'
import AddressEdit from "./addressEdit";
import { CouponItem } from "./myCoupon";

const radius = 10

const useStyle = makeStyles(theme => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    bottomPanel: {
        display: 'flex',
        position: 'fixed',
        zIndex: 99,
        bottom: 0,
        background: 'white',
        width: '100%',
        justifyContent: 'flex-end',
        alignItems: 'center',
        padding: '8px 18px',
        // maxWidth: config.maxWidth,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
        position: 'absolute',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
    card: {
        margin: 8,
        padding: 14,
        backgroundColor: 'white',
        borderRadius: radius,
    },
    uncheckButton: {
        borderRadius: 6,
        height: 60,
        flex: 1,
        border: '2px solid #888',
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    checkedButton: {
        borderRadius: 6,
        height: 60,
        flex: 1,
        border: '2px solid #D22820',
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    checkIcon: {
        position: 'absolute',
        top: -10,
        right: -10,
        color: '#D22820',
        backgroundColor: 'white',
        radius: 10,
    },
    h4: {
        fontSize: 14,
        fontWeight: '500',
        color: '#222'
    },
    summary: {
        fontSize: 12,
        color: '#888'
    },
    deliveryIcon: {
        width: 40,
        height: 40,
        marginRight: 10,
    },
    couponBox: {
        width: '100%',
        padding: '8px 0',
        display: 'inline-flex',
        justifyContent: 'space-between',
    },
    couponPage: {
        overflow: 'scroll',
        padding: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        backgroundColor: '#eee',
        height: '100%',
    },
}));
const OrderState = Object.freeze({
    inital: 0,
    prepare: 1,  // 进入下单页面
    caculate: 2, // 调用结算接口后
    submit: 3,   // 提交订单，等付款
    finish: 4,   // 付款成功
})
const PriceTextItem = ({ title, price }) => {
    return <Box my={2}>
        <Box component='span' fontSize={13}>{title}</Box>
        <Box component='span' style={{ float: 'right' }} color='red'>{price}</Box>
    </Box>
}

const priceCast = (price) => price ? (price / 100).toFixed(2) : 0


const GoodsItem = ({ item, amount, setAmount }) => {
    if (!item) {
        return <div />;
    }
    const variant = item.productVariants
    return <Box display='flex' flexDirection='row' alignItems='center' py={1}>
        <Avatar variant='rounded' src={variant.thumb} style={{ width: 70, height: 70 }} />
        <Box fontSize={12} color='#888' flex='1' ml={1} overflow='hidden'>
            <Box fontSize={14} color='black' fontWeight="fontWeightMedium"
                overflow='hidden'
                textOverflow="ellipsis"
                whiteSpace='nowrap'>{item.name}</Box>
            <Box mt={1}>{item.color} {variant.model} </Box>

            <Box display='flex'>
                <Box fontWeight='bold' color='#D22820' fontSize={14}> ¥{priceCast(variant.price)}</Box>
                <Box flex={1} />
                {
                    setAmount ? <NumberInput onChange={setAmount} /> : <Box> x {amount}</Box>
                }

            </Box>
        </Box>
    </Box>
}


const OrderItem = ({ item }) => {
    return <Box display='flex' flexDirection='row' alignItems='center' py={1}>
        <Avatar variant='rounded' src={item.thumb} style={{ width: 80, height: 80 }} />
        <Box fontSize={12} color='#888' flex='1' ml={1} overflow='hidden'>
            <Box fontSize={14} color='black' fontWeight="fontWeightMedium"
                overflow='hidden'
                textOverflow="ellipsis"
                whiteSpace='nowrap'>{item.name}</Box>
            <Box mt={1}>{item.color} {item.model} <Box component='span' >x{item.amount}</Box></Box>

            <Box fontWeight='bold' color='red' fontSize={14}> ¥{priceCast(item.price)}</Box>
        </Box>
    </Box>
}

export const OutletChip = ({ storeInfo }) => {
    const classes = useStyle()
    // 门店列表
    const [showOutlets, setShowOutlets] = React.useState(false)
    /// 门店弹窗处理
    const handleCloseOutlets = () => {
        setShowOutlets(false)
    }
    const storeId = storeInfo?.id
    // 提货点列表信息(门店)
    const { data: outlets } = useRequest(() => storeId ? getStoreOutlet(storeId) : null, {
        formatResult: res => res.data.data,
        refreshDeps: [storeId]
    })

    if (!storeInfo) {
        return <div />
    }

    return <Box borderRadius={8}
        style={{ marginTop: 10, padding: '0 10px', backgroundColor: '#efefef' }}>
        <Box mx={1} paddingTop={1} display='flex' alignItems='center'>
            <Box fontSize={14} color='#111'>提货点</Box>
            <Box flex={1}></Box>
            <Box hidden={outlets?.length === 0} color='#666' fontSize={13}>
                <Button onClick={() => setShowOutlets(true)}>查看更多门店</Button>
            </Box>
        </Box>
        {
            outlets?.length > 0 ? <OutletItem item={outlets?.[0]} /> : <Box p={1}>
                <Box fontSize={14}>联系电话:{storeInfo.storePhone}</Box>
                <Box fontSize={12} >{storeInfo.storeAddress}</Box>
            </Box>
        }
        <Popup
            visible={showOutlets} onMaskClick={handleCloseOutlets}
            bodyStyle={{ height: '80vh', backgroundColor: '#eee' }} >
            <OutletList storeId={storeId} handleCloseAddress={handleCloseOutlets} />
        </Popup>
    </Box>
}

const SubmitOrder = ({ location }) => {
    useNavigationBar({ title: '订单确认' })
    const { type, carts, variant, amount: num, store } = qs.parse(location.search, { ignoreQueryPrefix: true })
    console.log(type, carts, variant);
    const isCart = type === 'cart'

    const userAddress = useAddress()
    const classes = useStyle()
    const [amount, setAmount] = useState(Number(num))
    // 配送方式 1 快递 ，2 自提
    const [deliveryChannel, setDeliveryChannel] = useState(2)
    // 支付方式 0: 微信，1支付宝
    const [payChannel, setPayChannel] = useState(0)
    // 订单状态
    const [orderState, setOrderState] = useState(OrderState.prepare)
    // 选择收货地址
    const [address, setAddress] = useState(userAddress.defaultAddress)
    const [openAddress, setOpenAddress] = useState(false)
    const [addAddress, setAddAddress] = useState(false)
    // 选择优惠券
    const [openCoupons, setOpenCoupons] = useState(false)
    const [couponsId, setCouponsId] = useState(null)
    const [order, setOrder] = useState()

    // 店铺信息
    const { data: storeInfo } = useRequest(() => getStore(store), {
        formatResult: res => res.data,
        refreshDeps: [store]
    })


    useEffect(() => {
        // 刷新数据
        async function loadOrder() {
            if (isCart) {
                /// 购物车商品
                const ids = carts.split(',').map(e => ({ cartItemId: Number(e) }))
                const res = await updateOrder(ids, deliveryChannel)
                res.data.products.forEach(p => {
                    var cart = res.data.cartItemList
                        .filter(i => i.productVariantId === p.productVariants.id)?.[0]
                    console.log('find product,', p.productVariants.id, cart.amount);
                    p.amount = cart?.amount ?? 1
                })
                setOrder(res.data)
            } else {
                const res = await updateOrderSingle({
                    amount, productVariantId: variant, buyerId: couponsId,
                    shippingMethod: deliveryChannel
                })
                setOrder(res.data)
                console.log(res.data);
            }
        }
        loadOrder()
    }, [type, deliveryChannel, amount, couponsId])


    /// 地址弹窗处理
    const handleClickOpenAddress = () => {
        setOpenAddress(true);
        // props.getAllAddress()
    };
    const handleCloseAddress = () => {
        setOpenAddress(false);
    };
    const onAddAddress = () => {
        setAddAddress(false)
    }
    const onChooseAddress = (address) => {
        setAddress(address)
        setOpenAddress(false);
    }
    const onChooseCoupon = (coupon) => {
        setOpenCoupons(false)
        setCouponsId(coupon.id)
    }

    const submit = async (e) => {
        e.stopPropagation()
        e.nativeEvent.stopImmediatePropagation()
        if (!address?.id && deliveryChannel == 1) {
            Toast.show({
                content: '请选择地址',
            })
            return
        }
        let orderForm;
        if (type === 'cart') {
            /// 购物车结算
            const cartItems = order.cartItemList.map(it => ({ id: it.id, amount: it.amount }))
            const resp = await submitOrder({
                cartItemList: cartItems,
                receiveAddressId: address?.id,
                usedDiscountList: [],
                payChannel,
                shippingMethod: deliveryChannel
            })
            orderForm = resp.data
        } else {
            const resp = await submitOrderSingle({
                amount,
                productVariantId: variant,
                receiveAddressId: address?.id,
                buyerId: couponsId,
                shippingMethod: deliveryChannel,
                payChannel
            })
            orderForm = resp.data
        }
        if (isInIOSContainer || isInAndroidContainer) {
            callPay({
                id: orderForm.id, price: orderForm.totalPrice, payChannel
            })
            Toast.show({
                icon: 'loading',
                content: '加载中…',
            })
        }
    }

    const choosedCoupon = order?.usedDiscountList?.[0]?.discountTitle ?? "无优惠券"

    return <Box>
        <Box display='flex' flexDirection='column' height='100%' overflow='scroll'>

            <Box className={classes.card}>
                <Box display='flex' alignItems='center'>
                    <StorefrontIcon style={{ color: '#555', marginRight: 4 }} />
                    <Box color='#222' fontWeight='500' fontSize={14}>
                        {storeInfo?.storeName}
                    </Box>
                    <Box flex={1} />
                </Box>
                <Box display='flex' marginTop={2}>
                    <ButtonBase
                        onClick={() => setDeliveryChannel(2)}
                        className={deliveryChannel === 2 ? classes.checkedButton : classes.uncheckButton}>
                        <Box display='flex'>
                            <img className={classes.deliveryIcon} src={Delivery0}
                                style={{ filter: deliveryChannel === 2 ? null : 'grayscale(90%)' }}
                            />
                            {/* <SvgIcon component={Delivery0} viewBox="0 0 400, 400" /> */}
                            <Box>
                                <Box className={classes.h4}>
                                    到店自取
                                </Box>
                                <Box className={classes.summary}>
                                    可转赠他人
                                </Box>
                            </Box>
                        </Box>

                        {deliveryChannel === 2 && <CheckedIcon className={classes.checkIcon} />}
                    </ButtonBase>
                    <Box width={10}></Box>
                    <ButtonBase
                        onClick={() => setDeliveryChannel(1)}
                        className={deliveryChannel === 1 ? classes.checkedButton : classes.uncheckButton}>
                        <Box display='flex'>
                            <img className={classes.deliveryIcon} src={Delivery1}
                                style={{ filter: deliveryChannel === 1 ? null : 'grayscale(90%)' }} alt="Delivery0 Logo" />
                            <Box textAlign='left'>
                                <Box className={classes.h4}>
                                    快递
                                </Box>
                                <Box className={classes.summary}>
                                    快递到您的住址
                                </Box>
                            </Box>
                        </Box>
                        {deliveryChannel === 1 && <CheckedIcon className={classes.checkIcon} />
                        }
                    </ButtonBase>
                </Box>

                {
                    deliveryChannel === 2 && store && <OutletChip
                        storeId={store}
                        storeInfo={storeInfo} />
                }
                {
                    deliveryChannel === 1 &&
                    <ButtonBox
                        style={{ marginTop: 10, backgroundColor: '#efefef' }}
                        onClick={handleClickOpenAddress}>
                        <Box flex={1} textAlign='left'>
                                <Box color='#777' >选择收件人</Box>
                            <Box mt={1} className={classes.h4}>{address?.name} {address?.phone}</Box>
                            <Box mt={1} fontSize={12}>{address?.address}</Box>
                        </Box>
                        <ChevronRightIcon />
                    </ButtonBox>
                }
            </Box>

            <Box className={classes.card}>
                <Box mb={1}>商品详情</Box>
                <Divider />
                {
                    order && order.products?.map(p => (<Box>
                        <GoodsItem item={p} amount={p.amount} setAmount={type === 'goods' ? setAmount : null} />
                    </Box>))
                }
            </Box>
            <Box className={classes.card}>
                <Box mb={1}>优惠券</Box>
                <Divider />
                <ButtonBase className={classes.couponBox}
                    onClick={() => {
                        if (order?.unusedDiscountList) {
                            setOpenCoupons(true)
                        }
                    }}
                >
                    <Box>
                        选择优惠券
                    </Box>
                    {
                        choosedCoupon
                    }
                </ButtonBase>
            </Box>
            <Box className={classes.card}>
                <PriceTextItem title='商品金额' price={'¥' + priceCast(order?.totalCartPrice)} />
                <PriceTextItem title='优惠金额' price={'- ¥' + priceCast(order?.totalDiscountPrice)} />
                <PriceTextItem title='运费' price={'¥' + priceCast(order?.totalCargoPrice)} />
            </Box>

            <Box className={classes.card}>
                <Box display='flex' alignItems='center' width='100%'>
                    <WePayIcon />
                    <Box ml={1}>微信</Box>
                    <Box flex={1} />
                    <Radio
                        checked={payChannel === 0}
                        onChange={() => { setPayChannel(0) }}
                        value="0"
                        name="wechat"
                    />
                </Box>
                <Box display='flex' alignItems='center' width='100%'>
                    <AliPayIcon />
                    <Box ml={1}>支付宝</Box>
                    <Box flex={1} />
                    <Radio
                        checked={payChannel === 1}
                        onChange={() => { setPayChannel(1) }}
                        value="1"
                        name="alipay"
                    />
                </Box>
            </Box>
            <div style={{ height: '60px', flexShrink: 0 }} />
        </Box>
        <Box className={classes.bottomPanel}>
            <Box m={1} color='red' textAlign='right'>
                {address?.id < 0 ? "请选择收货地址" : null}
            </Box>
            合计：<Box component='span' color='red' fontSize={13}>¥</Box>
            <Box m={1} component='span' color='red' fontSize={24}>{priceCast(order?.totalPrice)}</Box>
            <Button variant='contained' style={{ borderRadius: 20 }}
                onClick={submit} disabled={!order || address?.id < 0} color='primary'>去支付</Button>
        </Box>

        <Popup
            visible={openAddress} onMaskClick={handleCloseAddress}
            bodyStyle={{ height: '70vh', backgroundColor: '#eee' }}
        >
            <AddressChoosePage
                onChooseAddress={onChooseAddress}
                handleCloseAddress={handleCloseAddress} />
        </Popup>

        <AddressEdit show={addAddress} onClose={onAddAddress} />

        <Popup
            visible={openCoupons}
            onMaskClick={() => {
                setOpenCoupons(false)
            }}
            bodyStyle={{ height: '70vh' }}>
            <NavBar onBack={() => {
                setOpenCoupons(false)
            }}>
                选择优惠券
            </NavBar>
            <Box className={classes.couponPage}>
                {
                    order?.usedDiscountList?.map(item => <CouponItem
                        key={item.id}
                        coupon={item}
                        isSelected={true}
                        onChoose={onChooseCoupon} />)
                }
                {
                    order?.unusedDiscountList?.map(item => <CouponItem
                        key={item.id}
                        coupon={item}
                        isSelected={false}
                        onChoose={onChooseCoupon} />)
                }
            </Box>
        </Popup>
    </Box>
}

export default SubmitOrder;