
import React, { useEffect, useState } from 'react';
import { makeStyles, Box, List, ListItem } from '@material-ui/core'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import { useAppHistory, useNavigationBar } from "utils/bridge";
import { licenseList } from './interface.ts';

const useStyles = makeStyles({
  factory_store_row: {
    "&.MuiBox-root nav": {
      borderBottom: '10px solid #F8F8F8'
    },
    "& ::-webkit-input-placeholder": {
      color: ' #BABABA',
      textAlign: 'right',
      padding: '0 6px',
      fontSize: '14px',
    },
    "& textarea": {
      boxSizing: 'border-box',
      maxWidth: '300px',
      padding: '6px 0',
      fontSize: '14px',
      marginBottom: '-8px',
      textAlign: 'right',
      color: '#3F3F3F',
      width: 0,
      minWidth: '130px',
      flexGrow: 1,
      border: 0,
      margin: 0,
      outline: 0,
      resize: 'none'
    }
  },
  btn: {
    position: 'fixed',
    bottom: '25px',
    width: '100%',
    maxWidth: '840px',
    "& button": {
      fontSize: 20,
      color: 'white',
      width: '100%',
      padding: 8,
      background: 'linear-gradient(90deg, #FDAE33, #FDC233)',
      borderRadius: '100px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    }
  }
})

export default function FactoryStore(props) {
  const history = useAppHistory()
  const classes = useStyles()
  const [list, setList] = useState()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    const { data } = await licenseList(0, 1)
    if (data) {
      setList(data?.[0])
    }
  }, [])
  //  state: null 去认证, false 去修改， true 去查看
  const isIncludes = e => list?.detail.includes(e) && !(list?.state === true)
  const itemList = [
    { title: '营业执照', href: '/business_license', status: isIncludes('companyInfo') },
    { title: '对公账户', href: '/corporate_account', status: isIncludes('corporateAccount') },
    { title: '管理员身份证', href: '/manage_authentication', status: isIncludes('adminFront') },
  ]
  useNavigationBar({
    title: '工厂店',
  })
  return (
    <div style={{ background: '#F8F8F8', height: '100%' }}>

      <Box sx={{ width: '100%', bgcolor: 'background.paper' }}
        classes={{ root: classes.factory_store_row }}>
        {itemList.map(item => (
          <nav aria-label="secondary mailbox folders">
            <List onClick={() => { history.push(`${item.href}`) }}>
              <ListItem disablePadding>
                <span style={{ flex: '1 1 auto' }}>{item.title}</span>
                <span style={{ color: '#3F3F3F', fontSize: 14, marginRight: '5px' }}>{item?.status ? '去认证' : '查看'}</span><ArrowForwardIosIcon style={{ color: '#aaa', fontSize: 16 }} />
              </ListItem>
            </List>
          </nav>
        ))}

        <nav aria-label="企业地址">
          <List onClick={() => { history.push(`/business_address`) }}>
            <ListItem disablePadding>
              <span style={{ flex: '1 1 auto' }}>企业地址</span>
              <span style={{ color: '#3F3F3F', fontSize: 14, marginRight: '5px' }}>{isIncludes('companyAddress') ? '去填写' : '查看'}</span><ArrowForwardIosIcon style={{ color: '#aaa', fontSize: 16 }} />
            </ListItem>
          </List>
        </nav>
      </Box>
    </div>
  )
}

