import {Box, Grid, Typography} from "@material-ui/core"
import { useCallback, useEffect, useState } from "react"
import { GoodsListItem, GoodsGrid } from 'components';
import { chooseObject, useAppHistory, useNavigationBar } from "../utils/bridge";
import { getFocusList, getFootPrint } from "../service/goods";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import StoreIcon from "@material-ui/icons/Store";
import { StatusBoxPro } from '../components/empty'

const MarkedShopList = ({ match, location }) => {
    useNavigationBar({ title: '我收藏的店' })
    const [result, setResult] = useState([])
    useEffect(() => {
        load(match.params.id)
    }, [])

    const load = async () => {
        const { data } = await getFocusList(1,0,{page:false})
        setResult(data)
    }
    return <Box>
        <Grid container spacing={1} style={{ padding: 8, }}>
            {
                result?.map(item =>
                    <Grid item xs={6} sm={4} key={item}> <ShopItem  item ={item} /></Grid>
                )
            }
        </Grid>
    </Box>
}


const MarkedGoodsList = ({ match }) => {
    useNavigationBar({ title: '我关注的商品' })
    const [result, setResult] = useState([])
    useEffect(() => {
        load(match.params.id)
    }, [])

    const load = async () => {
        const { data } = await getFocusList(2,0 ,{page:false})
        setResult(data)
    }

    return <Box>
        <GoodsGrid goodList={result} />
    </Box>
}

const msgBody = (goods) => {
    const content = {
        showPlayer: false,
        image: goods.img,
        title: goods.name,
        subTitle: goods.storeName,
        tag: null,
        price: (goods.price / 100),
        source: "商城"
    }
    const json = {
        id: String(goods.id),
        path: '/goods/' + goods.id
    }
    return {
        type: '1',
        content: JSON.stringify(content),
        jsonType: '1',
        json: JSON.stringify(json),
        title: "我的商品"
    }
}

const ChoooseMarkedGoodsList = () => {
    useNavigationBar({ title: '选择商品' })
    const goodsGrid = useCallback((result) => <Box bgcolor='white'>
        <Box height="16px" bgcolor='#eee' />
        {
        result?.map(item =>
            <GoodsListItem
                onClick={() => chooseObject('goods', msgBody(item))}
                item={item} key={item.productVariants?.id || item.id} />
        )
    }</Box>, [])

    return <StatusBoxPro
        request={async () => getFootPrint()}
        emptyTitle="还未关注任何商品哦，快去商城逛一逛吧"
        component={goodsGrid}
    />
}

const ShopItem = ({ item }) => {
    const history = useAppHistory()
    const gotoSearch = () => {
        history.push('/shop/' + item.id)
    }
    let area ='';
    if(item.areaText==='null' || item.areaText===null){
        area = item.area
    }
    return <Card >
        <CardActionArea
            onClick={gotoSearch} >
            <CardMedia
                style={{ height: 200 }}
                image={item.store_logo}
                title={item.store_name}
            />
            <CardContent>
                <Box display='flex' alignItems='center' fontSize='15px' fontWeight='500'>
                    <StoreIcon />    {item.store_name}
                </Box>
                <Typography variant="body2" color="textSecondary" component="p">
                    {area}
                </Typography>
            </CardContent>
        </CardActionArea>
    </Card>
}

export default {
    MarkedShopList,
    MarkedGoodsList,
    ChoooseMarkedGoodsList
}