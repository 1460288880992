import { useState, useEffect } from "react"
import { Box } from "@material-ui/core"
import { setNavigationBar, useAppHistory } from 'utils/bridge'
import { shareList } from 'service/goods'

import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const SpecificationListPage = props => {
    const [list, setList] = useState([])
    const [expanded, setExpanded] = useState(false)
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    }
    useEffect(async () => {
        setNavigationBar({ title: '服务项目解读' })
        const { data } = await shareList({ type: 4 })
        if (data) {
            setList(data)
        }
    }, [])

    return <Box p={1}>
        {
            list.map(item => {
                return <DocumentItem key={item.id} {...item}
                    handleChange={handleChange}
                    expanded={expanded} />
            })
        }
    </Box>
}


const DocumentItem = ({ id, textContent, html, expanded, handleChange }) => {
    return <Accordion expanded={expanded === id} onChange={handleChange(id)}>
        <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
        >
            <Typography >{textContent}</Typography>
        </AccordionSummary>
        <AccordionDetails>
            <div style={{
                fontSize: '13px',
                color: '#666'
            }} dangerouslySetInnerHTML={{
                __html: html
            }} />
        </AccordionDetails>
    </Accordion>
}

export default SpecificationListPage