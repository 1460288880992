export const isInIOSContainer = (window?.webkit?.messageHandlers?.nativeApp) !== undefined


export const isInAndroidContainer = ('android' in window)


export const postMessage = (method, json) => {
    if (isInIOSContainer) {
        window.webkit.messageHandlers.nativeApp.postMessage({
            method: `${method}`,
            params: `${json}`
        })
    } else if (isInAndroidContainer) {
        window.android.postMessage(method, json)
    }
}
