
import { Box, LinearProgress } from "@material-ui/core"
import { useRequest } from "ahooks";
import { getNearbyShop } from "../service/goods";
import { NavBar, Avatar, List, Ellipsis } from 'antd-mobile'
import { useAppHistory } from "../utils/bridge";

const ShopItem = ({ shop }) => {
    const { id, storeLogo, storeName, intro, distance } = shop
    const history = useAppHistory()
    const gotoShop = () => {
        history.push(`/shop/${id}`)
    }
    return <List.Item
        prefix={<Avatar src={storeLogo} />}
        description={<Ellipsis direction='end' rows={2} content={intro} />}
        onClick={gotoShop}
        extra={"距" + distance.toFixed(1) + "公里"}
    >
        <Box fontWeight={500}>
            {storeName}
        </Box>
    </List.Item>
}

const NearbyShop = ({ exclude, longitude, latitude }) => {
    const { data, loading, error } = useRequest(() => getNearbyShop(0, longitude, latitude), {
        formatResult: res => res.data.filter(i => i.id !== exclude)
    })
    if (loading) {
        return <LinearProgress />
    }
    return <Box bgcolor="#eee" height="100%">
        <NavBar backArrow={false} style={{ backgroundColor: 'white' }}>附近推荐</NavBar>
        <List style={{ height: 'calc(100% - 45px)', overflowY: 'scroll' }} >
            {
                data?.map(item => <ShopItem key={item.id} shop={item} />)
            }
        </List>
    </Box>
}

export default NearbyShop