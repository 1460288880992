import produce from "immer"
import * as api from '../service/goods'

const initalState = {
    allAddress: [],
    defaultAddress: null,
    currentAddress: null
}

const AddressActionType = {
    ListAddress: 'addr.list',
    SetDefault: 'addr.default',
    ChooseAddress: 'addr.choose',
    AddAddress: 'addr.add',
}

export const addressReducer = produce((draft, action) => {
    switch (action.type) {
        case AddressActionType.ListAddress:
            draft.allAddress = action.payload
            if (draft.defaultAddress === null) {
                draft.defaultAddress = action.payload[0]
            }
            break
        case AddressActionType.ChooseAddress:
            const selectAddress = draft.allAddress.find(i => i.id === action.payload)
            draft.currentAddress = selectAddress
            break
        case AddressActionType.SetDefault:
            const address = draft.allAddress.find(i => i.id === action.payload)
            draft.defaultAddress = address
    }
}, initalState)

export const getAllAddress = () => async dispatch => {
    const res = await api.getAddress()
    const data = res.data ?? []
    dispatch({
        type: AddressActionType.ListAddress,
        payload: data
    })
}


export const addAddress = (address) => async dispatch => {
    const { data } = await api.addAddressApi(address)
    dispatch({
        type: AddressActionType.AddAddress,
        payload: data
    })
    const { data: addr } = await api.getAddress()
    dispatch({
        type: AddressActionType.ListAddress,
        payload: addr
    })
}

export const setDefault = (id) => ({
    type: AddressActionType.SetDefault,
    payload: id
})

export const chooseAddress = (id) => ({
    type: AddressActionType.ChooseAddress,
    payload: id
})