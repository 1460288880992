import { Box, makeStyles, Button } from '@material-ui/core'
import { useNavigationBar, useAppHistory, refreshPage } from "utils/bridge";
import { postDynamic } from 'service/goods'
import { useForm } from "react-hook-form"

// 发布圈子动态
const useStyle = makeStyles(theme => ({
    inputPanel: {
        backgroundColor: 'white',
        marginTop: '16px',
        display: 'flex',
        flexDirection: 'column',
        padding: 12,
    },
    mutliInput: {
        border: 'none',
        outlineStyle: 'none',
        fontSize: '14px',
    }
}))


const ReleaseQuestion = () => {
    const classes = useStyle()
    useNavigationBar({ title: '提问题' })
    const history = useAppHistory()
    const { register, control, handleSubmit, formState: { errors } } = useForm()

    const publish = async requestData => {
        await postDynamic({
            type: 8,
            ...requestData
        })
        refreshPage("page_moment_list", "question")
        history.pop()
    }

    return <form onSubmit={handleSubmit(publish)}>
        <Box display='flex' flexDirection='column' height='100%'>
            <Box className={classes.inputPanel} mx={2} borderRadius={6}>
                <textarea className={classes.mutliInput} name="Text1" rows="6"
                    {...register("textContent")}
                    placeholder="请描述你的问题" />
            </Box>
            <Box height={70} />
            <Box my={2} mx={2} display='flex' justifyContent="flex-end" height='50%' flexDirection='column'>
                <Button type="submit" variant='contained' color='primary' fullWidth style={{ borderRadius: '20px' }}>
                    <div style={{ color: 'white' }}>提问</div>
                </Button>
            </Box>

        </Box></form>
}


export default ReleaseQuestion