import React, { useState, useEffect } from 'react'
import { List, ListItem, ListItemAvatar, Grid, ListItemText, Box, Typography, Tabs, Tab, InputBase, AppBar, Toolbar, Button, Dialog, Divider, DialogActions, DialogContent, makeStyles } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import { useAppHistory, useNavigationBar } from "utils/bridge"
import { productsManageList, batchDelete, batchLowerShelf, batchUpperShelf, modifyInventory } from './interface'
import { useAccount } from '../../../utils/hooks'
import { useToast } from "../../../models/toast"
import { StatusBox } from '../../../components/empty'

const useStyles = makeStyles(() => ({
  searchBar: {
    background: '#A786E8',
    color: '#fff',
    fontSize: '15px',
    padding: '15px',
    position: 'relative',
    '& .MuiSvgIcon-root': {
      position: 'absolute',
      top: '20px',
      zIndex: 2,
    },
    '& .MuiInputBase-root': {
      background: '#fff',
      width: '100%',
      borderRadius: '100px',
      padding: '2px 15px 2px 45px'
    },
    '& .MuiInputBase-input': {
      fontSize: '14px'
    }
  },
  manageContent: {
    background: '#ffffff',
    height: '100%',
    '& .MuiTabs-flexContainer': {
      borderBottom: '1px solid #EEEEEE'
    },
    '& .MuiTab-textColorInherit': {
      width: '33.3%',
      color: '#222222'
    },
    '& .MuiTab-textColorInherit.Mui-selected': {
      color: '#A786E8 !important'
    },
    '& .MuiTabs-indicator': {
      background: '#A786E8',
      maxWidth: '10%',
      margin: '0 11%',
    },
    '& .MuiBox-root': {
      padding: 0
    },
    '& ul': {
      borderBottom: '1px solid #eee'
    },
    '& .MuiGrid-grid-xs-2': {
      border: '1px solid #ADADAD',
      marginRight: '9px',
      textAlign: 'center',
      borderRadius: '3px',
      fontSize: '13px',
      color: '#000',
      padding: '3px 0'
    }
  },
  bottomBar: {
    textAlign: 'center',
    '& .MuiButtonBase-root': {
      border: '1px solid #A786E8',
      color: '#A786E8',
      width: '50%',
      borderRadius: '100px',
      padding: '9px'
    },
    '& .MuiButtonBase-root:nth-child(2)': {
      background: '#A786E8',
      color: '#fff',
      marginLeft: '10px'
    }
  },
  soldOut: {
    background: 'rgb(0,0,0,0.4)',
    width: '76px',
    height: '76px',
    position: 'absolute',
    top: 0,
    color: '#fff',
    '& button': {
      color: '#fff',
      border: '1px solid #fff',
      padding: 0,
      transform: 'rotate(-30deg)',
      marginTop: '24px',
      marginLeft: '5px'
    }
  },
  priceInput: {
    background: '#F7F7F7',
    fontSize: '14px',
    border: 'none',
    borderRadius: '100px',
    padding: '10px 33px',
    '&::-webkit-input-placeholder': {
      color: '#BABABA',
    }
  },
  dialog: {
    '& .MuiDialog-paper': {
      borderRadius: '10px'
    }
  }
})
)


export default function ProductsManage() {
  const classes = useStyles()
  const history = useAppHistory()
  const [value, setValue] = useState(0)
  const [open, setOpen] = useState(false)
  const [lowerShelfList, setLowerShelfList] = useState([])
  const [saleList, setSaleList] = useState([])
  const [soldOutList, setSoldOutList] = useState([])
  const [inventoryOpen, setInventoryOpen] = useState(false)
  const [selectInventoryProduct, setSelectInventoryProduct] = useState({})
  const [inventoryValue, setInventoryValue] = useState()
  const [priceValue, setPriceValue] = useState()
  const [keyWord, setKeyWord] = useState('')
  const { mall } = useAccount()
  const toast = useToast()

  useNavigationBar({
    title: '商品管理',
  })
  const TabPanel = (props) => {
    const { children, value, index, ...other } = props

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  // 打开改价弹窗
  const handleClickOpen = (product) => {
    setOpen(true)
    setSelectInventoryProduct(product)
  }

  // 关闭改价弹窗
  const handleClose = () => {
    setOpen(false)
  }
  // 打开修改库存弹窗
  const inventoryHandleOpen = (product) => {
    setInventoryOpen(true)
    setSelectInventoryProduct(product)
  }

  // 关闭修改库存弹窗
  const inventoryHandleClose = () => {
    setInventoryOpen(false)
  }

  // tabs切换
  const handleChange = (event, newValue) => {
    setKeyWord('')
    setValue(newValue) // tabs: 0 在售中，1已售罄，2已下架;  接口回传参数: live ：0 下架, live：1 在售中,stock：0 已售罄
    newValue === 0 ? effectSale() : newValue === 1 ? effectSoldOut() : effectLowerShelf()
  }

  // 搜索框
  const onTextChange = (e) => {
    const tag = e?.target?.value
    setKeyWord(tag)
    if (e.key === "Enter") {
      value === 0 ? effectSale({ tag }) : value === 1 ? effectSoldOut({ tag }) : effectLowerShelf({ tag })
    }
  }

  // 批量删除操作
  const batchDeleteFn = async (checked) => {
    const { data } = await batchDelete(checked)
    if (data) {
      toast.showToast("toast.success", "操作成功")
      // 刷新列表
      effectSale()
      effectSoldOut()
      effectLowerShelf()
    }
  }

  // 批量下架操作
  const batchLowerShelfFn = async (checked) => {
    const { data } = await batchLowerShelf(checked)
    if (data) {
      toast.showToast("toast.success", "操作成功")
      // 刷新列表
      effectSale()
      effectSoldOut()
      effectLowerShelf()
    }
  }


  // 批量上架操作
  const batchUpperShelfFn = async (checked) => {
    const { data } = await batchUpperShelf(checked)
    if (data) {
      toast.showToast("toast.success", "操作成功")
      // 刷新列表
      effectSale()
      effectSoldOut()
      effectLowerShelf()
    }
  }

  // 修改库存
  const modifyInventoryFn = async () => {
    setInventoryOpen(false)

    const params = {
      id: selectInventoryProduct?.productVariants?.id,
      stock: inventoryValue,
      price: selectInventoryProduct?.productVariants?.price,
      live: selectInventoryProduct?.live || value === 2 ? 0 : 1, // 必填参数，默认值 tabs: 0 在售中，1已售罄，2 已下架;  接口回传参数: live ：0 下架, live：1 在售中,stock：0 已售罄
      storeId: mall?.id,
      cargoPrice: 0, // 验证参数，暂时固定
      color: { id: 0 }, // 验证参数，暂时固定
      product: {
        id: selectInventoryProduct?.id
      }
    }
    const { data } = await modifyInventory(params)
    if (data) {
      toast.showToast("toast.success", "操作成功")
      // 刷新列表
      effectSale()
      effectSoldOut()
      effectLowerShelf()
    }
  }

  // 修改价格
  const modifyPriceFn = async () => {
    setOpen(false)
    const params = {
      id: selectInventoryProduct?.productVariants?.id,
      price: priceValue * 100,
      storeId: mall?.id,
      cargoPrice: 0, // 验证参数，暂时固定
      color: { id: 0 }, // 验证参数，暂时固定
      stock: selectInventoryProduct?.productVariants?.stock, // 回传原来值，必填参数
      live: selectInventoryProduct?.live || value === 2 ? 0 : 1, // 必填参数，默认值 tabs: 0 在售中，1已售罄，2 已下架;  接口回传参数: live ：0 下架, live：1 在售中,stock：0 已售罄
      product: {
        id: selectInventoryProduct?.id
      }
    }
    const { data } = await modifyInventory(params)
    if (data) {
      toast.showToast("toast.success", "操作成功")
      // 刷新列表
      effectSale()
      effectSoldOut()
      effectLowerShelf()
    }
  }


  /**
   * 列表接口
   */

  const effectLowerShelf = async (params) => {
    const { data } = await productsManageList({ live: 0, ...params, storeId: mall?.id })
    if (data) {
      setLowerShelfList(data)
    }
  }

  const effectSale = async (params) => {
    const { data } = await productsManageList({ ...params, storeId: mall?.id })
    if (data) {
      setSaleList(data)
    }
  }

  const effectSoldOut = async (params) => {
    const { data } = await productsManageList({ ...params, storeId: mall?.id, stock: 0 })
    if (data) {
      setSoldOutList(data)
    }
  }


  /**
   * live ：0 下架
   * live：1 在售中
   * stock：0 已售罄
   */
  useEffect(() => {
    effectLowerShelf()
    effectSale()
    effectSoldOut()
  }, [])

  const actionList = [
    {
      title: '下架',
      onclick: (product) => batchLowerShelfFn([product?.productVariants?.id]),
      type: [0, 1]
    },
    // {
    //   title: '预览',
    //   onclick: '',
    //   type: [0]
    // },
    {
      title: '删除',
      onclick: (product) => batchDeleteFn([product?.productVariants?.id]),
      type: [1, 2],
    },
    {
      title: '上架',
      onclick: (product) => batchUpperShelfFn([product?.productVariants?.id]),
      type: [2]
    },

    {
      title: '改库存',
      onclick: (product) => inventoryHandleOpen(product),
      type: [0, 1]
    },
    {
      title: '改价',
      onclick: (product) => handleClickOpen(product),
      type: [0, 1, 2]
    },
    // {
    //   title: '编辑',
    //   onclick: '',
    //   type: [0, 1, 2]
    // },
  ]

  const listHtml = (dataList, type) => (
    <StatusBox empty={dataList}>
      {dataList?.map((el, index) => (
        <List key={index}>
          <ListItem alignItems="flex-start">
            <ListItemAvatar style={{ position: 'relative' }}>
              <img width={76} height={76} style={{ borderRadius: '3px', marginRight: '13px' }} alt="Remy Sharp" src={el?.productVariants?.image} />
              {type === 1 && (
                <Box className={classes.soldOut}>
                  <Button variant="outlined" color='white'>已售罄</Button>
                </Box>
              )}
              {type === 2 && (
                <Box className={classes.soldOut}>
                  <Button variant="outlined" color='white'>已下架</Button>
                </Box>
              )}
            </ListItemAvatar>
            <ListItemText
              primary={
                <Box p={2}
                  overflow='hidden'
                  height={26}
                  style={{ display: '-webkit-box', WebkitLineClamp: 1, lineClamp: 1, WebkitBoxOrient: 'vertical', overflow: 'hidden', }}
                >
                  {el?.name}
                </Box>
              }
              secondary={
                <React.Fragment>
                  <Grid container style={{ color: '#999999', fontSize: '13px' }}>
                    <Grid item xs={6}>
                      累计销量 {el?.productVariants?.sellCount}
                    </Grid>
                    <Grid item xs={6}>
                      库存 {el?.productVariants?.stock}
                    </Grid>
                    <Grid item xs={6}>
                      {/* 总退款件数 {el?.productVariants?.} */}
                    </Grid>
                  </Grid>
                  <text style={{ color: '#FF1717' }}>¥ {el?.productVariants?.price ? (el?.productVariants?.price / 100)?.toFixed(2) : '-'}</text>
                </React.Fragment>
              }
            />
          </ListItem>
          <Grid container style={{ margin: '0 16px' }}>
            {actionList?.filter(i => i.type.includes(type))?.map((item, index) => (
              <Grid item xs={2} onClick={() => item?.onclick(el)} key={index}>
                {item?.title}
              </Grid>
            ))}
          </Grid>
        </List>
      ))}
    </StatusBox>
  )
  return (
    <Box>
      <Box className={classes.searchBar} >
        <Box>
          <SearchIcon style={{ color: '#BABABA', marginLeft: '12px' }} />
          <InputBase
            className={classes.input}
            placeholder="搜索"
            onKeyPress={e => onTextChange(e)}
            onChange={e => onTextChange(e)}
            value={keyWord}
            inputProps={{ 'aria-label': 'search goods' }}
          />
        </Box>
      </Box>
      <Box className={classes.manageContent}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider', position: 'sticky', top: '0px', zIndex: 99, background: '#fff', boxShadow: '#f3f3f3 0px 2px 8px' }}>
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
            <Tab label={'在售中(' + saleList?.length + ')'} />
            <Tab label={'已售罄(' + soldOutList?.length + ')'} />
            <Tab label={'已下架(' + lowerShelfList?.length + ')'} />
          </Tabs>
        </Box>

        <TabPanel value={value} index={0}>
          {listHtml(saleList, 0)}
        </TabPanel>
        <TabPanel value={value} index={1}>
          {listHtml(soldOutList, 1)}
        </TabPanel>
        <TabPanel value={value} index={2}>
          {listHtml(lowerShelfList, 2)}
        </TabPanel>
      </Box>
      <Box className={classes.bottomBar}>
        <AppBar color="inherit" style={{ position: 'fixed', top: 'auto', right: 'auto', bottom: 0, width: '100%', maxWidth: '841px', }}>
          <Toolbar>
            <Button onClick={() => history.push(`/b2c/workbench/batch_manage?status=${value}`)}>批量管理</Button>
            <Button disabled>增加商品</Button>
          </Toolbar>
        </AppBar>
      </Box>

      {/* 改价弹窗 */}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={classes.dialog}
      >
        <Box
          style={{
            padding: '20px',
            borderRadius: '10px !important',
            textAlign: 'center'
          }}
        >
          <text style={{ fontSize: '15px' }}>
            输入您的价格
        </text>
          <DialogContent>
            <input placeholder='输入您的价格' type="number" className={classes.priceInput} onChange={e => setPriceValue(e?.target.value)} />
          </DialogContent>
          <DialogActions style={{ justifyContent: 'center', height: '28px' }}>
            <Button onClick={handleClose} style={{ color: '#595959', fontSize: '14px' }}>取消</Button>
            <Divider orientation="vertical" variant="small" flexItem />
            <Button onClick={modifyPriceFn} style={{ color: '#A786E8', fontSize: '14px' }}>
              确定
          </Button>
          </DialogActions>
        </Box>
      </Dialog>

      {/* 改库存 */}
      <Dialog
        open={inventoryOpen}
        onClose={inventoryHandleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={classes.dialog}
      >
        <Box
          style={{
            padding: '20px',
            borderRadius: '10px !important',
            textAlign: 'center'
          }}
        >
          <text style={{ fontSize: '15px' }}>
            输入您的库存量
        </text>
          <DialogContent>
            <input placeholder='输入您的库存量' type="number" className={classes.priceInput} onChange={e => setInventoryValue(e?.target.value)} />
          </DialogContent>
          <DialogActions style={{ justifyContent: 'center', height: '28px' }}>
            <Button onClick={inventoryHandleClose} style={{ color: '#595959', fontSize: '14px' }}>取消</Button>
            <Divider orientation="vertical" variant="small" flexItem />
            <Button style={{ color: '#A786E8', fontSize: '14px' }} onClick={modifyInventoryFn}>
              确定
          </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </Box>
  )
}