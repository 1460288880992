import { Action, Dispatch } from "redux"
import { getCategory } from "../service/goods"

type StateType = {
    data: number[],
    loaded: boolean,
    error: false,
}

type ActionType = Action<typeof Actions.fetch> & {
    payload: any
}

const initalState: StateType = {
    data: [],
    loaded: false,
    error: false,
}

const Actions = {
    fetch: 'category.fetch'
}

export const categoryReducer = (state: StateType = initalState, action: ActionType) => {
    switch (action.type) {
        case Actions.fetch:
            return {
                data: action.payload,
                loaded: true,
                error: false,
            }
        default:
            return state
    }
}

export const loadCategoryAction = () => async (dispatch: Dispatch) => {
    const res = await getCategory()
    const data: CategoryRoot[] = res.data
    dispatch({
        type: Actions.fetch,
        payload: data.sort((l, r) => l.id - r.id)
    })
}
interface CategoryRoot {
    id: number,
    name: string,
    pid: number,
    categoryList: CategoryItem[]
}

interface CategoryItem {
    id: number,
    name: string,
    pid: number,
}