import { Avatar, Box, Button, ButtonBase, makeStyles, withStyles, Tabs, Tab, Grid } from "@material-ui/core"
import { useCallback, useEffect, useMemo, useState } from "react"
import { getStoreInfo, getGoodsByStore, getGroupbuyByStore, getCommentsByStore, storeWebsiteLoader } from 'service/goods'
import { priceCast } from "utils/price"
import { CountDown, ImageButton } from "components/base"
import { useAppHistory } from "utils/bridge"
import { red } from '@material-ui/core/colors';
import {addFocus, checkFocus, deleteFocus} from "../service/goods";
import {useToast} from "../models/toast";
import { useNavigationBar, openLocation } from "../utils/bridge";
import { isVideo } from "../jwl/moment/base"
import { StatusBoxPro } from "../components/empty"
import { useRequest } from "ahooks"
import { Swiper, Popup } from "antd-mobile"
import NearbyShop from './nearbyShop'

import Cookies from 'js-cookie';
import { caculateDistance } from 'utils/price'

const useStyle = makeStyles({
    logo: {
        width: 100,
        height: 100,
        borderRadius: 8,
        background: 'white',
    },
    ticket: {
        background: '#e12',
        borderRadius: 4,
        color: 'white',
        fontSize: 11,
        padding: '2px 6px',
        '&:hover': {
            background: '#e23',
        }
    },
    goodsItem: {
        background: 'white',
        borderRadius: 4,
        marginBottom: 8,
        padding: 8,
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'flex-start',
        alignItems: 'stretch',
        textAlign: 'left',
    },
    originPrice: {
        color: '#888',
        fontSize: '14px',
        marginLeft: '4px',
        textDecoration: 'line-through',
        '&::before': {
            content: '"¥"',
            fontSize: '10px',
        }
    },
    itemTagContained: {
        color: 'white',
        padding: '2px 6px',
        borderRadius: '4px',
        fontSize: '9px',
        background: 'linear-gradient(-45deg, #5BA0F4, #2584F7)',
    },
    goodsImg: {
        width: 100,
        height: 100,
        objectFit: 'cover',
        borderRadius: 8,
    },
    card: {
        background: 'white',
        borderRadius: 4,
        display: 'block',
        maxWidth: '100%',
    },
    cardMedia: {
        width: '100%',
        height: 210,
        borderRadius: '4px 4px 0 0',
        objectFit: 'cover',
    },
    cardTitle: {
        fontSize: 13,
        lineHeight: '1rem',
        maxHeight: '2.2rem',
        padding: '4px 8px 0 8px',
        fontWeight: 500,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        textAlign: 'left',
    },
    bottomBar: {
        position: 'fixed',
        bottom: 20,
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
    },
    chatRoomButton: {
        borderRadius: 20,
        width: '80%',
        height: 40,
        maxWidth: 200,
    }
})

const MarkButton = withStyles(theme => ({
    root: {
        padding: '2px 2px',
        color: theme.palette.getContrastText(red[500]),
        borderRadius: '18px',
        marginLeft: 16,
        boxShadow: 'none',
        fontSize: '12px',
        background: red[500],
        '&:hover': {
            background: red[700],
        }
    }
}))(Button)

const StyledTabs = withStyles({
    root: {
        color: '#7bb2f5',
        background: 'white',
        position: 'sticky',
    },
    indicator: {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: 'transparent',
        '& > span': {
            maxWidth: 40,
            width: '100%',
            backgroundColor: '#7bb2f5',
        },
    },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);


const StyledTab = withStyles((theme) => ({
    root: {
        textTransform: 'none',
        color: '#333',
        fontWeight: theme.typography.fontWeightRegular,
        fontSize: theme.typography.pxToRem(15),
        marginRight: theme.spacing(1),
        '&:focus': {
            color: '#2584F7',
            opacity: 1,
        },
        '&:hover': {
            color: '#2584F7',
            opacity: 1,
        },
        '&$selected': {
            color: '#2584F7',
            fontWeight: theme.typography.fontWeightMedium,
        },
        selected: {},
    },
}))((props) => <Tab disableRipple {...props} />);

const ShopPage = ({ match }) => {
    const shopId = match.params.id
    const classes = useStyle()
    const [value, setValue] = useState(1)
    const [store, setStore] = useState()
    const [showNearby, setShowNearby] = useState(false)
    const [star,setStar] = useState('关注');
    const toast = useToast();

    const handleChange = (event, newValue) => {
        setValue(newValue);
    }
    let location = useMemo(() => Cookies.get('location'), [])
    useNavigationBar({ title: '店铺' })
    useEffect(async () => {
        const { data } = await getStoreInfo(shopId)
        if (data && data.data) {
            setStore(data.data)
        }

        const {count} = await checkFocus(shopId)
        if(count>0){
            setStar('取关')
        }
    }, [])
    const pushToMap = () => {
        // 调用地图导航 
        openLocation(store.latitude, store.longitude, store.storeName)
    }

    const focus = async () => {
        if (star == '取关'){
            await deleteFocus(shopId)
            toast.showToast("toast.success", "已取消关注")
            setStar('关注')
        }else{
            await addFocus(1,shopId)
            toast.showToast("toast.success", "已加入关注")
            setStar('取关')
        }
    }

    const Header = useCallback(() => {
        if (store?.imgs) {
            return <Swiper>
                {
                    store.imgs?.split(',').map(item => <Swiper.Item key={item}>
                        <img className={classes.cardMedia} src={item} />
                    </Swiper.Item>)
                }
            </Swiper>
        } else {
            return <img className={classes.logo}
                src={store.storeLogo} />
        }
    }, [store])


    let distance = null
    if (!store) {
        return <div />
    }
    if (location) {
        let lon = location.split(",")[0]
        let lat = location.split(",")[1]
        distance = caculateDistance(lat, lon, store.latitude, store.longitude).toFixed(1)
    }
    return <Box height='100%' overflow='scroll'>
        <Box alignItems='center' bgcolor='white' p={2}>
            <Header />
        </Box>
        <Box alignItems='center' bgcolor='white' px={2} pb={2}>
            <Box fontSize='18px' fontWeight='600' ml={1} flex='1' >
                {store.storeName}
                <MarkButton onClick={focus} variant='contained'>
                    {star}
                </MarkButton>
            </Box>
        </Box>
        <Box alignItems='center' bgcolor='white' color='#666' fontSize={14} px={3}>
            商铺简介：{store.intro}
            <Box>
                联系电话：{store.storePhone}
            </Box>
            <Box bgcolor='#ddd' height='1px' my={1} />
            <Box display='flex' flexDirection='row'>
                <Box flex="1">
                    <Box>
                        {store.storeAddress}
                    </Box>
                    <Box hidden={distance} color='#888'>
                        距您位置{distance}公里
                    </Box>
                </Box>
                <ImageButton
                    onClick={pushToMap}
                    icon='https://h5.qihuaitech.com/files/ic_ditu@2x.png'
                    name='地图导航'
                />
                <ImageButton
                    onClick={() => {
                        setShowNearby(true)
                    }}
                    icon='https://h5.qihuaitech.com/files/ic_fujin1.png'
                    name='附近推荐'
                />
            </Box>
        </Box>

        <StyledTabs value={value} onChange={handleChange} centered variant={"fullWidth"} >
            <StyledTab label="主页" />
            <StyledTab label="商品" />
            <StyledTab label="限时团" />
            <StyledTab label="购推荐" />
        </StyledTabs>

        <TabWebPage value={value} shop={shopId} />
        <TabGoods value={value} shop={shopId} />
        <TabGroupBuy value={value} shop={shopId} />
        <TabComment value={value} shop={shopId} />

        <Popup
            visible={showNearby}
            onMaskClick={() => {
                setShowNearby(false)
            }}
            bodyStyle={{ height: '70vh' }}>
            <NearbyShop exclude={store.id} longitude={store.longitude} latitude={store.latitude} />
        </Popup>
    </Box>
}

const TabWebPage = ({ value, shop }) => {
    const page = useCallback((res) => <div
        className="article"
        dangerouslySetInnerHTML={{
            __html: res.html
        }} />, [shop])

    if (value !== 0) {
        return null
    }
    return <StatusBoxPro
        request={storeWebsiteLoader(shop)}
        component={page}
    />
}

const TabGoods = ({ value, shop }) => {
    const classes = useStyle()
    const [goodsList, setGoodsList] = useState([])
    const history = useAppHistory()
    useEffect(async () => {
        const { data } = await getGoodsByStore(shop)
        if (data && data.data) {
            setGoodsList(data.data)
        }
    }, [shop])

    if (value !== 1) {
        return null
    }
    return <Box p={1}>
        {
            goodsList.map(item => {
                const { productVariants } = item
                return <ButtonBase className={classes.goodsItem}
                    onClick={() => history.push('/goods/' + item.id)} key={item.id}>
                    <img className={classes.goodsImg} src={productVariants.thumb} />
                    <div style={{ padding: '8px' }}>
                        <Box fontWeight='500'>{item.name}</Box>
                        <Box fontSize='12px'>{productVariants.model}</Box>
                        <Box height={8} />
                        <Box className='price'>{priceCast(productVariants.price)}</Box>
                    </div>
                </ButtonBase>
            })
        }
    </Box>
}

const TabGroupBuy = ({ value, shop }) => {
    const classes = useStyle()
    const [goodsList, setGoodsList] = useState([])
    const history = useAppHistory()

    useEffect(async () => {
        const { data } = await getGroupbuyByStore(shop)
        if (data) {
            setGoodsList(data)
        }
    }, [shop])

    if (value !== 2) {
        return null
    }
    const textContainer = {
        padding: '8px', display: 'flex', flexDirection: 'column',
        alignItems: 'start',
    }
    return <Box p={1}>
        {
            goodsList.map(item => {
                return <ButtonBase className={classes.goodsItem}
                    onClick={() => history.push('/goods/' + item.id)} key={item.id}>
                    <img className={classes.goodsImg} src={item.thumb} />
                    <div style={textContainer}>
                        <Box>{item.productName}</Box>
                        <Box flex='1' />
                        <Box className='price'>{priceCast(item.actPrice)}
                            <Box className={classes.originPrice} component='span'>{priceCast(item.price)}</Box>
                        </Box>
                        <Box style={{ float: 'right' }} className={classes.itemTagContained} component='span'>
                            距离结束: <CountDown endTime={new Date(item.endTime)} />
                        </Box>
                    </div>
                </ButtonBase>
            })
        }
    </Box>
}

const TabComment = ({ value, shop }) => {
    const classes = useStyle()

    const { data: commentList } = useRequest(() => getCommentsByStore(shop), {
        formatResult: resp => resp.data,
        refreshDeps: [shop]
    })

    if (value !== 3) {
        return null
    }
    if (!commentList) {
        return <Box height='80px' p={2} color='#777'>
            还没有人发布评价哦
        </Box>
    }

    return <Grid container p={1} spacing={1} style={{ padding: '8px' }}>
        {
            commentList.map((item, i) => {
                const cover = item.file?.split(',')?.[0]
                return <Grid item xs={6} key={i} >
                    <ButtonBase className={classes.card}
                        // onClick={() => history.push('/service/comment/' + item.id)}
                    >
                        {
                            isVideo(cover) ? <video className={classes.cardMedia} src={cover}></video>
                                : <img className={classes.cardMedia} src={cover} />
                        }
                        <Box className={classes.cardTitle}>
                            {item.text}
                        </Box>
                        <Box p={1} display='flex' alignItems='center'>
                            <Avatar src={item.image} style={{ width: 24, height: 24 }} />
                            <Box fontSize={11} px={1} whiteSpace='nowrap'
                                overflow='hidden'
                                textOverflow='ellipsis'
                                color='#777'>
                                {item.name}
                            </Box>
                        </Box>
                    </ButtonBase>
                </Grid>
            })
        }
    </Grid>
}


export default ShopPage