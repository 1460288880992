import React, { useState, useRef, useEffect } from 'react'
import { Box, ListItem, ListItemAvatar, ListItemText, TextareaAutosize, makeStyles, Grid, ButtonBase, Typography } from '@material-ui/core'
import useFetch from "../../utils/useFetch"
import { useNavigationBar, useAppHistory, toast } from "utils/bridge"
import { useForm } from "react-hook-form"
import { UploadOnly } from '../../components/base'
import CloseIcon from '@material-ui/icons/Close'
import InfoIcon from '@material-ui/icons/Info'
import { produce } from 'immer'
import { shpppingComment } from './interface'
import { StatusBox } from '../../components/empty'
import { Rating } from '@material-ui/lab'

const useStyles = makeStyles(() => ({

  evidenceRow: {
    "& textarea": {
      width: '100%',
      marginTop: '10px',
      resize: 'none',
      outline: 0,
      padding: '11px',
      fontSize: '12px',
      background: '#fff',
      minHeight: '100px',
      borderColor: '#DCDCDC',
      borderRadius: '5px',
    },
  },

  btn: {
    position: 'fixed',
    bottom: '25px',
    width: '100%',
    maxWidth: '840px',
    "& button": {
      fontSize: 20,
      color: 'white',
      width: '100%',
      padding: 8,
      background: 'linear-gradient(90deg, #C90154, #E9097A)',
      borderRadius: '100px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    }
  },
}))
export default function RecommendedShopping({ match }) {
  const { id } = match.params
  const classes = useStyles()
  const inputImageRef = useRef(null);
  const [urls, setUrls] = useState([])
  const history = useAppHistory()


  const { loading, data, error } = useFetch('/mall/order/' + id)
  const { data: order } = data ?? {}
  const { register, setValue, handleSubmit, formState: { errors }, clearErrors } = useForm()

  /**
   * 上传图片
   * @param {*} url 
   */
  const onChooseImage = (url) => {
    setUrls(produce(draft => { draft.push(url) }))
    setValue('fileContent', [...urls, url])
    clearErrors('fileContent') // 清除错误提示
  }
  /**
   * 删除图片
   * @param {*} index 
   */
  const onDeleteImg = (index) => {
    setUrls(produce(draft => { draft.splice(index, 1) }))
    setValue('fileContent', urls.filter((el, indexs) => indexs !== index))
  }

  /**
   * 页面标题导航
   */
  useNavigationBar({
    title: '评价晒单',
  })
  /**
   * 表单提交
   * @param 
   */
  const onSubmit = reqData => {
    shpppingComment({
      ...reqData,
      orderId: id,
      type: 0,
      fileContent: reqData?.fileContent?.toString(),
      productVariantId: order?.orderItems?.[0]?.productVariantId,
      productId: order?.orderItems?.[0]?.url
    }).then(({ data }) => {
      const { msg } = data;
      toast(msg)
      history.pop()
    }).catch(_ => {
      toast("评价失败，请检查网络")
    })
  }

  // 错误提示语
  const errorsHtml = (register, message) =>
  ((register && message) &&
    (<p style={{ margin: '5px 0', color: '#ea4949', fontSize: '12px' }}>
      <InfoIcon style={{ color: '#ea4949', fontSize: 14, verticalAlign: '-2px', marginRight: '3px' }} />
      {message}
    </p>))

  useEffect(() => {
    setValue('commodityEvaluation', 4)
    setValue('speciality', 4)
  }, [])

  return (
    <Box style={{ background: '#fff', height: '100%' }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <StatusBox loading={loading} error={error} empty={data}>
          {order?.orderItems?.map((item, index) => (
            <React.Fragment key={index}>
              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  <img width={93} height={93} style={{ marginRight: '10px' }} alt="Remy Sharp" src={item?.thumb} />
                </ListItemAvatar>
                <ListItemText
                  primary={

                    <Box style={{ marginTop: '5px' }}>
                      <Box
                        p={2}
                        overflow='hidden'
                        height={41}
                        style={{ fontSize: '14px', lineHeight: '20px', display: '-webkit-box', margin: '10px 0', WebkitLineClamp: 2, lineClamp: 2, WebkitBoxOrient: 'vertical', overflow: 'hidden', padding: 0 }}
                      >
                        {item?.name}
                      </Box>
                      <text>
                        <Rating
                          name='rate'
                          precistion={1}
                          defaultValue={4}
                          onChange={(e, value) => {
                            setValue('commodityEvaluation', value)
                          }}
                        />
                      </text>
                    </Box>
                  }
                />
              </ListItem>
            </React.Fragment>
          ))}
        </StatusBox>
        <Box style={{ fontSize: 14, color: '#3F3F3F' }} className={classes.evidenceRow} mr={2} ml={2}>
          <TextareaAutosize
            aria-label="写下您的购物体验，帮助更多用户"
            minRows={1}
            placeholder="写下您的购物体验，帮助更多用户"
            {...register("textContent", { required: "写下您的购物体验，帮助更多用户" })}
          />
          {errorsHtml(errors?.textContent, errors?.textContent?.message)}
        </Box>

        <Box mt={2} mr={2} ml={2} mb={3} display='flex' flexDirection='column'>
          <Grid container spacing={4}>
            {urls?.map((url, index) =>
              <Grid item xs={4} key={url}>
                <Box
                  width='100px'
                  height='100px'
                  style={{ background: `url(${url}) center `, backgroundSize: 'cover' }}
                >
                  <CloseIcon
                    onClick={() => {
                      onDeleteImg(index)
                    }}
                    style={{
                      float: 'left',
                      position: 'relative',
                      top: '5px',
                      right: '-79%',
                      fontSize: '15px'
                    }}
                  />
                </Box>
              </Grid>
            )}
            {urls?.length < 3 && <Grid item xs={3} key={-1}>
              <UploadOnly size={100} onUpload={onChooseImage} inputImageRef={inputImageRef} borderRadius={0} />
            </Grid>
            }
          </Grid>
        </Box>

        <Box mr={2} ml={2}>
          <Typography style={{ fontSize: '14px' }}>
            物流评价
            <text style={{ verticalAlign: '-5px', paddingLeft: '13px' }}>
              <Rating
                name='rate'
                precistion={1}
                defaultValue={4}
                onChange={(e, value) => {
                  setValue('speciality', value)
                }}
              />
            </text>
          </Typography>

        </Box>

        <Box style={{ padding: '15px' }} className={classes.btn} ><ButtonBase type="submit" >提交</ButtonBase> </Box>
      </form>
    </Box>
  )
}