import { Box, Button, ButtonBase, Typography, TextField, makeStyles, withStyles, IconButton } from '@material-ui/core'
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos"
import { useState, useEffect } from 'react'
import { setNavigationBar, useAppHistory } from '../../utils/bridge'
import { Upload } from '../../components/base'
import ChooseListDialog from 'components/chooseListDialog'
import { getCategoryByPid, requestJoinPlatform } from 'service/goods'
import produce from "immer";
import { Close } from '@material-ui/icons'

const useStyle = makeStyles({
    root: {},
    form: {
        display: 'flex',
        flexDirection: 'column',
    },
})


const Arrow = withStyles({
    root: {
        color: '#aaa', fontSize: 18
    }
})(ArrowForwardIosIcon)

function SectionRow({ title, onClick, children }) {
    return <ButtonBase style={{
        backgroundColor: 'white',
        borderRadius: 4,
        padding: 4,
        display: 'flex',
        alignItems: 'center',
        fontSize: '15px',
        width: '100%',
        border: '#aaa solid 1px',
    }} onClick={onClick}>
        <Box p={1} flex='1' color="#777" textAlign='left'>
            {title}
        </Box>
        {children}
        <Arrow />
    </ButtonBase>
}

/**
 * 入驻平台 
 */
export const JoinPlatform = (props) => {
    const classes = useStyle()
    const history = useAppHistory()

    const [categoryList, setCategoryList] = useState([])
    const [category, setCategory] = useState(-1)
    const [showDialog, setShowDialog] = useState(false)
    const [shop, setShop] = useState({})

    const [shopVaild, setShopVaild] = useState(false)
    const [error, setError] = useState()

    const openDialog = () => {
        if (categoryList) {
            setShowDialog(true)
        }
    }
    const handleClose = (value) => {
        setShowDialog(false)
        console.log(value)
        if (value) {
            setCategory(value)
            changeShop('category', Number(value))
        }
    }
    const changeShop = (key, value) => {
        setShop(produce((draft) => {
            draft[key] = value
        }))
    }

    useEffect(() => {
        let vaild = true
        let requiredProps = ['storeLogo', 'area', 'category', 'storeName', 'storeAddress', 'storePhone']
        requiredProps.forEach(i => {
            vaild &= (i in shop)
        })
        console.log(shop)
        setShopVaild(vaild)
    }, [shop])

    useEffect(() => {
        setNavigationBar({
            title: '入驻申请'
        })
        async function loadCategory() {
            const { data: result } = await getCategoryByPid(1)
            if (result) {
                const categoryList = result.map(i => i.category)
                setCategoryList(categoryList)
            }
        }
        loadCategory()
    }, [])

    const submit = async () => {
        if (shopVaild) {
            const { data } = await requestJoinPlatform(shop)
            console.log(data)
            history.pop()
        }
    }
    const closePage = () => {
        history.pop()
    }

    return <Box bgcolor='#fafafa' height='100%' overflow='scroll' >
        <Box bgcolor='white' borderRadius='10px' p={2} m={1}>
            <Box marginTop={2} display='flex' alignItems='center' justifyContent='center'>
                <Typography style={{ margin: 'auto' }} variant='h5'>入驻申请</Typography>
                <IconButton onClick={closePage}>
                    <Close />
                </IconButton>
            </Box>
            <Box noValidate className={classes.form} autoComplete="off" >
                <Box style={{ right: '0', position: 'absolute' }}>

                </Box>
                <Box marginTop='50px' marginBottom='10px' > 店铺资料 </Box>
                <Upload onUpload={url => changeShop('storeLogo', url)}>店铺头像</Upload>
                <Box height='20px' />
                <TextField
                    size='small'
                    label='城市'
                    variant='outlined'
                    onChange={e => changeShop('area', e.target.value)}
                />

                <Box height='20px' />
                <SectionRow title="店铺分类" onClick={openDialog}>
                    {category < 0 ? '选择分类' : categoryList.find(i => i.id == category)?.name}
                </SectionRow>

                <ChooseListDialog
                    open={showDialog}
                    onClose={handleClose}
                    options={categoryList}
                    value={category}
                />
                <Box height='20px' />

                <TextField id="outlined-basic"
                    size="small"
                    label="店铺名称"
                    variant="outlined"
                    onChange={e => changeShop('storeName', e.target.value)}
                />

                <Box height='20px' />
                <TextField id="outlined-basic"
                    size="small"
                    label="店铺地址"
                    placeholder="填写完整的店铺地址"
                    variant="outlined"
                    onChange={e => changeShop('storeAddress', e.target.value)}
                />
                <Box height='20px' />
                {/* <Upload onUpload={url => console.log(url)}>营业执照</Upload> */}

                <Box marginTop='20px' marginBottom='10px' > 联系方式资料 </Box>

                <TextField id="outlined-basic"
                    size="small"
                    label="负责人"
                    variant="outlined"
                    onChange={e => changeShop('username', e.target.value)}
                />

                <Box height='20px' />
                <TextField id="outlined-basic"
                    type='number'
                    inputProps={{
                        maxLength: "11"
                    }}
                    onChange={event => {
                        const phoneNumber = event.target.value
                        const vaild = /^1[3456789]\d{9}$/.test(phoneNumber)
                        if (vaild) {
                            changeShop('storePhone', phoneNumber)
                            setError(null)
                        } else if (phoneNumber.length >= 11) {
                            setError("手机号码格式错误")
                        }
                    }}
                    size="small" label="手机号" variant="outlined" />
                <Box height='20px' />
                <Box textAlign='center' fontSize={13} color='red'>{error}</Box>
                <Button
                    variant='contained'
                    color='primary'
                    disabled={!(shopVaild)}
                    onClick={submit}>
                    提交申请
                </Button>
            </Box>
        </Box>
    </Box >
}
