
import { useMemo } from "react";
import { StatusBoxPro } from "../../components/empty"
import { getActGoodsList } from "../../service/goods";
import { useNavigationBar, useAppHistory } from '../../utils/bridge';
import { Box, ButtonBase, Grid } from '@material-ui/core'
import { Ellipsis } from "antd-mobile";

const GoodsList = (goodsList) => {
    return <Grid container spacing={1} style={{ padding: 8, }}>
        {
            goodsList?.map(item =>
                <Grid item xs={6} sm={4}> <FreeItem key={item.id} {...item} /></Grid>
            )
        }
    </Grid>
}

const FreeItem = (props) => {
    const history = useAppHistory()
    return <ButtonBase style={{ textAlign: 'left', backgroundColor: 'white', borderRadius: '8px', width: '-webkit-fill-available' }}
        onClick={() => {
            history.push(`/goods/${props.productId}`)
        }}>
        <Box display="flex" flexDirection="column" width='-webkit-fill-available' >

            <Box mt={0.5} height={180} bgcolor='#e9e9e9' display='flex' style={{
                backgroundImage: `url(${props.thumb})`,
                backgroundSize: '100% 100%',
                background: 'cover'
            }}
            >
            </Box>

            <Box display='flex' flexDirection='column'>
                <Box px={1} mt={1} style={{
                    color: "#000000",
                    fontSize: 13,
                }}>
                    <Ellipsis content={props.productName} rows={2} />
                </Box>

                <Box display='flex' alignItems='center' px={1} >
                    <text style={{ fontSize: 12, color: "#A8A8A8", marginTop: 2, textDecoration: 'line-through' }}>
                        原价:¥{props.price / 100}</text>
                </Box>

                <Box display='flex' alignItems='center' px={1} pb={1} >
                    <text style={{ fontSize: 12, color: "#F43939", marginTop: 1, }}>
                        现价:¥</text>
                    <text style={{ fontSize: 16, marginLeft: 4, color: "#F43939", marginTop: 2, fontWeight: 'bold' }}>
                        {props.actPrice / 100}</text>
                    <Box flex={1} />
                    <Box bgcolor='#5Ba0f4' color="white" display='flex' fontSize={11}
                        height='20px' ml={1} mt={1} textAlign='center' alignItems='center'
                        px={1} borderRadius={8} >
                        去囤货
                    </Box>
                </Box>

            </Box>
        </Box>
    </ButtonBase >
}
export default () => {
    useNavigationBar({ title: '囤货' })

    const page = useMemo(() => goods => GoodsList(goods), [])
    return <StatusBoxPro
        request={() => getActGoodsList(0, 2)}
        component={page}
    />
}