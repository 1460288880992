import { Box, ButtonBase, Badge, makeStyles, LinearProgress } from '@material-ui/core'
import qs from 'qs'
import { useState } from 'react'
import { OrderItem } from './orderList'
import { loadOrders } from '../service/goods';
import { useNavigationBar, useAppHistory } from '../utils/bridge';
import { useRequest } from 'ahooks';

const useStyles = makeStyles((theme) => ({
    checkedCategory: {
        height: 30,
        width: 100,
        color: 'white',
        backgroundColor: '#FFBE33',
        boxShadow: '1px 1px 5px 2px #FB35',
        borderRadius: '6px',
        border: 'none',
        zIndex: 2,

        "&:hover": {
            backgroundColor: '#FFBE33',
        },
    },
    normalCategory: {
        height: 30,
        width: 100,
        color: '#333',
        border: 'none',
        backgroundColor: '#eeeeee'
    },

    checkText: {
        color: '#333',
        fontWeight: '600',
        fontSize: '14',
    },
    normalText: {
        color: '#999',
        fontWeight: 'nomal',
        fontSize: '14'
    },
    iconButton: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        minWidth: 60,

    }
}));

const IconButton = ({ title, image, count, type, onSetType, checked, ...props }) => {
    const classes = useStyles()
    const onClick = () => {
        onSetType(type)
    }
    const opacity = checked ? 1 : 0.5
    const textStyle = checked ? classes.checkText : classes.normalText
    return (
        <Box  {...props}>
            <ButtonBase className={classes.iconButton} disableRipple onClick={onClick}  >
                <Badge badgeContent={count} color='secondary'>
                    <img src={image} style={{ width: 25, height: 25, marginTop: 4, opacity: opacity }} />
                </Badge>
                <Box className={textStyle} my={1} minWidth={40} >{title}</Box>
                {
                    checked ?
                        <Box width={20} height={2} bgcolor="#FFBE33" borderRadius={2} ></Box> : null
                }
            </ButtonBase>
        </Box>
    )
}

export const state_icons = [
    'https://202108251114.oss-cn-shanghai.aliyuncs.com/app/ic_all_order.png',
    'https://202108251114.oss-cn-shanghai.aliyuncs.com/app/ic_prepare_pay.png',
    'https://202108251114.oss-cn-shanghai.aliyuncs.com/app/ic_prepare_shipping.png',
    'https://202108251114.oss-cn-shanghai.aliyuncs.com/app/ic_shipping.png',
    'https://202108251114.oss-cn-shanghai.aliyuncs.com/app/ic_prepare_commend.png',
    'https://202108251114.oss-cn-shanghai.aliyuncs.com/app/ic_after_sales.png',
]

const state_data = [
    {}, // 全部
    { state: 0 }, // 待付款
    { shipped: 2, state: 1, shippingMethod: 2, pick: true }, // 待自提
    { shipped: 10, state: 1, shippingMethod: 1 }, // 待收货
    { state: 56 }, // 待评价
]

export const MyOrderPage = ({ location }) => {
    const query = qs.parse(location.search, { ignoreQueryPrefix: true })
    const [type, setType] = useState(Number(query.type ?? 0))
    const history = useAppHistory()
    useNavigationBar({
        title: '我的订单'
    })
    const { data: orderList, loading, error } = useRequest(() => loadOrders(state_data[type], 0), {
        formatResult: res => res.data,
        refreshDeps: [type]
    })

    const onSetType = (t) => setType(t)
    return (<Box height='100%' overflow="scroll">
        <Box display='flex' flexDirection='row' bgcolor='white' position='sticky' zIndex={9}
            padding={2} alignItems='center' overflow="scroll" boxShadow='0 0 5px 5px #9991'>
            <IconButton title="全部" type={0} checked={type == 0} image={state_icons[0]} onSetType={onSetType} />
            <IconButton title="待付款" type={1} checked={type == 1} image={state_icons[1]} onSetType={onSetType} />
            <IconButton title="待自提" type={2} checked={type == 2} image={state_icons[2]} onSetType={onSetType} />
            <IconButton title="待收货" type={3} checked={type == 3} image={state_icons[3]} onSetType={onSetType} />
            <IconButton title="购推荐" type={4} checked={type == 4} image={state_icons[4]} onSetType={onSetType} />
            <IconButton title="售后" type={5} checked={type == 5} image={state_icons[5]} onSetType={onSetType}
                onClick={() => history.push('/after_sale_list')} />
        </Box>
        <Box>
            {
                loading && <LinearProgress />
            }
            {
                error && <Box p={2}>加载遇到问题</Box>
            }
            {
                orderList?.map(order => <OrderItem key={order.id} {...order} />)
            }
        </Box>
        <Box height={32} />
    </Box>)
}