import { useNavigationBar, chooseObject } from '../utils/bridge';
import { loadOrders } from '../service/goods';
import { Box, Divider } from '@material-ui/core'
import { useCallback } from 'react'
import { SimpleOrderItem, orderStateText } from './orderList'
import { StatusBoxPro } from '../components/empty';
import dateformat from 'dateformat'

const msgBody = (order) => {
    const { orderItems, store } = order
    const goods = orderItems[0]
    const content = {
        showPlayer: false,
        image: goods.thumb,
        title: goods.name,
        subTitle: dateformat(new Date(order.date), 'mm-dd HH:MM'),
        tag: orderStateText(order),
        price: goods.price / 100,
        source: "订单：" + order.id
    }
    const json = {
        id: goods.id,
        path: '/order/' + order.id
    }
    return {
        type: '1',
        content: JSON.stringify(content),
        jsonType: '1',
        json: JSON.stringify(json),
        title: "我的订单"
    }
}

const ChooseOrderPage = () => {
    useNavigationBar({ 'title': "选择订单" })
    const orderList = useCallback((result) => <Box bgcolor='white'>
        <Box height="16px" bgcolor='#eee' />
        {
            result?.map(order => <Box key={order.id}>
                <SimpleOrderItem  {...order}
                    onClick={() => chooseObject('goods', msgBody(order))} />
                <Divider />
            </Box>)
        }
    </Box>, [])

    return <Box height='100%' overflow='scroll'>
        <StatusBoxPro
            request={async () => loadOrders({}, 0, false)}
            emptyTitle="还没有任何订单啦"
            errorTitle="网络错误，请重试"
            component={orderList}
        />
    </Box>
}

export default ChooseOrderPage