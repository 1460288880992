import React from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

/**
 * 输入文本的对话框
 * @param {*} title 对话框标题
 * @param {*} hint 占位符提示
 * @param {*} multiLine 是否是多行文本输入 true or false
 * @returns dialog
 */
const InputTextDialog = ({ open, title, hint, onFinish, onCancel, multiLine }) => {
    const [text, setText] = React.useState()
    const handleChange = (e) => {
        setText(e.target.value);
    }
    return <Dialog open={open} maxWidth='xl' fullWidth={true} onClose={onCancel}>
        <DialogTitle style={{ textAlign: 'center' }}>{title}</DialogTitle>
        <DialogContent>
            <Box minWidth='240px'>
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    multiline={multiLine}
                    rows={multiLine ? 4 : 1}
                    placeholder={hint}
                    variant="outlined"
                    type="text"
                    onChange={handleChange}
                    value={text}
                    fullWidth
                />
            </Box>
        </DialogContent>
        <DialogActions>
            <Button onClick={onCancel} color="primary">
                取消
            </Button>
            <Button onClick={() => onFinish(text)} color="primary">
                确定
            </Button>
        </DialogActions>
    </Dialog>
}

export default InputTextDialog;