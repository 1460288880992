import React, { useEffect, useState } from "react"
import { Box, Button, ButtonBase, makeStyles, Grid } from "@material-ui/core"
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'

import { getCategoryByPid } from 'service/goods'
import { setNavigationBar } from 'utils/bridge'
import {getGoodsList} from "../../service/goods";
import {useRequest} from "ahooks";
import {useAppHistory} from "../../utils/bridge";


const useStyles = makeStyles(theme => ({
    tabs: {
        minWidth: '8rem',
        width: '8rem',
        height: '100%',
        maxHeight: '100%',
        overflow: 'scroll',
        backgroundColor: 'white',
    },
    indicator: {
        left: 0,
        right: 'auto',
    },
    selected: {
        color: '#2584F7',
        background: theme.palette.grey[100],
    },
    categoryGroup: {
        overflow: 'scroll',
        flexGrow: '1',
        padding: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        alignContent: 'start'
    },
    gridContainer: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        width: '100%',
        flexGrow: '1',
    },
    textButton: {
        margin: '4px',
        backgroundColor: '#fff',
        padding: '5px 10px',
        minWidth: '60px',
        borderRadius: '4px',
    }
}))


const MyTab = (props) => {
    const classes = useStyles()
    return <Tab
        classes={{
            root: classes.tabs,
            selected: classes.selected,
        }}
        {...props} />
}

const ServiceCategoryPage = (props) => {
    const [tabIndex, setTabIndex] = useState(1)
    const classes = useStyles()
    const [categoryList, setCategories] = useState([])
    const [secondList, setSecondList] = useState([])

    setNavigationBar({
        title: '分类',
    })
    useEffect(() => {
        async function loadData() {
            const { data } = await getCategoryByPid(11)
            if (data) {
                setCategories(data)
                const first = data[0].category.id
                setTabIndex(first)
            }
        }
        loadData()
    }, [])

    useEffect(() => {
        async function loadData() {
            if (tabIndex == 1){
                return
            }
            const { data } = await getCategoryByPid(tabIndex)
            if (data) {
                console.log('2级分类', data)
                setSecondList(data)
            }
        }
        loadData()
    }, [tabIndex])
    const onTabChange = (_, v) => {
        console.log(v)
        setTabIndex(v)
    }
    return (<Box display='flex' overflow='hidden' flexDirection='row' height={1} width={1} flex='1'>
        <Tabs
            value={tabIndex}
            orientation='vertical'
            variant='scrollable'
            onChange={onTabChange}
            classes={{
                root: classes.tabs,
                indicator: classes.indicator
            }}
        >
            {
                categoryList?.map(({ category }) => (
                    <MyTab label={category.name} value={category.id} key={category.id} />
                ))
            }
        </Tabs>
        <Box className={classes.categoryGroup}>
            {
                secondList?.map(({ category }, index) => {
                    const textColor = colorList[index % 5]
                    return <CategoryGroup category={category} textColor={textColor} key={category.id} />
                })
            }
        </Box>
    </Box>)
}

const colorList = ['#FF8E49', '#2584F7', 'rgb(65 218 148)', '#F4CA4C', '#F44B34', '#AF25F7']

const CategoryGroup = ({ category, textColor }) => {
    const [items, setItems] = useState([])
    const classes = useStyles()
    const history = useAppHistory()
    const [id, setId] = useState([])

    useEffect(async () => {
        const { data } = await getCategoryByPid(category.id)
        if (data) {
            console.log('3级分类', data)
            setItems(data)
        }
    }, [])

    const getProduct = async (id)=>{
        history.push( '/service/list/'+id )
    }
    console.log('items',items)
    return <Box width={1}>
        <Box m={1} fontSize={14} fontWeight={500}>{category.name}</Box>
        <Box className={classes.gridContainer}>
            {items?.map(({ category: item, }, index) => {
                return <ButtonBase variant='contained' onClick={()=> getProduct(item.id)}
                    style={{ color: textColor }}
                    className={classes.textButton}>
                    {item.name}
                </ButtonBase>
            })
                // <ImageButton
                //     key={item.id}
                //     name={item.name}
                //     icon={'https://202108251114.oss-cn-shanghai.aliyuncs.com/app/ic_chat_group.png'}
                //     size={44} style={{ width: '33%', height: 80 }}
                // />
            }
        </Box>
        <Box height={30} />

    </Box>
}
export default ServiceCategoryPage