import { makeStyles } from "@material-ui/core"
import { setNavigationBar } from "../utils/bridge"
import Box from '@material-ui/core/Box'
import Badge from '@material-ui/core/Badge'
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import React from 'react'

const useStyles = makeStyles((theme) => ({
    card: {
        margin: theme.spacing(1),
        background: 'white',
        padding: '12px',
        borderRadius: '4px',
    }
}))

const TextButton = ({ title, count }) => {
    return (
        <Box display='flex' flexDirection='column' alignItems='center'>
            <Box fontSize={20}>{count}</Box>
            <Box color='#555' my={1} fontSize={14}>{title}</Box>
        </Box>
    )
}

const IconButton = ({ title, image, count }) => {
    return (
        <Box display='flex' flexDirection='column' alignItems='center'>
            <Badge badgeContent={count} color='secondary'>
                <ShoppingCartIcon />
            </Badge>
            <Box color='#555' my={1} fontSize={14}>{title}</Box>
        </Box>
    )
}
const MerchantPage = () => {

    const classes = useStyles()

    React.useEffect(() => {
        setNavigationBar({
            bgColor: '#FFFFFF',
            title: '商铺管理',
            darkBar: false,
        })
    }, [])
    return <Box paddingTop={1}>
        <Box className={classes.card} >
            <Box>订单管理</Box>
            <Box display='flex' flexDirection='row' justifyContent='space-around' mt={4}>
                <IconButton title="待付款" count="1" image="" />
                <IconButton title="待发货" count="4" image="" />
                <IconButton title="待收获" count="6" image="" />
                <IconButton title="购推荐" count="11" image="" />
                <IconButton title="售后" count={null} image="" />
            </Box>
        </Box>

        <Box className={classes.card}>
            <Box>商铺概况</Box>
            <Box display='flex' flexDirection='row' justifyContent='space-around' mt={4}>
                <TextButton title="在线商铺" count="1" />
                <TextButton title="关注店铺" count="4" />
                <TextButton title="收藏商铺" count="6" />
                <TextButton title="入仓" count="11" />
            </Box>
        </Box>
        <Box className={classes.card}>
            <Box>资金管理</Box>
            <Box display='flex' flexDirection='row' justifyContent='space-around' mt={4}>
                <TextButton title="总收入" count="1" image="" />
                <TextButton title="待收金" count="4" image="" />
                <TextButton title="待付金" count="6" image="" />
            </Box>
        </Box>

        <Box className={classes.card}>
            <Box>认证信息</Box>
        </Box>
        <Box className={classes.card}>
            <Box>常用功能</Box>
            <Box display='flex' flexDirection='row' justifyContent='space-around' mt={4}>
                <IconButton title="发布商品" count={null} image="" />
                <IconButton title="营销直通车" count="免费" image="" />
                <IconButton title="店员管理" count={null} image="" />
            </Box>
        </Box>
    </Box>
}

export default MerchantPage