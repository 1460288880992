import React, { useEffect } from 'react';
import {
    Box, Typography, FormControl, InputLabel,
    Input, InputAdornment, TextField, Button
} from '@material-ui/core';
import * as Actions from '../../models/account';
import { connect } from 'react-redux';
import { useToast } from '../../models/toast';

import { useAppHistory } from '../../utils/bridge';

const ChangeUserPage = ({ usernameState, myself, changeUserName, reset, getUserInfo }) => {
    const toast = useToast()

    const history = useAppHistory()

    const [values, setValues] = React.useState({
        username: myself?.grid,
        error: false,
    });

    if (usernameState === 2) {
        toast.showToast('toast.error', '设置用户名失败')
    } else if (usernameState === 1) {
        toast.showToast('toast.success', "修改成功")
        if ('pop' in history) {
            history.pop()
        }
    }
    useEffect(() => {
        getUserInfo()
        reset()
    }, [])

    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    const clickSetUserName = () => {
        let error = !values.username.match(/^\w{6,18}$/)
        if (error) {
            toast.showToast('toast.error', "用户名格式错误")
            setValues({ ...values, error: true })
            return
        }
        changeUserName(values.username)
    }

    return (
        <Box p={2}>
            <Typography variant='h3' component='h3'>修改用户名</Typography>
            <Box m={5}></Box>

            <FormControl fullWidth >
                <InputLabel htmlFor="standard-adornment-amount">用户名</InputLabel>
                <Input
                    id="standard-adornment-amount"
                    value={values.username}
                    error={values.error}
                    onChange={handleChange('username')}
                />
            </FormControl>

            <Box mt={2}>用户名每年只能修改一次，用户名由6-18位的数字字母组成</Box>
            <Box mt={14}>
                <Button disableElevation variant='contained'
                    onClick={clickSetUserName}
                    color='primary' fullWidth >
                    确认
                </Button>
            </Box>
        </Box>)
}

const propsMap = ({ account }) => (account)

export default connect(propsMap, Actions)(ChangeUserPage)