import React, { useMemo } from 'react'
import { Box, Grid, Avatar, makeStyles } from '@material-ui/core'
import { getCommentDetail } from 'service/goods'
import { priceCast } from 'utils/price'
import { StarScore } from 'components/base'
import { setNavigationBar } from '../../utils/bridge'
import { useRequest } from 'ahooks'
import { StatusBox } from '../../components/empty'

const useStyle = makeStyles({
    goodsCard: {
        borderRadius: 8,
        width: '100%',
        boxShadow: '#eee',
        marginBottom: 16,
    },
    media: {
        height: 200,
        borderRadius: 8,
        width: '100%',
        objectFit: 'cover',
    }
})

const CommentDetailPage = ({ match }) => {
    const classes = useStyle()
    const { id } = match.params

    setNavigationBar({ title: '购推荐' })

    const { loading, error, data: detail } = useRequest(id, {
        requestMethod: () => getCommentDetail(id),
        formatResult: resp => resp.data
    })

    const score = useMemo(() => detail ? (Math.round((detail.evaluate + detail.efficiency + detail.speciality) / 3)) : 0, [detail])

    return <StatusBox loading={loading} error={error} >
        <Box padding={2} bgcolor='#fafafa'>
            <Box display='flex' bgcolor='white' p={1} className={classes.goodsCard} >
                <img src={detail?.image} style={{ width: '100px', height: '100px', borderRadius: '8px' }} />
                <Box width='12px' />
                <Box color='#444'>
                    {detail ? detail.name : ''}
                    <div className='price'>
                        {priceCast(detail?.price)}
                    </div>
                </Box>
            </Box>
        <Grid container spacing={1}>
            {
                detail?.fileContent?.split(',')?.map(it =>
                    <Grid item xs={6}>
                        <Avatar className={classes.media} src={it} />
                    </Grid>
                )
            }

        </Grid>
        <Box my={2}>{detail?.textContent}</Box>

        <Box color='#666'>
            <Box display='flex'>服务态度:<Box flex='1' /><StarScore num={detail?.evaluate} /></Box>
            <Box display='flex'>服务完成事件：<Box flex='1' /><StarScore num={detail?.efficiency} /></Box>
            <Box display='flex'>服务专业性: <Box flex='1' /><StarScore num={detail?.speciality} /></Box>
            <Box display='flex'>购推荐星级: <Box flex='1' /><StarScore num={score} /></Box>
        </Box>



        </Box>
    </StatusBox>
}

export default CommentDetailPage