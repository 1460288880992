import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import { ButtonBase } from '@material-ui/core';
import { useAppHistory } from '../utils/bridge';
import { getUserInfo } from '../service/goods';
import MoreHoriz from "@material-ui/icons/MoreHoriz";
import { ReportMenuList } from '../jwl/moment/report';

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: '100%',
        minWidth: '40%',
    },
    media: {
        height: 0,
        paddingTop: '100.25%', // 16:9
    },
    padding8: {
        padding: '4px 8px',
        marginTop: 0,
        alignSelf: 'auto',
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    textContent: {
        lineHeight: '1rem',
        maxHeight: '1rem',
        overflow: 'hidden',
        minHeight: '1rem',
        margin: theme.spacing(0.5),
    },
    avatar: {
        backgroundColor: red[400],
        width: 28,
        height: 28
    },
    iconButton: {
        fontSize: '1rem',
        padding: '4px 8px',
    },
    carAction: {
        margin: 0
    },
    cardAvatar: {
        marginRight: 6,
    }
}));

export default function RecipeReviewCard({ id, udId, textContent, fileContent }) {
    const classes = useStyles();
    const [user, setUser] = React.useState()
    const img = fileContent?.split(',')[0]

    const history = useAppHistory()

    useEffect(async () => {
        const { data } = await getUserInfo(udId)
        setUser(data)
    }, [])

    const [openMenu, setOpenMenu] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null)

    const showMenu = (e) => {
        setOpenMenu(true)
        setAnchorEl(e.currentTarget);
        e.stopPropagation()
    }

    return (
        <Card className={classes.root} >
            <CardMedia
                className={classes.media}
                image={img}
                title="Paella dish"
                onClick={() => {
                    history.push(`/share/${id}`)
                }}
            />
            <CardContent classes={{
                root: classes.padding8
            }}>
                <Typography variant="body2" className={classes.textContent} color="textSecondary" component="p">
                    {textContent}
                </Typography>
            </CardContent>
            <CardHeader
                classes={{
                    root: classes.padding8,
                    action: classes.carAction,
                    avatar: classes.cardAvatar,
                }}
                avatar={
                    <ButtonBase >
                        <Avatar aria-label="recipe" className={classes.avatar} src={user?.firstImg}>
                            匿
                    </Avatar>
                    </ButtonBase>
                }
                action={
                    <IconButton aria-label="star"
                        onClick={showMenu}
                        className={classes.iconButton}>
                        <MoreHoriz />
                    </IconButton>
                }
                title={user?.nickname ?? "匿名用户"}
            />
            <ReportMenuList
                type="2"
                user={udId}
                id={id}
                open={openMenu}
                anchorEl={anchorEl}
                handleClose={() => setOpenMenu(false)}
            />
        </Card>
    );
}