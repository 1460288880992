import { Box } from '@material-ui/core'
import { useRequest } from 'ahooks'
import { List, SearchBar, Popup } from 'antd-mobile'
import { useState } from 'react'

export default function InputAddress({ visible, onClose, onChoose }) {
    const [keyword, setKeyword] = useState()
    const { data, loading, error, run } = useRequest(`https://restapi.amap.com/v3/assistant/inputtips?city=320800&keywords=${keyword ?? "小区"}&key=6f1ce1cfed6d716bafb30e5f1615030c`, {
        refreshDeps: [keyword],
    })

    const searchLocation = (keyword) => {
        setKeyword(keyword)
    }


    return <Popup
        visible={visible}
        onMaskClick={onClose}
        bodyStyle={{ height: '80vh' }}
    >
        <Box p={2}>
            <SearchBar
                onSearch={searchLocation}
                placeholder="输入地点名称查询" />
            <Box height={14} />
            <List visible={data?.status}>
                {
                    data?.tips?.map(item => {
                        return <List.Item key={item.id}
                            onClick={() => onChoose(item)}
                            description={item.district} clickable>
                            {item.name}
                        </List.Item>
                    })
                }
            </List>
        </Box>
    </Popup>
}