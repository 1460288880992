import { useEffect, useMemo, useState } from "react"
import { Box, Fab, makeStyles, LinearProgress, IconButton } from "@material-ui/core"
import { useAppHistory, useNavigationBar } from 'utils/bridge'
import { getShopManageCoupons, deleteCoupon } from 'service/goods'
import { StatusBoxPro } from "components/empty"
import { Add, Delete } from "@material-ui/icons"
import dateformat from 'dateformat'
import produce from "immer"
import { loadMyself } from "models/account"
import { useDispatch } from "react-redux"
import { useAccount } from "utils/hooks"

const useStyles = makeStyles(theme => ({
    couponRoot: {
        height: theme.spacing(15),
        backgroundColor: 'white',
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        borderRadius: '8px',
        display: 'flex',
        flexDirection: 'row',
    },
    couponKey: {
        height: '100%',
        width: theme.spacing(15),
        backgroundColor: theme.palette.primary.main,
        borderRadius: '8px 0 0 8px',
        color: 'white',
        fontWeight: 700,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        '&::before': {
            width: '20px',
            height: '20px',
            content: '""',
            display: 'block',
            position: 'fixed',
            background: '#eee',
            left: '5px',
            borderRadius: '10px'
        },
    },
    couponTitle: {
        color: '#222',
        '&::after': {
            height: '20px',
            content: '""',
            display: 'block',
        }
    },
    tag: {
        fontSize: 10,
        backgroundColor: theme.palette.primary.main,
        borderRadius: 9,
        lineHeight: '16px',
        display: 'inline',
        color: 'white',
        marginTop: '10px',
        padding: '2px 8px',
    },
}))
const CheckedIcon = () => {
    const style = {
        width: 40,
        height: 40,
        padding: '8px'
    }
    return <img style={style} src='https://202108251114.oss-cn-shanghai.aliyuncs.com/html/img/check.svg' />
}

function getTypePrice(type, price, discountPrecent) {
    if (type === 0) {
        return (<div><Box component='span' fontSize={38}>{discountPrecent / 10}</Box>折</div>)
    } else if (type === 1) {
        return (<div>¥<Box component='span' fontSize={38}>{price / 100}</Box></div>)
    } else {
        return (<div>¥<Box component='span' fontSize={38}>{price / 100}</Box></div>)
    }
}

function getTypeText(type) {
    switch (type) {
        case 0: return "折扣券"
        case 1: return "红包"
        case 2: return "满减券"
        default: return "优惠券"
    }
}

const CouponItem = ({ coupon, onDelete }) => {
    const classes = useStyles()
    const {
        type: discountType,
        title: discountTitle,
        start: startTime = 0,
        end: expire = 0,
        state,
        percent: discountPercent,
        metPrice,
        reducePrice } = coupon

    return <div className={classes.couponRoot} >
        <div className={classes.couponKey}>
            {getTypePrice(discountType, reducePrice, discountPercent)}
            <Box hidden={!metPrice}>
                满{metPrice / 100}
            </Box>
        </div>
        <Box p={2}>
            <Box className={classes.couponTitle}>
                {discountTitle}
            </Box>
            <div className={classes.tag}>{getTypeText(discountType)}</div>
            <Box mt='10px' color='#888' fontSize={14}>
                {dateformat(new Date(startTime), 'yyyy.mm.dd')}-
                {dateformat(new Date(expire), 'yyyy.mm.dd')}
            </Box>
        </Box>
        <Box flex='1' />
        <Box display='flex' alignItems='center' p={2}>
            <IconButton onClick={onDelete}>
                <Delete />
            </IconButton>
        </Box>
    </div>
}

export default function CouponManager(props) {
    const [list, setList] = useState([])
    const history = useAppHistory()
    useNavigationBar({ title: "优惠券管理" })
    const dispatch = useDispatch()
    const { mall } = useAccount()
    const [refresh, setRefresh] = useState(0)
    const store = mall?.id

    useEffect(async () => {
        if (!store) {
            loadMyself(dispatch)
        }
    }, [])


    const onDeleteCoupon = async (item) => {
        if (store) {
            const response = await deleteCoupon(store, item.id)
            console.log(response)
            if (response.code === "200") {
                const index = list.indexOf(item)
                setList(produce(draft => {
                    draft.splice(index, 1)
                }))
            }
        }
        setRefresh(refresh + 1)
    }
    const component = useMemo(() => (list) =>
        list.map(item => <CouponItem
            key={item.id}
            coupon={item}
            onDelete={() => onDeleteCoupon(item)} />), [])

    if (!store) {
        return <LinearProgress />
    }

    return <Box p={2} height='100%'>
        <StatusBoxPro
            cacheKey="shop_coupons"
            request={getShopManageCoupons(store)}
            emptyTitle="还没创建优惠券，立即创建吧。"
            options={{ refreshDeps: [refresh] }}
            component={component}>
        </StatusBoxPro>

        <Fab color='primary'
            onClick={() => history.push('/b2c/workbench/create_coupons')}
            style={{ position: 'fixed', bottom: 26, right: 26 }}>
            <Add />
        </Fab>
    </Box>
}