import { Box, Chip, LinearProgress, Grid, Button, TextField, makeStyles } from "@material-ui/core"
import { setNavigationBar } from "../utils/bridge"
import React from 'react'

import NumberFormat from 'react-number-format';
import { RadioChip } from "../components/base"
import { connect } from "react-redux"

import { loadCategoryAction } from '../models/category'

const ChipX = (props) => {
    return <RadioChip {...props} />
}

const InputPrice = ({ inputRef, onChange, ...other }) => {
    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: other.namea,
                        value: values.value
                    },
                })
            }}
            isNumbericString
            prefix={'¥'}
        />
    )
}
const useStyle = makeStyles({
    text_field_root: {
        width: '100px',
        "& .MuiInputBase-root": {
            borderRadius: 20,
        }
    }
})
const InputPriceField = (props) => {
    const classes = useStyle()
    return (
        <TextField id="outlined-basic"
            variant="outlined"
            classes={{ root: classes.text_field_root }}
            size='small'
            InputProps={{
                inputComponent: InputPrice,
            }}
            {...props}
        />
    )
}

const FilterPage = (props) => {
    React.useEffect(() => {
        setNavigationBar({
            bgColor: '#FFFFFF',
            title: '智优选',
            darkBar: false,
        })
    }, [])
    const categroyList = props.data
    //const { data, loading, error } = useFetch('/mall/category')

    React.useEffect(() => {
        if (categroyList.length === 0) {
            props.loadCategoryAction()
        }
    })
    const [expand, setExpand] = React.useState(false)

    const [category, setCategory] = React.useState(0)
    const [minPrice, setMinPrice] = React.useState()
    const [maxPrice, setMaxPrice] = React.useState()
    const [type, setType] = React.useState(null)
    const [shopType, setShopType] = React.useState(null)

    const onClickExpand = () => {
        // 点击查看全部分类
        setExpand(!expand)
    }

    const gridStyle = expand ? { maxHeight: '30rem', overflow: 'scroll' } : { maxHeight: '96px', overflow: 'hidden' }
    const buttonLabel = expand ? "收起" : "全部"

    if (!categroyList) {
        return <LinearProgress />
    }

    //debugger
    const subcategoryList = categroyList.flatMap(item => item.categoryList)
    const onChangeCategory = (v) => {
        setCategory(v)
    }
    const handleMinPriceChange = ({ target }) => {
        console.log(target)
        setMinPrice(target.value)
    }
    const handleMaxPriceChange = ({ target }) => {
        console.log(target)
        setMaxPrice(target.value)
    }
    const onConfirm = () => {

    }
    return (<Box p={2}>
        <Box mb={2}>产品分类
        <Button style={{ float: 'right' }} onClick={onClickExpand}>{buttonLabel}</Button>
        </Box>
        <Grid container spacing={1} style={gridStyle}>
            {
                subcategoryList.map(item => (<Grid item key={item.id}><ChipX checked={category.id === item.id} value={item} label={item.name} onChange={onChangeCategory} /></Grid>))
            }
        </Grid>
        <Box my={2}>价格区间(元)</Box>
        <form noValidate autoComplete="off">
            <InputPriceField label="最低价格" value={minPrice} onChange={handleMinPriceChange} />
            <Box component='span' lineHeight="2em" padding={1}>-</Box>
            <InputPriceField label="最高价格" value={maxPrice} onChange={handleMaxPriceChange} />
        </form>
        <Box my={2}>商品类型</Box>
        <Grid container spacing={1} style={gridStyle}>
            <Grid item ><ChipX checked={type === 1} value={1} label="限时团" onChange={(v) => setType(v)} /></Grid>
            <Grid item ><ChipX checked={type === 2} value={2} label="新品" onChange={(v) => setType(v)} /></Grid>
            <Grid item ><ChipX checked={type === 3} value={3} label="定制" onChange={(v) => setType(v)} /></Grid>
        </Grid>
        <Box my={2}>店铺</Box>

        <Grid container spacing={1} style={gridStyle}>
            <Grid item ><ChipX checked={shopType === 1} value={1} label="买过的店" onChange={(v) => setShopType(v)} /></Grid>
            <Grid item ><ChipX checked={shopType === 2} value={2} label="收藏的店铺" onChange={(v) => setShopType(v)} /></Grid>
            <Grid item ><ChipX checked={shopType === 3} value={3} label="线下店" onChange={(v) => setShopType(v)} /></Grid>
            <Grid item ><ChipX checked={shopType === 4} value={4} label="到店自取" onChange={(v) => setShopType(v)} /></Grid>
            <Grid item ><ChipX checked={shopType === 5} value={5} label="工厂" onChange={(v) => setShopType(v)} /></Grid>
            <Grid item ><ChipX checked={shopType === 6} value={6} label="女装" onChange={(v) => setShopType(v)} /></Grid>
        </Grid>

        <Box my={2}>
            <Button variant='contained' color="secondary" onClick={onConfirm}>确认</Button>
            <Box m={1} component='span' />
            <Button variant='contained' color="normal">取消</Button>
        </Box>
    </Box>)
}


export default connect(({ category }) => category, { loadCategoryAction })(FilterPage)