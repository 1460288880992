import { Box, LinearProgress, CircularProgress } from '@material-ui/core'
import Lottie from 'lottie-react'
import { useRequest } from 'ahooks'
import emptyAnim from '../img/empty.json'
import errorAnim from '../img/error.json'

export const Empty = ({ title }) => (
    <Box display='flex' minHeight={300} flexDirection='column' justifyContent='center' alignItems='center'>
    <Lottie animationData={emptyAnim}></Lottie>
        <Box m={2} color='#aaa'> {title ?? "当前数据为空"} </Box>
</Box>)

export const Error = ({ title, }) => (
    <Box display='flex' height={1} flexDirection='column' justifyContent='center' alignItems='center'>
        <Lottie animationData={errorAnim}></Lottie>
        <Box m={2} color='#aaa'> {title ?? "加载出错"} </Box>
    </Box>
)

export const StatusBox = ({ loading, empty = false, emptyTitle, error, errorTitle, ...other }) => {
    if (loading) {
        // return <Skeleton animation="wave" />
        return <LinearProgress />
    }
    if ((Array.isArray(empty) && empty.length === 0)) {
        return <Empty title={emptyTitle} />
    }
    if (empty === true) {
        return <Empty title={emptyTitle} />
    }
    if (error) {
        return <Error title={errorTitle} />
    }
    return (<Box> {other.children}  </Box>)
}


export const StatusBoxPro = ({ cacheKey, request, component, emptyTitle, errorTitle, options }) => {
    const { data, error, loading } = useRequest(() => request(), {
        formatResult: resp => resp.data,
        cacheKey: cacheKey,
        throwOnError: true,
        ...options
    })
    if (loading) {
        return <LinearProgress />
    }
    if ((Array.isArray(data) && data.length === 0)) {
        return <Empty title={emptyTitle} />
    }
    if (error) {
        return <Error title={errorTitle} />
    }
    return (<Box> {component(data)}  </Box>)

}