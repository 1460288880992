
import React, { useState, useEffect } from 'react';
import { makeStyles, Box, ButtonBase, Grid } from '@material-ui/core'
import InfoIcon from '@material-ui/icons/Info'
import { useAppHistory, useNavigationBar } from "utils/bridge";
import { Upload } from '../../components/base';
import { useForm } from "react-hook-form";
import { businessLicense, businessLicenseModify, licenseList } from './interface.ts';
import { useToast } from "../../models/toast";
import qs from 'qs'

const useStyles = makeStyles({
  titleIcon: {
    width: '6px',
    height: '20px',
    verticalAlign: '-3px',
    marginRight: 5,
    display: 'inline-block',
    background: 'linear-gradient(0deg, #FDAE33, #FDC233)'
  },
  input: {
    border: 'none',
    outlineStyle: 'none',
    textAlign: 'right',
    fontSize: '14px',
    minWidth: '280px',
    background: '#F8F8F8'
  },
  uploadBtn: {
    background: '#FDBA33',
    color: '#fff',
    fontSize: '14px',
    borderRadius: '5px',
    padding: '5px 90px',
    margin: '10px'
  },
  btn: {
    position: 'fixed',
    bottom: '25px',
    width: '100%',
    maxWidth: '840px',
    "& button": {
      fontSize: 20,
      color: 'white',
      width: '100%',
      padding: 8,
      background: 'linear-gradient(90deg, #FDAE33, #FDC233)',
      borderRadius: '100px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    }
  },
})

export default function MsanageAuthentication(props) {
  const query = qs.parse(window.location.search, { ignoreQueryPrefix: true })
  const { status } = query //  status: null 去认证, false 去修改， true 去查看
  const toast = useToast()
  const [list, setList] = useState({})
  const [detail, setDetail] = useState({})
  const [statusValue, setStatusValue] = useState(`${status}`)
  const history = useAppHistory()
  const classes = useStyles()
  const { register, setValue, handleSubmit, formState: { errors }, clearErrors } = useForm()
  const onSubmitLicense = async reqData => {
    const { data } =
      statusValue === 'false' //  status: null 去认证, false 去修改， true 去查看
        ? await businessLicenseModify({ ...reqData, type: 1, udid: list?.ud_id, id: list?.id })
        : await businessLicense({ ...reqData, type: 1, udid: list?.ud_id, id: list?.id })
    if (data) {
      toast.showToast("toast.success", "操作成功")
      history.pop()
    }
  };
  // 获取管理员身份证初始数据
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    const { data } = await licenseList(0, 1) // 管理员身份证初始数据
    if (data) {
      setList(data?.[0] || {})
      setDetail(JSON.parse(data?.[0]?.detail))
      const { adminFront, adminBack } = JSON.parse(data?.[0]?.detail)
      // 默认值初始化赋值
      setValue('adminFront', adminFront)
      setValue('adminBack', adminBack)
      setStatusValue(`${data?.[0]?.state}`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // 错误提示语
  const errorsHtml = (register, message) =>
    ((register && message) &&
      (<p style={{ textAlign: 'right', marginRight: '20px', color: '#ea4949', fontSize: '12px' }}>
        <InfoIcon style={{ color: '#ea4949', fontSize: 14, verticalAlign: '-2px', marginRight: '3px' }} />
        {message}
      </p>))

  const rulesList = [
    {
      img: '/file/6626163575929571541634.600.png',
      icon: '/file/09571635759033591568218.200.png',
      text: '标准'
    },
    {
      img: '/file/91551635759199966105488.000.png',
      icon: '/file/65261635759219640125161.500.png',
      text: '边框缺失'
    },
    {
      img: '/file/99031635758996946531566.000.png',
      icon: '/file/65261635759219640125161.500.png',
      text: '照片模糊'
    },
    {
      img: '/file/3999163575932398269897.900.png',
      icon: '/file/65261635759219640125161.500.png',
      text: '闪光强烈'
    }
  ]
  const uploadFn = (value, url) => {
    setValue(value, url)
    clearErrors(value) // 清除错误提示
  }
  useNavigationBar({
    title: '管理员身份认证',
  })
  return (
    <div style={{ background: '#F8F8F8', height: '100%' }}>
      <React.Fragment >
        <form onSubmit={handleSubmit(onSubmitLicense)}>
          <Box mt={2} ml={2} mr={2} fontSize={15}>
            <span className={classes.titleIcon} /><text style={{ fontSize: '15px', fontWeight: 'bold' }}>上传身份证照片</text>
            <Box mt style={{ textAlign: 'center', marginTop: '20px' }}>
              <Upload onUpload={url => {
                uploadFn('adminFront', url)
              }}
                width={123}
                height={82}
                borderRadius={0}
                imgBg={detail?.adminFront || window?.ossUrl + '/file/14291635752374279101445.600.png'}
                customize={<ButtonBase className={classes.uploadBtn} >身份证人像面</ButtonBase>}
              />
              <textarea style={{ opacity: 0, position: 'absolute' }} type="text" {...register("adminFront", { required: "上传身份证人像面" })} placeholder="上传身份证人像面" />
            </Box>
            <p style={{ padding: '0 60px', margin: '0 auto', width: '274px' }}>{errorsHtml(errors?.adminFront, errors?.adminFront?.message)}</p>

            <Box mt style={{ textAlign: 'center', marginTop: '20px' }}>
              <Upload onUpload={url => {
                uploadFn('adminBack', url)
              }}
                width={123}
                height={82}
                borderRadius={0}
                imgBg={detail?.adminBack || window?.ossUrl + '/file/56631635755861527255264.400.png'}
                customize={<ButtonBase className={classes.uploadBtn} >身份证国徽面</ButtonBase>}
              />
              <textarea style={{ opacity: 0, position: 'absolute' }} type="text" {...register("adminBack", { required: "上传身份证国徽面" })} placeholder="上传身份证国徽面" />
            </Box>
            <p style={{ padding: '0 60px', margin: '0 auto', width: '274px' }}>{errorsHtml(errors?.adminBack, errors?.adminBack?.message)}</p>
            <p style={{ fontSize: '15px', fontWeight: 'bold' }}>拍摄身份证要求</p>
            <text style={{ fontSize: '13px', color: '#A3A3A3', display: 'block' }}>大陆公民持有的本人有效二代身份证 </text>
            <text style={{ fontSize: '13px', color: '#A3A3A3', display: 'block' }}>拍摄时确保身份证<text style={{ color: '#FF0000' }}>边框完整，字体清晰；亮度均匀；</text></text>

            <Grid container spacing={2}>
              {rulesList.map(el => (
                <Grid item xs={3}>
                  <Box style={{ position: 'relative', textAlign: 'center' }} mt={2}>
                    <img src={window?.ossUrl + el.img} alt='' width={80} height={47} />
                    <img src={window?.ossUrl + el.icon} alt='' width={20} height={20} style={{ position: 'absolute', left: '30px', top: '34px' }} />
                    <text style={{ color: '#A3A3A3', fontSize: '12px', lineHeight: '30px' }}>{el.text}</text>
                  </Box>
                </Grid>
              ))}

            </Grid>

            {/* <nav aria-label="manage authority" style={{ paddingBottom: '100px' }}>
              <List onClick={() => { history.push(``) }}>
                <ListItem disablePadding>
                  <span style={{ flex: '1 1 auto', fontSize: '15px' }}>管理员权限</span>
                  <span style={{ color: '#3F3F3F', fontSize: '13px' }}>去设置</span><ArrowForwardIosIcon style={{ color: '#aaa', fontSize: 16 }} />
                </ListItem>
              </List>
            </nav> */}
          </Box>
          {statusValue === 'true' ?
            <Box style={{ padding: '15px' }} className={classes.btnGray + ' ' + classes.btn} ><ButtonBase >仅允许查看</ButtonBase> </Box>
            : <Box style={{ padding: '15px' }} className={classes.btn} ><ButtonBase type="submit" >提交</ButtonBase> </Box>
          }
        </form>
      </React.Fragment>
    </div >
  )
}

