import { useEffect, useState } from "react";
import { Avatar, Box, Button, IconButton, makeStyles, Typography, withStyles } from '@material-ui/core'
import { setNavigationBar } from "utils/bridge";
import EmojiEmotions from "@material-ui/icons/EmojiEmotionsOutlined";
import { CartIcon } from "../../components/icons";
import produce from "immer";
import { login, sendMessage } from 'service/webIm'

const useStyles = makeStyles({
    bottomBar: {
        backgroundColor: 'white',
        width: '100%',
        position: 'fixed',
        bottom: '0',
        display: 'flex',
        boxShadow: '1px',
        flexDirection: 'column',
    },
    inputBox: {
        margin: 8,
        flex: 1,
        borderRadius: 20,
        border: '1px solid #aaa',
        height: 40,
        display: 'flex',
    },
    input: {
        border: 'none',
        flex: 1,
        margin: '6px 8px',
        '&:focus': {
            outline: 'none'
        }
    }
})

const RoundButton = withStyles({
    root: {
        borderRadius: 15,
        height: 30,
        border: '1px solid #999',
        color: '#999'
    }
})(Button)


/**
 * 店铺的俱乐部 
 * @param {*} props 
 * @returns 
 */
export default function ShopClubPage(props) {
    const classes = useStyles()
    const [text, setText] = useState('')
    const [msgList, setMsgList] = useState([])

    useEffect(() => {
        setNavigationBar({ title: '俱乐部' })
        login()
    }, [])
    const sendText = (e) => {
        if (e.keyCode === 13) {
            setMsgList(produce(draft => {
                draft.push(text)
            }))
            setText('')
        }
    }
    return <Box>
        <Box display='flex' p={1} bgcolor='white'>
            <Avatar variant='rounded' style={{ width: 48, height: 48 }} />
            <Box ml={1}>
                <Box fontSize={15}>万里挑一俱乐部</Box>
                <Box display='flex' marginTop='6px'>
                    {
                        [...Array(5)].map(item => <Avatar style={{ width: 20, height: 20, marginRight: 3 }} />)
                    }
                </Box>
            </Box>
        </Box>
        <Box p={1} bgcolor='white' fontSize="14px">
            公告： 618显示特价，快来抢购
        </Box>

        {
            msgList.map(msg => <Box>
                {msg}
            </Box>)
        }

        <Box className={classes.bottomBar}>
            <Box padding="8px 8px 0px 8px" display='flex'>
                <RoundButton>优惠券</RoundButton>
                <Box width={8} />
                <RoundButton>软文推广</RoundButton>
            </Box>
            <Box display='flex'>
                <Box className={classes.inputBox}>
                    <input className={classes.input}
                        value={text}
                        onChange={e => setText(e.target.value)}
                        onKeyDown={sendText}
                    />
                    <IconButton>
                        <EmojiEmotions style={{ color: '#2584F7' }} />
                    </IconButton>
                </Box>
                <IconButton>
                    <CartIcon style={{ color: '#2584F7' }} />
                </IconButton>
            </Box>
        </Box>
    </Box>
}