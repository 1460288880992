import { Avatar, Box, Button, Dialog, DialogTitle, List, ListItem, ListItemText, ListItemAvatar, makeStyles } from "@material-ui/core";
import PersonPinCircleIcon from '@material-ui/icons/PersonPinCircle';
import Lottie from "lottie-react";
import { StatusBoxPro } from "../components/empty";
import success from '../img/order-success.json';
import waiting from '../img/order-waiting.json'
import failed from '../img/order-failed.json'
import sending from '../img/order-sending.json'
import ChatIcon from '@material-ui/icons/Face';
import { useNavigationBar, toast, callPay, useAppHistory, scan, postShareInfo } from "../utils/bridge";
import { priceCast } from '../utils/price';
import dateformat from 'dateformat'
import { confirmOrder, cancelOrder, orderDetail, presentCoupons } from '../service/goods'
import CropFreeIcon from '@material-ui/icons/CropFree';
import CreateIcon from '@material-ui/icons/Create';
import { AliPayIcon, WePayIcon } from 'components/icons'
import { useCallback, useState } from 'react'
import { orderStateText, RadiusButton } from "./orderList";
import { useAccount } from "../utils/hooks";
import InputTextDialog from "components/inputText"
import QRCode from 'qrcode.react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQrcode, faThumbsUp } from '@fortawesome/free-solid-svg-icons'
import qs from 'qs';
import { OutletChip } from "./submitOrder";

const useStyle = makeStyles((theme) => ({
    topPanel: {
        backgroundImage: 'linear-gradient(#6ecca1, #f5f5f5)',
        backgroundSize: '100% 30%',
        backgroundRepeat: 'no-repeat',
        height: '100%',
    },
    roundButton: {
        borderRadius: 20,
        padding: '2px 12px'
    },
    toolbar: {
        position: 'fixed',
        bottom: 0,
        width: '100%',
        backgroundColor: 'white',
        padding: '9px 16px',
        boxShadow: '-1px -3px 4px 5px rgba(125,125,125,0.1)',
        display: 'flex',
        gap: '4px'
    },
    payIcon: {
        backgroundColor: 'white',
        borderColor: 'grey',
    }
}))

const Panel = ({ title, children }) => (<Box m={2} p={2} bgcolor='white' borderRadius='8px'>
    <Box fontSize={15} fontWeight='800'>{title}</Box>
    {children}
</Box>)

const Line = ({ title, content, right }) => {
    const contentStyle = {
        float: right ? 'right' : 'none',
        fontWeight: 800,
    }
    return (<Box pt={2} fontSize={14}>
        {title}
        <Box style={contentStyle} pl={3} component='span'>{content}</Box>
    </Box>)
}

const statusImage = (state, shipped) => {
    switch (state) {
        case 0: return waiting
        case 1:
            return shipped === 1 ? sending : waiting
        case 2: return success
        case 3: return failed
        case 4: return failed
        case 5: return success
        default: return waiting
    }
}

const payMethod = (type) => {
    switch (type) {
        case 0: return '支付宝'
        case 1: return '微信'
        default: return '未支付'
    }
}

const payTime = (time) => {
    if (time) {
        return dateformat(new Date(time * 1000), 'yyyy-mm-dd HH:MM:ss')
    } else {
        return ''
    }
}

const QrCodeDialog = ({ orderId, code, open, onClose }) => {
    const url = `/qr/${code}`
    return <Dialog open={open} onClose={onClose} maxWidth='md'>
        <DialogTitle>请向店铺展示核销码</DialogTitle>
        <Box display='flex' flexDirection='column' alignItems='center'>
            <QRCode
                value={url}  //value参数为生成二维码的链接
                size={200} //二维码的宽高尺寸
                fgColor="#333"  //二维码的颜色
            />
            <Box m={1}>{code}</Box>
        </Box>
    </Dialog>
}

export const ChoosePayChannelDialog = ({ orderId, price, open, onClose }) => {
    const classes = useStyle()
    const handleClose = () => {
        onClose()
    }
    const onCallPay = (channel) => () => {
        handleClose()
        callPay({
            id: orderId,
            price,
            payChannel: channel
        })
    }
    return <Dialog open={open} onClose={handleClose} maxWidth='md' fullWidth={true}>
        <DialogTitle>选择支付方式</DialogTitle>
        <List>
            <ListItem button onClick={onCallPay(0)} key='wei'>
                <ListItemAvatar>
                    <Avatar className={classes.payIcon} >
                        <WePayIcon />
                    </Avatar>
                </ListItemAvatar>
                微信
            </ListItem>
            <ListItem button onClick={onCallPay(1)} key='ali'>
                <ListItemAvatar>
                    <Avatar className={classes.payIcon} >
                        <AliPayIcon />
                    </Avatar>
                </ListItemAvatar>
                支付宝
            </ListItem>
        </List>
    </Dialog>

}

const OrderDetail = ({ match, location }) => {
    const { id } = match.params
    const { exchange } = qs.parse(location.search, { ignoreQueryPrefix: true })
    useNavigationBar({
        title: '订单详情',
        bgColor: '#6ecca1',
        darkBar: true
    })

    const OrderUi = useCallback((data) => <OrderDetailUI showExchange={exchange} order={data} />, [id])
    return (<StatusBoxPro
        request={orderDetail(id)}
        component={OrderUi}
    />)
}

const OrderDetailUI = ({ order, showExchange }) => {
    const classes = useStyle()
    const { id, uid, payer } = order

    const history = useAppHistory()
    const cancel = async () => {
        const { data } = await cancelOrder(id)
        if (data.code === '200') {
            toast("订单已取消")
        }
    }
    const { myself } = useAccount()


    const goodsPage = (goodsId) => () => {
        history.push('/goods/' + goodsId)
    }

    /// 是否是卖家
    const isVender = order.store.userId === myself.id
    // 已经送人了
    const presented = payer != uid

    return <Box className={classes.topPanel}>
        <Box height={15} />
        <Box display='flex' flexDirection='column' alignItems='center' justifyContent='center' >
            <Box width={100}>
                <Lottie animationData={statusImage(order?.state, order?.shipped)}></Lottie>
            </Box>
            <Box fontSize={25} color='white' fontWeight='800' textAlign='center'>{orderStateText(order)}</Box>
        </Box>
        <Box></Box>
        {
            order.shippingMethod === 1 ?
            <Panel title='收件人'>
                <Box mt={2} display='flex' alignItems='center'>
                    <PersonPinCircleIcon color="disabled" fontSize="small" />
                    <Box>{order?.name}</Box>
                </Box>
                <Box mt={1}>{order?.address}</Box>
                </Panel> :
                <Panel title={order?.store?.storeName}>
                    <OutletChip storeInfo={order?.store} />
                </Panel>
        }

        <Panel title='商品信息'>
            {
                order?.orderItems.map(item => (
                    <Box key={item.id} mt={1} onClick={goodsPage(item.productId)} display='flex' flex={1} flexDirection='row'>
                        <Avatar src={item.thumb} variant='rounded' style={{ 'width': '88px', height: '88px' }} />
                        <Box ml={1.5} flex={1} textAlign='left'>
                            <Box mt={0.4} fontWeight='600' fontSize={17}> {item.name}</Box>
                            <Box mt={2} color='#888'>{(item.color ?? '') + ' x' + item.amount + ' ' + item.model}</Box>
                            <Box mt={0.6} fontSize={16} >¥{priceCast(item.price)}</Box>
                        </Box>
                    </Box>
                ))
            }
            <Box mt={1} fontSize={14} color='#888' display='flex' alignItems='center'>
                退换无忧
                <Box flex={1} />
                {
                    order.state == 5 && !presented && !isVender && <Button variant='outlined' className={classes.roundButton} onClick={() => history.push(`/select_after_sale_type/${id}`)}>售后</Button>
                }
            </Box>
        </Panel>
        <Panel title='商品金额'>
            <Line title="商品总额" right content={'¥' + priceCast(order?.totalCartPrice)} />
            {
                order?.shippingMethod === 1 && <Line title="运费" right content={'¥' + priceCast(order?.totalCargoPrice)} />
            }
            <Line title="优惠" right content={'¥' + priceCast(order?.totalDiscountPrice)} />
            <Box height={40}>
                <Box fontSize={14} mt={2} style={{ float: 'right' }}> 实付款：
                    <Box component='span' fontSize={20} color='#d20'>{'¥' + priceCast(order?.totalPrice)}</Box>
                </Box>
            </Box>
        </Panel>
        <Panel title='订单信息'>
            <Line title="订单编号" content={id} />
            <Line title="支付方式" content={payMethod(order?.payType)} />
            <Line title="支付时间" content={payTime(order?.payTime)} />
            <Line title="配送方式" content={order?.shippingMethod === 2 ? "自提" : "快递"} />
            {
                presented && <Line title="受礼人" content={uid} />
            }
        </Panel>
        <Box height='60px' />
        {
            isVender ? <VenderToolBar order={order} cancel={cancel} /> :
                <CustomerToolBar order={order} cancel={cancel} presented={presented} showQr={showExchange} />
        }
    </Box>
}

const CustomerToolBar = ({ order, cancel, showQr, presented }) => {
    const classes = useStyle()
    const history = useAppHistory()

    const { id, expressCompany, trackingNumber } = order
    const [openChannel, setOpenChannel] = useState(false)
    const [openQrCode, setOpenQrCode] = useState(showQr === "true")
    const comment = async () => {
        history.push('/recommended_shopping/' + id)
    }

    const finishOrder = async () => {
        /// 确认收货
        const { data } = await confirmOrder(id)
        if (data.code === "200") {
            toast("确认收货")
        } else {
            toast("确认收货失败")
        }
    }
    // 联系卖家
    const chat = () => {
        history.push(`app://chat/${order.store.userId}`)
    }

    const express = () => {
        if (expressCompany && trackingNumber) {
            history.push(`https://m.kuaidi100.com/app/query/?com=${expressCompany}&nu=${trackingNumber}&coname=giraffe`)
        }
    }

    const share = async () => {
        await presentCoupons(order.id).catch(e => {
            console.warn("分享礼券api出错", e);
        })
        const item = order.orderItems[0]
        postShareInfo({
            type: 1,
            content: {
                title: '您收到一个新到礼券啦',
                subTitle: item.name,
                image: item.thumb + "?x-oss-process=image/resize,w_40,h_40,m_fill",
                source: "订单"
            },
            jsonType: 2,
            json: `https://www.qihuaitech.com/wx/receive?id=${order.id}`,
        })
    }

    return <Box className={classes.toolbar}>
        <Button variant='outlined'
            className={classes.roundButton}
            onClick={chat}
            startIcon={<ChatIcon style={{ color: '#888' }} />}>
            联系卖家</Button>
        <Box flex="1" />
        {order.state === 0 && <Box display='flex' gridColumnGap='8px'>
            <Button variant='outlined' onClick={cancel}
                className={classes.roundButton}>关闭</Button>
            <Box width='2px' />
            <Button variant='outlined' onClick={() => setOpenChannel(true)}
                className={classes.roundButton}>支付</Button>
        </Box>
        }
        {
            order.state === 1 && order.shippingMethod === 2 && !presented && <>
                <Button
                    variant='outlined'
                    className={classes.roundButton}
                    color='secondary'
                    onClick={share}
                >
                    转赠
                </Button>
                <Box width='2px' />
                <Button variant='contained'
                    className={classes.roundButton}
                    color='secondary'
                    disableElevation={true}
                onClick={() => setOpenQrCode(true)}
                >
                    核销
                </Button>
            </>
        }
        {
            order.state === 1 && order.shippingMethod === 1 && <Box display='flex' gridColumnGap='8px'>
                <Button variant='outlined'
                    onClick={express}
                    className={classes.roundButton}>查看物流</Button>
                <Box width='2px' />
                <RadiusButton title="确认收货"
                    onClick={finishOrder} />
            </Box>
        }
        {
            order.state === 5 && <Button variant='outlined'
                startIcon={<Box p='1px' fontSize={16}>
                    <FontAwesomeIcon color="#888" icon={faThumbsUp} />
                </Box>}
                onClick={comment} className={classes.roundButton}>购推荐</Button>
        }
        <ChoosePayChannelDialog
            orderId={id}
            price={order.totalPrice}
            open={openChannel}
            onClose={() => { setOpenChannel(false) }} />

        <QrCodeDialog
            orderId={id}
            code={order.code}
            open={openQrCode}
            onClose={() => setOpenQrCode(false)}
        />
    </Box>
}

const VenderToolBar = ({ order, cancel }) => {
    const classes = useStyle()
    const [openInput, setOpenInput] = useState(false)
    const [openChoose, setOpenChoose] = useState(false)
    const history = useAppHistory()

    // 联系买家
    const chat = () => {
        history.push(`app://chat/${order.store.userId}`)
    }

    const onChooseInputSource = e => {
        if (e === 'scan') {
            scan()
        } else {
            setOpenInput(true)
        }
        setOpenChoose(false)
    }

    // 发物流
    const onSendComment = useCallback(async (e) => {
        setOpenInput(false)
        let data = false
        if (data) {
            toast("操作成功")
        }
    }, [])

    return <>
        <Box className={classes.toolbar}>
            <Button variant='outlined'
                className={classes.roundButton}
                onClick={chat}
                startIcon={<ChatIcon style={{ color: '#888' }} />}>
                联系买家</Button>
            <Box flex="1" />
            {
                order.state === 0 && <Box display='flex' gridColumnGap='8px'>
                    <Button variant='outlined' onClick={cancel}
                        className={classes.roundButton}>关闭</Button>
                </Box>
            }
            {
                order.state === 1 && order.shipped === 0 && <Box display='flex' gridColumnGap='8px'>
                    <Button variant='outlined'
                        onClick={() => setOpenChoose(true)}
                        className={classes.roundButton}>发货</Button>
                </Box>
            }
        </Box>
        <InputTextDialog
            open={openInput}
            title="填写快递单号"
            multiLine={false}
            onCancel={() => setOpenInput(false)}
            onFinish={onSendComment}
        />
        <ChooseDialog
            open={openChoose}
            handleClose={() => setOpenChoose(false)}
            onChoose={onChooseInputSource}
        />
    </>
}


const ChooseDialog = ({ open, handleClose, onChoose }) => {
    return <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
        <DialogTitle id="simple-dialog-title">选择输入订单的方式</DialogTitle>
        <List>
            <ListItem button onClick={() => onChoose('scan')} key='scan'>
                <ListItemAvatar>
                    <Avatar >
                        <CropFreeIcon />
                    </Avatar>
                </ListItemAvatar>
                <ListItemText primary="扫一扫" />
            </ListItem>
            <ListItem autoFocus button onClick={() => onChoose('pen')}>
                <ListItemAvatar>
                    <Avatar>
                        <CreateIcon />
                    </Avatar>
                </ListItemAvatar>
                <ListItemText primary="手动输入" />
            </ListItem>
        </List>
    </Dialog>
}

export default OrderDetail