import React from 'react'
import { Card, Grid, Avatar, Typography, makeStyles, Box, ListItem, ListItemAvatar, ListItemText, Button, Divider } from '@material-ui/core'
import { useNavigationBar, useAppHistory } from '../../utils/bridge'
import { StatusBox } from "../../components/empty"
import { detail } from './interface'
import { useRequest } from "ahooks"


const useStyles = makeStyles(() => ({
  content: {
    '&.MuiCard-root': {
      boxShadow: 'none',
      padding: '15px',
      margin: '10px 15px'
    },
    '& button': {
      background: 'linear-gradient(90deg, #C90154, #E9097A)',
      borderRadius: '100px',
      color: '#fff',
      padding: '1px',
      float: 'right'
    }
  }
}))
export default function CarefullyDetail({ match }) {
  const { id } = match.params

  const history = useAppHistory()
  const classes = useStyles()

  useNavigationBar({
    title: '精选清单详情',
  })

  /**
   * 获取精选订单详情数据
   */
  const { data, loading, error } = useRequest(() => detail(id), {
    formatResult: res => res?.data
  })

  return (
    <Card className={classes.content}>

      <StatusBox
        error={error}
        empty={data}
        loading={loading}
      >
        <Grid container style={{ marginBottom: '5px' }}>
          <Avatar alt="Remy Sharp" src={data?.firstImg} style={{ width: '36px', height: '36px', marginRight: '5px' }} />
          {data?.nickname}
        </Grid>
        <Typography style={{ fontSize: '13px', color: '#474747', lineHeight: '20px', marginBottom: '10px' }}>
          {data?.textContent}
        </Typography>


        <Box>
          {(data?.json ? (Array.isArray(JSON.parse(data?.json)) ? JSON.parse(data?.json) : [JSON.parse(data?.json)]) : [])?.map((item, index) => (
            <React.Fragment key={index}>
              <ListItem alignItems="flex-start" style={{ background: '#fff', padding: '7px 0px' }} onClick={() => { history.push('/goods/' + item.id) }}>
                <ListItemAvatar>
                  <img width={93} height={93} style={{ marginRight: '10px', borderRadius: '3px' }} alt="Remy Sharp" src={item?.thumb} />
                </ListItemAvatar>
                <ListItemText
                  primary={

                    <Box style={{ marginTop: '5px', marginBottom: '-10px' }}>
                      <Box
                        p={2}
                        overflow='hidden'
                        height={33}
                        style={{ fontSize: '13px', lineHeight: '16px', display: '-webkit-box', WebkitLineClamp: 2, lineClamp: 2, WebkitBoxOrient: 'vertical', overflow: 'hidden', padding: 0, marginBottom: '30px' }}
                      >
                        {item?.name}
                      </Box>

                      <text style={{ fontSize: '12px', color: '#FF3150', lineHeight: '37px', display: 'block', marginTop: 0 }}>
                        ¥ <span style={{ fontSize: '16px', fontWeight: 400 }}>{item?.price / 100}</span>
                        <Button onClick={() => { history.push('/goods/' + item.id) }}>购买</Button>
                      </text>
                    </Box>
                  }
                />
              </ListItem>
              <Divider />
            </React.Fragment>
          ))}
        </Box>
      </StatusBox>
    </Card>
  )
}