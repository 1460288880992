import { Box, makeStyles } from "@material-ui/core"
import { setNavigationBar, useAppHistory } from "utils/bridge";
import { ImageButton } from "components/base";
import { SearchPanel, GroupBuying, DemandAndOnlineService } from './base'
import { useEffect } from "react";
import useFetch from "utils/useFetch";
import { GoodsGrid } from "components";

const useStyles = makeStyles((theme) => ({
    itemTitle: {
        fontSize: 18,
        fontWeight: 500,
    },
}))

const CategoryData = [
    { title: '公司服务', icon: 'https://202108251114.oss-cn-shanghai.aliyuncs.com/app/ic_gongsifuwu1%402x.png', category: 110027 },
    { title: '运营', icon: 'https://202108251114.oss-cn-shanghai.aliyuncs.com/app/ic_yunying1%402x.png', category: 110028 },
    { title: '服装设计', icon: 'https://202108251114.oss-cn-shanghai.aliyuncs.com/app/ic_fuzhuangsheji%402x.png', category: 110035 },
    { title: '软件开发', icon: 'https://202108251114.oss-cn-shanghai.aliyuncs.com/app/ic_ruanjiankaifa1%402x.png', category: 110029 },
    { title: '房屋租售', icon: 'https://202108251114.oss-cn-shanghai.aliyuncs.com/app/ic_fangwuchushou1%402x.png', category: 110033 },
    { title: '建筑装修', icon: 'https://202108251114.oss-cn-shanghai.aliyuncs.com/app/ic_jianzhuzhuangxiu1%402x.png', category: 110037 },
    { title: '精英教育', icon: 'https://202108251114.oss-cn-shanghai.aliyuncs.com/app/ic_jingyingjiaoyu1%402x.png', category: 110030 },
    { title: '家政服务', icon: 'https://202108251114.oss-cn-shanghai.aliyuncs.com/app/ic_jiazhengfuwu1%402x.png', category: 110031 },
    { title: '健康管理', icon: 'https://202108251114.oss-cn-shanghai.aliyuncs.com/app/ic_yiliaojiankang1%402x.png', category: 110032 },
    { title: '更多', icon: 'https://202108251114.oss-cn-shanghai.aliyuncs.com/app/ic_gengduo1%402x.png', category: -1 },
];

const ServiceMallPage = (props) => {
    const classes = useStyles()
    const history = useAppHistory()

    useEffect(() => {
        setNavigationBar({
            title: '服务',
            darkBar: true,
            bgColor: '#2584F7',
        })

    }, [])

    const { data } = useFetch('mall/product/recommend', {
        params: {
            pageNo: 0,
            pageSize: 10,
            category: 11,
        }
    })

    const onClickCategroy = (category) => {
        if (category < 0) {
            history.push('/service/category')
        } else {
            history.push('/service_sub/' + category)
        }
    }

    return (<Box overflowY='scroll' height='100%'>

        <SearchPanel />
        <Box display='flex' flexDirection='row' flexWrap='wrap'>
            {
                CategoryData.map((item) => <ImageButton
                    key={item.category}
                    name={item.title} icon={item.icon} size='65'
                    onClick={() => onClickCategroy(item.category)}
                    style={{ width: '20%' }} />)
            }
        </Box>

        <GroupBuying />

        <DemandAndOnlineService />

        <Box mx={2} className={classes.itemTitle}>为您推荐</Box>
        <GoodsGrid goodList={data?.data || []} />
    </Box>)
}

export default ServiceMallPage