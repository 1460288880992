import { useSelector } from "react-redux";

/**
 * 获取账户信息，当前用户是否登录，用户信息等。
 * @returns 当前用户 {login, myself}
 */
export const useAccount = () => {
    return useSelector(state => state.account)
}

export const useAddress = () => {
    return useSelector(state => state.address)
}