import { Box, ButtonBase, IconButton, makeStyles } from "@material-ui/core";
import { setNavigationBar, useAppHistory } from '../../utils/bridge';
import { MyIconButton, NumberButton } from '../../components/base';
import { state_icons } from "../myOrder";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { useState, useEffect } from "react";
import { getOrderCounts } from "service/goods";
import { wrapBlueTheme } from "./base"

const useStyles = makeStyles((theme) => ({
    pageModelButton: {
        padding: '0 30px',
        backgroundImage: 'linear-gradient(90deg, #2584F7 0%, #5BA0F4 100%)',
        minHeight: '40px',
        color: 'white',
        borderRadius: '20px',
        left: '-20px',
    },
    orderCard: {
        margin: theme.spacing(1),
        background: 'white',
        padding: '4px',
        borderRadius: '8px',
    },
    fab: {
        position: 'fixed',
        bottom: '40px',
        right: '20px',
    },
    button: {
        backgroundColor: 'white',
        borderRadius: 8,
        padding: theme.spacing(1),
        margin: theme.spacing(1),
        display: 'flex',
        alignItems: 'center',
        fontSize: '15px',
    }
}))


const MyServicePage = (props) => {
    setNavigationBar({
        title: '我的服务'
    })
    const classes = useStyles()
    const history = useAppHistory()
    const [countList, setCountList] = useState([0, 0, 0, 0, 0])
    const goto = (path) => () => {
        history.push(path)
    }
    useEffect(async () => {
        const { data: counts } = await getOrderCounts(true, 1)
        if (counts) {
            setCountList(counts)
        }
    }, [])
    return (<>
        <Box margin={1}
            padding={2}
            borderRadius={8}
            bgcolor='white'
            display='flex'
            flexDirection='row'>
            <NumberButton num={4} title="服务店关注" onClick={goto("/marked_shops")} />
            <NumberButton num={8} title="服务收藏" onClick={goto("/marked_shops")} />
            <NumberButton num={22} title="足迹" onClick={goto("app://footprint")} />
            <NumberButton num={2} title="优惠券" onClick={goto('/my_coupons')} />
        </Box>

        <Box className={classes.orderCard}>
            <Box display='flex' alignItems='center'>
                <Box flex='1' ml={1}>我的订单</Box>
                <IconButton onClick={goto('/my_orders')}>
                    <ArrowForwardIosIcon style={{ color: '#aaa' }} />
                </IconButton>
            </Box>
            <Box display='flex' flexDirection='row' justifyContent='space-around' mt={3}>
                <MyIconButton title="待付款" count={countList[1]} image={state_icons[1]} type='1' onClick={goto('/my_orders?tab=2&type=1')} />
                <MyIconButton title="待确认" count={countList[1]} image={state_icons[2]} type='2' onClick={goto('/my_orders?tab=2&type=2')} />
                <MyIconButton title="进行中" count={countList[2]} image={state_icons[3]} type='3' onClick={goto('/my_orders?tab=2&type=3')} />
                <MyIconButton title="购推荐" count={countList[3]} image={state_icons[4]} type='4' onClick={goto('/my_orders?tab=2&type=4')} />
            </Box>
        </Box>

        {/*
        临时关闭入口
        <Box className={classes.orderCard} display='flex' alignItems='center'>
            <img src='https://202108251114.oss-cn-shanghai.aliyuncs.com/app/ic_payment_detail.png' style={{ width: 30 }} />
            <Box p={1} flex='1'>
                账单明细
            </Box>
            <IconButton>
                <ArrowForwardIosIcon style={{ color: '#aaa' }} />
            </IconButton>
        </Box>*/}
        {/* <Fab className={classes.fab} color="primary">
            <CreateIcon style={{ color: '#fff' }} />
        </Fab> */}
    </>)
}

function SectionRow({ title, onClick }) {
    const classes = useStyles()
    return <ButtonBase className={classes.button} style={{ width: 'calc(100% - 16px)' }} onClick={onClick}>
        <Box p={1} flex='1' textAlign='left'>
            {title}
        </Box>
        <ArrowForwardIosIcon style={{ color: '#aaa', fontSize: 18 }} />
    </ButtonBase>
}

export default wrapBlueTheme(MyServicePage)