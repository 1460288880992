import Box from '@material-ui/core/Box';
import LinearProgress from '@material-ui/core/LinearProgress';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import ButtonBase from '@material-ui/core/ButtonBase';
import { makeStyles } from '@material-ui/core';
import RoomIcon from '@material-ui/icons/Room';
import React, { useState } from 'react'
import SwipeableViews from "react-swipeable-views";
import { ButtonLinearLayout, ImageButton } from "../components/base";
import CartToolBar from "../components/cartToolBar";
import ChooseVersionPanel from "../components/chooseVariantPanel";
import { Empty } from '../components/empty'
import { setShareInfo, useAppHistory, useNavigationBar, openLocation, toast } from '../utils/bridge';
import { getGoodsDetail } from "../service/goods";
import { useRequest } from 'ahooks';
import Cookies from 'js-cookie';
import { caculateDistance } from '../utils/price';
import RecommendCommentPage from './service/comment';


const detailContent = (imgs) => imgs.map(src => <img key={src} style={{ maxWidth: '100%' }} src={src} />)

const useStyles = makeStyles(theme => ({
    pageIndicator: {
        background: '#000000a3',
        textAlign: 'center',
        fontSize: 14,
        position: 'relative',
        width: '30px',
        lineHeight: '1.3rem',
        float: 'right',
        right: '20px',
        bottom: '40px',
        color: 'white',
        borderRadius: 12,
    },
    arcHead: {
        height: 20,
        bottom: 10,
        width: '100%',
        borderRadius: '10px',
        position: 'relative',
        backgroundColor: '#F8F8F8',
    },
    punch: {
        backgroundColor: 'white',
        width: '100%',
        marginTop: 20,
        fontSize: 14,
        borderRadius: 8,
        height: 40,
    }
}))

function GoodsHeader({ goods, variant }) {
    const classes = useStyles()
    const [imgIndex, setImgIndex] = useState(1);
    const store = goods.store
    const history = useAppHistory()

    let covers
    if (variant.multiView) {
        covers = variant.multiView.split(',').map(src => {
            return <img src={src} alt='cover-image' key={src} style={{
                objectFit: 'cover',
                width: '100%',
                height: 300,
            }} />
        })
    } else {
        covers = <img width='100%' src="https://via.placeholder.com/400x350/8f8e94/FFFFFF?text=loading.." height="350" />
    }
    const location = Cookies.get('location')
    const changeIndex = (index, _lastIndex, _meta) => {
        setImgIndex(index + 1)
    }
    const pushToMap = () => {
        // 调用地图导航 
        openLocation(store.latitude, store.longitude, store.storeName)
    }
    const pushToPunch = () => {
        if (location) {
            // 打卡
            const [lon, lat] = location.split(',')
            const distance = caculateDistance(lat, lon, store.latitude, store.longitude)
            if (distance > 2) {
                toast(`距离(${distance.toFixed()}km)太远啦，无法打卡`)
            } else {
                history.push(`/release_tour?storeId=${store.id}`)
            }
        }
    }

    return <Box>
        <Box position='abstract'>
            <SwipeableViews onChangeIndex={changeIndex}>
                {covers}
            </SwipeableViews>
            <Box className={classes.pageIndicator} >
                {imgIndex}/{covers.length}
            </Box>
            <Box className={classes.arcHead}>
            </Box>
            <Box padding='0px 14px 14px 10px' mt={-1} zIndex={10} borderRadius={8}>
                <Box mb={1} fontSize={16} fontWeight={500}>{goods.name}</Box>
                <Box fontSize={14} color='#777'>
                    <Box>
                        {store.intro}
                    </Box>
                    <Box display='flex' alignItems='center' mt={1}>
                        <RoomIcon style={{ color: '#777' }} />
                        <Box flex={1}>{store.storeAddress} </Box>
                        <ImageButton
                            onClick={pushToMap}
                            icon='https://h5.qihuaitech.com/files/ic_ditu@2x.png' name='地图' />
                    </Box>
                </Box>
                {
                    location && <ButtonBase onClick={pushToPunch} className={classes.punch}>
                        <img src='https://h5.qihuaitech.com/files/ic_zuji@2x.png'
                        style={{ width: 30, height: 30, marginRight: 8 }} />
                    <Box>足迹打卡</Box>
                </ButtonBase>
                }
            </Box>
        </Box>
    </Box>
}

function ScenerySpotPage({ match, location }) {
    const { id: goodsId } = match.params
    useNavigationBar({ title: '景点详情' })

    const [tabIndex, setTabIndex] = React.useState(0);
    const [openChoose, setOpenChoose] = React.useState(false);
    const [variant, setVariant] = React.useState()

    const handleChange = (event, newValue) => {
        setTabIndex(newValue);
    };
    const history = useAppHistory()

    const { data: goods } = useRequest(() => getGoodsDetail(goodsId), {
        formatResult: res => res.data,
    })
    const comments = []

    let variantlist = goods?.productVariantDetails?.filter(i => i.live === 1)
    if (goods) {
        console.log('goods: ', goods);

    }
    React.useEffect(() => {
        if (goods) {
            const firstVariant = variantlist[0]
            setVariant(firstVariant)
            const tag = goods.tag?.split(',')?.[0]
            // 分享参数
            setShareInfo({
                type: 1,
                jsonType: 1,
                content: {
                    title: goods.name,
                    price: firstVariant.price / 100,
                    image: firstVariant.image || firstVariant.thumb,
                    source: '商品',
                    tag: tag
                },
                json: {
                    path: location.pathname
                }
            })
        }
    }, [goods])


    const closeChoosePanel = () => {
        setOpenChoose(false);
    }
    const handleChooseVariant = () => {
        setOpenChoose(true);
    };

    const goToCart = () => {
        console.log('gotoCart')
        history.push('/main/cart')
    }

    if (!goods || !variant) {
        return <LinearProgress />
    }

    const gotoCS = () => {
        history.push('app://chat/' + goods.store.userId)
    }

    let detail
    if (goods.details) {
        detail = detailContent(goods.details.split(','))

    } else {
        detail = <Empty title="商品未上线，敬请期待" />
    }

    return (
        <Box bgcolor='#F8F8F8' style={{ overflowY: 'scroll', overflowX: 'hidden' }} height={1}>
            <GoodsHeader variant={variant} goods={goods} handleChooseVariant={handleChooseVariant} />

            <Tabs
                style={{
                    backgroundColor: '#F8F8F8',
                    position: 'sticky',
                    top: 0
                }}
                value={tabIndex}
                onChange={handleChange}
                textColor="primary"
                indicatorColor="primary" >
                <Tab label="商品详情" />
                <Tab label="购推荐" />
            </Tabs>
            {tabIndex === 0 ? <Box>{detail} </Box> : <RecommendCommentPage goodsId={goodsId} />}
            <ButtonLinearLayout>
                <Box component="span" className='item-title'>售后保障</Box>
                <Box flex={1} />
            </ButtonLinearLayout>
            <Box pb={16} color='grey.400' p={2}>
                所有商品信息、客户评价、商品咨询、网友讨论等内容，是我们的重要的经营资源，未经许可，禁止非法转载使用。
                <br />
                注：本站商品信息均来自于合作方，其真实性、准确性和合法性由信息拥有者（合作方）负责。本站不提供任何保证，并不承担任何法律责任。
            </Box>

            <ChooseVersionPanel open={openChoose}
                closeChoosePanel={closeChoosePanel}
                current={variant}
                variantlist={variantlist}
                setVariant={setVariant}
            />

            <CartToolBar go_cs={gotoCS} cartList={goToCart} goods={goods}
                store={() => history.push(`/shop/${goods.store.id}`)}
                cart={handleChooseVariant} buy={handleChooseVariant} />

        </Box>
    )
};

export default ScenerySpotPage