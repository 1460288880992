import { WelfareCouponsItem } from './welfareSpace'
import { useEffect, useState } from 'react'
import { getShareItem } from 'service/goods'
import { setNavigationBar, useAppHistory } from 'utils/bridge'
import { Button, Box, Grid } from '@material-ui/core'

const WelfarePicturePage = ({ match }) => {
    const id = match.params.id
    const [item, setItem] = useState()
    const history = useAppHistory()
    useEffect(async () => {
        setNavigationBar({ title: '福利详情' })
        const { data } = await getShareItem(id)
        if (data) {
            setItem(data)
        }
    }, [])
    if (!item) {
        return <div />
    }
    const { textContent, fileContent, json } = item
    const store = JSON.parse(json)
    const picList = fileContent.split(',')

    return <div style={{ background: 'white' }}>
        <Box color='#222' fontSize='16px' textAlign='left' p={2}>
            {textContent}
            <Box height={8} />
            <Grid container spacing={1} >
                {
                    // list all of picList
                    picList.map(item =>
                        <Grid item key={item} xs={6}>
                            <img src={item} style={{
                                background: 'grey',
                                height: 180,
                                width: '100%',
                                borderRadius: '4px',
                                objectFit: 'cover'
                            }} />
                        </Grid>
                    )
                }
            </Grid>
        </Box>
        <WelfareCouponsItem json={item.json} />
        <div style={{
            padding: '8dp',
            display: 'flex',
            justifyContent: 'center',
            height: 48,
        }}>
            <Button >立即使用</Button>
        </div>
    </div>
}

export default WelfarePicturePage