import websdk from 'easemob-websdk'
import Cookies from 'js-cookie';

let conn = {}


const config = {
    socketServer: '//im-api-v2.easemob.com/ws',    // socket Server地址
    restServer: '//a1.easemob.com',               // rest Server地址
    appkey: 'YXA6wTq8nW-9TnCJBmkDGIO_Eg',        // App key
    https: false,                            // 是否使用https
    isHttpDNS: true,                          // 3.0 SDK支持，防止DNS劫持从服务端获取XMPPUrl、restUrl
    isMultiLoginSessions: false,              // 是否开启多页面同步收消息，注意，需要先联系商务开通此功能
    isDebug: false,                           // 打开调试，会自动打印log，在控制台的console中查看log
    autoReconnectNumMax: 2,                   // 断线重连最大次数
    heartBeatWait: 30000,                     // 心跳间隔（只在小程序中使用）
    delivery: false,                           // 是否发送已读回执
    useOwnUploadFun: false,         // 是否使用自己的上传方式（如将图片文件等上传到自己的服务器，构建消息时只传url）
    deviceId: 'webim'               // 设备ID，默认可不传，如果传一个固定值，在没开启多端登录的情况下同一个账号会互踢
}

let WebIM = {
    config
}

conn = WebIM.conn = new websdk.connection({
    appKey: WebIM.config.appkey,
    isHttpDNS: WebIM.config.isHttpDNS,
    isMultiLoginSessions: WebIM.config.isMultiLoginSessions,
    https: WebIM.config.https,
    url: WebIM.config.socketServer,
    apiUrl: WebIM.config.restServer,
    isAutoLogin: WebIM.config.isAutoLogin,
    autoReconnectNumMax: WebIM.config.autoReconnectNumMax,
    autoReconnectInterval: WebIM.config.autoReconnectInterval,
    delivery: WebIM.config.delivery,
    useOwnUploadFun: WebIM.config.useOwnUploadFun
})

export function login() {
    let userId = Cookies.get('userId')
    conn.open({
        user: userId,
        pwd: '$2a$10$LZhV8CoJeakXgq8PCDzgHe57iU90/3sDCEcZlkAOmeMsVzt.0.m/C',
        appKey: WebIM.config.appkey,
    })
}


export function sendMessage(text, receiver) {
    let id = conn.getUniqueId();
    let msg = new WebIM.message('txt', id)
    msg.set({
        msg: text,
        to: receiver,
        chatType: 'groupChat',
        success: () => {
            console.log('send success')
        }
    })
    conn.send(msg.body)
}

export default {
    login,
    sendMessage,
}