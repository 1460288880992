import { Menu, MenuItem } from "@material-ui/core"
import { useDispatch } from "react-redux"
import { blockUser } from "../../models/account"
import { useToast } from "../../models/toast"
import { useAppHistory } from "../../utils/bridge"


export const ReportMenuList = ({ anchorEl, open, handleClose, type, id, user, block = true }) => {
    const history = useAppHistory()
    const toast = useToast()
    const dispatch = useDispatch()

    const onReportUser = () => {
        handleClose()
        history.push(`/report?type=5&id=${user}&user=${user}`)
    }

    const onReport = () => {
        handleClose()
        history.push(`/report?type=${type}&id=${id}&user=${user}`)
    }

    const onBlock = () => {
        blockUser(user, dispatch)
        toast.showToast('toast.success', '已经拉黑该用户')
        handleClose()
    }

    return <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose} >
        <MenuItem onClick={onReportUser}>举报该用户</MenuItem>
        {
            block && <MenuItem onClick={onBlock}>屏蔽该用户内容</MenuItem>
        }
        <MenuItem onClick={onReport}>举报内容不适</MenuItem>
    </Menu>
}