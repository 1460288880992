import React, { useState } from 'react'
import { List, ListItem, Checkbox, ListItemText, ListItemAvatar, Box, Grid, AppBar, Toolbar, Button, makeStyles } from '@material-ui/core'
import { useNavigationBar } from "utils/bridge"
import PanoramaFishEyeIcon from '@material-ui/icons/PanoramaFishEye'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import { batchUpperShelf, batchDelete, productsManageList } from './interface'
import { useToast } from "../../../models/toast"
import { useAccount } from '../../../utils/hooks'
import { StatusBox } from '../../../components/empty'
import { useRequest } from "ahooks"
import qs from 'qs'

const useStyles = makeStyles(() => ({
  bottomBar: {
    marginBottom: 50,
  },
  upperShelfBtn: {
    background: '#A786E8 !important',
    color: '#fff',
    borderRadius: '100px',
    padding: '5px',
    marginLeft: '10px'
  },
  deleteBtn: {
    fontSize: '14px',
    color: '#FF4141',
    border: '1px solid #FF4141',
    borderRadius: '100px',
    padding: '5px',
  },
}))
export default function BatchManage() {
  const toast = useToast()
  const [checked, setChecked] = useState([])
  const [allChecked, setAllChecked] = useState(false)
  const classes = useStyles()
  const query = qs.parse(window.location.search, { ignoreQueryPrefix: true })
  const { status } = query //  status: 0 在售中，1 已售罄， 2已下架
  const { mall } = useAccount()

  /**
   * 批量管理列表接口
   * tabs: 0 在售中，1已售罄，2已下架;  
   * 接口回传: live ：0 下架, live：1 在售中,stock：0 已售罄
   */
  const { data, loading, error, refresh } = useRequest(() => productsManageList(status === '0' ? { storeId: mall?.id } : status === '1' ? { stock: 0, storeId: mall?.id } : { live: 0, storeId: mall?.id }), {
    formatResult: res => res.data
  })


  // 勾选列表商品
  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);

    // 已勾选全部商品则页面底部‘全部’更新状态
    setAllChecked(newChecked?.length === data?.length)
  }

  // 全选操作
  const onChangeFn = e => {
    setAllChecked(e.target.checked)
    // 已选
    setChecked(e.target.checked ? data?.map(el => el?.productVariants?.id) : [])
  }

  // 批量删除操作
  const batchDeleteFn = async (checked) => {
    if (checked?.length) {
      const { data } = await batchDelete(checked)
      if (data) {
        toast.showToast("toast.success", "操作成功")
        // 刷新列表
        refresh()
      }
    } else {
      toast.showToast("toast.warning", "请选择商品")
      return
    }
  }

  // 批量上架操作
  const batchUpperShelfFn = async (checked) => {
    if (checked?.length) {
      const { data } = await batchUpperShelf(checked)
      if (data) {
        toast.showToast("toast.success", "操作成功")
        // 刷新列表
        refresh()
      }
    } else {
      toast.showToast("toast.warning", "请选择商品")
      return
    }
  }



  useNavigationBar({
    title: '批量管理',
  })
  return (
    <div>
      <StatusBox loading={loading} error={error} empty={data}>
        <List style={{ margin: 0, padding: 0 }}>
          {data?.map((value) => {
            const labelId = `checkbox-list-label-${value?.productVariants?.id}`;

            return (
              <ListItem
                key={value?.productVariants?.id}
                style={{ borderBottom: '1px solid #eee', borderColor: 'divider', padding: 0, background: '#fff' }}
              >
                <Box onClick={handleToggle(value?.productVariants?.id)} style={{ width: '100%' }}>
                  <ListItem alignItems="flex-start">
                    <Checkbox
                      edge="start"
                      checked={checked.indexOf(value?.productVariants?.id) !== -1}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ 'aria-labelledby': labelId }}
                      icon={<PanoramaFishEyeIcon style={{ color: '#CACACA', fontSize: '26px', marginTop: '20px' }} />}
                      checkedIcon={<CheckCircleIcon style={{ color: '#A786E8', fontSize: '26px', marginTop: '20px' }} />}
                    />

                    <ListItemAvatar>
                      <img width={65} height={65} style={{ borderRadius: '3px', marginRight: '13px' }} alt="Remy Sharp" src={value?.productVariants?.image} />
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <Box p={2}
                          overflow='hidden'
                          height={26}
                          style={{ display: '-webkit-box', WebkitLineClamp: 1, lineClamp: 1, WebkitBoxOrient: 'vertical', overflow: 'hidden', padding: 0, width: '100%' }}
                        >
                          {value?.name}
                        </Box>
                      }
                      secondary={
                        <React.Fragment>
                          <text style={{ color: '#FF1717', lineHeight: '27px' }}>¥ 3334</text>
                          <Grid container style={{ color: '#999999', fontSize: '13px' }}>
                            <Grid xs={5}>
                              累计销量 {value?.productVariants?.sellCount || '-'}
                            </Grid>
                            <Grid xs={4}>
                              库存 {value?.productVariants?.stock || '-'}
                            </Grid>
                          </Grid>
                        </React.Fragment>
                      }
                    />
                  </ListItem>

                  {/* <ListItemText id={labelId} primary={`Line item ${value + 1}`} /> */}
                </Box>
              </ListItem>
            );
          })}
        </List>
      </StatusBox>

      <Box className={classes.bottomBar}>
        <AppBar color="inherit" style={{ position: 'fixed', top: 'auto', right: 'auto', bottom: 0, width: '100%', maxWidth: '841px', }}>
          <Toolbar>
            <Grid container style={{ color: '#999999', fontSize: '13px' }}>
              <Grid xs={6}>
                <Checkbox
                  edge="all"
                  checked={allChecked}
                  // tabIndex={-1}
                  disableRipple
                  onChange={onChangeFn}
                  // inputProps={{ 'aria-labelledby': labelId }}
                  icon={<PanoramaFishEyeIcon style={{ color: '#CACACA', fontSize: '26px' }} />}
                  checkedIcon={<CheckCircleIcon style={{ color: '#A786E8', fontSize: '26px' }} />}
                  style={{ padding: 0 }}
                />
                <text style={{ fontSize: '13px', paddingLeft: '10px' }}>全部</text>
              </Grid>

            </Grid>
            <Button className={classes.deleteBtn} onClick={() => batchDeleteFn(checked)}>删除</Button>
            <Button
              className={classes.upperShelfBtn}
              onClick={() => batchUpperShelfFn(checked)}
            >
              上架
            </Button>
            {/* <Button
              className={classes.upperShelfBtn}
              onClick={() => batchLowerShelfFn(checked)}>
              下架
            </Button> */}
          </Toolbar>
        </AppBar>
      </Box>
    </div>
  )
}