import request, { requestX } from '../utils/request'
import { AddressType } from './model'

export async function getUserInfo(id: number) {
    return requestX.get('users/user/' + id)
}

export async function getGoodsDetail(id: number) {
    return requestX.get(`mall/getProduct/${id}`)
}

export async function getGoodsComment(id: number, no: number) {
    return requestX.get(`mall/${id}/comment/0/${no}`)
}

export async function addToCart(amount: number, id: number) {
    return requestX.post(`mall/cart/`, {
        amount: amount,
        productVariantId: id,
    })
}

export async function getAllCart() {
    return requestX.get('mall/cart')
}

export const deleteItemFromCart = async (ids: number[]) => {
    return requestX.post('mall/cart/remove', {
        cartItems: ids
    })
}

export async function clearCart() {
    return requestX.delete('mall/cart')
}

export async function changeCartNumber(amount: number, cartItemId: number) {
    return requestX.put(`mall/cart/change`, {
        amount: amount,
        cartItemId: cartItemId,
    })
}

export async function getUserIfo(id: number) {
    return request.get('users/user/' + id)
}

export async function getCategory() {
    return requestX.get('mall/category/goods')
}
// 获取职业荟顶部列表
export async function getJobCategory() {
    return requestX.get('/mall/category/vocation')
}
export async function getCategoryByPid(pid: number) {
    return requestX.get('/category/' + pid)
}
// 在getCategoryByPid后去到category.id后执行传入下面函数
export async function getCategoryMixByPid(id: number) {
    return requestX.get('/category/mix/' + id)
}
export async function getAddress() {
    return request.get('mall/address')
}


export async function addAddressApi(address: AddressType) {
    return requestX.post('mall/address', address)
}

/**
 * 商品直接发起结算，不用加入购物车购买
 * @param product 商品
 * @returns 结算结果
 */
export async function updateOrderSingle(product: { amount: number, productVariantId: number, buyerId: number }) {
    return requestX.post('mall/order/single', product)
}

type ProductOrder = {
    amount: number,
    buyerId: number,
    productVariantId: number,
    receiveAddressId: number
}

/**
 * 提交订单, 商品直接下单，未加入购物车
 * @param product 订单信息， 包含商品id，数量， 优惠券，收件地址id
 * @returns 下单结果
 */
export async function submitOrderSingle(product: ProductOrder) {
    return requestX.post('mall/order/separate', product)
}

/**
 * 支付宝支付 
 * @param id k
 * @param price 
 * @returns 
 */
export async function callAliPay(id: number, price: number) {
    return requestX.post('alipay/pcPay', {
        id, price
    })
}

export async function callAliPayMobile(id: number, price: number) {
    return requestX.post('alipay/sign', {
        id, price
    })
}

/**
 * 结算购物车商品
 * @param cartItems 购物车商品
 * @param shippingMethod 1快递，2自提 
 * @returns 结算结果
 */
export async function updateOrder(cartItems: [], shippingMethod: number) {
    return requestX.post('mall/order/settlement', { products: [...cartItems], shippingMethod })
}

export async function submitOrder(data: any) {
    return requestX.post('mall/order/part', data)
}

export async function loadOrders(data: { shipped: number, state: number, storeId: number, userId: number },
    page: number, virtual: boolean) {
    return requestX.get('mall/order', {
        params: {
            ...data,
            pageNo: page,
            pageSize: 20,
            virtual,
        }
    })
}

export function getOrders(data: { shipped: number, state: number, storeId: number, userId: number },
    page: number, virtual: boolean) {
    return async () => loadOrders(data, page, virtual);
}

/**
 * 确认收货
 * @param id 订单id
 */
export async function confirmOrder(id: number) {
    return request.put('mall/order/confirm/' + id)
}

/**
 * 根据核销码完成消费
 * @param id 订单id
 */
export async function costsByCode(id: number) {
    return request.put('mall/order/code/' + id)
}

export async function cancelOrder(code: String) {
    return request.put('mall/order/cancel/' + code)
}

/**
 * 发送手机验证码
 * @param phone 手机号
 * @returns
 */
export async function sendSms(phone: String, code: number) {
    return request.get('auth/sendSMS', {
        params: {
            phone: phone,
            code: code
        }
    })
}

export async function setPassword(phone: String, code: String, password: String) {
    return request.put('auth/setPasswordBySms', { phone, code, password })
}

export async function changePhone(phone: String, code: String) {
    return requestX.put('users/setPhone', { phone, code })
}

export async function getMyUserInfo() {
    return requestX.get('users/user')
}

export async function getBlockList() {
    return requestX.get('users/black/list')
}

export async function postBlockUser(id: number | string) {
    return requestX.post(`users/black/${id}`)
}

export async function deleteBlockUser(id: number | string) {
    return requestX.delete(`users/black/${id}`)
}

export async function changeGrid(grid: string) {
    return request.put('users/user', { grid })
}

export async function messageCount() {
    return request.get('users/message/count')
}

export async function getMessage(type: number, page: number) {
    return request.get(`users/message/${type}/${page}`)
}

export async function markUnread(type: number) {
    return request.put('users/message/' + type)
}


export async function postCircle(content: any) {
    return requestX.post('/dynamic/release', content)
}

export async function postDynamic(content: any) {
    return requestX.post('/bazaar/dynamic/release', content)
}

/**
 * 集市里面的图文和文章等。
 * @param type: 0：个人发布的动态（包括朋友圈转发）, 1：悦分享, 2：福利（优惠券）,3：行业交流`
 * @param subType 子类型
 * @param tag 标签
 * @returns
 */
export async function shareList(params: { type: number, subType?: number, tag?: String }) {
    return requestX.get('bazaar/dynamic/getDynamic', {
        params: {
            ...params,
            pageNo: 0,
            pageSize: 20
        }
    })
}

/**
 * 获取获取某个评论的回复
 * @param id 评论id
 * @returns
 */
export async function dynamicComments(id: number) {
    return requestX.get(`bazaar/dynamic/comment/${id}/reply`)
}

export function shareDynamicList(params: { type: number, subType?: number, tag?: String }) {
    return async () => shareList(params);
}

/**
 * 获取动态，悦分享，福利等详情（单条）
 * @param id 详情id
 * @returns 
 */
export async function getShareItem(id: number) {
    return requestX.get('bazaar/dynamic/' + id)
}

export async function getOnlineItem(id: number) {
    return requestX.get('/famous/problem/list', {
        params: {
            id: id,
            category: 1,
            pageNo: 0,
            type: 2
        }
    })
}

/**
 * 发布动态的评论
 * @param ask true:问，false:答
 * @param bqcId
 * @param content 评论内容
 * @param pid 上级回复
 * @returns
 */
export async function postOnlineComment(ask: boolean, bqcId: number, content: string, pid: number) {
    return requestX.post('famous/answer', {
        ask: ask,
        bqcId: bqcId,
        content: content,
        pid: pid
    })
}

export async function updateOnlineThumb(flag: boolean, bdId: number) {
    return requestX.post(`famous/${bdId}/thumbs/${flag}`)
}

/**
 * 发布动态的评论
 * @param id 动态id
 * @param text 评论内容
 * @returns 
 */
export async function postDynamicComment(id: number, text: string) {
    return requestX.post(`bazaar/dynamic/${id}/comment`, {
        text: text
    })
}

/**
 * 加载动态的评论列表
 * @param id 动态id
 * @returns 
 */
export async function loadAllComments(id: string | number) {
    return requestX.get(`/bazaar/dynamic/${id}/comments`)
}

/**
 * 发布圈子动态的评论
 * @param id 动态id
 * @param text 评论内容
 * @returns
 */
export async function postUserDynamicComment(id: number, text: string) {
    return requestX.post(`dynamic/${id}/comment`, {
        text: text
    })
}

export async function updateThumb(flag: boolean, bdId: number) {
    if (flag) {
        return requestX.post(`bazaar/dynamic/${bdId}/thumb`)
    } else {
        return requestX.put(`bazaar/dynamic/${bdId}/thumb`)
    }
}

/**
 * 需求广场， 服务达人
 * @param category 分类
 * @param registrant 0为需求方， 1为服务方
 * @param pageNo 当前页
 * @returns 
 */
export async function getDeamandList(category: number, registrant: number, pageNo: number) {
    return request.get('demand/list', { params: { category: category, registrant: registrant, pageNo: 0 } })
}

export function getDemandList(params: any) {
    return async () => requestX.get('demand/list', {
        params: { ...params }
    })
}


// 团购列表
export async function groupbuyingList(category: number) {
    return request.get('mall/limit/group', { params: { categroy: category, pageNo: 0 } })
}
export function groupBuyingList(category: number) {
    return async () => requestX.get('mall/limit/group', { params: { category: category, pageNo: 0 } })
}

// 精品购推荐
export async function commentRecommend(category: number, type: number = 0) {
    return requestX.get(`mall/recommend/${type}/${category}/0`)
}

// 精品购推荐Detail
export async function getCommentDetail(id: number) {
    return requestX.get(`mall/comment/${id}`)
}

// 店铺信息
export async function getStoreInfo(id: number) {
    return request.get(`/mall/store/info/${id}`)
}


// 店铺信息
export async function getStore(id: number) {
    return requestX.get(`/mall/store/info/${id}`)
}

// 店铺门店信息
export async function getStoreOutlet(id: number) {
    return requestX.get(`/mall/store/sale/List`, {
        params: {
            id,
            pageNo: 0,
            pageSize: 50
        }
    })
}

/// 店铺的H5介绍
export function storeWebsiteLoader(store: number) {
    return async () => requestX.get(`/mall/store/website/${store}`)
}

//  店铺的商品
export async function getGoodsByStore(store: number) {
    return request.get('/mall/product', {
        params: {
            storeId: store,
            pageNo: 0,
            pageSize: 30,
        }
    })
}

// 店铺的团购
export async function getGroupbuyByStore(store: number) {
    return requestX.get('/mall/group', {
        params: {
            storeId: store,
            pageSize: 30,
            pageNo: 0,
        }
    })
}

// 店铺的所有优惠券 
export function getShopCoupons(store: number) {
    return async () => requestX.get(`/mall/${store}/coupons`)
}

export function getShopManageCoupons(store: number) {
    return async () => requestX.get(`/mall/${store}/coupons/manage`)
}

/**
 * 创建优惠券 
 * @param store 店铺id
 * @param coupon 优惠券信息
 * @returns 
 */
export async function createCoupons(store: number, coupon: any) {
    return requestX.post(`/mall/${store}/discount`, coupon)
}

/**
 * 删除优惠券
 * @param store 店铺id
 * @param id 优惠券id
 * @returns 
 */
export async function deleteCoupon(store: number, id: number) {
    return requestX.delete(`/mall/${store}/discount/${id}`)
}

// 店铺的购推荐
export async function getCommentsByStore(store: number) {
    return requestX.get(`mall/store/${store}/recommend/0`)
}

/**
 * 领取优惠券 
 * @param id 优惠券id
 * @returns 
 */
export async function pickCoupons(id: number) {
    return requestX.get('mall/discount/' + id)
}

/**
 * 查询我自己的服务店， 未开通返回null
 * @returns 服务店信息
 */
export async function myMall() {
    return requestX.get('mall/store/mine')
}

/**
 * 订单各个状态的订单数量统计
 * @returns 订单数量，依次安 待付款，待发货， 待收货，已完成， 售后顺序。
 */
export async function getOrderCounts(virtual: boolean, storeId?: number) {
    return requestX.get('mall/order/counts', {
        params: {
            virtual,
            storeId
        }
    })
}

type MallModel = {
    storeName?: String, storeLogo?: String, category?: number, intro?: String
}

export async function listMall() {
    return requestX.get('/mall/store/list', {
        params: {
            pageNo: 0
        }
    })
}

/**
 * 我发布的圈子列表
 * @param pageNo 当前页
 * @param pageSize 每页展示的数量
 * @param category 商铺分类
 * @returns 列表
 */
export function getStoreList(category: number | string, pageNo: number = 0, pageSize: number = 20) {
    return async () => requestX.get('/mall/store/list', {
        params: {
            pageNo: pageNo,
            pageSize: pageSize,
            category: category
        }
    })
}

/**
 * 修改店铺信息
 * @param storeLogo "店铺头像",
 * @param storeName "店铺名称",
 * @returns 
 */
export async function setMall(data: MallModel) {
    return requestX.put('mall/store', data)
}

export async function postDemand(data: {
    img: String, title?: String, content?: String, reward: number, category: number
}) {
    return requestX.post('demand/publish', data)
}

export async function getMyClub() {
    return requestX.get("voc/mall/club")
}

export async function createClub(request: {
    img: String, name: String, storeId?: number, category?: number,
    type?: number, theme?: String, intro?: String
}) {
    return requestX.post("voc/mall/club", request)
}

type RecommentRequest = {
    type: number, //type: 0: 购推荐， 1：视频购
    pageNo: number,
    category?: string,
    goodsName?: string,
}
/** 
 * 获取推荐的商品列表
*/
export async function getRecementProducts(pageNo: number, category: string, goodsName?: string, ) {
    return requestX.get(`mall/product/recommend`, {
        params: {
            pageNo: pageNo,
            pageSize: 30,
            category: category,
            name: goodsName,
        }
    })
}

/**
 * 发送手机验证码
 * @param phone 手机号
 * @returns 
 */
export async function sendCode(phone: string) {
    return requestX.get('auth/sendSMS', {
        params: {
            code: 801677,
            phone: phone
        }
    })
}

/**
 * 验证码登录系统
 * @param phone 手机号
 * @param code 验证码
 */
export async function loginWithCode(phone: string, code: string) {
    return requestX.post('auth/loginOrRegisterBySms', {
        code: code,
        phone: phone,
    })
}

/**
 * 账号密码登录
 * @param username 账号
 * @param password 密码
 * @returns 
 */
export async function loginWithPassword(username: string, password: string) {
    return requestX.post('auth/login', {
        username: username,
        password: password,
    })
}



/** 
 * 申请成为店铺
*/
export async function requestJoinPlatform(shop: any) {
    return requestX.post('mall/store', shop)
}



export async function postJournal(content: string) {
    return requestX.post('dynamic/diary', {
        textContent: content
    })
}

export async function getMyJournal() {
    return requestX.get('dynamic/type/4/0')
}

export function getGoodsList(params: any) {
    return async () => requestX.get('mall/product', {
        params: params
    })
}

/**
 * 搜索商品
 * @param keyword 关键词
 * @returns 
 */
export async function searchProduct(keyword: string) {
    return requestX.get('mall/product/search', {
        params: {
            keyword: keyword,
            page: 0,
            size: 20
        }
    })
}

/**
 * 搜索商品
 * @param keyword 关键词
 * @returns
 */
export async function searchVideo(keyword: string) {
    return requestX.get('mall/commend/video', {
        params: {
            keyword: keyword,
            pageNo: 0
        }
    })
}
/**
 * 根据条件查询商品
 * @param category 分类
 * @param storeId 店铺id
 */
export async function getProductList(category?: string, storeId?: string) {
    return requestX.get('mall/product', {
        params: {
            pageNo: 0,
            pageSize: 20,
            category: category,
            storeId: storeId
        }
    })
}

/**
 * 拿特殊的群列表
 * @param type 0: 职业荟， 1俱乐部，2 粉丝群，3: 车友会
 * @returns 
 */
export function getClubList(type: number, creator: number, pageNo = 0) {
    return async () => requestX.get('/voc/group/list', {
        params: {
            type: type,
            pageSize: 20,
            creator: creator,
            pageNo: pageNo,
        }
    })
}

/**
 * 查询创意创商列表
 * @param type 0:创意 1：创投
 * @param category
 * @param userId
 * @param recommend
 * @param pageNo
 * @param pageSize
 * @returns 列表
 */
export function getBusinessList(type: number, category: number, userId?: number, recommend?: boolean, pageNo: number = 0, pageSize: number = 20) {
    return async () => requestX.get(`/bazaar/business/${type}/${pageNo}/${pageSize}`, {
        params: {
            userId: userId,
            category: category,
            recommend: recommend
        }
    })
}


/**
 * 查询关注的人发送的集市动态
 * @param pageNo
 * @param pageSize
 * @returns 列表
 */
export function getFocusDynamicList(pageNo: number = 0, pageSize: number = 20) {
    return async () => requestX.get('/bazaar/dynamic/focus', {
        params: {
            pageNo: pageNo,
            pageSize: pageSize
        }
    })
}
/**
 * 查询投资专家列表
 * @param category
 * @param pageNo
 * @returns 列表
 */
export function getInvestList(category: number = 1, pageNo: number = 0) {
    return async () => requestX.get(`/bazaar/invest/${category}/${pageNo}`)
}

/**
 * 查询线下约玩列表
 * @param type 0:创意 1：创投
 * @param lat
 * @param lon
 * @param gender
 * @returns 列表
 */
export function getOfflineActsList(type: number, lat: number, lon?: number, gender?: String) {
    return async () => requestX.get(`/place/gather`, {
        params: {
            type: type,
            longitude: lon,
            latitude: lat,
            gender: gender
        }
    })
}

/**
 * 免费抢产品
 * @returns 列表
 * @param params
 */
export function getFreeProductList(params: any) {
    return async () => requestX.get(`/mall/free`, {
        params
    })
}
/**
 * 查询人找车、车找人列表
 * @returns 列表
 * @param params
 */
export function getPlaceCar(params: any) {
    return async () => requestX.get('/place/car', {
        params: params
    })
}

/**
 * 查询车车订单列表
 * @returns 列表
 * @param flag
 * @param pageNo
 */
export function getPlaceCarOrder(flag: boolean, pageNo: number = 0) {
    return async () => requestX.get(`/place/car/mine/${flag}/${pageNo}`)
}

/**
 * 接单、搭载
 * @param id
 */
export async function postCarOrder(id: number) {
    return requestX.put(`place/car/reach/${id}`)
}

/**
 * 查询附近购推荐的商家
 * @param category
 * @param lon
 * @param lat
 * @param pageNo
 * @returns 列表
 */
export function getStoreByRecommend(category: number, lon: number, lat: number, pageNo: number = 0) {
    return async () => requestX.get(`/place/recommend/store/list/${pageNo}`, {
        params: {
            category: category,
            lon: lon,
            lat: lat
        }
    })
}
/**
 * 获取我发布的话题
 * @param id 
 */
export function getMyTopicList(id: number | string) {
    return async () => requestX.get(`/bazaar/dynamic/getDynamic/${id}`)

}

/**
 * 查看个人时间轴
 * @param agency 是否查看今日代办
 * @param pageNo
 * @returns 列表
 */
export function getMyTimeLine(agency: boolean, pageNo: number = 0) {
    return async () => requestX.get(`/user/timeline/list/${agency}/${pageNo}`)
}

/**
 * 查询招聘、招人信息
 * @param pageNo,
 * @param type,
 * @param position,
 * @param company,
 * @param companyId,
 * @param udId,
 * @param category
 * @returns 列表
 */
export function getJobs(type: number, category?: number, companyId?: number, udId?: number,
    company?: string, position?: string, pageNo: number = 0) {
    return async () => requestX.get('/bazaar/recruit/list', {
        params: {
            type: type,
            category: category,
            companyId: companyId,
            company: company,
            udId: udId,
            position: position,
            pageNo: pageNo
        }
    })
}

/**
 * 查询加盟信息
 * @param pageNo 当前页
 * @param pageSize 每页显示数量
 * @param id 加盟卡id
 * @param category 分类
 * @param products 主营产品
 * @param recommend 推荐标记位
 * @returns 列表
 */
export function getFranchiseList(id?: number, category?: number, products?: string, recommend?: boolean, pageNo: number = 0, pageSize: number = 20) {
    return async () => requestX.get(`/bazaar/franchise/list/${pageNo}/${pageSize}`, {
        params: {
            id: id,
            category: category,
            products: products,
            recommend: recommend
        }
    })
}

/**
 * 根据店铺类型获取商品
 * @param type, 商铺类型
 * @param index,首页
 * @param category 分类
 * @param name, 商品名
 * @param pageNo,当前页
 * @param pageSize,当前页显示数量
 * @returns 列表
 */
export function getProductByStoreType(index?: string, category?: number, name?: string, type?: number,
    pageNo: number = 0, pageSize: number = 20) {
    return async () => requestX.get('/mall/product/recommend', {
        params: {
            type: type,
            index: index,
            category: category,
            name: name,
            pageNo: pageNo,
            pageSize: pageSize
        }
    })
}

/**
 * 根据店铺类型获取商品
 * @param type 关注类型 0：人,1：店,2：商品,3：动态
 * @param pageNo,当前页
 * @param params 是否分页
 * @returns 列表
 */
export async function getFocusList(type: number = 0, pageNo: number = 0, params: any) {
    return requestX.get(`/bazaar/focus/list/${type}/${pageNo}`, params)
}

/** 查看历史记录 */
export async function getFootPrint() {
    return requestX.get('/mall/foot/print')
}

/**
 * 获取我的粉丝列表
 * @param type 关注类型 0：人,1：店,2：商品,3：动态
 * @param pageNo,当前页
 * @param params 是否分页
 * @returns 列表
 */
export function getFocusMeList(type: number = 0, pageNo: number = 0, params: any) {
    return async () => requestX.get(`/bazaar/fans/${type}/list/${pageNo}`, params)
}

/**
 * 获取关注的店铺、商品、足迹、优惠券的数量
 */
export async function getFocusCount() {
    return requestX.get('/bazaar/fans/count')
}

/**
 * 创建群聊（职业荟、俱乐部、粉丝群、车友会）
 */
export async function vocGroup(params: any) {
    return requestX.post('/voc/group', {
        'img': params.img,
        'name': params.name,
        'category': params.category,
        'type': params.type,
        'intro': params.intro,
        'theme': params.theme,
        'storeId': params.storeId
    })
}

/**
 * 发布创意创投
 */
export async function releaseBusiness(params: any) {
    let view = '';
    if (params.type === 1) {
        view = params?.multiView?.join(',')
    } else {
        view = params?.multiView
    }
    return requestX.post('/bazaar/business', {
        category: params.category,
        type: params.type,
        projectName: params.projectName,
        multiView: view,
        prospectus: params.prospectus,

        demand: params.demand,
        fund: params.fund,
        deadline: params.deadline,
    })
}


/**
 * 发布创意创投
 */
export async function releaseActCar(params: any) {
    return requestX.post('/place/gather', {
        ...params
    })
}

/**
 * 问题广场
 * @param target 被提问的用户id
 * @param content 问题关键字
 * @param userId 用户id
 * @param type 0:问题、1:大咖讲座、2:大咖在线
 * @param category 分类
 * @param pageNo 当前页
 * @returns 列表
 */
export function getProblemList(category: number, target?: number, content?: string, userId?: number, type?: number,
    pageNo: number = 0) {
    if (category === undefined) {
        return
    }
    return async () => requestX.get('/famous/problem/list', {
        params: {
            category: category,
            type: type,
            target: target,
            content: content,
            userId: userId,
            pageNo: pageNo
        }
    })
}

/**
 * 查看问题的答案列表
 * @param id
 * @param pageNo 当前页
 * @returns
 */
export async function getProblemReplyList(id: number, pageNo: number) {
    return requestX.get(`famous/problem/${id}/reply/List/${pageNo}`)
}
/**
 * 大咖在线
 * @param advantage 擅长
 * @param nickname 用户昵称
 * @param category 分类
 * @param pageNo 当前页
 * @returns 列表
 */
export function getFamousList(category: number, advantage?: string, nickname?: string, pageNo: number = 0) {
    if (category === undefined) {
        return
    }
    return async () => requestX.get('/famous/list', {
        params: {
            category: category,
            nickname: nickname,
            advantage: advantage,
            pageNo: pageNo
        }
    })
}

/**
 * 发布招聘、应聘信息
 */
export async function releaseRecruit(params: any) {
    let pos = params.position
    if (params.keyword !== undefined && params.keyword !== '' && params.keyword !== null) {
        pos = pos + '|' + params.keyword
    }
    return requestX.post('/bazaar/recruit', {
        category: params.category,
        type: params.type,
        position: pos,
        area: params.area,
        salaryRange: params.salaryRange,
        content: params.content,
        education: params.education,
        years: params.years,
        state: params.state
    })
}

/**
 * 圈子列表
 * @param pageNo 当前页
 * @param pageSize 每页显示数量
 * @returns 列表
 */
export function getCircleList(pageNo: number = 0, pageSize: number = 20) {
    return async () => requestX.get('/dynamic/friends', {
        params: {
            pageNo: pageNo,
            pageSize: pageSize
        }
    })
}

/**
 * 圈子列表
 * @param pageNo 当前页
 * @param pageSize 每页显示数量
 * @returns 列表
 */
export function getCircleListByType(pageNo: number = 0, pageSize: number = 20) {
    return async () => requestX.get('/dynamic/friends/type', {
        params: {
            pageNo: pageNo,
            pageSize: pageSize
        }
    })
}

/**
 * 我发布的圈子列表
 * @param pageNo 当前页
 * @param pageSize 每页显示数量
 * @returns 列表
 */
export function getMyCircleList(id: number | string, pageNo: number = 0, pageSize: number = 20) {
    return async () => requestX.get('/dynamic/' + id, {
        params: {
            pageNo: pageNo,
            pageSize: pageSize
        }
    })
}

/**
 * 发布车车出行信息
 */
export async function releasePassenger(params: any) {
    return requestX.post('/place/car', {
        ...params
    })
}

export async function collectLeague(id: number) {
    return requestX.post(`/bazaar/franchise/${id}/collect`)
}

/**
 * 关注（收藏）
 * @param type 店铺id
 * @param fid 对象id
 * @returns
 */
export async function addFocus(type: number, fid: number) {
    return requestX.post(`/bazaar/focus/${type}/${fid}`)
}
/**
 * 取消关注（收藏）
 * @param fid 对象id
 * @returns
 */
export async function deleteFocus(fid: number) {
    return requestX.delete(`/bazaar/focus/${fid}`)
}
/**
 * 验证是否关注（收藏）
 * @param fid 对象id
 * @returns
 */
export async function checkFocus(fid: number) {
    return requestX.get(`/bazaar/focus/${fid}`)
}

/* 举报
 * @param type 类型，0：聊天 1：动态 2：集市动态 3：评论 4：即刻心情 5：人
 * @param informants: 被举报人
 * @param reason: 原因
 * @param service_id: 评论id，动态id,等业务的id
 * @returns 
 */
export async function reportContent(params: { type: string, reasons: string, informants: string, service_id: string }) {
    return requestX.post(`/users/report`, params)
}


/**
 * 关注（收藏）
 * @param type 店铺id
 * @param pid 对象id
 * @returns
 */
export async function addWarehouse(type: number, pid: number) {
    return requestX.post(`/mall/collect/${type}/${pid}`)
}
/**
 * 验证是否存在于精选清单（收藏）
 * @param pid 商品id
 * @param type 0：入仓 1：精选清单
 * @returns
 */
export async function checkWarehouse(type: number, pid: number) {
    return requestX.get(`/mall/${type}/collect/${pid}`)
}

/**
 * 订单详情
 * @param id 订单id
 * @returns 
 */
export function orderDetail(id: number | string) {
    return async () => requestX.get('/mall/order/' + id)
}

/**
 * 订单详情
 * @param code 核销码
 * @returns
 */
export function orderDetailByQr(code: number | string) {
    return async () => requestX.get('/mall/order/qr/' + code)
}


/** 店铺的所有门店 */
export function getOutletList(id: string) {
    return async () => requestX.get(`/mall/store/sale/${id}/List`, {
        params: {
            pageNo: 0,
            pageSize: 50
        }
    })
}

/** 订单的回收价 */
export async function getReclaimPrice(id: string) {
    return requestX.get(`/mall/order/buyBack/${id}`).then(res => res.data)
}

/** 回收商品 */
export async function postReclaim(id: string) {
    return requestX.post('/wxPay/wxBuyBack?out_trade_no=' + id).then(res => res.data)
}

// 视频购、购推荐 点赞
export async function videoThumbsUp(mpcId: any) {
    return requestX.post(`/mall/video/${mpcId}/thumb`)
}

// 视频购、购推荐 取消点赞
export async function videoThumbsCancel(mpcId: any) {
    return requestX.put(`/mall/video/${mpcId}/thumb`)
}

export async function postVideoComment(id: number, text: string) {
    return requestX.post(`/mall/video/comment`, {
        text: text,
        mpcId:id
    })
}

/** 
 * 限时团，囤货等商品列表 
 * "actType", value = "活动类型0：限时团，1：预售，2：囤货",
*/
export async function getActGoodsList(page: number, type: number) {
    return requestX.get(`/mall/limit/group`, {
        params: {
            pageNo: page,
            actType: type
        }
    }).then(resp => resp.data)
}

/** 特产详情接口 */
export async function getSpecialtyInfo(id: number | string) {
    return requestX.get(`/mall/specialty/${id}`)
}

/** 发起赠送礼券 */
export async function presentCoupons(id: string | number) {
    return requestX.post('mall/transfer/add', {
        id
    })
}


/// 附近的商家列表
export async function getNearbyShop(pageNo: number, longitude: number, latitude: number) {
    return requestX.get(`/place/mall/store/list/${pageNo}`, {
        params: {
            longitude,
            latitude,
        }
    })
}

export async function getAllCoupons(page: number, store: number, productId: number) {
    return requestX.get("/mall/coupons", {
        params: {
            pageSize: 20,
            pageNo: page,
            pid: productId,
            sid: store,
        }
    })
}

/// 获取商品的活动
export async function getProductActivity(product: number | string) {
    return requestX.get(`/mall/group/product/${product}`)
}