import { CouponsList } from '../components/chooseCoupons'
import { pickCoupons } from '../service/goods'
import { useToast } from '../models/toast'

export default function ShopCouponsPage({ match }) {
    const { shop } = match.params
    const toast = useToast()
    const onChoose = async (item) => {
        // todo 领取成功
        const { data } = await pickCoupons(item.id)
        console.log(data);
        toast.showToast("toast.success", "领取成功")
    }
    return <CouponsList storeId={shop} onChoose={onChoose} />
}