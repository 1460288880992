
import React, { useState, useEffect } from 'react';
import { useTheme, makeStyles, Box, List, ListItem, TextareaAutosize, ButtonBase, MenuItem, FormControl, OutlinedInput } from '@material-ui/core'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import { useAppHistory, useNavigationBar } from "utils/bridge";
import Select from '@material-ui/core/Select';
import { Upload } from '../../components/base';
import { useForm } from "react-hook-form";
import { businessLicense, businessLicenseModify, licenseList } from './interface.ts';
import InfoIcon from '@material-ui/icons/Info'
import { getCategoryByPid } from '../../service/goods';
import { useToast } from "../../models/toast";
import qs from 'qs'

const useStyles = makeStyles({
  store_certification: {
    "&.MuiBox-root nav": {
      borderBottom: '10px solid #F8F8F8'
    },
    "& ::-webkit-input-placeholder": {
      color: ' #BABABA',
      textAlign: 'right',
      padding: '0 6px',
      fontSize: '14px',
      background: '#fff'
    },
    "& textarea": {
      boxSizing: 'border-box',
      maxWidth: '300px',
      padding: '6px 0',
      fontSize: '14px',
      marginBottom: '-8px',
      textAlign: 'right',
      color: '#3F3F3F',
      width: 0,
      minWidth: '170px',
      flexGrow: 1,
      border: 0,
      margin: 0,
      outline: 0,
      resize: 'none'
    },
    '& .PrivateNotchedOutline-root-10': {
      border: 'none'
    },
    '& .makeStyles-input-85': {
      background: '#fff'
    },
    '& .MuiSelect-select:focus': {
      background: '#fff'
    },
    '& .MuiSelect-select.MuiSelect-select': {
      paddingRight: '24px',
      color: '#3F3F3F',
      fontSize: '13px',
      margin: '-16px 0'
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#fff'
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: '#fff'
    }
  },
  tag: {
    color: '#BABABA',
    border: '1px solid #BABABA',
    borderRadius: '30px',
    fontSize: '12px',
    padding: '4px 9px',
    marginLeft: '7px'
  },
  tagChecked: {
    color: '#FDB133',
    border: '1px solid #FDB133',
    borderRadius: '30px',
    fontSize: '12px',
    padding: '4px 9px',
    marginLeft: '7px'
  },
  input: {
    border: 'none',
    outlineStyle: 'none',
    textAlign: 'right',
    fontSize: '14px',
    minWidth: '280px',
    background: '#fff',
    color: '#3F3F3F'
  },
  btn: {
    position: 'fixed',
    bottom: '25px',
    width: '100%',
    maxWidth: '840px',
    "& button": {
      fontSize: 20,
      color: 'white',
      width: '100%',
      padding: 8,
      background: 'linear-gradient(90deg, #FDAE33, #FDC233)',
      borderRadius: '100px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    }
  },
  btnGray: {
    "& button": {
      background: '#ddd',
    }
  },
})
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 0;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function StoreCertification(props) {
  const query = qs.parse(window.location.search, { ignoreQueryPrefix: true })
  const { status } = query //  status: null 去认证, false 去修改， true 去查看
  const [statusValue, setStatusValue] = useState(`${status}`)
  const toast = useToast()
  const history = useAppHistory()
  const classes = useStyles()
  const theme = useTheme();
  const [list, setList] = useState()
  const [detail, setDetail] = useState({})
  const [personName, setPersonName] = useState([]);
  const [categoryList, setCategoryList] = useState([])
  const { register, setValue, handleSubmit, formState: { errors }, clearErrors, watch } = useForm()
  const onSubmit = async reqData => {
    const reqDataList = { ...reqData, category: categoryList.find(el => el.name === reqData.category)?.id || detail?.category, type: 2, udid: list?.ud_id, id: list?.id }
    const { data } =
      statusValue === 'false' //  status: null 去认证, false 去修改， true 去查看
        ? await businessLicenseModify({ ...reqDataList })
        : await businessLicense({ ...reqDataList })
    if (data) {
      toast.showToast("toast.success", "操作成功")
      history.pop()
    }
  };
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    clearErrors('category') // 清除错误提示
    setValue('category', value)
    setPersonName(
      // On autofill we get a the stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };
  // 错误提示语
  const errorsHtml = (register, message) =>
    ((register && message) &&
      (<p style={{ textAlign: 'right', marginRight: '20px', color: '#ea4949', fontSize: '12px' }}>
        <InfoIcon style={{ color: '#ea4949', fontSize: 14, verticalAlign: '-2px', marginRight: '3px' }} />
        {message}
      </p>))
  const uploadFn = (value, url) => {
    setValue(value, url)
    clearErrors(value) // 清除错误提示
  }
  const storeTypeList = [
    { title: '工厂店', type: 0, isChecked: (watch("storeType") || detail?.type) === 0 },
    { title: '线下店', type: 1, isChecked: (watch("storeType") || detail?.type) === 1 },
    { title: '个人店', type: 2, isChecked: (watch("storeType") || detail?.type) === 2 }
  ]

  // 获取店铺列表初始数据
  const storeFn = async () => {
    const { data } = await licenseList(0, 2)
    if (data) {
      setDetail(JSON.parse(data?.[0]?.detail))
      setList(data?.[0])
      const { type, intro, storeName, storeLogo, category } = JSON.parse(data?.[0]?.detail) || {}

      // 初始化初始数据
      setValue('storeType', type)
      setValue('intro', intro)
      setValue('storeName', storeName)
      setValue('storeLogo', storeLogo)
      setValue('category', category)
      setStatusValue(`${data?.[0]?.state}`)
    }
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    storeFn()
    const { data } = await getCategoryByPid(10)
    if (data) {
      const categoryList = data.map(i => i.category)
      setCategoryList([...categoryList])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useNavigationBar({
    title: '店铺认证',
  })
  return (
    <div style={{ background: '#F8F8F8', height: '100%' }}>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Box sx={{ width: '100%', background: '#fff' }}
          classes={{ root: classes.store_certification }}>

          <nav aria-label="store type">
            <List>
              <ListItem disablePadding>
                <span style={{ flex: '1 1 auto' }}>店铺类型</span>
                {storeTypeList.map(el => (
                  <span className={`${el.isChecked ? classes.tagChecked : classes.tag}`} onClick={() => setValue('storeType', el.type)}>{el.title}</span>
                ))}

              </ListItem>
            </List>
          </nav>

          <nav aria-label="store name">
            <List>
              <ListItem disablePadding>
                <span style={{ flex: '1 1 auto' }}>店铺名称</span>
                <input className={classes.input} defaultValue={detail?.storeName} type="text" {...register("storeName", { required: "填写您的店铺名称" })} placeholder="填写您的店铺名称" />
              </ListItem>
              {errorsHtml(errors?.storeName, errors?.storeName?.message)}
            </List>
          </nav>

          <nav aria-label="store avatar">
            <List>
              <ListItem disablePadding>
                <span style={{ flex: '1 1 auto' }}>店铺头像</span>
                <Upload onUpload={url => { uploadFn('storeLogo', url) }}
                  width={35}
                  height={35}
                  borderRadius={100}
                  imgBg={detail?.storeLogo}
                  customize={<></>}
                >店</Upload>

                <ArrowForwardIosIcon style={{ color: '#aaa', fontSize: 16 }} />
                <textarea style={{ opacity: 0, position: 'absolute' }} type="text" {...register("storeLogo", { required: "上传店铺头像" })} placeholder="上传店铺头像" />
              </ListItem>
              {errorsHtml(errors?.storeLogo, errors?.storeLogo?.message)}
            </List>
          </nav>

          <nav aria-label="store brief introduction">
            <List>
              <ListItem disablePadding>
                <span style={{ flex: '1 1 auto' }}>店铺简介</span>
                <Box style={{ fontSize: 14, color: '#3F3F3F' }}>
                  <TextareaAutosize
                    aria-label="填写您的店铺简介"
                    minRows={1}
                    placeholder="填写您的店铺简介"
                    {...register("intro", { required: "填写您的店铺简介" })}
                    defaultValue={detail?.intro}
                  />
                </Box>
              </ListItem>
              {errorsHtml(errors?.intro, errors?.intro?.message)}
            </List>
          </nav>

          <nav aria-label="business category">
            <List >
              <ListItem disablePadding>
                <span style={{ flex: '1 1 auto' }}>经营品类</span>
                <FormControl sx={{ m: 1, width: 300 }}>
                  <Select
                    // multiple
                    displayEmpty
                    value={personName}
                    onChange={e => handleChange(e)}
                    input={<OutlinedInput />}
                    renderValue={(selected) => {

                      if (selected.length === 0) {
                        setValue('category', detail?.category)
                        return !categoryList.find(el => el.id === detail?.category)?.name ? <span style={{ color: '#BABABA' }}>请选择品类</span> : categoryList.find(el => el.id === detail?.category)?.name;
                      }

                      return selected.join(', ');
                    }}
                    MenuProps={MenuProps}
                    inputProps={{ 'aria-label': 'Without label' }}
                  >
                    {categoryList.map((el) => (
                      <MenuItem
                        key={el.id}
                        value={el.name}
                        style={getStyles(el.name, personName, theme)}
                      >
                        {el.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </ListItem>

              <textarea style={{ opacity: 0, position: 'absolute' }} type="text" {...register("category", { required: "请选择品类" })} placeholder="请选择品类" />
              {errorsHtml(errors?.category, errors?.category?.message)}
            </List>
          </nav>

        </Box>
        {statusValue === 'true' ?
          <Box style={{ padding: '15px' }} className={classes.btnGray + ' ' + classes.btn} ><ButtonBase >仅允许查看</ButtonBase> </Box>
          : <Box style={{ padding: '15px' }} className={classes.btn} ><ButtonBase type="submit" >去认证</ButtonBase> </Box>
        }
      </form>
    </div >
  )
}

