
import React, { useEffect, useState } from 'react';
import { makeStyles, Box, List, ListItem, ButtonBase } from '@material-ui/core'
import { useForm } from "react-hook-form";
import InfoIcon from '@material-ui/icons/Info'
import { useAppHistory, useNavigationBar } from "utils/bridge";
import { businessLicense, businessLicenseModify, licenseList } from './interface.ts';
import { useToast } from "../../models/toast";
import qs from 'qs'

const useStyles = makeStyles({
  factory_store_row: {
    "&.MuiBox-root nav": {
      background: '#F8F8F8',
      fontSize: '15px'
    },
    "& ::-webkit-input-placeholder": {
      color: ' #BABABA',
      textAlign: 'right',
      padding: '0 6px',
      fontSize: '14px',
    }
  },
  input: {
    border: 'none',
    outlineStyle: 'none',
    textAlign: 'right',
    fontSize: '14px',
    minWidth: '200px',
    background: '#F8F8F8'
  },
  btn: {
    position: 'fixed',
    bottom: '25px',
    width: '100%',
    maxWidth: '840px',
    "& button": {
      fontSize: 20,
      color: 'white',
      width: '100%',
      padding: 8,
      background: 'linear-gradient(90deg, #FDAE33, #FDC233)',
      borderRadius: '100px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    }
  }
})

export default function CorporateAccount(props) {
  const query = qs.parse(window.location.search, { ignoreQueryPrefix: true })
  const { status } = query //  status: null 去认证, false 去修改， true 去查看
  const toast = useToast()
  const [statusValue, setStatusValue] = useState(`${status}`)
  const classes = useStyles()
  const [list, setList] = useState({})
  const [detail, setDetail] = useState({})
  const { register, handleSubmit, formState: { errors }, setValue } = useForm()
  const history = useAppHistory()
  const onSubmit = async reqData => {
    const { data } =
      statusValue === 'false' //  status: null 去认证, false 去修改， true 去查看
        ? await businessLicenseModify({ ...reqData, type: 1, udid: list?.ud_id, id: list?.id })
        : await businessLicense({ ...reqData, type: 1, udid: list?.ud_id, id: list?.id })
    if (data) {
      toast.showToast("toast.success", "操作成功")
      history.pop()
    }
  };

  // 错误提示语
  const errorsHtml = (register, message) =>
    ((register && message) &&
      (<p style={{ textAlign: 'right', marginRight: '20px', color: '#ea4949', fontSize: '12px' }}>
        <InfoIcon style={{ color: '#ea4949', fontSize: 14, verticalAlign: '-2px', marginRight: '3px' }} />
        {message}
      </p>))

  useNavigationBar({
    title: '对公账户',
  })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    const { data } = await licenseList(0, 1) // 对公账户初始数据
    if (data) {
      setList(data?.[0] || {})
      const detailVaules = JSON.parse(data?.[0]?.detail)
      setDetail(detailVaules)
      // 默认值初始化赋值
      setValue('corporateAccount', detailVaules?.corporateAccount)
      setValue('bank', detailVaules?.bank)
      setValue('location', detailVaules?.location)
      setValue('bankBranch', detailVaules?.bankBranch)
      setStatusValue(`${data?.[0]?.state}`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <div style={{ background: '#F8F8F8', height: '100%' }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box sx={{ width: '100%', bgcolor: 'background.paper' }}
          classes={{ root: classes.factory_store_row }}>
          <nav aria-label="main mailbox folders">
            <List>
              <ListItem disablePadding>
                <span style={{ flex: '1 1 auto' }}>银行开户号</span>
                <input className={classes.input} defaultValue={detail?.corporateAccount} type="text" {...register("corporateAccount", { required: "填写您的卡号" })} placeholder="填写您的卡号" />
              </ListItem>
              {errorsHtml(errors?.corporateAccount, errors?.corporateAccount?.message)}
            </List>
          </nav>

          <nav aria-label="main mailbox folders">
            <List>
              <ListItem disablePadding>
                <span style={{ flex: '1 1 auto' }}>开户银行</span>
                <Box style={{ fontSize: 14, color: '#3F3F3F' }}>
                  <input className={classes.input} defaultValue={detail?.bank} type="text" placeholder="填写您的开户银行"  {...register("bank", { required: "填写您的开户银行" })}
                  />
                </Box>
              </ListItem>
              {errorsHtml(errors?.bank, errors?.bank?.message)}
            </List>
          </nav>
          <nav aria-label="main mailbox folders">
            <List>
              <ListItem disablePadding>
                <span style={{ flex: '1 1 auto' }}>开户银行所在地</span>
                <input className={classes.input} defaultValue={detail?.location} type="text" {...register("location", { required: "填写您的开户银行所在地" })} placeholder="填写您的开户银行所在地" />
              </ListItem>
              {errorsHtml(errors?.location, errors?.location?.message)}
            </List>
          </nav>
          <nav aria-label="main mailbox folders" style={{ paddingBottom: '100px' }}>
            <List>
              <ListItem disablePadding>
                <span style={{ flex: '1 1 auto' }}>开户银行支行名称</span>
                <input className={classes.input} defaultValue={detail?.bankBranch} type="text" {...register("bankBranch", { required: "填写您的开户银行支行名称" })} placeholder="填写您的开户银行支行名称" />
              </ListItem>
              {errorsHtml(errors?.bankBranch, errors?.bankBranch?.message)}
            </List>
          </nav>

        </Box>
        <Box style={{ padding: '15px' }} className={classes.btn} >
          <ButtonBase type="submit" >完成</ButtonBase>
        </Box>
      </form>
    </div>
  )
}

