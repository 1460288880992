import { Box, makeStyles} from '@material-ui/core'
import { useNavigationBar } from "../utils/bridge"
import { StatusBox } from '../components/empty'
import React from 'react'
import { GoodsListItem } from "../components";
import { getGoodsList } from "../service/goods"
import { useRequest } from 'ahooks'


const useStyles = makeStyles((theme) => ({
  
}))

const GoodsListAdapterPage = (props) => {
    const category = props.match.params.category
    const { data, loading, error } = useRequest(getGoodsList({ pageNo: 0, pageSize: 20, category: category }), {
        refreshDeps: [category],
        formatResult: resp => resp.data
    })
    useNavigationBar({ title: '商品列表' })
    const classes = useStyles()

    return (<Box >
         <StatusBox loading={loading} error={error} empty={data}>

        <Box className={classes.header} height='100%'>
        {
                    data?.map(item =>
                        <GoodsListItem item={item} key={item.productVariants?.id || 0} />
                )
            }
        </Box>
        </StatusBox>
    </Box>)
}

export default GoodsListAdapterPage