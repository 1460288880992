import { Box, Button, Chip, Grid, makeStyles } from "@material-ui/core"

import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import { useState } from "react";
import { useAppHistory, useNavigationBar } from "../utils/bridge";
import { GoodsGrid } from 'components';
import { searchProduct } from "service/goods";
import { Empty } from 'components/empty'

const useStyle = makeStyles((theme) => ({
    searchBar: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        borderRadius: '25px',
        backgroundColor: '#F8F8F8',
        padding: theme.spacing(0.5),
        margin: '0px 8px',
    },
    searchButton: {
        background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
        borderRadius: 15,
        border: 0,
        color: 'white',
        height: 30,
        padding: '0px 10px',
        boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
}))

const hotSearch = ['鸡糕', '茶散', '淮安', '捆蹄', '新疆棉', '乳鸽', '龙宫', '优抗力']

const SearchPage = (props) => {
    const classes = useStyle()
    const localhistory = (localStorage.getItem('search_history') ?? '无搜索记录').split(',')

    if (!Array.isArray(localhistory)) {
        localhistory = []
    }
    useNavigationBar({ title: '搜索' })
    const [searchHistory, setSearchHistory] = useState(localhistory)
    const [result, setResult] = useState(null)

    const history = useAppHistory()

    const onTextChange = (e) => {
        const text = e.target.value
        console.log(text);
        if (e.key === "Enter") {
            onSearch(text)
        } else {
            if (!Boolean(text)) {
                setResult(null)
            }
        }
    }


    const goFilter = () => {
        history.push('/filter')
    }

    const onSearch = async (text) => {
        //跳转搜索结果页面
        if (!text in searchHistory) {
            const lastHistory = [text, ...searchHistory].slice(0, 10)
            localStorage.setItem('search_history', lastHistory)
            setSearchHistory(lastHistory)
        }
        const { data } = await searchProduct(text)
        setResult(data)
    }
    const initState = !Boolean(result)
    const emptyState = result && result.length === 0

    return (<Box bgcolor='white' p={1} height={1}>
        <Box className={classes.searchBar} >
            <SearchIcon style={{ color: 'red', marginLeft: '8px' }} />
            <InputBase
                className={classes.input}
                placeholder="搜索产品"
                onKeyPress={onTextChange}
                onChange={onTextChange}
                inputProps={{ 'aria-label': 'search goods' }}
            />
        </Box>

        {
            emptyState && <Empty title={'未找到相关的商品'} />
        }
        {
            result && result.length > 0 && <GoodsGrid goodList={result} />
        }
        {
            initState && <RelativeSearch searchHistory={searchHistory} hotSearch={hotSearch} onSearch={onSearch} />
        }
    </Box>)
}

const RelativeSearch = ({ searchHistory, hotSearch, onSearch }) => {
    const onClickChip = (e) => {
        onSearch(e.target.textContent)
    }
    return <>
        <Box p={1} my={2} color='#666'>历史搜索</Box>
        <Grid container spacing={2}>
            {
                searchHistory.map(item => (<Grid item><Chip label={item} onClick={onClickChip} /></Grid>))
            }
        </Grid>
        <Box p={1} my={2} color='#666'>搜索发现</Box>
        <Grid container spacing={2}>
            {
                hotSearch.map(item => (<Grid item><Chip label={item} onClick={onClickChip} /></Grid>))
            }
        </Grid>
    </>
}

export default SearchPage