import React from 'react'
import { Box, makeStyles } from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog'

import DialogTitle from '@material-ui/core/DialogTitle';
import { getShopCoupons } from '../service/goods'
import Slide from '@material-ui/core/Slide';
import { StatusBoxPro } from './empty';
import dateformat from 'dateformat'

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles(theme => ({
    root: {
        alignItems: 'flex-end',
    },
    dialogPaper: {
        margin: 0,
        width: '100%',
        maxHeight: '70%',
    },
    couponRoot: {
        height: theme.spacing(15),
        backgroundColor: 'white',
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        borderRadius: '8px',
        display: 'flex',
        flexDirection: 'row',
    },
    couponKey: {
        height: '100%',
        width: theme.spacing(15),
        backgroundColor: theme.palette.primary.main,
        borderRadius: '8px 0 0 8px',
        color: 'white',
        fontWeight: 700,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        '&::before': {
            width: '20px',
            height: '20px',
            content: '""',
            display: 'block',
            position: 'absolute',
            background: '#eee',
            left: '-4px',
            borderRadius: '10px'
        },
    },
    couponTitle: {
        color: '#222',
        '&::after': {
            height: '20px',
            content: '""',
            display: 'block',
        }
    },
    tag: {
        fontSize: 10,
        backgroundColor: theme.palette.primary.main,
        borderRadius: 9,
        lineHeight: '16px',
        display: 'inline',
        color: 'white',
        marginTop: '10px',
        padding: '2px 8px',
    }
}))

function getTypePrice(type, price, discountPrecent) {
    if (type === 0) {
        return (<div><Box component='span' fontSize={38}>{discountPrecent / 10}</Box>折</div>)
    } else if (type === 1) {
        return (<div>¥<Box component='span' fontSize={38}>{price / 100}</Box></div>)
    } else {
        return (<div>¥<Box component='span' fontSize={38}>{price / 100}</Box></div>)
    }
}

function getTypeText(type) {
    switch (type) {
        case 0: return "折扣券"
        case 1: return "红包"
        case 2: return "满减券"
        default: return "优惠券"
    }
}

/**
 * 优惠券item
 * @param {item, onChoose} param0 设置优惠券显示数据， 点击事件回调接口
 * @returns 
 */
export const CouponItem = ({ item, onChoose }) => {
    const classes = useStyles()
    const { start, end, type, title, productId, percent, metPrice, state, reducePrice, surplus } = item


    const onClick = () => {
        if (onChoose) {
            onChoose(item)
        }
    }
    return <div className={classes.couponRoot} onClick={onClick}>
        <div className={classes.couponKey}>
            {getTypePrice(type, reducePrice, percent)}
            <Box hidden={!metPrice}>
                满{metPrice}
            </Box>
        </div>
        <Box p={2}>
            <Box className={classes.couponTitle}>
                {title}
            </Box>
            <div className={classes.tag}>{getTypeText(type)}</div>
            <Box mt='10px' color='#888' fontSize={14}>
                {dateformat(new Date(start), 'yyyy.mm.dd')}-
                {dateformat(new Date(end), 'yyyy.mm.dd')}
            </Box>
        </Box>
        <Box flex='1' />
    </div>
}


/**
 * 选择店铺优惠券弹窗
 * @param {} props 
 */
export default function ChooseCouponsDialog(props) {
    const classes = useStyles()
    const { storeId, onClose, value: valueProp, options, onChoose, open, ...other } = props

    return <Dialog
        maxWidth="lg"
        TransitionComponent={Transition}
        onClose={onClose}
        fullWidth
        aria-labelledby="coupons-dialog"
        open={open}
        classes={{
            container: classes.root,
            paper: classes.dialogPaper,
        }}
        {...other} >
        <DialogTitle id="dialog-title">选择优惠券</DialogTitle>
        <CouponsList storeId={storeId} onChoose={onChoose} />
    </Dialog>
}

export function CouponsList({ storeId, onChoose }) {
    return <StatusBoxPro
        request={getShopCoupons(storeId)}
        emptyTitle="没有找到优惠券哦"
        component={list =>
            <Box bgcolor='#eee' padding={1}>
                {
                    list.map(item => <CouponItem key={item.id} item={item} onChoose={onChoose} />)
                }
            </Box>
        } />
}