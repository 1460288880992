
import React, { useEffect, useState } from 'react'
import { Box, ButtonBase, makeStyles, Avatar } from "@material-ui/core"

import { getDeamandList } from "service/goods";
import { setNavigationBar, useAppHistory } from 'utils/bridge'
import { ServiceTab, SearchBar } from './base'
import { priceCast } from '../../utils/price';

const useStyle = makeStyles(theme => ({
    deamandItem: {
        boxShadow: '0px 4px 12px 0px rgba(0, 0, 0, 0.05)',
        margin: '8px 16px',
        padding: theme.spacing(1),
        height: 130,
        minHeight: 130,
        backgroundColor: 'white',
        borderRadius: '4px',
        display: 'flex',
        flexDirection: 'row',
    },
    itemTag: {
        borderRadius: '4px',
        fontSize: '9px',
        color: '#2584F7',
        border: '1px solid #2584F7',
        padding: '2px 6px',
        marginTop: '2px',
    },
    itemTagContained: {
        color: 'white',
        padding: '2px 6px',
        borderRadius: '4px',
        fontSize: '9px',
        background: 'linear-gradient(-45deg, #5BA0F4, #2584F7)',
    },
    price: {
        color: '#e12',
        fontSize: '16px',
        fontWeight: 500,
        '&::before': {
            content: '"¥"',
            fontSize: '12px',
        }
    },
}))

const ServiceForthwithPage = (props) => {
    const [currentIndex, setIndex] = useState(null)
    const [deamandList, setDeamandList] = useState([])
    setNavigationBar({
        title: '服务达人在线',
        darkBar: true,
        bgColor: '#2584F7',
    })
    const onChoose = (i) => {
        setIndex(i)
    }
    useEffect(async () => {
        if (currentIndex) {
            const { data } = await getDeamandList(currentIndex.id ,1 ,0)
            if (data && data.data) {
                console.log(data.data)
                setDeamandList(data.data)
                return
            }
        }
        setDeamandList([])
    }, [currentIndex])

    return <Box display='flex' height={1} flexDirection='column'>
        <SearchBar />
        <ServiceTab onChange={onChoose} />
        <Box flex={1} overflow='scroll' display='flex' flexDirection='column' >
            {
                deamandList.map(item => <ServiceForthItem key={item} {...item} />)
            }
        </Box>

    </Box>
}

const ServiceForthItem = ({ title, reward, category, registerId, logo }) => {
    const classes = useStyle()
    const history = useAppHistory()
    return <ButtonBase className={classes.deamandItem}
        onClick={() => history.push('/service/shop/' + registerId)}
    >
        <Box marginLeft={1}>
            <Avatar variant='rounded' style={{ width: 100, height: 100 }} src={logo} />
        </Box>
        <Box fontSize={18} flex='1' textAlign='left' p={1} display='flex' flexDirection='column' height='100%'>
            {title}
            <Box display='flex' alignItems='center'>
                <Box className={classes.itemTag} >类别</Box>
            </Box>
            <Box flex='1' />
            <Box fontSize={13} >
                <Box className={classes.price} component='span'>{priceCast(reward)}</Box>
                <Box style={{ float: 'right' }} className={classes.itemTagContained}>沟通</Box>
            </Box>
        </Box>
    </ButtonBase>
}

export default ServiceForthwithPage