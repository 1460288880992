import { Box, Button, Dialog, makeStyles, DialogTitle, Checkbox } from "@material-ui/core"
import FormControl from '@material-ui/core/FormControl';
import React, { useState, useEffect, useCallback } from "react";
import Slide from '@material-ui/core/Slide';

import { getFocusList } from "service/goods";
import { SectionRow } from "../components/section"
import { StatusBoxPro } from "../components/empty";
import { GoodsListItem } from "../components";
import { toast, useAppHistory, useNavigationBar } from "../utils/bridge";
import {UploadVideo} from "../components/base";
import { shpppingComment } from "../pages/afterSale/interface";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  root: {
    width: '-webkit-fill-available',
    overflowY: 'scroll',
  },
  dialogRoot: {
    alignItems: 'flex-end',
    '& .MuiIconButton-label:hover, .MuiIconButton-root': {
      background: '#fff !important'
    },
  },
  dialogPaper: {
    margin: 0,
    width: '100%',
    maxHeight: '70%',
  },
  btn: {
    position: 'fixed',
    bottom: '25px',
    width: '100%',
    maxWidth: '840px',
    "& button": {
      fontSize: 20,
      color: 'white',
      width: '100%',
      padding: 8,
      background: 'linear-gradient(90deg, #C90154, #E9097A)',
      borderRadius: '100px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    }
  },
  textContent: {
    backgroundColor: 'white',
    padding: 16,
    marginBottom: 8,
    display: 'flex',
    alignItems: 'start',
    fontSize: '15px',
    flexDirection: 'column',
  }
}))

const PublicVideo = (props) => {
  const classes = useStyles()
  const history = useAppHistory()

  const [showCouponDialog, setCouponsDialog] = useState(false)

  const [goods, setGoods] = useState()
  const [title, setTitle] = useState()

  const [urls, setUrls] = useState('')


  useNavigationBar({ title: '发布视频购' })

  const onChoose = (item) => {
    console.log(item);
    setGoods(item)
    setCouponsDialog(false)
  }

  const onChooseVideo = (url) => {
    setUrls(url)
  }

  const publish = async () => {
    const { data } = await shpppingComment({
      type: 1,
      textContent:title,
      fileContent: urls,
      productVariantId: goods?.pvId }
    )
    console.log(data)
    toast("发布成功")
    history.pop()
  }

  return <FormControl className={classes.root}>

    <Box className={classes.textContent} >
      <Box marginBottom={1}>标题</Box>
      <textarea placeholder='请填写你的标题' onChange={e => setTitle(e.target.value)} style={{ width: '100%', height: '200px' }} />
    </Box>


    <SectionRow title="关联商品" onClick={() => setCouponsDialog(true)}>
      {goods?.name}
    </SectionRow>

    <Box display="flex" flexDirection='column' mt mx={2}>
      <div style={{ marginBottom: 8 }}>上传视频</div>
      <UploadVideo height={180} onUpload={onChooseVideo} />
    </Box>

    <Box style={{ padding: '15px' }} className={classes.btn} ><Button onClick={publish}>发布</Button> </Box>

    <ChoooseGoodsList
        open={showCouponDialog}
        onChoose={onChoose}
        onClose={() => setCouponsDialog(false)} />
  </FormControl >
}

const ChoooseGoodsList = ({ open, onClose, onChoose }) => {
  const classes = useStyles()
  const goodsGrid = useCallback((result) => <Box bgcolor='white'>
    <Box height="16px" bgcolor='#eee' />
    {
      result?.map(item =>
          <GoodsListItem
              onClick={() => { onChoose(item) }}
              item={item} key={item.productVariants?.id || item.id} />
      )
    }</Box>, [])

  return <Dialog
      maxWidth="lg"
      TransitionComponent={Transition}
      onClose={onClose}
      fullWidth
      aria-labelledby="coupons-dialog"
      open={open}
      classes={{
        container: classes.dialogRoot,
        paper: classes.dialogPaper,
      }} >
    <DialogTitle id="dialog-title">我关注的商品</DialogTitle>
    <StatusBoxPro
        request={async () => getFocusList(2, 0, { page: false })}
        emptyTitle="还未关注任何商品哦，快去商城逛一逛吧"
        component={goodsGrid}
    />
  </Dialog>
}

export default PublicVideo