import { Box, makeStyles } from '@material-ui/core'
import { setNavigationBar } from '../utils/bridge'
import useFetch from '../utils/useFetch'
import dateformat from 'dateformat'
import { StatusBox } from '../components/empty'
import { useMemo } from 'react'


const useStyles = makeStyles(theme => ({
    root: {
        overflow: 'scroll',
        padding: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch'
    },
    couponRoot: {
        height: theme.spacing(15),
        backgroundColor: 'white',
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        borderRadius: '8px',
        display: 'flex',
        flexDirection: 'row',
    },
    couponKey: {
        height: '100%',
        width: theme.spacing(15),
        backgroundColor: theme.palette.primary.main,
        borderRadius: '8px 0 0 8px',
        color: 'white',
        fontWeight: 700,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        '&::before': {
            width: '20px',
            height: '20px',
            content: '""',
            display: 'block',
            position: 'absolute',
            background: '#eee',
            left: '10px',
            borderRadius: '10px'
        },
    },
    couponTitle: {
        color: '#222',
        '&::after': {
            height: '20px',
            content: '""',
            display: 'block',
        }
    },
    tag: {
        fontSize: 10,
        backgroundColor: theme.palette.primary.main,
        borderRadius: 9,
        lineHeight: '16px',
        display: 'inline',
        color: 'white',
        marginTop: '10px',
        padding: '2px 8px',
    },
}))
const CheckedIcon = () => {
    const style = {
        width: 40,
        height: 40,
        padding: '8px'
    }
    return <img style={style} src={window.ossUrl+'/html/img/check.svg'} />
}

// 待领取的优惠券
export const CouponItem1 = ({ coupon, onChoose }) => {
    const classes = useStyles()
    const {
        type,
        startTime,
        endTime,
        title,
        discountPercent,
        metPrice,
        reducePrice } = coupon
    const onClick = () => {
        if (onChoose) {
            onChoose(coupon)
        }
    }
    const dateRange = useMemo(() => {
        if (startTime && endTime) {
            return dateformat(new Date(startTime), 'yyyy.mm.dd') + '-' +
                dateformat(new Date(endTime), 'yyyy.mm.dd')
        } else {
            return "-"
        }
    }, [startTime, endTime])
    return <div className={classes.couponRoot} onClick={onClick}>
        <div className={classes.couponKey}>
            {getTypePrice(type, reducePrice, discountPercent)}
            <Box hidden={!metPrice}>
                满{metPrice / 100}
            </Box>
        </div>
        <Box p={2} flex={1}>
            <Box className={classes.couponTitle}>
                {title}
            </Box>
            <Box display='flex' alignItems='center'>
                <div className={classes.tag}>{getTypeText(type)}</div>
                <Box flex='1' />
                <Box display='flex' alignItems='center' >
                    领取
                </Box>
            </Box>

            <Box mt='10px' color='#888' fontSize={14}>
                {dateRange}
            </Box>
        </Box>

    </div>
}

// 我拥有的优惠券
export const CouponItem = ({ coupon, isSelected, onChoose }) => {
    const classes = useStyles()
    const {
        discountType,
        discountTitle,
        startTime,
        expire,
        state,
        discountPercent,
        metPrice,
        reducePrice } = coupon
    const onClick = () => {
        if (onChoose) {
            onChoose(coupon)
        }
    }
    return <div className={classes.couponRoot} onClick={onClick}>
        <div className={classes.couponKey}>
            {getTypePrice(discountType, reducePrice, discountPercent)}
            <Box hidden={!metPrice}>
                满{metPrice / 100}
            </Box>
        </div>
        <Box p={2}>
            <Box className={classes.couponTitle}>
                {discountTitle}
            </Box>
            <div className={classes.tag}>{getTypeText(discountType)}</div>
            <Box mt='10px' color='#888' fontSize={14}>
                {dateformat(new Date(startTime), 'yyyy.mm.dd')}-
                {dateformat(new Date(expire), 'yyyy.mm.dd')}
            </Box>
        </Box>
        <Box flex='1' />
        <Box display='flex' alignItems='center' p={2}>
            {isSelected ? <CheckedIcon /> : null}
        </Box>
    </div>
}

export const ChooseCouponPage = ({ couponList, selected, onChoose }) => {

    const classes = useStyles()
    const selectdList = selected ? selected.map(i => i.id) : []
    return <div className={classes.root}
        onClick={e => {
            //阻止冒泡
            e.stopPropagation();
            e.nativeEvent.stopImmediatePropagation()
        }}>
        {
            couponList.map(item => <CouponItem
                key={item.id}
                coupon={item}
                isSelected={item.id in selectdList}
                onChoose={onChoose} />)
        }
    </div>

}

const MyCouponPage = (props) => {
    const classes = useStyles()
    setNavigationBar({
        title: '我的优惠券',
        bgColor: '#FFFFFF',
    })
    const { error, data } = useFetch('/mall/discount/mine')
    const couponList = data?.data ?? []
    return <StatusBox
        error={error}
        errorTitle="加载出错"
        empty={data}
        emptyTitle="没有优惠券哦，快去逛一逛"
    >
        <div div className={classes.root}>
        {
            couponList.map(item => <CouponItem key={item.id} coupon={item} />)
        }
        </div>
    </StatusBox>
}

function getTypePrice(type, price, discountPrecent) {
    if (type === 0) {
        return (<div><Box component='span' fontSize={38}>{discountPrecent / 10}</Box>折</div>)
    } else if (type === 1) {
        return (<div>¥<Box component='span' fontSize={38}>{price / 100}</Box></div>)
    } else {
        return (<div>¥<Box component='span' fontSize={38}>{price / 100}</Box></div>)
    }
}

function getTypeText(type) {
    switch (type) {
        case 0: return "折扣券"
        case 2: return "红包"
        case 1: return "满减券"
        default: return "优惠券"
    }
}


export default MyCouponPage