import { Box, makeStyles, Grid } from "@material-ui/core"
import { useEffect, useState } from "react";

import { GoodsGrid } from "components";
import { ImageButton } from "components/base";
import useFetch from "utils/useFetch";
import { setNavigationBar, useAppHistory } from "utils/bridge";
import { getCategoryByPid } from "service/goods";
import { SearchPanel, GroupBuying, DemandAndOnlineService, GoodsItem } from './base'

const useStyles = makeStyles((theme) => ({
    itemTitle: {
        fontSize: 18,
        fontWeight: 500,
    },
}))

const ServiceSubMallPage = ({ match }) => {
    const classes = useStyles()
    const history = useAppHistory()
    const [categoryList, setCategoryList] = useState([])

    useEffect(() => {
        setNavigationBar({
            title: '服务',
            darkBar: true,
            bgColor: '#2584F7',
        })
        async function loadData() {
            const { data } = await getCategoryByPid(match.params.id)
            if (data) {
                setCategoryList(data)
            }
        }
        loadData()
    }, [])
    const { data } = useFetch('mall/product/recommend', {
        params: {
            pageNo: 0,
            pageSize: 10,
            category: match.params.id,
        }
    })
    const onClickCategroy = (category, name) => {
        console.log(category)
        if (category < 0) {
            history.push('/service/category')
        } else {
            history.push('/service/list/' + category)
        }
    }
    return (<Box overflowY='scroll' height='100%'>
        <SearchPanel />

        <Box display='flex' flexDirection='row' flexWrap='wrap'>
            {
                categoryList.map(({ category: item }) => <ImageButton key={item.id}
                    name={item.name} icon={item.imgUrl} size='65'
                    onClick={() => onClickCategroy(item.id, item.name)}
                    style={{ width: '20%' }} />)
            }
        </Box>

        <GroupBuying />

        <DemandAndOnlineService />

        <Box mx={2} className={classes.itemTitle}>为您推荐</Box>

        <GoodsGrid goodList={data?.data || []} />

    </Box>)
}

export default ServiceSubMallPage