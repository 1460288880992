import { Box, ButtonBase, makeStyles, Avatar, Divider } from '@material-ui/core'
import { useNavigationBar } from '../utils/bridge';
import {useRequest} from "ahooks";
import { getFocusList, getFocusMeList } from "../service/goods";
import {followUser, removeFollowUser} from "../models/account";
import {useToast} from "../models/toast";
import {useDispatch} from "react-redux";
import {useAccount} from "../utils/hooks";
// 我的粉丝
const useStyles = makeStyles((theme) => ({
    header: {
        background: ''
    }
}))
const titleMap = {
    "yue_share_fans": "我的粉丝",
    "yue_share_follows": "我的关注",
}
const categoryMap = {
    "fans": 1,
    "follow": 2,

}

const MyFansPage = ({ match }) => {
    const classes = useStyles()
    const title = titleMap[match.params.place] ?? '我的粉丝'
    const topCategory = categoryMap[match.params.place] ?? 1
    useNavigationBar({
        title: title
       
    })

    let request;
    if (title == '我的粉丝'){
        request = getFocusMeList(0,0, {})
    }else{
        request = async () => getFocusList(0,0,{})
    }

    const { data, refresh, error } = useRequest(request, {
        formatResult: res => res.data
    })

    return (<Box bgcolor='white' height='100%'>
        <Box className={classes.header}>
            {
                data?.map(item => {
                        if (title == '我的粉丝'){
                            return <FollowersItem key={item} {...item} />
                        }else{
                            return <FansItem key={item} refresh={refresh}  {...item} />
                        }
                    }
                )
            }
        </Box>
    </Box>)
}
const FansItem = (props) => {
    const classes = useStyles()
    const toast = useToast();
    const dispatch = useDispatch();

    const postFocus = async () => {
        await removeFollowUser(props.user,dispatch)
        toast.showToast('toast.success','已取关')
        props.refresh()
    }

    return <Box display='flex' flexDirection='column'>
        <Box m={2} style={{ textAlign: 'left' }}>
            <Box display='flex' flexDirection='row' mx={1}>
                <Avatar src={props.img}/>
                <Box display='flex' flexDirection='column' mx={1}>
                    <Box fontSize={17}>{props.nickname}</Box>
                    <Box fontSize={13} color='#787878' style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }} width={220}>
                        {props.personality}</Box>
                </Box>
                <Box flex={1}></Box>
                <ButtonBase >
                    <Box display='flex' alignItems='center' justifyContent='center' ml={0.5} style={{
                        width: 60, height: 26, borderRadius: 4,
                        borderColor: '#b2b2b2', color: '#b2b2b2',
                        borderStyle: 'solid',
                        borderWidth: '1px',
                    }}>
                        <div style={{ fontSize: 13, }} onClick={postFocus}>已关注</div>
                    </Box>
                </ButtonBase>
            </Box>

        </Box>
        <Divider style={{ marginLeft: 8 }} />
    </Box>
}

const FollowersItem = (props) => {
    const toast = useToast();
    const dispatch = useDispatch();
    const { myself,followList } = useAccount()
    const focusText = followList?.map(i => i.user)?.includes(props.user) ? "已关注" : "关注"
    const postFocus = async () => {
        if(focusText == '关注'){
            await followUser(props.user,dispatch)
            toast.showToast('toast.success','已关注')
        }else{
            await removeFollowUser(props.user,dispatch)
            toast.showToast('toast.success','已取关')
        }
    }


    return <Box display='flex' flexDirection='column'>
        <Box m={2} style={{ textAlign: 'left' }}>
            <Box display='flex' flexDirection='row' mx={1}>
                <Avatar src={props.img}/>
                <Box display='flex' flexDirection='column' mx={1}>
                    <Box fontSize={17}>{props.nickname}</Box>
                    <Box fontSize={13} color='#787878' style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }} width={220}>
                        {props.personality}</Box>
                </Box>
                <Box flex={1}></Box>
                <ButtonBase >
                    <Box display='flex' alignItems='center' justifyContent='center' ml={0.5} style={{
                        width: 60, height: 26, borderRadius: 4,
                        borderColor: '#b2b2b2', color: '#b2b2b2',
                        borderStyle: 'solid',
                        borderWidth: '1px',
                    }}>
                        <div style={{ fontSize: 13, }} onClick={postFocus}>{focusText}</div>
                    </Box>
                </ButtonBase>
            </Box>

        </Box>
        <Divider style={{ marginLeft: 8 }} />
    </Box>
}
export default MyFansPage