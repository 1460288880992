import { Box, ButtonBase, makeStyles} from "@material-ui/core";
import React from 'react'


import { setNavigationBar, useAppHistory } from "../utils/bridge";
import Search from '@material-ui/icons/Search'
import StoreProductItem from "../components/storeProductItem";
import {useRequest} from "ahooks";
import {getStoreList} from "../service/goods";
// 店铺列表
const useStyles = makeStyles((theme) => ({
    searchPanel: {
        width: '100%',
        background: 'radial-gradient(circle, rgba(253,174,51,1) 0%, rgba(253,194,51,1) 100%)',
        padding: theme.spacing(2),
    },
  
    searchBar: {
        width: '100%',
        height: 30,
        backgroundColor: 'white',
        borderRadius: 22,
        textAlign: 'left',
        color: '#999',
        fontSize: '16px',
        paddingLeft: '16px',
        justifyContent: 'start'
    },

}))
const StoreListAdapterPage = (props) => {
    const classes = useStyles()
    const history = useAppHistory()
    const category = props.match.params.category
    const gotoSearch = () => {
        history.push('/service/search')
    }
 


    React.useEffect(async () => {
        setNavigationBar({
            title: '店铺列表',
            darkBar: true,
            bgColor: '#fdc133',
        })
   
    }, [])


    const { mutate,data, loading, error } = useRequest(getStoreList(category,0,20), {
        formatResult: resp => resp.data
    })


    return <Box >

        <Box className={classes.searchPanel} display='flex' flexDirection='row' >
            <ButtonBase className={classes.searchBar} onClick={gotoSearch}>
                <Search />
                搜索您想要的...
            </ButtonBase>
        </Box>

        {
            //便利商铺item
            data?.map(item =>
                <RestaurantItem key={item} {...item} />
            )
        }
    </Box>

}


const RestaurantItem = (props) => {
    const history = useAppHistory();
    return <Box display='flex' flexDirection='column' p={2} onClick={() => history.push(`/shop/${props.id}`)}>

        <Box display='flex' flexDirection='row'>
            <ButtonBase style={{ textAlign: 'left' }}>
                <img height={44} width={44} src={props.storeLogo}/>
                <Box display='flex' height={1} flexDirection='column' px={1}>
                    <text>{props.storeName}</text>
                    <Box display='flex' fontSize='12px' flexDirection='row' color='#858585'>
                        <text>简介：</text>
                        <text>{props.intro}</text>
                    </Box>

                </Box>
            </ButtonBase>
            <Box flex={1}></Box>
            <ButtonBase  >
                <Box display='flex' alignItems='center' justifyContent='center' ml={0.5} style={{
                    width: 60, height: 26, borderRadius: 20,
                    borderColor: '#fdae33', color: '#fdae33',
                    borderStyle: 'solid',
                    borderWidth: '1px',
                }}>
                    <text style={{ fontSize: 13, }}>+关注</text>

                </Box>
            </ButtonBase>
        </Box>

        <StoreProductItem {...props} />
        {/*<ButtonBase width='100%'  >
            <Box display='flex' flexDirection='row'  my={2} alignItems='center' width='100%' justifyContent='space-between'> 
             space-around

                <Box>
                    <img height={90} width={90}></img>
                </Box>
                <Box >
                    <img height={90} width={90}></img></Box>
                <Box >
                    <img height={90} width={90}></img>
                </Box>

            </Box>
        </ButtonBase>*/}

    </Box>
}

export default StoreListAdapterPage