import { Box, makeStyles } from '@material-ui/core'
import React from 'react'

const useStyle = makeStyles(theme => ({
    inputItem: {
        backgroundColor: 'white',
        marginTop: '16px',
        borderRadius: 8,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: 12,
    },
    input: {
        border: 'none',
        outlineStyle: 'none',
        textAlign: 'right',
        fontSize: '14px',
    }
}))

export const InputItem = ({ title, type, placeHolder, ...rest }) => {
    const classes = useStyle()
    return <Box className={classes.inputItem}>
        {title}
        <Box flex='1' />
        <input className={classes.input}
            type={type ?? 'text'}
            placeholder={placeHolder}
            {...rest}
        ></input>
    </Box>
}

export const InputItemRef = React.forwardRef(({ title, type, placeHolder, ...rest }, ref) => {
    const classes = useStyle()
    return <Box className={classes.inputItem}>
        {title}
        <Box flex='1' />
        <input className={classes.input}
            ref={ref}
            type={type ?? 'text'}
            placeholder={placeHolder}
            {...rest}
        ></input>
    </Box>
})
