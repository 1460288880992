import { Box, Button, Typography, TextField, makeStyles } from '@material-ui/core'
import { useState, useEffect } from 'react'
import { requestX } from '../../utils/request'
import { sendSms as sendSmsApi, loginWithPassword } from 'service/goods'
import { toastLevel, useToast } from '../../models/toast'
import { connect } from 'react-redux'

import * as Actions from 'models/account';
import { CheckBox } from '@material-ui/icons'

const useStyle = makeStyles({
    root: {},
    form: {
        display: 'flex',
        flexDirection: 'column',
    }
})

const LoginWithCode = ({ loginEvent, sendSms }) => {
    const classes = useStyle()
    const [phoneVaild, setPhoneVaild] = useState(false)
    const [codeVaild, setCodeVaild] = useState(false)
    const [phone, setPhone] = useState()
    const [code, setCode] = useState()

    const [timeLeft, setTimeLeft] = useState(-2)
    const toast = useToast()

    useEffect(() => {
        if (timeLeft === -2) {
            return
        }
        const timer = setTimeout(() => {
            setTimeLeft(timeLeft - 1)
        }, 1000);
        return () => clearTimeout(timer)
    })

    const sendCode = async () => {
        //发送验证码
        setTimeLeft(60)
        const result = await sendSmsApi(phone, 801677)
        if (result.status > 300) {
            setTimeLeft = -2
        } else {
            toast.showToast(toastLevel.ToastSuccess, "发送成功")
        }

    }
    const login = () => {
        loginEvent(phone, code)
    }
    return <>
        <Box marginTop={2} display='flex'
            alignItems='center' justifyContent='center'>
            <Typography variant='h5'>手机号登录/注册</Typography>
        </Box>

        <Box noValidate className={classes.form} autoComplete="off" >
            <Box height='50px' />
            <TextField id="outlined-basic"
                type='number'
                value={phone}
                onChange={event => {
                    const phoneNumber = event.target.value
                    const vaild = /^1[3456789]\d{9}$/.test(phoneNumber)
                    setPhoneVaild(vaild)
                    setPhone(phoneNumber)
                }}
                size="small" label="手机号" variant="outlined" />
            <Box height='20px' />
            <Box display='flex' justifyContent='space-between'>
                <TextField id="outlined-basic"
                    size="small"
                    label="验证码"
                    type='number'
                    value={code}
                    variant="outlined"
                    onChange={event => {
                        const codeNumber = event.target.value
                        setCode(codeNumber)
                        setCodeVaild(/\d{4,6}/.test(codeNumber))
                    }} />

                <Box width={8} />
                <Button variant='outlined'
                    onClick={sendCode} disabled={timeLeft > 0 || !phoneVaild}>
                    {timeLeft === -2 ? '发送验证码' : `剩余${timeLeft}秒`}
                </Button>
            </Box>

            <Box height='20px' />
            <Button
                variant='contained'
                color='primary'
                disabled={!(phoneVaild & codeVaild)}
                onClick={login}>
                登录
            </Button>
        </Box>
    </>
}

const LoginWithPassword = ({ loginPassword }) => {
    const classes = useStyle()
    const [phoneVaild, setPhoneVaild] = useState(false)
    const [codeVaild, setCodeVaild] = useState(false)
    const [account, setAccont] = useState()
    const [password, setPassword] = useState()

    const toast = useToast()
    const login = async () => {
        loginPassword(account, password, toast)
    }
    return <>
        <Box marginTop={2} display='flex'
            alignItems='center' justifyContent='center'>
            <Typography variant='h5'>账号登录</Typography>
        </Box>

        <Box noValidate className={classes.form} autoComplete="off" >
            <Box height='50px' />
            <TextField id="outlined-basic"
                value={account}
                inputProps={{
                    maxLength: "11"
                }}
                onChange={event => {
                    const username = event.target.value
                    const vaild = /^\w{5,15}$/.test(username)
                    setPhoneVaild(vaild)
                    setAccont(username)
                }}
                size="small" label="账号" variant="outlined" />
            <Box height='20px' />
            <TextField id="outlined-basic"
                size="small"
                label="密码"
                value={password}
                variant="outlined"
                onChange={event => {
                    const pwd = event.target.value
                    setPassword(pwd)
                    setCodeVaild(/\w{4,12}/.test(pwd))
                }} />

            <Box height='20px' />
            <Button
                variant='contained'
                color='primary'
                disabled={!(phoneVaild & codeVaild)}
                onClick={login}>
                登录
            </Button>
        </Box>
    </>
}

const Login = (props) => {
    const [loginType, setLoginType] = useState(true)
    const toggleType = () => {
        setLoginType(!loginType)
    }

    return <Box p={2} height='100%' display="flex" alignItems="center" justifyContent="center" >
        <Box bgcolor='white' borderRadius='10px' p={2} position="relative" margin="auto" maxWidth="80%" >
            {
                loginType ? <LoginWithCode {...props} /> : <LoginWithPassword {...props} />
            }
            <Button onClick={toggleType}>
                {toggleType ? "账号密码登录" : "手机号登录 "}
            </Button>
            <Box display='flex' alignItems='center' marginTop={2}>
                <CheckBox color='secondary' ></CheckBox>
                <Box marginLeft={1} fontSize={12}>
                    登录即代表您同意<a href="https://www.qihuaitech.com/app_user_police.html">《用户许可协议》</a>和<a href="https://www.qihuaitech.com/user_privacy.html">《用户隐私协议》</a>
                </Box>
            </Box>
        </Box>
    </Box >
}




export const LoginPage = connect(({ account }) => (account), Actions)(Login)