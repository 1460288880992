import * as api from '../service/goods'
import { showToast } from './toast'
import produce from "immer"

const CartType = {
    AddToCart: "cart.add",
    ListCart: "cart.list",
    ChangeNumberCart: 'cart.change.number',
    CheckdCartItem: 'cart.checked',
    CheckdShopItem: 'cart.shopChecked',
    CheckdAllCartItem: 'cart.checkedAll',
    ClearCart: 'cart.clear',
    RemoveFromCart: 'cart.remove',
    CartBuy: 'cart.buy',
}


const initalState = {
    groupCart: [],
    loaded: false,
    preOrders: [] //暂存用于提交订单
}

export const cartReducer = produce((draft, action) => {
    switch (action.type) {
        case CartType.ListCart:
        case CartType.RemoveFromCart:
        case CartType.ChangeNumberCart:
        case CartType.AddToCart:
            let groupCart = []
            action.payload.forEach(cart => {
                let i = cart.store?.id
                const localShop = draft.groupCart.find(shop => shop.i === i)
                const item = groupCart.find(el => el.i === i)
                if (localShop) {
                    const c = localShop.list.find(c => cart.id === c.id)
                    cart.checked = c?.checked
                }
                if (item) {
                    item.list.push(cart)
                } else {
                    groupCart.push({
                        i,
                        checked: localShop?.checked ?? false,
                        shop: cart.store,
                        list: [cart]
                    })
                }
            })
            console.log(groupCart);
            draft.groupCart = groupCart
            draft.loaded = true
            break;
        case CartType.CheckdAllCartItem:
            draft.groupCart.forEach(shop => {
                shop.list.forEach(cart => {
                    cart.checked = action.payload
                })
            })
            break
        case CartType.CheckdShopItem: {
            const [id, checked] = action.payload
            draft.groupCart.forEach(shop => {
                if (shop.i === id) {
                    shop.checked = checked
                    shop.list.forEach(cart => cart.checked = checked)
                } else {
                    shop.checked = false
                    shop.list.forEach(cart => {
                        cart.checked = false
                    })
                }
            })
            break
        }
        case CartType.CheckdCartItem: {
            const [id, shopId, checked] = action.payload
            draft.groupCart.forEach(shop => {
                if (shop.i === shopId) {
                    shop.checked = true
                    shop.list.forEach(cart => {
                        if (cart.id === id) {
                            cart.checked = checked
                        }
                    })
                } else {
                    shop.checked = false
                    shop.list.forEach(cart => {
                        cart.checked = false
                    })
                }
            })
            break
        }
        case CartType.CartBuy:
            const selectCart = groupCart.find(item => item.checked === true)?.list?.filter(item => item.checked) ?? []
            draft.preOrders = selectCart
            break
        case CartType.ClearCart:
            draft.groupCart = []
            draft.loaded = true
            break
        default:
            break
    }
}, initalState)

export const getAllCartsAction = () => async dispatch => {
    const res = await api.getAllCart()
    const data = res.data ?? { cartItems: [] }
    const carts = data.cartItems

    dispatch({
        type: CartType.ListCart,
        payload: carts,
    })
}

export const changItemNumberAction = (number, cartId) => async dispatch => {
    const res = await api.changeCartNumber(number, cartId)
    const carts = res.data.cartItems
    dispatch({
        type: CartType.ChangeNumberCart,
        payload: carts,
    })
}

const checkShop = (id, checked) => ({
    type: CartType.CheckdShopItem,
    payload: [id, checked]
})

const checkedItem = (id, shopId, checked) => ({
    type: CartType.CheckdCartItem,
    payload: [id, shopId, checked]
})

const checkedAllAction = (checked) => ({
    type: CartType.CheckdAllCartItem,
    payload: checked,
})

export const deleteItemAction = (cartIds) => async dispatch => {
    let res = await api.deleteItemFromCart(cartIds)
    console.log(res)
    dispatch({
        type: CartType.RemoveFromCart,
        payload: res.data.cartItems,
    })
}

export const deleteAllAction = () => async dispatch => {
    const res = await api.clearCart()
    console.log(res)
    dispatch({
        type: CartType.ClearCart,
        payload: []
    })

}

export const prepareBuyAction = () => ({
    type: CartType.CartBuy,
    payload: []
})

export const addToCartAction = (number, variantId) => async dispatch => {
    const res = await api.addToCart(number, variantId)
    console.log("add chart api: ", res)
    const items = res.data.cartItems
    if (!items) {
        showToast(dispatch)('toast.error', "加入购物车失败, " + res.data.msg)
        return
    }
    dispatch({
        type: CartType.AddToCart,
        payload: items
    })
}

export const cartActions = {
    getAllCartsAction,
    changItemNumberAction,
    addToCartAction,
    deleteItemAction,
    deleteAllAction,
    checkedAllAction,
    prepareBuyAction,
    checkedItem,
    checkShop,
}