import {Box, makeStyles, InputBase, Button, Card, Grid, Avatar, Typography, Fab} from "@material-ui/core"
import React, { useState } from "react"
import {useNavigationBar, viewImage} from "../../utils/bridge"
import SearchIcon from '@material-ui/icons/Search';
import {postDynamicComment, postVideoComment, searchVideo, videoThumbsCancel, videoThumbsUp} from "../../service/goods";
import { GoodsGrid } from 'components';
import {StatusBox} from "../../components/empty";
import FavoriteIcon from "@material-ui/icons/Favorite";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import AddIcon from "@material-ui/icons/Add";
import {useHistory} from "react-router-dom";
import {thumbsCancel, thumbsUp} from "../carefullySelectionList/interface";
import {useToast} from "../../models/toast";
import produce from "immer";
import InputTextDialog from "../../components/inputText";
import {useToggle} from "ahooks";

const useStyle = makeStyles((theme) => ({
    searchBar: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        borderRadius: '25px',
        backgroundColor: '#FFF',
        padding: theme.spacing(0.5),
        margin: '8px 8px',
        width: 'calc(100% - 40px)',
    },
    searchButton: {
        background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
        borderRadius: 15,
        border: 0,
        color: 'white',
        height: 30,
        padding: '0px 10px',
        boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    content: {
        margin: '0 15px',
        '& .MuiGrid-root': {
            fontSize: '12px',
            display: 'inline-flex',
            lineHeight: '42px',
            marginBottom: 0
        },
        '& .MuiSvgIcon-root': {
            fontSize: '18px',
            verticalAlign: '4px'
        },
        '& .MuiGrid-item': {
            display: 'block'
        },
        '& .MuiGrid-item img': {
            borderRadius: '3px'
        },
        '& .MuiImageListItemBar-root': {
            background: 'none',
            height: '26px'
        },
        '& .MuiImageListItemBar-titleWrap': {
            color: '#000000',
            margin: 0
        },
        '& .MuiImageListItemBar-title': {
            fontSize: '11px',
        },
        '&.MuiCard-root': {
            boxShadow: 'none',
            padding: '15px',
            margin: '10px 15px'
        }
    },
    redIcon: {
        color: '#FF0000',
    }
}))

export const VideoSearchPage = (props) => {
    const classes = useStyle()
    const history = useHistory()
    const toast = useToast()

    const [value, setValue] = useState('')
    const [result, setResult] = useState([])

    const [handleThumb, setHandleThumb] = useState([])
    const [open, { toggle }] = useToggle()

    const [selectId, setSelectId] = useState()

    useNavigationBar({ title: '搜索' })

    const thumbsUpFn = async (id) => {
        const isThumb = handleThumb.includes(id)
        const { data } = handleThumb.includes(id) ? await videoThumbsCancel(id) : await videoThumbsUp(id)
        if (data) {
            setHandleThumb(handleThumb.includes(id) ? handleThumb?.filter(el => el !== id) : [...handleThumb, id])
            toast.showToast("toast.success", "操作成功")
            setResult(produce(draft => {
                if (isThumb){
                    draft.filter(el => el.id === id)[0].thumbs-=1
                }else{
                    draft.filter(el => el.id === id)[0].thumbs+=1
                }
            }))
            //refresh()
        }
    }

    const onSendComment = async (text) => {
        toggle()
        const { data } = await postVideoComment(selectId, text)
        if (data) {
            // 发表评论成功。
            setResult(produce(draft => {
                draft.filter(el => el.id === selectId)[0].comments+=1
            }))
        }
    }

    const search = async () => {
        const { data } = await searchVideo(value)
        setResult(data)
    }

    const onTextChange = (e) => {
        console.log(e.target.value);
        setValue(e.target.value)
    }

    return <Box>
        <Box className={classes.searchBar} >
            <SearchIcon style={{ color: '#2584F7', marginLeft: '8px' }} />
            <InputBase
                className={classes.input}
                placeholder="搜索更多精彩"
                onChange={onTextChange}
                inputProps={{ 'aria-label': 'search goods' }}
            />
            <Button variant='contained' className={classes.searchButton} onClick={search}>搜索</Button>
        </Box>
        {/*<GoodsGrid goodList={result} />*/}

        <Box>

                <Box style={{ paddingBottom: '50px' }}>
                    {result?.map((el,index) => (
                        <Card className={classes.content}>
                            <Grid container style={{ marginBottom: '5px' }}>
                                <Avatar alt="Remy Sharp" src={el?.firstImg} style={{ width: '36px', height: '36px', marginRight: '5px' }} />
                                {el?.nickname}
                            </Grid>
                            <Typography style={{ fontSize: '13px', color: '#474747', lineHeight: '20px', marginBottom: '10px', display: '-webkit-box', WebkitLineClamp: 4, lineClamp: 4, WebkitBoxOrient: 'vertical', overflow: 'hidden' }} onClick={() => history.push(`/main/carefully_detail/${el?.id}`)}>
                                {el?.textContent}
                            </Typography>
                            <Grid container spacing={2} onClick={() => history.push(`app://video?key=${value}&index=${index}`)}>

                                {
                                    <Grid item xs={6}>
                                        <img
                                            src={`${el?.fileContent+'.jpg'}`}
                                            srcSet={`${el?.fileContent+'.jpg'}`}
                                            alt={''}
                                            loading="lazy"
                                            width={'100%'}
                                        />
                                        {/*<video height={220} width='100%' style={{ objectFit: 'cover', width: '100%' }} controls controlsList='nodownload  noremoteplayback' disablePictureInPicture>
                                            <source src={el?.fileContent}/>
                                        </video>*/}
                                        {/*<Typography style={{ fontSize: '12px', marginTop: '-8px', display: '-webkit-box', WebkitLineClamp: 1, lineClamp: 1, WebkitBoxOrient: 'vertical', overflow: 'hidden' }}>{item?.name}</Typography>*/}
                                    </Grid>
                                }
                            </Grid>

                            <Grid container style={{ margin: 0, height: '28px' }}>

                                <Grid xs={6} item>
                                    <text
                                        onClick={() => thumbsUpFn(el?.id)}>
                                        {handleThumb.includes(el?.id)
                                            ? <FavoriteIcon width={18} height={18} className={classes.redIcon} />
                                            : <FavoriteBorderIcon width={18} height={18} />
                                        }
                                        <text
                                            style={{
                                                fontSize: '14px',
                                                lineHeight: '14px',
                                                verticalAlign: '8px',
                                                padding: '8px'
                                            }}>
                                            {el?.thumbs??0}
                                        </text>
                                    </text>

                                    <text onClick={() => {
                                        setSelectId(el?.id)
                                        toggle()
                                    }} >
                                        <img src='https://202108251114.oss-cn-shanghai.aliyuncs.com/file/2891164023253184345158.000.png' alt='' width='25' height='25' />

                                        <text
                                            style={{
                                                fontSize: '14px',
                                                lineHeight: '14px',
                                                verticalAlign: '8px',
                                                color: '#585858'
                                            }}>{el?.comments??0}</text>
                                    </text>

                                </Grid>

                            </Grid>
                        </Card>
                    ))}

                </Box>
        </Box>
        <InputTextDialog
            open={open}
            title="输入评论"
            multiLine={true}
            onCancel={() => toggle()}
            onFinish={onSendComment}
        />
    </Box >

}