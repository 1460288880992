import { CouponsItem } from "./welfareSpace"
import { useNavigationBar } from 'utils/bridge'
import { getAllCoupons } from 'service/goods'
import { StatusBoxPro } from "../../components/empty"
import { useCallback } from "react"

const CouponListPage = props => {
    useNavigationBar({ title: '优惠券' })
    const body = useCallback((data) =>
        data?.map(item => {
            return <CouponsItem key={item.id} coupon={item} />
            })
        , [])
    return <StatusBoxPro
        request={() => getAllCoupons(1)}
        component={body}
    />
}

export default CouponListPage