export const loadState = () => {
    try {
        const serializedState = localStorage.getItem('state');
        if (serializedState === null) {
            return undefined;
        }
        const shareState = JSON.parse(serializedState);
        const accountState = JSON.parse(localStorage.getItem('account')) ?? {}
        return {
            ...shareState,
            account: accountState
        }
    } catch (err) {
        console.warn(err);
        return undefined;
    }
}

export const saveState = (state) => {
    const { toast, goodsDetail, category, account, order, ...shareState } = state
    try {
        const serializedState = JSON.stringify(shareState);
        localStorage.setItem('state', serializedState);
        const accountState = JSON.stringify(account)
        localStorage.setItem('account', accountState);
    } catch (err) {
        console.warn(err);
        // ignore write errors
    }
};