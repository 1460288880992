import { useState } from 'react'
import { Box, Avatar, Card, Grid, makeStyles, Typography, Fab } from '@material-ui/core'
import { useAppHistory, useNavigationBar } from "../../utils/bridge"
import { StatusBox } from "../../components/empty"
import { selectList, thumbsUp, thumbsCancel } from './interface'
import AddIcon from '@material-ui/icons/Add'
import FavoriteIcon from '@material-ui/icons/Favorite'
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder'
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import { useToast } from "../../models/toast"
import { useRequest } from "ahooks"

const useStyles = makeStyles(() => ({

  content: {
    margin: '0 15px',
    '& .MuiGrid-root': {
      fontSize: '12px',
      display: 'inline-flex',
      lineHeight: '42px',
      marginBottom: 0
    },
    '& .MuiSvgIcon-root': {
      fontSize: '18px',
      verticalAlign: '4px'
    },
    '& .MuiGrid-item': {
      display: 'block'
    },
    '& .MuiGrid-item img': {
      borderRadius: '3px'
    },
    '& .MuiImageListItemBar-root': {
      background: 'none',
      height: '26px'
    },
    '& .MuiImageListItemBar-titleWrap': {
      color: '#000000',
      margin: 0
    },
    '& .MuiImageListItemBar-title': {
      fontSize: '11px',
    },
    '&.MuiCard-root': {
      boxShadow: 'none',
      padding: '15px',
      margin: '10px 15px'
    }
  },
  redIcon: {
    color: '#FF0000',
  },
  fab: {
    position: 'fixed',
    bottom: '80px',
    right: '20px',
  }
}))

export const SelectList = () => {
  const classes = useStyles()
  const toast = useToast()
  const history = useAppHistory()
  const [handleThumb, setHandleThumb] = useState([])

  useNavigationBar({
    title: '精选清单',
  })

  // 点赞
  // 目前点赞功能仅支持一个id用户的所有照片
  const thumbsUpFn = async (id) => {
    const { data } = handleThumb.includes(id) ? await thumbsCancel(id) : await thumbsUp(id)
    if (data) {
      setHandleThumb(handleThumb.includes(id) ? handleThumb?.filter(el => el !== id) : [...handleThumb, id])
      toast.showToast("toast.success", "操作成功")
      refresh()
    }
  }

  /**
   * 获取精选订单列表数据
   */
  const { data, loading, error, refresh } = useRequest(() => selectList(), {
    formatResult: res => res.data.data
  })

  return (
    <Box>
      <StatusBox
        error={error}
        empty={data}
        loading={loading}
      >
        <Box style={{ paddingBottom: '50px' }}>
          {data?.map(el => (
            <Card className={classes.content}>
              <Grid container style={{ marginBottom: '5px' }}>
                <Avatar alt="Remy Sharp" src={el?.avatar} style={{ width: '36px', height: '36px', marginRight: '5px' }} />
                {el?.nickname}
              </Grid>
              <Typography style={{ fontSize: '13px', color: '#474747', lineHeight: '20px', marginBottom: '10px', display: '-webkit-box', WebkitLineClamp: 4, lineClamp: 4, WebkitBoxOrient: 'vertical', overflow: 'hidden' }} onClick={() => history.push(`/carefully_detail/${el?.id}`)}>
                {el?.text}
              </Typography>
              <Grid container spacing={2} onClick={() => history.push(`/carefully_detail/${el?.id}`)}>
                {(el?.json ? (Array.isArray(JSON.parse(el?.json)) ? JSON.parse(el?.json) : [JSON.parse(el?.json)]) : [])?.slice(0, 3)?.map((item, index) => (
                  <Grid item xs={4} key={index} >
                    <img
                      src={`${item?.thumb}?w=200&h=200&fit=crop&auto=format`}
                      srcSet={`${item?.thumb}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                      alt={''}
                      loading="lazy"
                      width='100%'
                      height='100px'
                    />
                    <Typography style={{ fontSize: '12px', marginTop: '-8px', display: '-webkit-box', WebkitLineClamp: 1, lineClamp: 1, WebkitBoxOrient: 'vertical', overflow: 'hidden' }}>{item?.name}</Typography>
                  </Grid>
                ))}
              </Grid>

              <Grid container style={{ margin: 0, height: '28px' }}>

                <Grid xs={6} item>
                  <text
                    onClick={() => thumbsUpFn(el?.id)}>
                    {handleThumb.includes(el?.id)
                      ? <FavoriteIcon width={18} height={18} className={classes.redIcon} />
                      : <FavoriteBorderIcon width={18} height={18} />
                    }
                    <text
                      style={{
                        fontSize: '14px',
                        lineHeight: '14px',
                        verticalAlign: '8px',
                        padding: '8px'
                      }}>
                      {el?.thumbs}
                    </text>
                  </text>

                  <text>
                    <img src='https://202108251114.oss-cn-shanghai.aliyuncs.com/file/2891164023253184345158.000.png' alt='' width='25' height='25' />

                    <text
                      style={{
                        fontSize: '14px',
                        lineHeight: '14px',
                        verticalAlign: '8px',
                        color: '#585858'
                      }}>{el?.comments}</text>
                  </text>

                </Grid>

                <Grid xs={6} item style={{ textAlign: 'right', display: 'block' }}>
                  <KeyboardBackspaceIcon onClick={() => history.push(`/carefully_detail/${el?.id}`)} style={{ color: '#D0035C', transform: 'rotate(180deg)', fontSize: '26px' }} />
                </Grid>
              </Grid>
            </Card>
          ))}

        </Box>

      </StatusBox>
      <Fab color="secondary" aria-label="add" className={classes.fab}
        onClick={() => history.push('/publish_select')}>
        <AddIcon style={{ fontSize: '38px' }} />
      </Fab>
    </Box>
  )
}