import { useEffect, useState } from "react"
import { GoodsGrid } from 'components';
import { getProductList } from "service/goods";
import {useNavigationBar} from "../../utils/bridge";
import { StatusBoxPro } from "../../components/empty";

export const ServiceList = ({ match, location }) => {
    useNavigationBar({title:'服务列表'})
    const { params: { id } } = match

    return <StatusBoxPro
        request={() => getProductList(id)}
        emptyTitle="暂无服务，敬请期待"
        component={result => <GoodsGrid goodList={result} />}
    />
}