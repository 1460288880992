import Avatar from "@material-ui/core/Avatar";
import Backdrop from '@material-ui/core/Backdrop';
import { Box, Button, Paper, Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core"
import { makeStyles } from '@material-ui/core/styles';
import { useMemo, useState } from "react";
import { NumberInput, RadioChip } from "../components/base";
import { SimpleCartBar } from "../components/cartToolBar";
import { priceCast } from '../utils/price'
import { useHistory } from 'react-router-dom'
import * as api from '../service/goods'
import { toast } from "../utils/bridge";
import { useAccount } from "../utils/hooks";

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        width: '100%',
        justifyContent: 'flex-end',
    },
    card: {
        borderRadius: '12px 12px 0 0 ',
        width: '100%',
    },
    productIcon: {
        width: theme.spacing(7),
        height: theme.spacing(7),
    }
}));

const ChooseVersionPanel = ({
    open,
    closeChoosePanel,
    current, variantlist,
    addToCart,
    setVariant }) => {
    const classes = useStyles();
    const variant = variantlist[0]
    // 数量
    const [number, setNumber] = useState(1)
    const [color, setColor] = useState(variant.color)
    const [model, setModel] = useState(variant.model)
    // 提示绑定手机号
    const [showBindPhone, setShowBindPhone] = useState(false)
    // color and variant
    const history = useHistory()
    const { myself } = useAccount()

    const hadBindPhone = useMemo(() => {
        if (myself?.username) {
            return myself.username.match(/^[1][3,4,5,6,7,8,9]\d{9}$/)
        }
        return false
    }, [myself])

    const cart = async () => {
        if (!hadBindPhone) {
            setShowBindPhone(true)
            return
        }
        if (addToCart) {
            addToCart(number, current.id)
        } else {
            await api.addToCart(number, current.id)
        }
        closeChoosePanel()
        toast("已加入购物车")
    }

    const buy = () => {
        if (!hadBindPhone) {
            setShowBindPhone(true)
            return
        }
        const variant = variantlist.find(e => {
            return e.color === color && e.model === model
        })
        if (variant) {
            closeChoosePanel()
            history.push(`/submit?type=goods&amount=${number}&store=${variant.storeId}&variant=${variant.id}`)
        }
    }
    const gotoBindPhone = () => {
        setShowBindPhone(false)
        history.push('/change_phone')
    }

    const handleChooseColor = (value) => {
        setColor(value)
        select(value, model)
    }
    const handChooseModel = (value) => {
        setModel(value)
        select(color, value)
    }
    const select = (color, model) => {
        const variant = variantlist.find(e => {
            return e.color === color && e.model === model
        })
        if (variant) {
            setVariant(variant)
        }
    }

    const colors = [...new Set(variantlist.map(i => i.color).filter(i => i !== null))]
    const models = [...new Set(variantlist.map(i => i.model).filter(i => i !== null))]
    // debugger
    const modelDisplay = models.length === 0 ? 'none' : 'flex'
    const colorDisplay = colors.length === 0 ? 'none' : 'flex'

    return (<Backdrop open={open} onClick={closeChoosePanel} className={classes.backdrop}>
        <Paper width={1} className={classes.card} onClick={(e) => e.stopPropagation()} >
            <Box m={2} display='flex' flexDirection='row' alignItems='center'>
                <Avatar className={classes.productIcon} variant='rounded' src={current.thumb} />
                <Box p={1} color='red'>¥{priceCast(current.price)}</Box>
            </Box>
            <Box m={2} display={colorDisplay} className='item-title-small'>颜色分类</Box>
            <Box mx={2} display={colorDisplay} alignItems='center' flexWrap='wrap'>
                {colors.map(item =>
                    <RadioChip value={item} key={item} checked={item === color} label={item} onChange={handleChooseColor} />
                )}
            </Box>
            <Box mx={2} mt={2} mb={1} display={modelDisplay} className='item-title-small'>型号</Box>
            <Box mx={2} display={modelDisplay} alignItems='center' flexWrap='wrap'>
                {models.map(item =>
                    <RadioChip value={item} key={item} checked={item === model} label={item} onChange={handChooseModel} />
                )}
            </Box>
            <Box margin='8px 16px 24px 16px' display='flex' flexDirection='row' alignItems='center'>
                <Box className='item-title-small'>数量</Box>
                <Box flex='1' />
                <NumberInput onChange={(number) => setNumber(number)} />
            </Box>

            <SimpleCartBar cart={cart} buy={buy} />
        </Paper>
        <Dialog open={showBindPhone} onClose={() => setShowBindPhone(false)}  >
            <DialogTitle>绑定手机号</DialogTitle>
            <DialogContent>
                当前账号尚未绑定手机号，为了保证购物过程能够及时通知您，请绑定手机号以后操作。
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setShowBindPhone(false)} color="primary">
                    取消
                </Button>
                <Button onClick={gotoBindPhone} color="primary" autoFocus>
                    确定
                </Button>
            </DialogActions>
        </Dialog>
    </Backdrop>)
}

export default ChooseVersionPanel;